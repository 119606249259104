import React from 'react';
import styles from './customer.module.css';
import { Tooltip} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {getDateFromString} from './../../mixins/DateFunctions'
import EditIcon from '@material-ui/icons/Edit';


                     
                        
export const editableCell = (companyName, companyId,attrVal, aditionalValue, log,headCell, handleOpenEditable,setEditableColumn ) => {
    
    const isEditable = headCell.is_editable;
    const label = headCell.label;
    const columnId = headCell.id;
    const category = headCell.category;

    if (!isEditable ) {
      return typeof(attrVal)==="number" ? (<>{attrVal.toLocaleString()}</>) : (<>{attrVal} </>) 
    } else {
      let tooltipText = <>
                        <div>Last Edit:  {(log.length > 0? getDateFromString(log[0].timestamp) : '-') } </div>
                        <div> Type: { (log.length===0? 'Auto Update' : log[0].user_id === '' ? 'Auto Update': 'Manual Edit')}</div>
                        </>
      return (
        <>
          {typeof(attrVal)==="number" ? (<>{attrVal.toLocaleString()}</>) : (<>{attrVal} </>) }
          <div class={styles.overlay}>
              <Tooltip title={tooltipText} placement="top" arrow classes={{ tooltip: styles.customTooltipWidth }}>
                <IconButton component="a" className={styles.overlayButton}
                      onClick={() => {
                         setEditableColumn(prepareEditableData( (typeof(attrVal)==="number"? attrVal : aditionalValue), 
                         (typeof(attrVal)==="number"? aditionalValue : attrVal),label,columnId,category, companyName,companyId));
                         handleOpenEditable();
                      }
                }
                >
                <EditIcon className={styles.overlayIcon} />
                </IconButton>
              </Tooltip>
          </div>
          
        </>
      )
    }
  }
  
export const prepareEditableData = (value,normalized,label,columnId, category ,companyName,companyId ) => {
    return { 
      title: label,
      companyName: companyName,
      companyId: companyId,
      columnId: columnId,
      value: value,
      normalized: normalized,
      category: category
    }
  }

export const editableIndicator = (companyName, companyId,attrVal, aditionalValue, log,headCell,handleOpenEditable,setEditableColumn) => {
  const label = headCell.label;
  const columnId = headCell.id;
  const category = headCell.category;
  

  let tooltipText = <>
          <div>Last Edit:  {(log.length > 0? getDateFromString(log[0].timestamp) : '-') } </div>
          <div> Type: { (log.length===0? 'Auto Update' : log[0].user_id === '' ? 'Auto Update': 'Manual Edit')}</div>
          </>
     
  return (
    <>
       <div class={styles.overlay}>
          <Tooltip title={tooltipText} placement="top" arrow classes={{ tooltip: styles.customTooltipWidth }}>
            <IconButton component="a" className={styles.overlayButton}
                  onClick={() => {
                     setEditableColumn(prepareEditableData( (typeof(attrVal)==="number"? attrVal : aditionalValue), 
                     (typeof(attrVal)==="number"? aditionalValue : attrVal),label,columnId,category, companyName,companyId));
                     handleOpenEditable();
                  }
            }
            >
            <EditIcon className={styles.overlayIcon} />
            </IconButton>
          </Tooltip>
      </div>
      
    </>
  )

}