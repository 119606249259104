import React from 'react';
import {Box, Container, Link, Tooltip, Typography,} from '@material-ui/core';

import NavBar from '../NavBar';
import LoginForm from './LoginForm';
import {methodIcons} from './account.constants';
import {accountStyles} from './account.theme';

const Login = () => {
  const classes = accountStyles();
  return (
      <div>
      <NavBar />
      <div className={classes.banner} style={{display:"none"}}>
        <Container maxWidth="md">
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
          >
            <Box
              alignItems="center"
              display="flex"
            >
              <Tooltip title="">
                <img
                  alt="Auth0"
                  className={classes.logo}
                  src={methodIcons['LOGO']}
                />
              </Tooltip>
            </Box>
          </Box>
        </Container>
      </div>
      <Container
        className={classes.cardContainer}
        maxWidth="sm"
      >
          <div >
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              mb={3}
            >
              <div style={{width:"100%"}}>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h3"
                  align="center"
                  style={{color: "#263238", marginBottom:"-10px",
                      fontFamily: "Roboto",
                      fontSize: "24px",
                      letterSpacing: "-0.06px",
                      lineHeight: "28px"}}
                >
                  Login - Staging
                </Typography>
              </div>
            </Box>
            <LoginForm />
            <Box my={3}>
            </Box>
            <Container>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{marginLeft: "45px"}}
            >
              Don't have an account?
              {' '}

              <Link
              variant="body2"
              href = "/signup"
              underline = 'always'
              color="textSecondary"
            >
            Sign Up
            </Link>
            </Typography>
          </Container>
          </div>
      </Container>
      </div>
  );
};

export default Login;
