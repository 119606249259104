import React from 'react';
import { Box, makeStyles, Typography, Dialog, } from '@material-ui/core';

const useStyles = makeStyles({
    dialogPaper: {
        maxWidth: '496px',
        maxHeight: '408px',
    },
});

const InvitesSentDialog = props => {
    const classes = useStyles();

    return (
        <Dialog open={props.openDialog} onClose={props.closeDialogFun} classes={{ paper: classes.dialogPaper }}>
            <Box>
                <img
                    src={require('./../../assets/imagesinvites-sent.png')}
                    alt="Invites sent"
                    style={{ width: '496px', height: '280px' }}
                />
            </Box>
            <Box p={2} justifyContent="center">
                <Typography variant="h3" align="center" color="textPrimary">Invites Sent</Typography>
            </Box>
            <Box paddingBottom={4} justifyContent="center">
                <Typography variant="subtitle1" align="center" color="textSecondary">We sent email invites to your team members.</Typography>
            </Box>
        </Dialog>

    );

}
export default InvitesSentDialog;