import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Collapse,
  Grid,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import styles from './customer.module.css';
import CustomerProbToRenew from './CustomerProbToRenew';
import { useStyles } from './customerDataTable.theme';
import HelpIcon from '@material-ui/icons/Help';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import DialogEditableValue from './DialogEditableValue';
import { editableCell } from './DataTableUtils'

const StyledTableCell = withStyles(theme => ({
  head: {
    color: '#546E7A',
  },
  body: {
    color: '#263238',
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: '20px',
    textAlign: 'left',
    border: 'none',
    padding: '8px',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(4n+1)': {
      backgroundColor: '#FAFBFF',
    },
    '&:nth-of-type(4n+2)': {
      backgroundColor: '#FAFBFF',
    },
    '& > *': {
      borderBottom: 'unset',
    },
  },
}))(TableRow);

const tableHead = (headCell, props, classes) => {

  return (
    <TableCell
      className={headCell.id === 'product_name' ? clsx(classes.tableProductHead) : clsx(classes.tableHead)}
      id={headCell.id}
      key={headCell.id}
    >
      <Box display="flex">
        <span style={{ paddingTop: '8px', paddingBottom: '8px' }}>
          {' '}
          {headCell.label}
          {headCell.tooltipBody && (
            <Tooltip title={headCell.tooltipBody} arrow placement="top" classes={{ tooltip: classes.headerTooltip }}>
              <HelpIcon className={classes.iconClasses} />
            </Tooltip>
          )}
        </span>
        <Box flexGrow="1" />
        {headCell.label !== ' ' && (
          <Box display="grid">
            {props.orderBy === headCell.id && props.order === 'asc' ? (
              <ArrowDropUpIcon
                className={classes.cursorPointer}
                onClick={props.createSortHandler(headCell.id, 'asc')}
                color="primary"
              />
            ) : (
              <ArrowDropUpIcon
                className={classes.cursorPointer}
                onClick={props.createSortHandler(headCell.id, 'asc')}
              />
            )}
            {props.orderBy === headCell.id && props.order === 'desc' ? (
              <ArrowDropDownIcon
                className={classes.cursorPointer}
                onClick={props.createSortHandler(headCell.id, 'desc')}
                color="primary"
              />
            ) : (
              <ArrowDropDownIcon
                className={classes.cursorPointer}
                onClick={props.createSortHandler(headCell.id, 'desc')}
              />
            )}
          </Box>
        )}
      </Box>
    </TableCell>
  );

};

const tableCell = (attrCategory, companyName, companyId, attrVal, aditionalValue, log, headCell, handleOpenEditable, setEditableColumn) => {
  if (typeof attrVal !== "undefined" && typeof attrCategory !== "undefined") {
    switch (attrCategory) {
      case 'Low':
        if (headCell.is_support_ticket) {
          return <StyledTableCell className={styles.high}>{editableCell(companyName, companyId, attrVal, aditionalValue, log, headCell, handleOpenEditable, setEditableColumn)}</StyledTableCell>;
        } else {
          return <StyledTableCell className={styles.low}>
            {editableCell(companyName, companyId, attrVal, aditionalValue, log, headCell, handleOpenEditable, setEditableColumn)}</StyledTableCell>
        }
      case 'Medium':
        return <StyledTableCell className={styles.medium}>{editableCell(companyName, companyId, attrVal, aditionalValue, log, headCell, handleOpenEditable, setEditableColumn)}</StyledTableCell>;
      case 'High':
        if (headCell.is_support_ticket) {
          return <StyledTableCell className={styles.low}>{editableCell(companyName, companyId, attrVal, aditionalValue, log, headCell, handleOpenEditable, setEditableColumn)}</StyledTableCell>;
        } else {
          return <StyledTableCell className={styles.high}>{editableCell(companyName, companyId, attrVal, aditionalValue, log, headCell, handleOpenEditable, setEditableColumn)}</StyledTableCell>;
        }
      case 'Does not Exist':
        return <StyledTableCell className={styles.doesNotExist}>{typeof (attrVal) === "number" ? (<>{attrVal.toLocaleString()}</>) : (<>{attrVal} </>)}</StyledTableCell>;
      default:
        return;
    }
  } else {
    return;
  }
};

function CollapsedRow(rowProps) {
  const classes = useStyles();

  return (
    <Box ml={8.75} mt={2} mb={2} id={rowProps.customer._id}>
      {rowProps.collapsedDetails.map((row, i) => (
        <span key={rowProps.customer._id + i}>
          {rowProps.customer.hasOwnProperty(row.id) && rowProps.customer[row.id] !== 'Exclude' && (
            <Grid container item xs={12} style={{ marginBottom: '8px' }} id={i}>
              <Grid item xs={2} className={classes.customerAttribute}>
                {row.label}
              </Grid>

              {typeof (row.id === 'number') ? (
                <Grid item xs={9} className={classes.customerAttributeValue}>
                  {rowProps.customer[row.id].toLocaleString('en-US', { maximumFractionDigits: 10 })}
                </Grid>
              ) : (
                <Grid item xs={9} className={classes.customerAttributeValue}>
                  {rowProps.customer[row.id]}
                </Grid>
              )}
            </Grid>
          )}
        </span>
      ))}
    </Box>
  );
}

function Row(rowProps) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);



  return (
    <React.Fragment key={rowProps.customer._id}>
      <StyledTableRow key={rowProps.customer._id}>
        {rowProps.collapsedDetails != null && rowProps.collapsedDetails.length > 0 ? (
          <StyledTableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>
        ) : ''}
        {rowProps.customer.client_status === 'Churned' ? (
          <StyledTableCell>
            <Tooltip title="Churned" arrow placement="top">
              <span className={classes.redCircle}></span>
            </Tooltip>
          </StyledTableCell>
        ) : (
          <StyledTableCell>
            <Tooltip title={rowProps.customer.client_status ? rowProps.customer.client_status : ''} arrow placement="top">
              <span className={classes.greenCircle}></span>
            </Tooltip>
          </StyledTableCell>
        )}

        {rowProps.customer.hasOwnProperty('customer360_link') ? (
          <StyledTableCell style={{ maxWidth: '350px' }}>
            <Tooltip title="Link to Customer 360 profile" arrow placement="top">
              <a
                underline="always"
                target="_blank"
                rel="noopener noreferrer"
                href={rowProps.customer.customer360_link}
                style={{ color: '#263238' }}
              >
                {rowProps.customer.company_name}
              </a>
            </Tooltip>
          </StyledTableCell>
        ) : (
          rowProps.dataColumns.findIndex(col => col.id === 'company_name') !== -1) ? <StyledTableCell style={{ maxWidth: '350px' }}>{rowProps.customer.company_name}</StyledTableCell> : ''
        }

        {rowProps.dataColumns.map(headCell => (
          <>{headCell.hasOwnProperty('category') ? (
            <>{
              tableCell(rowProps.customer[headCell.category], rowProps.customer.company_name, rowProps.customer.account_id, rowProps.customer[headCell.id],
                rowProps.customer[headCell.id.replace('_value', '')],
                rowProps.customer.logs !== undefined ? rowProps.customer.logs.filter(x => x.field_id === headCell.id) : [],
                headCell,
                rowProps.handleOpenEditable, rowProps.setEditableColumn)}
            </>
          ) : (
            (headCell.label !== ' ' && headCell.id !== 'company_name' && headCell.id !== 'probability_to_renew') ? <StyledTableCell>{rowProps.customer[headCell.id]}</StyledTableCell> : ''
          )}</>
        ))}

        <StyledTableCell>
          <CustomerProbToRenew customer={rowProps.customer} />
        </StyledTableCell>
        <StyledTableCell style={{ width: '10px', padding: '0px' }}>
          <IconButton
            aria-label="delete"
            className={classes.chevron}
            id={rowProps.customer._id}
            onClick={() => rowProps.props.showMoreInfo(rowProps.customer)}
          >
            <ChevronRightIcon fontSize="inherit" />
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>

      <StyledTableRow>
        <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={15}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <CollapsedRow id={rowProps.customer._id} customer={rowProps.customer} collapsedDetails={rowProps.collapsedDetails} />
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

const DataTableAdvancedView = props => {
  const classes = useStyles();
  const [openEditable, setOpenEditable] = useState(false);
  const [editableColumn, setEditableColumn] = useState({});

  const handleOpenEditable = () => {
    setOpenEditable(true);
  }

  const updateData = (editableColumn) => {
    let customers = [...props.customerTableData];
    let index = customers.findIndex(x => editableColumn.companyName === x.company_name);
    if (index > -1) {
      let customerToReplace = customers[index];
      customerToReplace[editableColumn.columnId] = editableColumn.value;
      customerToReplace[editableColumn.columnId + '_category'] = editableColumn.normalized;
      customerToReplace[editableColumn.columnId.replace('_value', '')] = editableColumn.normalized;
      customerToReplace['probability_to_renew'] = editableColumn.new_prediction;

      let indexLog = customerToReplace['logs'].findIndex(x => editableColumn.log['field_id'] === x.field_id)
      if (indexLog > -1)
        customerToReplace['logs'][indexLog] = editableColumn.log;
      else
        customerToReplace['logs'].push(editableColumn.log);
      customers[index] = customerToReplace;

      props.setCustomers(customers);
    }
  }

  return (
    <>
      <TableContainer id="dataSimTable" className={styles.dataSimTable}>
        <Table stickyHeader>
          <TableHead>
            <TableRow id="dataSimTableHead" style={{ height: '60px' }}>
              {props.dataColumns.map(headCell => (
                <>
                { (headCell.id !=='expand' || (headCell.id === 'expand' && props.collapsedDetails != null && props.collapsedDetails.length > 0)) && (
                  tableHead(headCell, props, classes)
                )}
                </>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.customerTableData.map(customer => (
              <Row key={customer._id} id={customer._id} customer={customer} props={props} dataColumns={props.dataColumns}
                collapsedDetails={props.collapsedDetails} handleOpenEditable={handleOpenEditable} setEditableColumn={setEditableColumn} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DialogEditableValue
        open={openEditable}
        setOpenEditable={setOpenEditable}
        editableColumn={editableColumn}
        updateData={updateData}
      >
      </DialogEditableValue>
    </>
  );
};

export default DataTableAdvancedView;
