  import axios from 'axios';
  import { attachToken } from '../../utils/auth';
  import {UPDATE_DASHBOARD_VALUES} from '../../constants/ApiEndpoints'

export function getDataTable(callback, errorcallback) {
  return axios(
    attachToken({
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/table',
      data: {},
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function getDataTableConfig(callback, errorcallback) {
  return axios(
    attachToken({
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/table/config',
      data: {},
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function dataTableSearch(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/table/search',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function getTrendData(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/table/trend',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function getHealthTrendData(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/table/health_trend',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function postNPSFeedback(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/user/product_feedback',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function getContactData(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/table/contact',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function updateContactData(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/table/update_contact',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function deleteContact(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'delete',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/table/delete_contact',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function getCustomerDetails(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/table/get_customer_info',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function getContactUserRoles(callback, errorcallback) {
  return axios(
    attachToken({
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/table/contact_user_role',
      data: {},
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function updateDashboadTable(requestData,callback, errorcallback) {
  
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + UPDATE_DASHBOARD_VALUES,
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}
