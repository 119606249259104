import React from 'react';
import SecondaryNav from './../../SecondaryNav';
import { Box, Button, Container, Typography } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import { useHistory } from 'react-router-dom';
import styles from './datasources.module.css';

const DataExportFail = () => {
  const history = useHistory();
  const backToActivation = () => {
    // TO DO: Update code to redirect to exact integration page.
    // history.push('/integrations/salesforce', { params: 'SalesforceLogIn' });
    history.push('/integrations');
  };
  return (
    <div>
      <SecondaryNav />

      <Container className={styles.cardContainer}>
        <Container align="center" style={{ width: 420 }}>
          <CardMedia className={styles.mediaExport} image={require('./../../../assets/uploadfail.png')} />
        </Container>
        <Box mt={2} />
        <Container style={{ width: 420 }}>
          <Typography color="textPrimary" gutterBottom variant="h4" align="center">
            Something went wrong...
          </Typography>
        </Container>
        <Box align="center">
          <Typography variant="body2" color="textSecondary" align="left">
            Something went wrong with authentication. Please recheck your credentials and security token and try again.
          </Typography>
        </Box>

        <Box mt={2} />

        <Container style={{ width: 420 }} align="center">
          <Button
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            align="center"
            onClick={backToActivation}
          >
            BACK TO ACTIVATION
          </Button>
        </Container>
      </Container>
    </div>
  );
};

export default DataExportFail;
