import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, SvgIcon, Button, FormControl, Select } from '@material-ui/core/';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { ruleStyles } from './../rule.theme';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import cloneDeep from 'lodash/cloneDeep';

const ActionOptions = ({ ...props }) => {
  const styles = ruleStyles();
  let tempColDetails =
    props.columnsList && props.columnsList[0] ? props.columnsList[0] : { id: 0, values: ['empty'], label: 'Empty' };
  const [selectedColumn, setSelectedColumn] = useState(tempColDetails.id);
  const [isAddNew, setIsAddNew] = useState(false);
  const [columnValues, setColumnValues] = useState(tempColDetails.values);
  const [selectedColVal, setSelectedColVal] = useState(tempColDetails.values[0]);

  useEffect(() => {
    tempColDetails =
      props.columnsList && props.columnsList[0] ? props.columnsList[0] : { id: 0, values: ['empty'], label: 'Empty' };
    setSelectedColumn(tempColDetails.id);
    setColumnValues(tempColDetails.values);
    setSelectedColVal(tempColDetails.values[0]);
  }, [props.columnsList]);
  const handleColumnChange = e => {
    setSelectedColumn(e.target.value);
    let tempArr = props.columnsList.filter(item => item.id === e.target.value);
    setColumnValues(tempArr[0].values);
    setSelectedColVal(tempArr[0].values[0]);
  };

  const handleColValChange = e => {
    setSelectedColVal(e.target.value);
  };

  const handleAdd = () => {
    let tempArr = cloneDeep(props.selectedColumns);
    tempArr.push({
      _id: props.lastIndex,
      column: selectedColumn,
      value: selectedColVal
    })
    props.setLastIndex(props.lastIndex+1);
    props.setSelectedColumns(tempArr);
    setIsAddNew(false);
  };
  return (
    <Box>
      {isAddNew ? (
        <>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1" color="textPrimary">
              Add Action
            </Typography>
            <Box flexGrow="1" />

            <IconButton onClick={() => setIsAddNew(false)}>
              <SvgIcon>
                <CloseIcon />
              </SvgIcon>
            </IconButton>
          </Box>

          <Box p={1.5} display="flex" alignItems="center" border={1} className={styles.addNewBlock}>
            <Typography variant="body2" color="textPrimary">
              Change &nbsp;
            </Typography>
            <FormControl variant="outlined" size="small">
              <Select native value={selectedColumn} onChange={e => handleColumnChange(e)} style={{ fontSize: '14px' }}>
                {props.columnsList.map(column => (
                  <option value={column.id} key={column.id} id={column.id}>
                    {column.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Typography variant="body2" color="textPrimary">
             &nbsp; to &nbsp;
            </Typography>
            <FormControl variant="outlined" size="small">
              <Select native value={selectedColVal} onChange={e => handleColValChange(e)} style={{ fontSize: '14px' }}>
                {columnValues.map(columnVal => (
                  <option value={columnVal} key={columnVal} id={columnVal}>
                    {columnVal}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Box flexGrow="1" />
            <Button variant="contained" color="primary" onClick={handleAdd}>
              Add
            </Button>
          </Box>
        </>
      ) : (
        <Box mt={1}>
          <Button
            variant="text"
            className={styles.addNewBtn}
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => setIsAddNew(true)}
          >
            Add Action
          </Button>
        </Box>
      )}
    </Box>
  );
};

const Action = ({ ...props }) => {
  const [isEditing, setIsEditing] = useState(false);
  const styles = ruleStyles();
  const [selectedColumn, setSelectedColumn] = useState(props.info);

  let tempColDetails =
    props.columnsList.filter((col, index) =>
      col.id === props.info.column
    );
  const [newColumn, setNewColumn] = useState(tempColDetails && tempColDetails[0] ? tempColDetails[0].id: '');
  const [columnValues, setColumnValues] = useState(tempColDetails && tempColDetails[0] ? tempColDetails[0].values: []);
  const [selectedColVal, setSelectedColVal] = useState(props.info.value ? props.info.value : '');

  useEffect(() => {
    tempColDetails =
      props.columnsList.filter((col, index) =>
        col.id === props.info.column
      );
    setNewColumn(tempColDetails[0].id);
    setColumnValues(tempColDetails[0].values);
    setSelectedColVal(props.info.value);
  }, [props.columnsList]);

  const handleCancel = () => {
    tempColDetails =
      props.columnsList.filter((col, index) =>
        col.id === props.info.column
      );
      setNewColumn(tempColDetails[0].id);
      setColumnValues(tempColDetails[0].values);
      setSelectedColVal(props.info.value);
    setIsEditing(false);
  };

  const handleColumnChange = e => {
    setNewColumn(e.target.value);
    let tempArr = props.columnsList.filter(item => item.id === e.target.value);
    setColumnValues(tempArr[0].values);
    setSelectedColVal(tempArr[0].values[0]);
  };
  const handleColValChange = e => {
    setSelectedColVal(e.target.value);
  };

  const handleSave = () => {
    let tempArr = cloneDeep(props.selectedColumns);
    for (var i=0; i< tempArr.length; i++){
      if(tempArr[i]._id === props.info._id){
        tempArr[i].column = newColumn;
        tempArr[i].value = selectedColVal;
        setSelectedColumn({
          _id: tempArr[i]._id,
          column: newColumn,
          value : selectedColVal
        })
      }
    }
    props.setSelectedColumns(tempArr);
    setIsEditing(false);
  };

  const handleDelete = () => {
    let tempSelectedColumns = [...props.selectedColumns];
    tempSelectedColumns.splice(tempSelectedColumns.findIndex(item => item._id === selectedColumn._id), 1);
    props.setSelectedColumns(tempSelectedColumns);
  };

  return (
    <>
      {isEditing ? (
        <>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1" color="textPrimary">
              Edit Action
            </Typography>
            <Box flexGrow="1" />
            <IconButton onClick={handleCancel}>
              <SvgIcon>
                <CloseIcon />
              </SvgIcon>
            </IconButton>
          </Box>
          <Box mt={1} mb={2} p={1.5} display="flex" alignItems="center" border={1} className={styles.addNewBlock}>
            <Typography variant="body2" color="textPrimary">
              Change&nbsp;
            </Typography>
            <FormControl variant="outlined" size="small">
              <Select native value={newColumn} onChange={e => handleColumnChange(e)} style={{ fontSize: '14px' }}>
                {props.columnsList.map(column => (
                  <option value={column.id} key={column.id} id={column.id}>
                    {column.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Typography variant="body2" color="textPrimary">
             &nbsp;to&nbsp;
            </Typography>
            <FormControl variant="outlined" size="small">
              <Select native value={selectedColVal} onChange={e => handleColValChange(e)} style={{ fontSize: '14px' }}>
                {columnValues.map(columnVal => (
                  <option value={columnVal} key={columnVal} id={columnVal}>
                    {columnVal}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Box flexGrow="1" />
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
          </Box>
        </>
      ) : (
        <Box p={1.5} display="flex" alignItems="center" className={styles.addedBlock}>
          <Typography variant="body2" color="textPrimary">
            Change&nbsp;<b>{props.columnsList.filter(item => item.id === selectedColumn.column)[0].label}</b>&nbsp;to&nbsp;<b>{selectedColumn.value}</b>
          </Typography>
          <Box flexGrow="1" />
          <IconButton size="small" aria-label="delete-trigger" onClick={() => setIsEditing(true)}>
            <EditIcon  fontSize="small" />
          </IconButton>
          <IconButton size="small" style={{ marginLeft: '8px', alignItems: 'flex-end' }} onClick={handleDelete}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </>
  );
};

const Actions = ({ ...props }) => {
  let tempLength = props.selectedColumns ? props.selectedColumns.length : 0;
  let tempIndex = tempLength === 0? 1: props.selectedColumns[tempLength -1]._id;
  const [lastIndex, setLastIndex] = useState(tempIndex);


  return (
    <Box mt={3}>
        <Typography variant="subtitle1" color="textPrimary">
          Action
        </Typography>
        {props.selectedColumns.map((column, index) => (
          <Box key={column._id} mt={1} id={column._id}>
            <Action
              info={column}
              columnsList={props.columnsList}
              setSelectedColumns={props.setSelectedColumns}
              index={index}
              selectedColumns={props.selectedColumns}
            />
          </Box>
        ))}
        <ActionOptions
          columnsList={props.columnsList}
          setSelectedColumns={props.setSelectedColumns}
          selectedColumns={props.selectedColumns}
          lastIndex={lastIndex}
          setLastIndex ={setLastIndex}
        />
    </Box>
  );
};
export default Actions;
