import React, { useState } from 'react';
import { Box, Typography, TextField, InputAdornment } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import cloneDeep from 'lodash/cloneDeep';

const CustomAutocomplete = withStyles({
    tag: {
        backgroundColor: '#ECEFF1',
        height: 24,
        position: 'relative',
        zIndex: 0,
        '& .MuiChip-label': {
            color: '#263238',
        },
        '& .MuiChip-deleteIcon': {
            color: '#B0BEC5',
        },
        '&:after': {
            content: '""',
            right: 10,
            top: 6,
            height: 12,
            width: 12,
            position: 'absolute',
            backgroundColor: 'white',
            zIndex: -1,
        },
    },
})(Autocomplete);

const useStyles = makeStyles(theme => ({
    root: {
        width: '550px',
        color: theme.palette.text.secondary,
        height: '100%',
    },
    tabs: {
        '& .MuiTab-wrapper': {
            flexDirection: 'row',
        },
    },
    saveAsNewBtn: {
        color: '#0061FF',
        backgroundColor: 'white !important',
        boxShadow: 'none !important',
    }
}));

const Filters = ({ className, customer, filterOptions, savedFilters, setPermissions, ...props }) => {
    const classes = useStyles();
    const [filters, setFilters] = useState(cloneDeep(savedFilters));
    const filterList = props.dataColumns;

    const selectFilterVal = (event, val, filteredElement) => {
        const tempFilterVal = cloneDeep(filters);

        let tempFilter = tempFilterVal;
        if (tempFilter.hasOwnProperty(filteredElement)) {
            val.length > 0 ? (tempFilter[filteredElement] = val) : delete tempFilter[filteredElement];
        } else {
            tempFilter[filteredElement] = val;
        }
        setFilters(tempFilter);
        setPermissions(tempFilter)
    };    

    const displayFilter = (filterProps, filters, columnLabel) => {
        return (
            <Box mt={2}>
                <CustomAutocomplete
                    multiple
                    id={filterProps.id}
                    options={filterProps.values}
                    onChange={(e, value) => selectFilterVal(e, value, filterProps.id)}
                    getOptionLabel={option => option}
                    value={filters.hasOwnProperty(filterProps.id) ? filters[filterProps.id] : []}
                    filterSelectedOptions
                    renderInput={params => <TextField {...params} variant="outlined" label={columnLabel} />}
                    ChipProps={{
                        root: classes.filterChips,
                    }}
                />
            </Box>
        );
    };

    const handleDateChange = (date, elementId) => {
        let splitVal = elementId.split(':');
        let filteredElementId = splitVal[0];

        const filterVal = filterOptions.find(o => o.id === filteredElementId);
        var dateVal = [filterVal.date_range.start_date, filterVal.date_range.last_date];
        if (filters.hasOwnProperty(filteredElementId)) {
            dateVal = filters[filteredElementId]
        }

        dateVal[splitVal[1]] = date;
        const filterObj = dateVal;

        const tempFilterVal = cloneDeep(filters);
        let tempFilter = tempFilterVal;
        if (dateVal[0] !== null || dateVal[1] !== null) {
            tempFilter[filteredElementId] = filterObj;
        } else {
            delete tempFilter[filteredElementId]
        }


        setFilters(tempFilter);
        setPermissions(tempFilter)
    };

    const formatDate = date => {
        if (date == null) {
            return '';
        }
        return new Intl.DateTimeFormat('en').format(new Date(date));
    }

    const handleRangeFilterChange = event => {
        let tempFilter = cloneDeep(filters);
        let filteredElementId = event.target.id.split(':');        

        if (tempFilter.hasOwnProperty(filteredElementId[0])) {
            let existingFilterVal = tempFilter[filteredElementId[0]];
            if (filteredElementId[1] === 'min') {
                existingFilterVal[0] = event.target.value === '' ? null : parseFloat(event.target.value);
            } else {
                existingFilterVal[1] = event.target.value === '' ? null : parseFloat(event.target.value);
            }
            if (existingFilterVal[0] !== null || existingFilterVal[1] !== null) {
                tempFilter[filteredElementId[0]] = existingFilterVal;
            } else {
                delete tempFilter[filteredElementId[0]]
            }
        } else {
            let tempArr = [];
            if (filteredElementId[1] === 'min') {
                tempArr = [parseFloat(event.target.value), null];
            } else {
                tempArr = [null, parseFloat(event.target.value)];
            }
            tempFilter[filteredElementId[0]] = tempArr;
        }
        
        setFilters(tempFilter);
        setPermissions(tempFilter)
    };

    const dateFilter = (filterProps, simpleViewColumns, columnLabel) => {
        return (
            <Box mt={2}>
                <Typography>{columnLabel}</Typography>
                <Box display="flex" mt={0}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            autoOk={true}
                            disableToolbar
                            inputVariant="outlined"
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id={filterProps.id + ':min'}
                            placeholder={formatDate(filterProps.date_range.start_date)}
                            minDate={filterProps.date_range.start_date}
                            maxDate={filterProps.date_range.last_date}
                            value={filters.hasOwnProperty(filterProps.id) ? filters[filterProps.id][0] : null}
                            onChange={(d) => handleDateChange(d, filterProps.id + ':0')}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>

                    <Typography style={{ alignSelf: 'center', margin: '0 16px 0 16px' }}>to</Typography>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            autoOk={true}
                            disableToolbar
                            inputVariant="outlined"
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id={filterProps.id + ':max'}
                            placeholder={formatDate(filterProps.date_range.last_date)}
                            minDate={filterProps.date_range.start_date}
                            maxDate={filterProps.date_range.last_date}
                            value={filters.hasOwnProperty(filterProps.id) ? filters[filterProps.id][1] : null}
                            onChange={(d) => handleDateChange(d, filterProps.id + ':1')}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Box>
            </Box>
        )
    }

    const rangeFilter = (filterProps, simpleViewColumns, columnLabel) => {
        return (
            <Box mt={2}>
                <Typography>{columnLabel}</Typography>
                <Box display="flex" mt={2}>
                    <TextField
                        id={filterProps.id + ':min'}
                        variant="outlined"
                        onChange={handleRangeFilterChange}
                        placeholder={filterProps.range.min.toString()}
                        label="Min"
                        style={{ width: '160px' }}
                        defaultValue={filters.hasOwnProperty(filterProps.id) ? filters[filterProps.id][0] : null}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">{columnLabel.includes('$') ? '$' : null}</InputAdornment>
                            ),
                        }}
                    />
                    <Typography style={{ alignSelf: 'center', margin: '0 16px 0 16px' }}>to</Typography>
                    <TextField
                        id={filterProps.id + ':max'}
                        variant="outlined"
                        onChange={handleRangeFilterChange}
                        placeholder={filterProps.range.max.toString()}
                        label="Max"
                        style={{ width: '160px' }}
                        defaultValue={filters.hasOwnProperty(filterProps.id) ? filters[filterProps.id][1] : null}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">{columnLabel.includes('$') ? '$' : null}</InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
        );
    };

    const filterRow = (filterName, columnLabel, filterOptions, simpleViewColumns, filters) => {
        const individualFilter = filterOptions.find(o => o.id === filterName);
        return (
            <>
                {columnLabel !== undefined && individualFilter !== undefined && (
                    <>
                        {individualFilter.hasOwnProperty('values') && (
                            <>{displayFilter(individualFilter, filters, columnLabel)}</>
                        )}
                        {individualFilter.hasOwnProperty('range') && (
                            <>{rangeFilter(individualFilter, simpleViewColumns, columnLabel)}</>
                        )}
                        {individualFilter.hasOwnProperty('date_range') && (
                            <>{dateFilter(individualFilter, simpleViewColumns, columnLabel)}</>
                        )}
                    </>
                )}
            </>
        );
    };

    return (
        <Box mt={3} mb={2} key="filters" id="filters">
            <Box key={'client_status'}>
                {filterRow('client_status', 'Customer Status', filterOptions, filterList, filters)}
            </Box>
            {filterList.map((filterName) => (
                <>
                    {filterName !== ' ' && (
                        <Box key={filterName.id}>
                            {filterRow(filterName.id, filterName.label, filterOptions, filterList, filters)}
                        </Box>
                    )}
                </>
            ))}
        </Box>
    );
};

export default Filters;