import React, { useEffect, useState } from 'react';
import { Box, Typography, Container } from '@material-ui/core';
import { getChartData } from '../../ReportsAPI';
import GroupedChart from './GroupedChart';
import CategoricalChart from './CategoricalChart';
import { reportsStyles } from './../../reports.theme';
import ImagePlaceHolder from './../../../customers/ImagePlaceholder';
import { useParams } from 'react-router-dom';

export default function ChartWidget({
  id,
  duration,
  blockConfig,
  recordCount,
  filters,
  className,
  ...rest
}) {
  const styles = reportsStyles();
  const { reportId } = useParams();
  const barConfig = blockConfig.components.filter(item => item.type === 'bar');
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorFlag, setErrorFlag] = useState(false);
  useEffect(() => {
    getChartData({
      block: '',
      metrics: barConfig[0].metrics,
      duration: duration,
      filters: filters,
      report_id: reportId
    }).then(response => {
      if (response.data.data) {
        setChartData(response.data.data);
        setErrorFlag(false);
      } else {
        setErrorFlag(true);
      }
      setIsLoading(false);
    });
  }, [duration, filters]);

  return (
    <>
      <Box pl={2} pt={2}>
        <Typography variant="subtitle1">{barConfig[0].name}</Typography>{' '}
      </Box>
      <Box ml={2} mt={1}>
        <Typography variant="body2" color="textSecondary">
          average for {recordCount} customers
        </Typography>
      </Box>
      <Container maxWidth={false} style={{ paddingLeft: '16px', marginTop: '24px' }}>
        {isLoading ? (
          <div className={styles.chartDataloader}> </div>
        ) : (
          <>
            {errorFlag ? (
              <ImagePlaceHolder
                message={'Something went wrong. Please contact the involve.ai team to get this fixed.'}
                imageUrl={require('./../../../../assets/error-general.png')}
                altText={'Error'}
              />
            ) : (
              <>
                {barConfig[0].metrics[0] !== 'severity_of_support_tickets' &&
                  barConfig[0].metrics[0] !== 'probability_to_renew' && (
                    <GroupedChart fullDataSet={chartData} labels={chartData[0].dates} />
                  )}
                {barConfig[0].metrics[0] === 'severity_of_support_tickets' && (
                  <CategoricalChart trend={chartData[0]} labels={chartData[0].dates} supportTicket={true} />
                )}
                {barConfig[0].metrics[0] === 'probability_to_renew' && (
                  <CategoricalChart trend={chartData[0]} labels={chartData[0].dates} supportTicket={false} />
                )}
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
}
