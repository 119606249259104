import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { Box, Button, Dialog, Slider, Tooltip, TextField, SvgIcon } from '@material-ui/core/';
import axios from 'axios';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {attachToken} from "../../utils/auth";
import LoadingSpinner from '../LoadingSpinner';
import Alert from '@material-ui/lab/Alert';


const useStyles = makeStyles({
  root: {
    backgroundColor: '#F9F9F9',
    borderRadius: '4px',
    maxWidth: '552px',
    minHeight: '66px',
    marginTop: '16px',
    boxShadow: 'none',
  },
});

const useStylesTooltip = makeStyles(theme => ({
  arrow: {
    fontSize: '14px',
    width: 17,
    '&::before': {
      border: '1px solid #EEEEEE',
      backgroundColor: '#fff',
      boxSizing: 'border-box',
    },
  },
  tooltip: {
    backgroundColor: 'white',
    color: '#263238',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: '17px',
    textAlign: 'left',
    border: '1px solid #EEEEEE',
    borderRadius: 1,
    fontWeight: '400',
  },
}));

const FeedbackSlider = withStyles({
  root: {
    color: '#0061FF',
    height: 8,
    width: '99%',
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    marginTop: -8,
    marginLeft: -12,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    '&:focus, &:hover, &$active': {
      boxShadow: '#ccc 0 2px 3px 1px',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    color: '#d8d8d8',
    height: 8,
    borderRadius: 4,
  },
})(Slider);

export default function CustomerFeedback({ className, customer, ...rest }) {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [sliderValue, setSliderValue] = useState(parseInt(customer.probability_to_renew));
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [feedbackSubmittedId, setFeedbackSubmittedId] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessageModal, setErrorMessageModal] = useState('');
  const [errorMessage,setErrorMessage] = useState('');

  const prediction_value = customer.probability_to_renew;

  const handleSubmit = event => {

    let optionSelected = 'None';
    event.target.parentElement.value === 'Yes' || event.target.value === 'Yes'
      ? (optionSelected = 'Yes')
      : event.target.parentElement.value === 'No' || event.target.value === 'No'
      ? (optionSelected = 'No')
      : (optionSelected = 'Undo');

    setErrorMessageModal ('');
    setErrorMessage ('');
    if (optionSelected === 'No' && prediction_value === sliderValue){
      setErrorMessageModal('The new percetange must be different from the predicted');
      return;
    }

    setIsSubmitting(true);
    var apiUrl = process.env.REACT_APP_API_BASE_URL + 'v1/table/feedback';
    var feedbackData = {
      _id: feedbackSubmittedId,
      company_name: customer.company_name,
      email: localStorage.getItem('email'),
      Value: sliderValue,
      Option_selected: optionSelected,
      Feedback: feedbackText,
      prediction_value: prediction_value
    };

    axios(attachToken({
      method: 'post',
      url: apiUrl,
      data: feedbackData,
      headers: {},
    }))
      .then(function (response) {
        if (response.data.hasOwnProperty('Error')) {
          console.log(response);
        } else {
          if(optionSelected === 'Undo'){
            setFeedbackSubmitted(false);
            setFeedbackSubmittedId('');
          } else {
            setFeedbackSubmitted(true);
            setFeedbackSubmittedId(response.data.response);
          }
          setIsModalOpen(false);
          setFeedbackText('');
          setSliderValue(parseInt(customer.probability_to_renew));
        }
        setIsSubmitting(false);
        setErrorMessageModal ('');
        setErrorMessage('');
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        setIsSubmitting(false);
        if (optionSelected === 'No') {
          setErrorMessageModal('Error while updating prediction');
        } else {
          setErrorMessage('Error while processing your request. Please try again');
        }
      });
  };

  function ValueLabelComponent(props) {
    const { children, open, value } = props;

    const tooltipClasses = useStylesTooltip();
    let newTooltipValue = value + '%';
    return (
      <Tooltip
        open={open}
        enterTouchDelay={0}
        leaveDelay={100}
        placement="top"
        title={newTooltipValue}
        arrow
        classes={tooltipClasses}
      >
        {children}
      </Tooltip>
    );
  }

  const handleFeedbackNo = () => {
    setIsModalOpen(true);
  };
  return (
    <Card className={classes.root}>
      {errorMessage !== '' ? (
                    <Alert severity="error" p={1}>
                        {' '}
                        {errorMessage}
                    </Alert>
                ) : null}
      {feedbackSubmitted ? (
        <Box display="flex" alignItems="center" m={1} mt={2}>
          <SvgIcon style={{ width: 32, height: 32 }}>
            <CheckCircleIcon color="primary" />
          </SvgIcon>
          <Typography variant="body1" style={{ paddingLeft: '8px' }}>
            Thanks for your feedback!
          </Typography>
          <Box flexGrow={1} />
          <Button color="primary" style={{ marginRight: '8px' }} value="Undo" onClick={handleSubmit}>
            Undo
          </Button>
        </Box>
      ) : (
        <Box display="flex" alignItems="center">
          <img
            src={process.env.PUBLIC_URL + '/accuracy.png'}
            alt="Target symbol"
            style={{ width: '70px', height: '65px' }}
          />
          <Typography variant="body1">Is this prediction accurate?</Typography>
          <Box flexGrow={1} />
          <Button
            variant="contained"
            className={classes.backButton}
            style={{ marginRight: '8px' }}
            onClick={handleFeedbackNo}
          >
            No
          </Button>

          <Button variant="contained" color="primary" style={{ marginRight: '8px' }} value="Yes" onClick={handleSubmit}>
            Yes
          </Button>
        </Box>
      )}
      <Dialog maxWidth="sm" fullWidth onClose={() => setIsModalOpen(false)} open={isModalOpen}>
        {isModalOpen && (
          <Box p={3}>
            <Typography gutterBottom variant="h3" color="textPrimary">
              Tell Us Why
            </Typography>

            <Typography gutterBottom variant="body2" color="textSecondary">
              What should the percentage be?
            </Typography>
                {errorMessageModal !== '' ? (
                    <Alert severity="error" style={{ maxWidth: '400px' }} p={1}>
                        {' '}
                        {errorMessageModal}
                    </Alert>
                ) : null}
            <Box mt={5} />
            <FeedbackSlider
              ValueLabelComponent={ValueLabelComponent}
              aria-label="custom thumb label"
              valueLabelDisplay="on"
              value={sliderValue}
              onChange={(event, val) => {
                setSliderValue(val);
              }}
            />
            <Box mt={2} />
            <TextField
              id="outlined-textarea"
              label="Accuracy Feedback"
              placeholder="Please describe as detailed as possible"
              multiline
              rows={5}
              fullWidth
              variant="outlined"
              onChange={event => {
                setFeedbackText(event.target.value);
              }}
            />
            <Box display="flex" pt={2}>
              <Button variant="contained" onClick={() =>
                { setIsModalOpen(false); setErrorMessageModal ('');}}>
                Cancel
              </Button>
              <Box flexGrow={1} mr={2} />
              {isSubmitting ?
                <LoadingSpinner></LoadingSpinner> :
                <Button variant="contained" color="primary" onClick={handleSubmit} value="No">
                  Submit
                </Button>
              }
            </Box>
          </Box>

        )}


      </Dialog>
    </Card>
  );
}
