import { makeStyles } from '@material-ui/core';

export const salesforceStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    icon: {
        width: "32px",
        height: "32px"
    },
    filterDimensions:{
      width: '260px',
      height: '40px',
      fontSize: '14px'
    },
    searchField: {
      fontSize: '14px'
    },
    fixedButtons:{
      position: 'fixed',
      backgroundColor: 'white',
      width: '550px',
      bottom: 0,
      paddingBottom: '16px'
    },
    checkBoxIndeterminate: {
      color: '#0061ff',
    }
}));
