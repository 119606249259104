import React, { useState } from 'react';
import { Box, Typography, IconButton, Popover, MenuList, MenuItem, Button, TextField } from '@material-ui/core/';
import { insightsStyles } from './insights.theme';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import classes from './insights.module.css';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const AddNewNote = ({ ...props }) => {
  const styles = insightsStyles();
  const [isAddNew, setIsAddNew] = useState(false);
  const [description, setDescription] = useState('');
  const handleAdd = () => {
    props.handleUpdateTaskNote(props.id, 'note', 'create', {
      description: description,
    });
    setIsAddNew(false);
  };

  return (
    <Box mt={1.5}>
      {isAddNew ? (
        <>
          <TextField
            id="outlined-textarea"
            placeholder="Add a new note"
            multiline
            rows={3}
            fullWidth
            variant="outlined"
            onChange={e => setDescription(e.target.value)}
          />

          <Box mt={2} display="flex">
            <Button variant="contained" style={{ marginRight: '16px' }} onClick={() => setIsAddNew(false)}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={() => handleAdd()}>
              Add
            </Button>
          </Box>
        </>
      ) : (
        <Button
          variant="text"
          className={styles.addNew}
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => setIsAddNew(true)}
        >
          Add New Note
        </Button>
      )}
    </Box>
  );
};

const Note = ({ company_id, ...props }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState(props.info.description);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleDeleteClick = item_id => {
    handleClose();
    props.handleUpdateTaskNote(company_id, 'note', 'update', {
      item_id: props.info.item_id,
      description: description,
      is_deleted: true,
    });
  };

  const handleSave = () => {
    props.handleUpdateTaskNote(company_id, 'note', 'update', {
      item_id: props.info.item_id,
      description: description,
      is_deleted: false,
    });
    setIsEditing(false);
  };

  const handleCancel = () => {
    setDescription(props.info.description);
    setIsEditing(false);
  };
  const handleEdit = () => {
    handleClose();
    setIsEditing(true);
  };
  return (
    <>
      {isEditing ? (
        <Box mt={2}>
          <TextField
            id="outlined-textarea"
            placeholder="Add a new task"
            multiline
            rows={3}
            fullWidth
            variant="outlined"
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          <Box mt={2} mb={1} display="flex">
            <Button variant="contained" style={{ marginRight: '16px' }} onClick={() => handleCancel()}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={() => handleSave()}>
              Save
            </Button>
          </Box>
        </Box>
      ) : (
        <Box mt={2} display="flex">
          <img
            src={require('../../assets/note.png')}
            alt="Note"
            style={{ width: '16px', height: '11px', margin: 'auto' }}
          />
          <Typography
            variant="body2"
            color="textPrimary"
            style={{ paddingRight: '16px', width: '533px', marginLeft: '16px' }}
          >
            {props.info.description}
          </Typography>

          <IconButton style={{ height: '10px', width: '10px' }} onClick={handleClick} className={classes.moreIcon}>
            <MoreHorizIcon />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuList>
              <MenuItem onClick={() => handleEdit()}>Edit</MenuItem>
              <MenuItem onClick={() => handleDeleteClick(props.info.item_id)}>Delete</MenuItem>
            </MenuList>
          </Popover>
        </Box>
      )}
    </>
  );
};

const Notes = ({ ...props }) => {
  return (
    <Box mt={2}>
      <>
        <Typography variant="subtitle1" color="textPrimary">
          Notes
        </Typography>
        {props.cardDetails.notes.map(info => (
          <Box key={info.item_id}>
            {!info.is_deleted && (
              <Note
                info={info}
                company_id={props.cardDetails.company_id}
                changeInsightStatus={props.changeInsightStatus}
                classes={classes}
                setShowDetailedCard={props.setShowDetailedCard}
                handleUpdateTaskNote={props.handleUpdateTaskNote}
              />
            )}
          </Box>
        ))}

        <AddNewNote id={props.cardDetails.company_id} handleUpdateTaskNote={props.handleUpdateTaskNote} />
      </>
    </Box>
  );
};
export default Notes;
