import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Box, Typography } from '@material-ui/core/';
import styles from './customer.module.css';
import KeyIndicatorsTrending from './KeyIndicatorsTrending';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {editableIndicator} from './DataTableUtils';
import DialogEditableValue from './DialogEditableValue';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: 'calc(100vh - 320px)',
    overflowY: 'auto',
  },
  customerAttribute: {
    color: '#546E7A',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: '20px',
    textAlign: 'left',
  },
  customerAttributeValue: {
    color: '#263238',
    fontFamily: 'Roboto',
    fontSize: '12px',
    letterSpacing: '0.1px',
    lineHeight: '20px',
    textAlign: 'left',
    textTransform: 'uppercase',
    fontWeight: '600',
  },
  customerAttributeTrend: {
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: '20px',
    textAlign: 'left',
    fontWeight: '600',
  }
}));

const keyIndicators = [
  {id: 'current_balance_category', label: 'Current Balance', supportTicket: false},
  {id: 'current_due_category', label: 'Current Due', supportTicket: false},
  {id: 'customer_status_category', label: 'Customer Status Category', supportTicket: false},
  {id: 'upsell', label: 'Upsell', supportTicket: false},
  { id: 'product_usage', label: 'Product Usage', supportTicket: false },
  { id: 'nps', label: 'NPS/CSAT', supportTicket: false },
  { id: 'interaction_frequency', label: 'Interaction Frequency', supportTicket: false },
  { id: 'number_of_support_tickets', label: 'Number of Support Tickets', supportTicket: true },
  { id: 'severity_of_support_tickets', label: 'Severity of Support Tickets', supportTicket: true },
  { id: 'renewal_sentiment', label: 'Customer Sentiment', supportTicket: false },
  { id: 'customer_pulse', label: 'Customer Owner Pulse', supportTicket: false},
];

export default function CustomerKeyIndicators({ className, customer, trend, errorFlag,setCustomer, ...rest }) {
  const classes = useStyles();
  const [openEditable, setOpenEditable] = useState(false);
  const [editableColumn, setEditableColumn] = useState({});
  
  const handleOpenEditable = () => {
    setOpenEditable (true);
  }

  const updateData = (editableColumn) => {
    //let customers = [...props.customerTableData];
    //let index = customers.findIndex(x=> editableColumn.companyName === x.company_name);
   // if (index > -1) {
      let customerToReplace = {...customer};
      customerToReplace[editableColumn.columnId + '_value'] = editableColumn.value;
      customerToReplace[editableColumn.columnId + '_value_category'] = editableColumn.normalized;
      customerToReplace[editableColumn.columnId] = editableColumn.normalized;
      customerToReplace['probability_to_renew'] = editableColumn.new_prediction;

      let index = customerToReplace['logs'].findIndex(x=> editableColumn.log['field_id']=== x.field_id)
      if (index > -1)
        customerToReplace['logs'][index] = editableColumn.log;
      else 
        customerToReplace['logs'].push(editableColumn.log);
      setCustomer(customerToReplace);
      
    //}
  }

  const attributeVal = (attrVal, supportTicket) => {
    switch (attrVal) {
      case 'Low':
        if (supportTicket) {
          return (
            <span className={styles.high} style={{ padding: '4px' }}>
              {attrVal}
            </span>
          );
        } else {
          return (
            <span className={styles.low} style={{ padding: '4px' }}>
              {attrVal}
            </span>
          );
        }
      case 'Medium':
        return (
          <span className={styles.medium} style={{ padding: '4px' }}>
            {attrVal}
          </span>
        );
      case 'High':
        if (supportTicket) {
          return (
            <span className={styles.low} style={{ padding: '4px' }}>
              {attrVal}
            </span>
          );
        } else {
          return (
            <span className={styles.high} style={{ padding: '4px' }}>
              {attrVal}
            </span>
          );
        }
      case 'Does not Exist':
        return (
          <span className={styles.doesNotExist} style={{ padding: '4px' }}>
            {attrVal}
          </span>
        );
      default:
        return <span style={{ padding: '4px' }}>{attrVal}</span>;
    }
  };

    const attributeTrend = (trendVal, supportTicket) => {
      switch (supportTicket) {
        case true:
          return <span> {trendVal === 0 && <span className={styles.noChange}>{trendVal}</span>}
          {trendVal > 0 && (
            <span className={styles.decreasedDelta}>
              <ArrowUpwardIcon className={styles.arrowClass} />+{trendVal}%
            </span>
          )}
          {trendVal < 0 && (
            <span className={styles.increasedDelta}>
              <ArrowDownwardIcon className={styles.arrowClass} /> {trendVal}%
            </span>
          )}</span>;
        default:
        return <span> {trendVal === 0 && <span className={styles.noChange}>{trendVal}</span>}
        {trendVal > 0 && (
          <span className={styles.increasedDelta}>
            <ArrowUpwardIcon className={styles.arrowClass} />+{trendVal}%
          </span>
        )}
        {trendVal < 0 && (
          <span className={styles.decreasedDelta}>
            <ArrowDownwardIcon className={styles.arrowClass} /> {trendVal}%
          </span>
        )}</span>
      }
    }
  return (
    <Box className={classes.root} py={3} pb={1}>
      <Box mb={0.5}>
        <Typography variant="subtitle1" color="textPrimary">Key Indicators</Typography>
      </Box>
      <Box style={{ width: '540px' }}>
        <Grid container spacing={1}>
          {keyIndicators.map((keyIndicator, index) => (
            <>
              {(customer.hasOwnProperty(keyIndicator.id)  && customer[keyIndicator.id] !== 'Exclude') && (
                <Grid container item xs={12} style={{ marginTop: '8px' }} key={['KeyIndicator-', keyIndicator.id].join()}>
                  <Grid item xs={5} className={classes.customerAttribute}>
                    {keyIndicator.label}
                  </Grid>
                  <Grid item xs={2} className={classes.customerAttributeValue}>
                    {attributeVal(customer[keyIndicator.id], keyIndicator.supportTicket)}
                  </Grid>
                  {(trend[0] && trend[0].hasOwnProperty([keyIndicator.id])) && (
                    <Grid item xs={2} className={classes.customerAttributeTrend}>
                      {attributeTrend(trend[0][keyIndicator.id].key_indicator_change, keyIndicator.supportTicket)}
                    </Grid>
                  )}
                  { keyIndicator.isEditable !== undefined && keyIndicator.isEditable ? 
                   <Grid item xs={1} >
                     { editableIndicator(customer.company_name, customer.account_id,customer[keyIndicator.id + '_value'], customer[keyIndicator.id], 
                      customer['logs'] !== undefined? customer['logs'].filter(x=> x.field_id === keyIndicator.id + '_value'):[], keyIndicator, handleOpenEditable,setEditableColumn )  }
                   <DialogEditableValue 
                        open={openEditable} 
                        setOpenEditable={setOpenEditable} 
                        editableColumn={editableColumn} 
                        updateData = {updateData}
                      >
                    </DialogEditableValue>

                   </Grid> 
                   : null}

                </Grid>
              )}
            </>
          ))}
        </Grid>
      </Box>
      {(trend.length!== 0) && <KeyIndicatorsTrending trend={trend} errorFlag={errorFlag} customer={customer}/>}
    </Box>
  );
}
