import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SecondaryNav from '../SecondaryNav';
import { Container, FormControlLabel, Typography, Box, Switch } from '@material-ui/core';
import CustomerDataTable from './CustomerDataTable';
import { dataTableSearch } from './CustomerAPI.js';
import styles from './customer.module.css';
import ProductFeedback from './ProductFeedback.js';
import { useLocation } from 'react-router-dom';
import { getDataTableConfig } from './CustomerAPI.js';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const Customers = () => {
  const location = useLocation();
  const classes = useStyles();
  const [customers, setCustomers] = useState([]);
  const [loadingFlag, setLoadingFlag] = useState(true);
  const [syntheticDataFlag, setSyntheticDataFlag] = useState(false);
  const [showNPSDialog, setShowNPSDialog] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const email = localStorage.getItem('email');
  const [advancedViewFlag, setAdvancedViewFlag] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [errorFlag, setErrorFlag] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [dataColumnsDetailed, setDataColumnsDetailed] = useState([]);  
  const [collapsedDetails, setCollapsedDetails] = useState([]);
  const [loadingFilterData, setLoadingFilterData] = useState(false);  

  let tempselectedCustomerName = '';
  if (localStorage.getItem('searchedCustomer')) {
    tempselectedCustomerName = localStorage.getItem('searchedCustomer').replace('#', '');
  } else {
    tempselectedCustomerName = location.hash.replace('#', '');
  }
  tempselectedCustomerName = tempselectedCustomerName.replace('%20', ' ');
  const selectedCustomerName = tempselectedCustomerName;
  useEffect(() => {
    setLoadingFlag(true);
    setLoadingFilterData(true);
    getDataTableConfig()
    .then(function (response) {if(response.data){
      setFilterOptions(response.data.columns);
      setDataColumns(response.data.data_table_columns_simple);
      setDataColumnsDetailed(response.data.data_table_columns_detailed);
      setCollapsedDetails(response.data.data_table_detailed_collapsed);
      localStorage.setItem('columnsSimpleView', JSON.stringify(response.data.data_table_columns_simple));
      localStorage.setItem('columnsDetailedView', JSON.stringify(response.data.data_table_columns_detailed));
      localStorage.setItem('collapsedDetails', JSON.stringify(response.data.data_table_detailed_collapsed));
    }else{
      console.log("An error occured in the config API");
    }
    setLoadingFilterData(false);
    setLoadingFlag(false);
  })
    .catch(function (response) {
      console.log(response);
    });

    let requestData = {
      search: '',
      view: 'simple',
      advanced_search: [],
      page_number: 1,
      rows_per_page: 25,
      sorting: {
        column_name: 'renewal_date',
        direction: 'desc',
      },
    };

    dataTableSearch(requestData).then(response => {
      if(response.data.hasOwnProperty("Error")){
        setErrorFlag(true);
      }else{
        let tempCustomer = response.data.data;
        setCustomers(tempCustomer);
        setSyntheticDataFlag(response.data.synthetic_data);
        setTotalRecords(response.data.total);
        localStorage.setItem('syntheticDataFlag', response.data.synthetic_data);
        setLoadingFlag(false);
        if (location.state) {
          setCompanyName(location.state.company_name);
          const timer = setTimeout(() => {
            setShowNPSDialog(location.state.nps);
          }, 3000);
          return () => clearTimeout(timer);
        }
      }

    });
  }, [location.state]);  

  return (
    <div className={classes.root}>
      <SecondaryNav />
      {showNPSDialog && <ProductFeedback openDialog={true} companyName={companyName} email={email} />}      
      <Container
        style={{
          height: 'calc(100vh - 70px)',
          marginTop: '64px',
          overflow: 'overlay',
          paddingLeft: '24px',
          paddingRight: '16px',
          maxWidth: '100%',
        }}
        className="hideScroll"
      >
        <Box display="flex">
          <Typography variant="h4" style={{ marginTop: '32px' }} color="textPrimary">
            {syntheticDataFlag ? <span>Customer Health Simulation Data</span> : <span>Customer Health Data</span>}
          </Typography>
          <Box flexGrow="1" />
          <Box mt={2.75}>
            <FormControlLabel
              value="AdvancedView"
              control={<Switch checked={advancedViewFlag} color="primary" />}
              label="Detailed View"
              labelPlacement="start"
              onChange={e => setAdvancedViewFlag(!advancedViewFlag)}
            />
          </Box>
        </Box>

        {(loadingFlag || loadingFilterData) ? (<div className={styles.loader}> </div>)
        :(
          <React.Fragment>          
          <CustomerDataTable
            customers={customers}
            advancedViewFlag={advancedViewFlag}
            setAdvancedViewFlag = {setAdvancedViewFlag}
            selectedCustomerName={selectedCustomerName}
            totalRecords = {totalRecords}
            setCustomers={setCustomers}
            setTotalRecords={setTotalRecords}
            setLoadingFlag={setLoadingFlag}
            errorFlag = {errorFlag}
            setErrorFlag = {setErrorFlag}
            filterOptions={filterOptions}
            dataColumns={dataColumns}
            setDataColumns={setDataColumns}
            dataColumnsDetailed={dataColumnsDetailed}
            setDataColumnsDetailed={setDataColumnsDetailed}
            collapsedDetails={collapsedDetails}            
          />
          </React.Fragment>
        )}
      </Container>
    </div>
  );
};

export default Customers;
