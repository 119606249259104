import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Container,
  FormControl,
  Select,
  Divider,
  Button,
  Drawer,
  Avatar,
  SvgIcon,
  TextField,
  Snackbar,
} from '@material-ui/core';
import SecondaryNav from './../../SecondaryNav';
import Overview from './Overview';
import { getReportsConfig, getNumberRecords, updateReportConfig, getMetrics } from './../ReportsAPI';
import cloneDeep from 'lodash/cloneDeep';
import Filters from '../../customers/Filters.js';
import FilterListIcon from '@material-ui/icons/FilterList';
import BlockIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import ChartIcon from '@material-ui/icons/InsertChartOutlinedOutlined';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { reportsStyles } from './../reports.theme';
import { getDataTableConfig } from './../../customers/CustomerAPI.js';
import { getViews } from './../../customers/ViewApi';
import ImagePlaceHolder from './../../customers/ImagePlaceholder';
import { useParams } from 'react-router-dom';
import ShareModal from './ShareModal';
import CreateNewChartModal from './CreateNewChartModal';
import CreateNewBlockPopover from './CreateNewBlockPopover';
import PreviewModal from './Preview';
import EditIcon from '@material-ui/icons/Edit';

const Reports = () => {
  const styles = reportsStyles();
  const { reportId } = useParams();
  const [configData, setConfigData] = useState([]);
  const [initialLayout, setInitialLayout] = useState([]);
  const [loadingFlag, setLoadingFlag] = useState(true);
  const [duration, setDuration] = useState('monthly');
  const [showFilters, setShowFilters] = useState(false);
  const [savedFilters, setSavedFilters] = useState({});
  const [filterOptions, setFilterOptions] = useState([]);
  const [recordCount, setRecordCount] = useState(0);
  const [openDialogFlag, setOpenDialogFlag] = useState(false);
  const [previewFlag, setPreviewFlag] = useState(false);
  const [metrics, setMetrics] = useState([]);
  const [createChartFlag, setCreateChartFlag] = useState(false);
  const [newChart, setNewChart] = useState(0);
  const [counter, setCounter] = useState(1);
  const [dataColumns, setDataColumns] = useState([]);
  const [editFlag, setEditFlag] = useState(false);
  const [savedTitle, setSavedTitle] = useState('');
  const [unsavedTitle, setUnsavedTitle] = useState('');
  const [blockAnchorEl, setBlockAnchorEl] = React.useState(null);
  const [blockDeleted, setBlockDeleted] = useState('');
  const [deleteBlockFlag, setdeleteBlockFlag] = useState(5);
  const [toastCopied, setToastCopied] = useState(false);
  const [views, setViews] = useState([]);
  const [selectedView, setSelectedView] = useState(0);

  const shareReport = () => {
    setOpenDialogFlag(true);
  };
  const handleCloseDialog = e => {
    if (e === 'copied') {
      setToastCopied(true);
      setOpenDialogFlag(false);
    } else {
      setOpenDialogFlag(false);
    }
  };

  const blockPopoverClose = () => {
    setBlockAnchorEl(null);
  };
  const handleBlockClick = event => {
    setBlockAnchorEl(event.currentTarget);
  };
  const open = Boolean(blockAnchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (newChart !== 0) {
      getReportsConfig({
        report_id: reportId,
      }).then(response => {
        setSelectedView(response.data.data.view_id);
        setConfigData(response.data.data);
        let tempInitialLayout = { lg: [], md: [], sm: [] };
        let tempBlockData = cloneDeep(response.data.data.blocks);
        let lgX = 0;
        let lgY = 0;
        let mdX = 0;
        let mdY = 0;
        let smX = 0;
        let smY = 0;
        tempBlockData.forEach((item, i) => {
          if (item.layout === 'full-width') {
            tempInitialLayout.lg.push({ i: item.id, x: 0, y: lgY, w: 12, h: 9, minH: 9, maxH: 16 });
            tempInitialLayout.md.push({ i: item.id, x: 0, y: mdY, w: 12, h: 14, minH: 9 });
            tempInitialLayout.sm.push({ i: item.id, x: 0, y: smY, w: 12, h: 20, minH: 9 });
            lgY = lgY + 9;
            mdY = mdY + 14;
            smY = smY + 20;
            lgX = 0;
            mdX = 0;
            smX = 0;
          } else {
            tempInitialLayout.lg.push({ i: item.id, x: lgX, y: lgY, w: 6, h: 6.5 });
            tempInitialLayout.md.push({ i: item.id, x: 0, y: mdY, w: 12, h: 6.5 });
            tempInitialLayout.sm.push({ i: item.id, x: smX, y: smY, w: 6, h: 6.5 });
            lgY = lgY + 6.5;
            lgX = lgX === 0 ? lgX + 6 : 0;
            mdY = mdY + 6.5;
            mdX = mdX === 0 ? mdX + 6 : 0;
            smY = smY + 6.5;
            smX = smX === 0 ? smX + 6 : 0;
          }
        });
        setInitialLayout(tempInitialLayout);
        setCounter(counter + 1);
        setLoadingFlag(false);
      });
    }
  }, [newChart]);

  useEffect(() => {
    getDataTableConfig()
      .then(function (response) {
        if (response.data) {
          setDataColumns(response.data.data_table_columns_simple);
          setFilterOptions(response.data.columns);
        } else {
          console.log('An error occured in the config API');
        }
      })
      .catch(function (response) {
        console.log(response);
      });

      getViews().then(response => {
        if (response.data.hasOwnProperty('Error')) {
          //setErrorFlag(true);
        } else {
          setViews(response.data.data);
        }
      });

    getReportsConfig({
      report_id: reportId,
    }).then(response => {
      getNumberRecords({
        report_id: reportId,
      }).then(response => {
        setRecordCount(response.data.count);
      });
      setSelectedView(response.data.data.view_id);
      setSavedTitle(response.data.data.name);
      setUnsavedTitle(response.data.data.name);
      setConfigData(response.data.data);
      setSavedFilters(response.data.data.filters);
      setDuration(response.data.data.duration);

      let tempInitialLayout = { lg: [], md: [], sm: [] };
      let tempBlockData = cloneDeep(response.data.data.blocks);
      let lgX = 0;
      let lgY = 0;
      let mdX = 0;
      let mdY = 0;
      let smX = 0;
      let smY = 0;
      tempBlockData.forEach((item, i) => {
        if (item.layout === 'full-width') {
          tempInitialLayout.lg.push({ i: item.id, x: 0, y: lgY, w: 12, h: 9, minH: 9, maxH: 16 });
          tempInitialLayout.md.push({ i: item.id, x: 0, y: mdY, w: 12, h: 14, minH: 9 });
          tempInitialLayout.sm.push({ i: item.id, x: 0, y: smY, w: 12, h: 20, minH: 9 });
          lgY = lgY + 9;
          mdY = mdY + 14;
          smY = smY + 20;
          lgX = 0;
          mdX = 0;
          smX = 0;
        } else {
          tempInitialLayout.lg.push({ i: item.id, x: lgX, y: lgY, w: 6, h: 6.5 });
          tempInitialLayout.md.push({ i: item.id, x: 0, y: mdY, w: 12, h: 6.5 });
          tempInitialLayout.sm.push({ i: item.id, x: smX, y: smY, w: 6, h: 6.5 });
          lgY = lgY + 6.5;
          lgX = lgX === 0 ? lgX + 6 : 0;
          mdY = mdY + 6.5;
          mdX = mdX === 0 ? mdX + 6 : 0;
          smY = smY + 6.5;
          smX = smX === 0 ? smX + 6 : 0;
        }
      });
      setInitialLayout(tempInitialLayout);
      setLoadingFlag(false);
      getMetrics()
        .then(function (response) {
          if (response.data) {
            setMetrics(response.data.data);
          } else {
            console.log('An error occured in the config API');
          }
        })
        .catch(function (response) {
          console.log(response);
        });
    });
  }, []);

  const handleDurationChange = event => {
    setDuration(event.target.value);
    updateReportConfig({
      report_id: reportId,
      duration: event.target.value,
      filters: savedFilters,
    }).then(response => {
      if (response.data.response !== 'success') {
        // TO DO: Update with error flag
        console.log('Error saving');
      }
    });
  };

  const handleApplyFilters = filters => {
    setLoadingFlag(true);
    setRecordCount(-1);
    setShowFilters(false);
    let tempFilters = Object.assign({}, filters);
    setSavedFilters(tempFilters);
    updateReportConfig({
      report_id: reportId,
      duration: duration,
      filters: tempFilters,
    }).then(response => {
      if (response.data.response === 'success') {
        getNumberRecords({
          report_id: reportId,
        }).then(response => {
          setRecordCount(response.data.count);
          setLoadingFlag(false);
        });
      } else {
        // TO DO: Update with error flag
        console.log('Error!');
      }
    });
  };

  const openPreview = () => {
    setPreviewFlag(true);
  };

  const closePreview = () => {
    setPreviewFlag(false);
  };

  const handleBlockDelete = useCallback(item => {
    setdeleteBlockFlag(Math.random() * deleteBlockFlag);
    setBlockDeleted(item.name);
  }, []);

  const handleSave = () => {
    setSavedTitle(unsavedTitle);
    updateReportConfig({
      report_id: reportId,
      name: unsavedTitle,
    }).then(response => {
      if (response.data.response && response.data.response === 'success') {
        // setErrorFlag(false);
        setEditFlag(false);
      } else {
        // setErrorFlag(true);
      }
    });
  };

  const cancelEdit = () => {
    setUnsavedTitle(savedTitle);
    setEditFlag(false);
  };

  const handleViewChange = (event) => {
    setSelectedView(event.target.value);
    if(event.target.value === "all_customers"){
      setSavedFilters({});
      handleApplyFilters({});
    }else{
      let selectedviewConfig = views.filter(item=> item._id === event.target.value);
      setSavedFilters(selectedviewConfig[0].filters);
      handleApplyFilters(selectedviewConfig[0].filters);
    }

    updateReportConfig({
      report_id: reportId,
      view_id: event.target.value === "all_customers" ? "" : event.target.value,
    }).then(response => {
      if (response.data.response && response.data.response === 'success') {
        setEditFlag(false);
      } else {
        // setErrorFlag(true);
      }
    });
  }

  return (
    <>
      <SecondaryNav />
      <Container
        style={{
          height: 'calc(100vh - 70px)',
          marginTop: '64px',
          overflow: 'overlay',
          paddingLeft: '24px',
          paddingRight: '16px',
          maxWidth: '100%',
        }}
        className="hideScroll"
      >
        {loadingFlag ? (
          <div className={styles.loader}> </div>
        ) : (
          <>
            <ShareModal
              openDialogFlag={openDialogFlag}
              reportId={reportId}
              handleClose={handleCloseDialog}
              reportName={configData.name}
              accessCode={configData.password}
              createdDate={configData.created_date}
              configDD={configData}
              expirationDate={configData['expiration_date'] !== undefined ? configData['expiration_date'] : ''}
            />
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={toastCopied}
              message="Copied to clipboard"
              autoHideDuration={1200}
              onClose={() => setToastCopied(false)}
              ContentProps={{
                className: styles.snackbar,
              }}
            />
            <CreateNewChartModal
              reportId={reportId}
              openDialogFlag={createChartFlag}
              chartOptions={metrics}
              handleClose={() => setCreateChartFlag(false)}
              setNewChart={setNewChart}
              loaderClass={styles.newChartLoader}
            />
            <CreateNewBlockPopover
              id={id}
              open={open}
              anchorEl={blockAnchorEl}
              onClose={blockPopoverClose}
              BlockData={configData}
              blockDeleted={blockDeleted}
              setNewChart={setNewChart} //Replace later for Blocks
              checkEntry={deleteBlockFlag}
            />
            {previewFlag && <PreviewModal open={previewFlag} handleClose={closePreview} />}
            <Box mt={4} mb={3} mr={1} display="flex">
              <Box display="flex" alignItems="center">
                {editFlag ? (
                  <Box display="flex">
                    <TextField
                      fullWidth
                      autoFocus
                      label=""
                      size="small"
                      margin="normal"
                      name="report_name"
                      type="text"
                      defaultValue=""
                      value={unsavedTitle}
                      variant="outlined"
                      onChange={e => setUnsavedTitle(e.target.value)}
                      onKeyPress={ev => {
                        if (ev.key === 'Enter') {
                          handleSave();
                          ev.preventDefault();
                        }
                      }}
                    />
                    <Box ml={2} alignSelf="center" pt={1} display="flex">
                      <Button
                        onClick={handleSave}
                        color="primary"
                        type="submit"
                        variant="contained"
                        size="small"
                        style={{ height: '38px' }}
                      >
                        Save
                      </Button>
                      <IconButton onClick={cancelEdit} size="small">
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Typography variant="h4">{savedTitle}</Typography>{' '}
                    <IconButton
                      size="small"
                      style={{ marginLeft: '8px', alignItems: 'flex-end' }}
                      onClick={() => {
                        setEditFlag(true);
                      }}
                    >
                      <EditIcon size="small" />
                    </IconButton>
                  </>
                )}
              </Box>
              <Box flexGrow="1" />
              <Box mr={2}>
                <Button variant="contained" size="large" onClick={openPreview}>
                  Preview
                </Button>
              </Box>
              <Box>
                <Button variant="contained" size="large" color="primary" onClick={shareReport}>
                  Share
                </Button>
              </Box>
            </Box>
            <Box display="flex" textAlign="center" mb={2} mr={-1}>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              style={{ marginRight: '8px', marginTop: 'auto', marginBottom: 'auto' }}
            >
              View
            </Typography>
            <FormControl variant="outlined" size="small">
              <Select native value={selectedView} onChange={e => handleViewChange(e)}>
              {selectedView === "" && <option value={""}>{"Select"}</option>}
              <option value={"all_customers"}>{"All Customers"}</option>
              {views.map(view => (
                <option value={view._id}>{view.name}</option>
              ))}
              </Select>
            </FormControl>
            <Divider orientation="vertical" variant="middle" flexItem style={{ marginLeft: '32px' }} />

              <Typography
                variant="subtitle2"
                color="textSecondary"
                style={{ marginRight: '8px', marginTop: 'auto', marginBottom: 'auto' }}
              >
                Duration
              </Typography>
              <FormControl variant="outlined" size="small">
                <Select native value={duration} onChange={e => handleDurationChange(e)}>
                  <option value={'monthly'}>Monthly</option>
                  <option value={'quarterly'}>Quarterly</option>
                </Select>
              </FormControl>
              <Divider orientation="vertical" variant="middle" flexItem style={{ marginLeft: '32px' }} />
              <Box display="flex">
                <Button
                  variant="contained"
                  className={styles.filterBtn}
                  style={{ marginRight: '8px' }}
                  onClick={() => setShowFilters(true)}
                  startIcon={<FilterListIcon />}
                >
                  FILTERS
                  {Object.keys(savedFilters).length > 0 && (
                    <Avatar className={styles.filterCount}>{Object.keys(savedFilters).length}</Avatar>
                  )}
                </Button>
              </Box>
              <Drawer anchor={'right'} open={showFilters} onClose={() => setShowFilters(!showFilters)}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={2}
                  style={{ maxWidth: '555px' }}
                >
                  <Box flexGrow="1" />
                  <IconButton onClick={() => setShowFilters(!showFilters)}>
                    <SvgIcon>
                      <CloseIcon />
                    </SvgIcon>
                  </IconButton>
                </Box>
                <Filters
                  filterOptions={filterOptions}
                  handleApplyFilters={handleApplyFilters}
                  savedFilters={savedFilters}
                  dataColumns={dataColumns}
                />
              </Drawer>
              <Box flexGrow="1" />
              <Button
                variant="contained"
                className={styles.filterBtn}
                style={{ marginRight: '8px' }}
                onClick={handleBlockClick}
                startIcon={<BlockIcon />}
              >
                ADD NEW BLOCK
              </Button>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Button
                variant="contained"
                className={styles.filterBtn}
                onClick={() => setCreateChartFlag(true)}
                startIcon={<ChartIcon />}
              >
                ADD NEW CHART
              </Button>
            </Box>
            <Divider style={{ marginBottom: '8px' }} />
            {recordCount === 0 && (
              <ImagePlaceHolder
                message={'No records match your search filters. Try another search.'}
                imageUrl={require('./../../../assets/history-no-results.png')}
                altText={'No Results Search'}
              />
            )}
            {recordCount > 0 && (
              <Box mb={1} key={counter} id={counter}>
                <Overview
                  configData={configData}
                  initialLayout={initialLayout}
                  duration={duration}
                  filters={savedFilters}
                  recordCount={recordCount}
                  blockDeleted={handleBlockDelete}
                />
              </Box>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default Reports;
