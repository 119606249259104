import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import SecondaryNav from './../../SecondaryNav';
import { Box, Container } from '@material-ui/core';
import styles from './datasources.module.css';
import Typography from '@material-ui/core/Typography';
import DataCard from './DataCard';
import ExportDialog from './ExportDialog';
import IntegrationsDialog from './IntegrationsDialog';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import SyncAll from './SyncAll';
import axios from 'axios';
import { attachToken } from '../../../utils/auth';

const DataSource = () => {
  const location = useLocation();
  const history = useHistory();
  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [openSyncIntegrationsDialog, setOpenSyncIntegrationsDialog] = useState(false);
  const [openDisconnectIntegrationsDialog, setOpenDisconnectIntegrationsDialog] = useState(false);
  const integrationsData = [
    {
      integration: 'Salesforce',
      last_update: 'x',
      next_update: 'mm/dd/yyyy',
      status: 'Disconnected',
      job_id: 23,
    },
    {
      integration: 'Outlook',
      last_update: 2,
      next_update: 'Nov 24, 2020',
      status: 'Disconnected',
      job_id: 23,
    },
    {
      integration: 'Zendesk',
      last_update: 2,
      next_update: 'Nov 24, 2020',
      status: 'Disconnected',
      job_id: 23,
    },
    {
      integration: 'CSV Data',
      last_update: 2,
      next_update: 'Nov 24, 2020',
      status: 'Disconnected',
      job_id: 23,
    },
  ];
  useEffect(() => {
    if (location.state && location.state.status === 'dataExport') {
      setOpenExportDialog(true);
      history.replace('/integrations', { state: { status: false } });
    }
  }, [location.state, history]);

  useEffect(() => {
    let apiUrl = process.env.REACT_APP_API_BASE_URL + 'v1/connector/status';
    axios(
      attachToken({
        method: 'get',
        url: apiUrl,
        data: {
          company_name: 'relsci',
        },
        headers: {},
      }),
    )
      .then(function (response) {})
      .catch(function (response) {
        console.log(response);
      });
  }, []);

  const handleSync = () => {
    setOpenSyncIntegrationsDialog(true);
  };
  const handleDisconnect = () => {
    setOpenDisconnectIntegrationsDialog(true);
  };
  const syncAllFunction = () => {
    console.log('Inside sync all function');
    alert('This feature will be available soon');
    setOpenSyncIntegrationsDialog(false);
  };
  const disconnectFunction = () => {
    console.log('Inside disconnect function');
    alert('This feature will be available soon');
    setOpenDisconnectIntegrationsDialog(false);
  };
  const closeDialogFunction = () => {
    setOpenSyncIntegrationsDialog(false);
    setOpenDisconnectIntegrationsDialog(false);
  };

  return (
    <div>
      <SecondaryNav />
      {openExportDialog && <ExportDialog openDialog={openExportDialog} />}
      {openSyncIntegrationsDialog && (
        <IntegrationsDialog
          openDialog={openSyncIntegrationsDialog}
          name="sync"
          primaryFunction={syncAllFunction}
          closeDialog={closeDialogFunction}
          lastUpdate={integrationsData[0].last_update}
        />
      )}
      {openDisconnectIntegrationsDialog && (
        <IntegrationsDialog
          openDialog={openDisconnectIntegrationsDialog}
          name="disconnect"
          primaryFunction={disconnectFunction}
          closeDialog={closeDialogFunction}
        />
      )}

      <div className={styles.hideScroll} style={{ backgroundColor: '#FFF' }}>
        <Container maxWidth="xl">
          <Box display="flex">
            <Box>
              <Box mt={4} />
              <Typography variant="h4" color="textPrimary"> Integrations</Typography>
              <Box m={1.5} />
              <Typography variant="body2" color="textSecondary" style={{ maxWidth: '100%' }}>
                {' '}
                This is where you connect all your data sources. Hover over integrations, click connect and follow the
                steps.
              </Typography>
            </Box>
            <Box flexGrow="1" />
            <SyncAll
              lastUpdate={integrationsData[0].last_update}
              nextUpdate={integrationsData[0].next_update}
              syncFunction={handleSync}
            />
          </Box>
          <Box m={4} />

          <Grid container spacing={3}>
            <Grid item>
              <DataCard
                name="salesforce"
                syncFunction={handleSync}
                disconnectFunction={handleDisconnect}
                integrationData={integrationsData.find(a => a.integration === 'Salesforce')}
              />
            </Grid>
            <Grid item>
              <DataCard
                name="outlook"
                syncFunction={handleSync}
                disconnectFunction={handleDisconnect}
                integrationData={integrationsData.find(a => a.integration === 'Outlook')}
              />
            </Grid>
            <Grid item>
              <DataCard
                name="zendesk"
                syncFunction={handleSync}
                disconnectFunction={handleDisconnect}
                integrationData={integrationsData.find(a => a.integration === 'Zendesk')}
              />
            </Grid>
            <Grid item>
              <DataCard
                name="csv"
                syncFunction={handleSync}
                disconnectFunction={handleDisconnect}
                integrationData={integrationsData.find(a => a.integration === 'CSV Data')}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default DataSource;
