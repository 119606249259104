import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Box, Button, Typography, Dialog, DialogActions } from '@material-ui/core/';

const useStyles = makeStyles({
  dialogPaper: {
    maxWidth: '496px',
    maxHeight: '580px',
  },
});

const dialogDetails = {
  deleteReport: {
    title: 'Delete Report',
    message:
      'Are you sure you want to delete this report? You will no longer be able to retrieve it.',
    primaryButtonLabel: 'Delete',
    ariaLabel: 'delete-report'
  }
};

const MessageDialog = props => {
  const classes = useStyles();
  const displayInfo = dialogDetails[props.dialogType];

  return (
    <Dialog open={props.openDialog} onClose={props.handleCloseDialog} classes={{ paper: classes.dialogPaper }}>
      <Box p={4}>
        <Typography variant="h4" color="textPrimary">
          {displayInfo.title}
        </Typography>
        <Box pt={2} pb={4}>
          <Typography variant="body2" color="textSecondary">
            {displayInfo.message}
          </Typography>
        </Box>
        <DialogActions style={{ padding: '0px' }}>
          <Button variant="contained" onClick={props.handleCloseDialog}>
            Cancel
          </Button>
          <Box flexGrow="1" />
          <Button color="primary" type="submit" variant="contained" onClick={props.handlePrimaryButtonClick} aria-label={props.ariaLabel}>
            {displayInfo.primaryButtonLabel}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default MessageDialog;
