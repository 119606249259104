import React, {useState} from 'react';
import {Box, Container, Link, TextField, Tooltip, Typography,} from '@material-ui/core';
import { MAX_IMAGE_SIZE } from '../../constants/ReferenceConstants';
import StepWizard from 'react-step-wizard';
import NavBar from '../NavBar';
import Button from "@material-ui/core/Button";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import {SendCode} from './Signup';
import {methodIcons} from './account.constants';
import {accountStyles} from './account.theme';
import LoadingSpinner from "../LoadingSpinner";

const resetPassword = (user, success_fn, failure_fn) => {
    var apiUrl = process.env.REACT_APP_API_BASE_URL + "v1/auth/reset_password";
    var data = {};
    if(user.email === "") {
        failure_fn({"data":{"message":"Please enter your email"}})
        return
    }
    if (!(MAX_IMAGE_SIZE.test(user.email))) {
        failure_fn({"data":{"message":"Your email address is invalid. Please try again"}})
        return
    }

    data['email'] = user.email
    axios({
        method: 'post',
        url: apiUrl,
        data: data,
        headers: {}
    })
        .then(function (response) {
            if (response.status === 200){
                //handle success
                console.log(response);
                if(success_fn) {
                    success_fn(response);
                }
            }
            else{
                failure_fn(response)
                //handle success
                console.log(response);
            }
        })
        .catch(function (error) {
            failure_fn(error.response)
            //handle error
            console.log(error);
            // window.location = '/dataexportfail'
        });
}

const SendResetPasswordEmail = (props) => {
    const  [email, setEmail] = useState('')
    const [errorMessage, setErrorMessage] = React.useState('')
    const [isLoading, setLoading] = React.useState(false)
    return (<>
        <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            mb={3}
        >
            <div style={{width:"100%"}}>
                <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                    align="center"
                >Forgot Password
                </Typography>
                <Typography
                    gutterBottom
                    variant="body2"
                    color="textSecondary"
                    style={{marginLeft:"65px",
                        marginTop: "24px"
                    }}
                >
                    Please enter your email below so we can send you a link to create a new password.
                </Typography>
            </div>
        </Box>
        {(errorMessage !== '') ?
            (<Alert severity="error" style={{maxWidth: "400px", marginLeft: "65px", marginBottom:"20px", marginTop: "-20px"}} p={1}> {errorMessage}</Alert>)
            : (null)
        }
        <Box
            style={{marginLeft:"65px"}}
        >
            <Box >
                <TextField
                    fullWidth
                    autoFocus
                    label="Email Address"
                    margin="normal"
                    name="email"
                    type="email"
                    defaultValue=''
                    value={email}
                    variant="outlined"
                    style={{maxWidth: "432px", marginTop: "-10px"}}
                    onChange={(e)=>{setEmail(e.target.value)}}
                />
            </Box>
            <Box mt={1}>
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{maxWidth: "432px"}}
                    onClick = {()=>{
                        setLoading(true)
                        props.user.email = email
                        resetPassword(props.user, (response) =>{
                            props.user.verification_token = response.data.verification_token
                            props.nextStep()
                            setLoading(false)
                        }, (response) => {
                            if(response.data && response.data.message) {
                                setErrorMessage(response.data.message)
                            }else{
                                setErrorMessage("Error with status " + response.statusText)
                            }
                            setLoading(false)
                        })
                    }
                    }
                >
                    Reset Password
                </Button>)}
            </Box>
        </Box>
    </>)
}

const updatePassword = (user, success_fn) => {
    var apiUrl = process.env.REACT_APP_API_BASE_URL + "v1/auth/update_password";
    var data = {};
    data['password'] = user.password
    data['verification_token'] = user.verification_token
    axios({
        method: 'post',
        url: apiUrl,
        data: data,
        headers: {}
    })
        .then(function (response) {
            if (response.status === 200){
                //handle success
                console.log(response);
                if(success_fn) {
                    success_fn(response);
                }
            }
            else{
                //handle success
                console.log(response);
            }
        })
        .catch(function (error) {
            //handle error
            console.log(error.response);
            // window.location = '/dataexportfail'
        });
}


const ResetPassword = (props) => {
    const [isLoading, setLoading] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [password2, setPassword2] = React.useState('')
    return (<>
        <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            mb={3}
        >
            <div style={{width:"100%"}}>
                <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                    align="center"
                >
                    Create New Password
                </Typography>
            </div>
        </Box>
        {(errorMessage !== '') ?
            (<Alert severity="error" style={{maxWidth: "400px", marginLeft: "65px"}} p={1}> {errorMessage}</Alert>)
            : (null)
        }
        <Box style={{marginLeft: "65px", marginTop:"-15px"}}>
            <div>
                <TextField
                    fullWidth
                    label="Password"
                    margin="normal"
                    name="password"
                    type="password"
                    defaultValue=''
                    variant="outlined"
                    style={{maxWidth: "432px"}}
                    onChange={(e)=>{setPassword(e.target.value)}}
                />
                <TextField
                    fullWidth
                    label="Confirm Password"
                    margin="normal"
                    name="password2"
                    type="password"
                    defaultValue=''
                    variant="outlined"
                    style={{maxWidth: "432px", marginTop:"10px"}}
                    onChange={(e)=>{setPassword2(e.target.value)}}
                />
            </div>

        </Box>
        <Box mt={1}>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
            <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                style={{maxWidth: "432px", marginLeft: "65px"}}
                onClick={()=>{
                    setLoading(true)
                    setErrorMessage('')
                    var password_check =  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/;
                    if(password2 !== password){
                        setErrorMessage("Repeated password does not match")

                    }else if(!password.match(password_check)){
                        setErrorMessage("Password must be at least 8 characters long and include at least 1 digit and 1 special character.")
                    }else {
                        setErrorMessage("")
                        props.user.password = password
                        updatePassword(props.user, (response) => {
                            window.location = "/login"
                            setLoading(false)
                        })
                    }
                }
                }
            >
                Save
            </Button>)}
        </Box>
    </>);
}

const ForgotPassword = () => {
    const classes = accountStyles();
    const user= useState({})[0];
    return (
        <div>
            <NavBar />
            <div className={classes.banner} style={{display:"none"}}>
                <Container maxWidth="md">
                    <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="center"
                    >
                        <Box
                            alignItems="center"
                            display="flex"
                        >
                            <Tooltip title="">
                                <img
                                    alt="Auth0"
                                    className={classes.logo}
                                    src={methodIcons['LOGO']}
                                />
                            </Tooltip>
                        </Box>
                    </Box>
                </Container>
            </div>
            <Container
                className={classes.cardContainer}
                maxWidth="sm"
            >
                <StepWizard initialStep={1}>
                    <SendResetPasswordEmail user={user}/>
                    <SendCode user={user} />
                    <ResetPassword user={user}/>
                </StepWizard>

                <Box my={3}>
                </Box>
                <Container style={{marginLeft:"45px", marginTop:"-10px"}}>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                    >
                        <Link
                            variant="body2"
                            href = "/login"
                            underline = 'always'
                            style={{color: "#006FFF",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                letterSpacing: "0.01px",
                                lineHeight: "20px"}}
                        >
                            Back to login
                        </Link>
                    </Typography>
                </Container>
            </Container>
        </div>
    );
};

export default ForgotPassword;
