import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';
import BlockWidget from './BlockWidget';
import ChartWidget from './ChartWidget';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    border: '1px solid #EEEEEE',
    borderRadius: '4px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem',
  },
  spacer: {
    flexGrow: 1,
  },
  body: {
    padding: '0.5rem',
    flexGrow: 1,
  },
  chart: {
    height: 200,
  }
});

export default function Widget({ id, configData, recordCount, filters, duration, className, ...rest }) {
  const classes = useStyles();
  const configuration = configData[0];

  return (
    <Card className={classes.root}>
      {configuration.layout ==="full-width" ? (
        <BlockWidget blockConfig={configuration} id={id} duration={duration} filters={filters} recordCount={recordCount}/>
      ): (
        <ChartWidget blockConfig={configuration} id={id} duration={duration} filters={filters} recordCount={recordCount}/>
      )}
    </Card>
  );
}
