export function FormatDate (timestampVal) {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  if (timestampVal !== undefined && timestampVal !== '') {
    let unformattedDate = new Date(timestampVal);
    let year = unformattedDate.getFullYear();
    let month = months[unformattedDate.getMonth()];
    let date = unformattedDate.getDate();
    let formattedDate = date + ' ' + month + ', ' + year + ' ';
    return formattedDate;
  } else {
    return ' ';
  }
};
