import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Box, Avatar } from '@material-ui/core';
import styles from './datasources.module.css';
import { useHistory } from 'react-router-dom';
import SyncIcon from '@material-ui/icons/Sync';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import LinkIcon from '@material-ui/icons/Link';
import axios from 'axios';

const useStyles = makeStyles({
  actionArea: {
    width: '324px',
    height: '300px',
    '&:hover $focusHighlight': {
      opacity: 0,
    },
  },
  focusHighlight: {},
  cardButton: {
    borderRadius: '16px',
    backgroundColor: '#F1F3F3',
    fontSize: '12px',
    width: '112px',
    height: '32px',
    padding: '8px',
  },
  connectorCircle: {
    backgroundColor: '#e7f8f1',
    borderRadius: '100%',
    width: '32px',
    height: '32px',
  },
  linkIcon: {
    color: '#09BC71',
  },
});

const SOURCE_CONFIG = {
  salesforce: {
    title: 'Salesforce',
    image: require('./../../../assets/icons/salesforce.png'),
    path: '/integrations/salesforce',
    status: false,
  },
  outlook: {
    title: 'Outlook',
    image: require('./../../../assets/icons/outlook.png'),
    path: '/integrations/outlook',
    status: false,
  },
  zendesk: {
    title: 'Zendesk',
    image: require('./../../../assets/icons/zendesk.png'),
    path: '/integrations/zendesk',
    status: false,
    width: '130px'
  },
  csv: {
    title: 'CSV Data',
    image: require('./../../../assets/icons/csv_upload.png'),
    path: '/integrations/csv',
    status: false,
    width: '130px'
  },
};

const DataCard = props => {
  const [jobProgess, setJobProgress] = useState(0);
  const [integrationStatus, setIntegrationStatus] = useState(props.integrationData.status);
  const classes = useStyles();
  const history = useHistory();
  const handleClickOpen = path => {
    history.push({
      pathname: path,
    });
  };

  const { name } = props;
  const source = SOURCE_CONFIG[name];

  useEffect(() => {
    if (jobProgess !== 100 && integrationStatus === 'Processing') {
      const interval = setInterval(() => {
        clearInterval(interval);
        setJobProgress(100);
        let apiUrl = process.env.REACT_APP_API_BASE_URL + 'connector/job_status';
        axios({
          method: 'post',
          url: apiUrl,
          data: {
            job_id: props.integrationData.job_id,
          },
          headers: {},
        })
          .then(function (response) {
            setJobProgress(response.percentage);
            if (response.percentage === 100) {
              setIntegrationStatus('Connected');
            }
          })
          .catch(function (response) {
            // setJobProgress(100);
            console.log(response);
          });
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [integrationStatus, jobProgess, props.integrationData.job_id]);
  return (
    <Card className={styles.dataCardContainer}>
      {integrationStatus === 'Connected' && (
        <span>
          <Box display="flex" mt={3} mr={2} className={styles.cardConnectorIcon}>
            {' '}
            <Box flexGrow="1" />{' '}
            <Avatar className={classes.connectorCircle}>
              <LinkIcon className={classes.linkIcon} />
            </Avatar>
          </Box>
          <div style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Box
              display="flex"
              style={{ padding: '0px 0px 15px 0px !important' }}
              mt={3}
              mx={2}
              className={styles.cardContentBtn}
            >
              <Button
                variant="contained"
                className={classes.cardButton}
                startIcon={<SyncIcon />}
                onClick={props.syncFunction}
              >
                SYNC NOW
              </Button>
              <Box flexGrow="1" />
              <Button
                variant="contained"
                className={classes.cardButton}
                startIcon={<LinkOffIcon />}
                onClick={props.disconnectFunction}
              >
                DISCONNECT
              </Button>
            </Box>

            <CardContent align="center" className={styles.cardContentIcon} style={{ marginTop: '45px' }}>
              <CardMedia className={styles.media} image={source.image} title="DataSource image"  style={{ width : source.width }}/>
            </CardContent>

            <CardContent align="center" className={styles.cardContentTitle}>
              <Typography gutterBottom variant="h5" component="h2" align="center">
                {source.title}
              </Typography>
            </CardContent>
          </div>
        </span>
      )}

      {integrationStatus === 'Disconnected' && (
        <div style={{ justifyContent: 'center', alignItems: 'center' }} className={styles.hoverCard}>
          <CardContent align="center" className={styles.cardContentIcon}>
            <CardMedia className={styles.media} image={source.image} title="DataSource image" style={{ width : source.width }}/>
          </CardContent>
          <CardContent align="center" className={styles.cardContentTitle}>
            <Typography gutterBottom variant="h5" component="h2" align="center">
              {source.title}
            </Typography>
          </CardContent>
          <CardContent
            align="center"
            className={styles.cardContentBtn}
            style={{ padding: '15px 0px 0px 0px !important', justifyContent: 'center' }}
          >
            <Button
              color="primary"
              type="submit"
              variant="contained"
              style={{ fontSize: '15px' }}
              onClick={evt => handleClickOpen(source.path)}
              className={styles.hiddenButton}
            >
              {source.title === "CSV Data" ? ('Upload') : ('Connect')}
            </Button>
          </CardContent>
        </div>
      )}
      {integrationStatus === 'Processing' && (
        <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: '75px' }}>
          <CardContent align="center" className={styles.cardContentIcon}>
            <CardMedia className={styles.media} image={source.image} title="DataSource image"  style={{ width : source.width }}/>
          </CardContent>

          <CardContent align="center" className={styles.cardContentTitle}>
            <Typography variant="h5" component="h2" align="center">
              {source.title}
            </Typography>
            {jobProgess === 100 && (
              <>
                <div className={styles.processingLoader}> </div>
                <Typography variant="body2" color="textSecondary" style={{ marginTop: '8px' }}>
                  Processing your data...({jobProgess}%)
                </Typography>
              </>
            )}
          </CardContent>
        </div>
      )}
    </Card>
  );
};
export default DataCard;
