import { makeStyles } from '@material-ui/core';

export const userRolesDialogStyles = makeStyles((theme) => ({
    chips: {
        backgroundColor: '#ECEFF1',
        borderRadius: '16.5px',
        height: '32px',
      },
      chipRoot: {
        backgroundColor: '#ECEFF1',
        height: '20px',
        width: '66px',
        marginTop: '8px',
      },
      chipLabel: {
        padding: '0px',
      }
}));