import { makeStyles } from '@material-ui/core';
import LoaderGif from '../../assets/loader.gif';

export const useStyles = makeStyles({
  root: {
    backgroundColor: '#FFFFFF',
  },
  customTextField: {
    color: '#263238',
    fontFamily: 'Roboto',
    fontSize: '14px',
    textAlign: 'left',
    marginTop: '2px',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  dropdownMultiSelect: {
    padding: '0px',
    color: '#263238',
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'left',
    paddingRight: '8px',
  },
  tableHead: {
    color: '#546E7A',
    backgroundColor: 'white',
    fontSize: '12px',
    letterSpacing: '0.3px',
    lineHeight: '14px',
    textAlign: 'left',
    border: 'none',
    fontWeight: '400',
    padding: '8px',
  },
  tableProductHead: {
    color: '#546E7A',
    backgroundColor: 'white',
    fontSize: '12px',
    letterSpacing: '0.3px',
    lineHeight: '14px',
    textAlign: 'left',
    border: 'none',
    fontWeight: '400',
    padding: '8px',
    width: '109px',
  },
  formControl: {
    width: '200px',
    height: '42px',
    padding: '0px',
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
    },
    WebkitBoxShadow: '0 0 0 1000px white inset',
  },
  formSort: {
    width: '252px',
    height: '42px',
    padding: '0px',
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
    },
    WebkitBoxShadow: '0 0 0 1000px white inset',
  },
  queryField: {
    width: '291px',
    height: '42px',
  },
  resetBtn: {
    width: '133px',
    height: '39px',
    backgroundColor: '#F5F5F5',
    borderRadius: '4px',
    marginTop: '-5px',
  },
  selectedCheckBox: {
    backgroundColor: 'white !important',
  },
  divider: {
    marginTop: '8px',
  },
  removeSelectFocus: {
    height: '39px',
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      backgroundColor: '#FFFFFF',
      outline: 'none',
    },
  },
  label: {
    color: '#263238',
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '5px',
    textAlign: 'left',
    marginTop: '-2px',
  },
  checkBoxDimensions: {
    width: '18px',
    height: '18px',
  },
  chips: {
    backgroundColor: '#ECEFF1',
    borderRadius: '16.5px',
    height: '32px',
  },
  filterHeight: {
    height: '42px',
    fontSize: '14px',
  },
  chevron: {
    width: '10px',
    height: '16px',
  },
  greenCircle: {
    display: 'inline-block',
    height: '8px',
    width: '8px',
    backgroundColor: '#09BC71',
    borderRadius: '50%',
  },
  redCircle: {
    display: 'inline-block',
    height: '8px',
    width: '8px',
    backgroundColor: '#D32F2F',
    borderRadius: '100%',
  },
  iconClasses: {
    width: '12px',
    height: '12px',
    color: '#c1c4c6',
  },
  headerTooltip: {
    maxWidth: '120px',
  },
  columnTruncatedTooltip: {
    backgroundColor: 'white',
    color: '#546E7A',
    fontSize: '12px',
    letterSpacing: '0.1px',
    lineHeight: '18px',
    textAlign: 'left',
    border: '1px solid #EEEEEE',
    borderRadius: 4,
    fontWeight: '400',
    maxWidth: '380px',
    maxHeight: '220px',
    overflow: 'scroll',
    whiteSpace: 'pre-line',
  },
  columnTruncatedArrow: {
    fontSize: '14px',
    width: 17,
    '&::before': {
      border: '1px solid #EEEEEE',
      backgroundColor: '#fff',
      boxSizing: 'border-box',
    },
  },
  customerAttribute: {
    color: '#546E7A',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: '20px',
    textAlign: 'left',
  },
  customerAttributeValue: {
    color: '#263238',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: '20px',
    textAlign: 'left',
  },
  verticalAlignText: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    marginRight: '16px',
  },
  searchButton: {
    borderRadius: '4px',
    backgroundColor: '#0061FF',
    width: '56px',
    height: '42px',
    color: '#FFFFFF',
    marginLeft: '8px',
    '&:hover': {
      backgroundColor: '#0061FF',
    },
  },
  filterBtn: {
    color: '#0061FF',
    backgroundColor: 'white !important',
    boxShadow: 'none !important',
  },
  filterCount: {
    backgroundColor: '#0061FF',
    width: '24px',
    height: '24px',
    marginLeft: '8px',
    fontSize: '14px',
  },
  filterChips: {
    color: 'blue'
  },
  sortBtn:{
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    width: '42px',
    height: '42px',
    backgroundColor: 'white',
    marginLeft: '16px'
  },
  healthTrendloader: {
    backgroundImage: `url(${LoaderGif})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '50px',
    width: '50px',
    zIndex: '1',
    backgroundSize: 'contain',
    left: '50%',
    position: 'relative',
    marginTop: '50px'
  },
  selectViews: {
    width: '53px',
    height: '16px',
    border: '1px solid rgba(0, 0, 0, 0.23)'
  }
});
