import React, { useState } from 'react';
import { Box, Typography, Container, TextField, Divider, Button } from '@material-ui/core';
import SecondaryNav from './../SecondaryNav';
import { userRolesStyles } from './userRolesDataTable.theme';
import IconButton from '@material-ui/core/IconButton';
import { Search as SearchIcon } from 'react-feather';
import InviteUsersDialog from './inviteUsersDialog'
import UserRolesDataTable from './userRolesDataTable';
import { getUserRoles, getDataTableConfig } from './UserRoleApi';
import { useHistory } from 'react-router-dom';
import UserSettingsMenu from './UserSettingsMenu'
import styles from './usersettings.module.css';

const UserSettings = () => {
  const classes = userRolesStyles();
  const [loadingFlag, setLoadingFlag] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [roles, setRoles] = useState([]);
  const [openDialogFlag, setOpenDialogFlag] = useState(false);
  const [enteredEmails, setEnteredEmails] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [filterOptions, setFilterOptions] = useState([]);
  const history = useHistory();

  let requestData = {
    search: searchText,
    page_number: pageNumber
  }

  const search = () => {
    setPageNumber(1);
    handleQueryChange();
  }

  const handleQueryChange = () => {
    setLoadingFlag(true);    
    getUserRoles(requestData).then(response => {
      if (response.data.hasOwnProperty('Error')) {
        //setErrorFlag(true);
      } else {
        setRoles(response.data.data);        
        setTotalPages(response.data.number_of_pages);
      }
      setLoadingFlag(false);
    });
  }  

  React.useEffect(() => {    
    handleQueryChange();
  }, [pageNumber]);

  React.useEffect(() => {
    getFilterOptions();
  }, []);

  const getFilterOptions = () => {
    getDataTableConfig().then(response => {
        if (response.data.hasOwnProperty('Error')) {
        } else {
          setFilterOptions(response.data.columns);
        }
      });
  }

  const handleInvite = () => {  
    setOpenDialogFlag(true);
  };

  const closeDialogFun = () => {
    setEnteredEmails([]);
    setErrorMessage('');
    setOpenDialogFlag(false);    
  };

  const addInvites = () => {
    var error = {};
    if(enteredEmails.length===0)
      error['mailsCount'] = 'Please add at least one email';
      
    if (Object.keys(error).length !== 0) {
        setErrorMessage(error);
        return;
    }
    history.push({
        pathname: '/usersettings/assignroles',
        state: {mails: enteredEmails}
      });
  }

  return (
    <div className={classes.root}>
      <SecondaryNav />
      <UserSettingsMenu></UserSettingsMenu>
      <Container
        style={{
          height: 'calc(100vh - 70px)',
          marginTop: '64px',
          overflow: 'overlay',
          paddingLeft: '0px',
          paddingRight: '16px',
          maxWidth: '100%',
        }}
        className="hideScroll"
      >
        <Box display="flex" alignItems="center" pt={2} pb={2}>
          <Box flexGrow={1}>
            <Typography variant="h5" style={{paddingLeft: '16px' }} color="textPrimary">
              <span>Users & Roles</span>
            </Typography>
          </Box>

          <Box mr={2} ml={0}>
            <TextField
              className={classes.queryField}
              InputProps={{
                classes: {
                  root: classes.filterHeight,
                },
              }}
              onChange={e => setSearchText(e.target.value)}
              onKeyPress={ev => {
                if (ev.key === 'Enter') {
                  search();
                  ev.preventDefault();
                }
              }}
              placeholder="Search..."
              value={searchText}
              variant="outlined"
              size="small"
            />
            <IconButton aria-label="search" className={classes.searchButton} onClick={search} >
              <SearchIcon />
            </IconButton>

            <InviteUsersDialog
              openDialog={openDialogFlag}
              closeDialogFun={closeDialogFun}
              enteredEmails={enteredEmails}
              setEnteredEmails={setEnteredEmails}
              addInvites={addInvites}
              errorMessage={errorMessage}
            />
          </Box>
          <Box mt={2} mb={2}>
            <Divider orientation="vertical" />
          </Box>
          <Box>
            <Button
              variant="contained"
              className={classes.backButton}
              onClick={handleInvite}
            >
              INVITE USERS
            </Button>
          </Box>
        </Box>
        <Divider></Divider>

        {loadingFlag ? (<div className={styles.loader}> </div>)
        :(

        <UserRolesDataTable
          userRoles={roles}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          totalPages={totalPages}
          setLoadingFlag={setLoadingFlag}
          handleQueryChange={handleQueryChange}
          filterOptions={filterOptions}
        ></UserRolesDataTable>

        )}        
      </Container>

    </div>
  );
};

export default UserSettings;
