import React, { useState, useEffect } from 'react';
import { makeStyles, Box, TextField, DialogTitle, DialogContent, Checkbox, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { updateReportConfig } from './../ReportsAPI';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { FormatDate } from '../../common/FormatDate';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  dialogPaper: {
    minWidth: '700px',
    maxHeight: '542px',
  },
});

const ShareModal = ({ reportName, reportId, accessCode, createdDate, openDialogFlag, expirationDate, ...rest }) => {
  const classes = useStyles();
  const [dateInDateFormat, setDateInDateFormat] = useState(new Date());
  const [addExpirationDate, setAddExpirationDate] = React.useState(false);
  const [ifChecked, setIfChecked] = useState(false);
  const [shareExpirationDate, setShareExpirationDate] = useState('');

  useEffect(() => {
    setShareExpirationDate(expirationDate.length > 0 ? '\nExpiration Date: ' + FormatDate(expirationDate) : '');
    setDateInDateFormat(expirationDate.length > 0 ? expirationDate : new Date());
    setIfChecked(expirationDate.length > 0 ? true : false);
    setAddExpirationDate(expirationDate.length > 0 ? true : false);
  }, []);

  const handleDateChange = date => {
    setDateInDateFormat(date);
    setShareExpirationDate('\nExpiration Date: ' + FormatDate(date));
    updateReportConfig({
      report_id: reportId,
      expiration_date: ifChecked ? date : '',
    }).then(response => {
      if (response.data.response !== 'success') {
        // TO DO: Update with error flag
        console.log('Error in saving Expiration Date');
      }
    });
  };

  const handleExpiration = () => {
    setIfChecked(!ifChecked);
    setAddExpirationDate(!addExpirationDate);
    if (ifChecked) {
      setDateInDateFormat(new Date());
    }
    setShareExpirationDate(!ifChecked ? '\nExpiration Date: ' + FormatDate(dateInDateFormat) : '');
    updateReportConfig({
      report_id: reportId,
      expiration_date: !ifChecked ? dateInDateFormat : '',
    }).then(response => {
      if (response.data.response !== 'success') {
        // TO DO: Update with error flag
        console.log('Error in saving Expiration Date');
      }
    });
  };

  const handleClose = () => {
    navigator.clipboard.writeText(reportShareableData);
    rest.handleClose('copied');
  };

  const reportShareableData =
    'Report: ' +
    reportName +
    '\nReport Generated on: ' +
    FormatDate(createdDate) +
    '\nReport Link: ' +
    window.location.origin +
    '/reports/view/' +
    localStorage.getItem('company_name') +
    '/' +
    reportId +
    '\nAccess Code: ' +
    accessCode +
    shareExpirationDate;

  return (
    <Dialog open={openDialogFlag} onClose={rest.handleClose} classes={{ paper: classes.dialogPaper }}>
      <Grid justify="space-between" container>
        <Grid item>
          <DialogTitle id="add-new-report-dialog" style={{ paddingBottom: '0px' }}>
            Share Report
          </DialogTitle>
        </Grid>

        <Grid item>
          <div>
            <IconButton onClick={rest.handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </Grid>
      </Grid>

      <DialogContent>
        <Box mt={2} mb={0}>
          <TextField
            id="outlined-read-only-share-report"
            label="Copy to Share"
            value={reportShareableData}
            multiline
            rows={6}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
          />
          <Box mt={1.5}>
            <FormControlLabel
              control={
                <Checkbox
                  checkedIcon={<CheckBoxIcon />}
                  checked={ifChecked}
                  color="primary"
                  id={'revenue'}
                  onChange={handleExpiration}
                />
              }
              label=" Add link expiration date"
            />
          </Box>
          <Box display="flex" mt={-1}>
            {addExpirationDate && (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  inputVariant="outlined"
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  minDate={new Date()}
                  value={dateInDateFormat}
                  onChange={d => handleDateChange(d)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  autoOk="true"
                />
              </MuiPickersUtilsProvider>
            )}
          </Box>
        </Box>
      </DialogContent>

      <DialogActions style={{ justifyContent: 'flex-start', padding: '8px 24px 16px 24px' }}>
        <Button onClick={handleClose} color="primary" variant="contained">
          copy to clipboard
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ShareModal;
