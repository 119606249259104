import React, { useState, useCallback, useRef, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { Box, Typography } from '@material-ui/core';
import { insightsStyles } from './insights.theme';
import CustomerCard from './CustomerCard';
import { updateInsightAPI } from './InsightsAPI';
import cloneDeep from 'lodash/cloneDeep';
import Grow from '@material-ui/core/Grow';
import classes from './insights.module.css';
import { updateCard, updateInsight, updateTaskNote } from './KanbanFunctions';
import { useLocation } from 'react-router-dom';

const insight_statuses = ['suggestion', 'to_do', 'in_progress', 'done'];
const labelsMap = {
  suggestion: 'Suggestions',
  to_do: 'To Do',
  in_progress: 'In Progress',
  done: 'Done',
};

const Board = ({ insightsData, ...props }) => {
  const [insights, setInsights] = useState(insightsData);
  const styles = insightsStyles();
  const [showSuggestions, setShowSuggestions] = useState(false);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const isUrlCompany = useQuery().get('company');

  useEffect(() => {
    let timer1 = setTimeout(() => setShowSuggestions(true), 1.5 * 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  const changeCardStatus = useCallback(
    (id, status) => {
      let tempArr = updateCard(insights, id, status);
      let card = insights.find(card => card.company_id === id);
      const cardIndex = insights.indexOf(card);
      if (status === 'done') {
        for (var i = 0; i < tempArr[cardIndex].tasks.length; i++) {
          if (tempArr[cardIndex].tasks[i].is_completed !== true) {
            tempArr[cardIndex].tasks[i].is_completed = true;
          }
        }
      }
      setInsights(tempArr);
    },
    [insights],
  );

  const handleUpdateInsight = useCallback(
    (id, itemDetails) => {
      updateInsight(insights, id, itemDetails).then(function (response) {
        setInsights(response);
      });
    },
    [insights],
  );

  const changeInsightStatus = useCallback(
    ({ company_id, item_id, action, is_completed, is_dismissed }) => {
      let card = insights.find(card => card.company_id === company_id);
      let action_item = card.insights.find(item => item.item_id === item_id);
      const cardIndex = insights.indexOf(card);
      const actionItemIndex = card.insights.indexOf(action_item);

      if (action === 'checked') {
        action_item = { ...action_item, is_completed };
        let newActionItems = update(card.insights, {
          [actionItemIndex]: { $set: action_item },
        });
        let newInsights = cloneDeep(insights);
        newInsights[cardIndex].insights = newActionItems;
        setInsights(newInsights);
      } else if (action === 'dismissed') {
        action_item = { ...action_item, is_dismissed };
        let newActionItems = update(card.insights, {
          [actionItemIndex]: { $set: action_item },
        });
        let newInsights = cloneDeep(insights);
        newInsights[cardIndex].insights = newActionItems;
        setInsights(newInsights);
      }

      let requestData = {
        company_id: card.company_id,
        company_name: card.company_name,
        insights: [
          {
            item_id: item_id,
            is_completed: is_completed ? is_completed : action_item.is_completed,
            is_new: false,
            is_dismissed: is_dismissed ? is_dismissed : false,
          },
        ],
      };
      updateInsightAPI(requestData).then(response => {
        if (response.data.hasOwnProperty('Error')) {
          console.log('We ran into an error');
        } else {
          console.log('successfully updated');
        }
      });
    },
    [insights],
  );

  const changeNewStatus = useCallback(
    ({ company_id }) => {
      let card = insights.find(card => card.company_id === company_id);
      const cardIndex = insights.indexOf(card);
      let requestData = {
        company_id: card.company_id,
        company_name: card.company_name,
        insights: [],
      };
      let newInsights = cloneDeep(insights);
      let newActionItems = newInsights[cardIndex].insights;
      for (var i = 0; i < newActionItems.length; i++) {
        if (newActionItems[i].is_new === true) {
          newActionItems[i].is_new = false;
          let newObj = {
            item_id: newActionItems[i].item_id,
            is_completed: newActionItems[i].is_completed,
            is_new: false,
            is_converted_to_task: newActionItems[i].is_converted_to_task,
            is_dismissed: newActionItems[i].is_dismissed,
          };
          requestData.insights.push(newObj);
        }
      }
      setInsights(newInsights);
      if (requestData.insights.length > 0) {
        updateInsightAPI(requestData).then(response => {
          if (response.data.hasOwnProperty('Error')) {
            console.log('We ran into an error');
          } else {
            console.log('successfully updated');
          }
        });
      }
    },
    [insights],
  );

  const handleUpdateTaskNote = useCallback(
    (id, type, action, itemDetails) => {
      updateTaskNote(insights, id, type, action, itemDetails).then(function (response) {
        let card = insights.find(card => card.company_id === id);
        if (card.status === 'suggestion') {
          let updatedInsights = updateCard(response, id, 'to_do');
          setInsights(updatedInsights);
        } else {
          setInsights(response);
        }
      });
    },
    [insights],
  );

  const handleUpdateConvert = useCallback(
    (id, type, action, itemDetails, insightDetails) => {
      updateTaskNote(insights, id, type, action, itemDetails).then(function (response) {
        updateInsight(response, id, insightDetails).then(function (response) {
          setInsights(response);
        });
      });
    },
    [insights],
  );

  const suggestionsCount = insights.filter(item => item.status === 'suggestion').length;

  return (
    <main>
      <DndProvider backend={HTML5Backend}>
        <section className={styles.board}>
          {insight_statuses.map((channel, i) => (
            <KanbanColumn key={i} status={channel} changeCardStatus={changeCardStatus}>
              <div className={channel === 'suggestion' ? styles.suggestionsColumn : styles.column}>
                <Typography className={styles.columnHead} variant="subtitle1">
                  {labelsMap[channel]}
                </Typography>
                {!showSuggestions && channel === 'suggestion' ? (
                  <>
                    <div className={classes.processingLoader} />
                    <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center' }}>
                      Looking for the best suggestions...
                    </Typography>
                  </>
                ) : (
                  <div>
                    {channel === 'suggestion' ? (
                      <>
                        {suggestionsCount <= 0 ? (
                          <>
                            <Box display="flex" justify="center" mt={3} mb={3} style={{ justifyContent: 'center' }}>
                              <img
                                src={require('./../../assets/insights-empty.png')}
                                alt={'Empty'}
                                style={{ width: '70px', height: '70px' }}
                              />
                            </Box>
                            <Typography gutterBottom variant="body2" color="textSecondary" align="center">
                              There are no new suggestions.
                            </Typography>
                          </>
                        ) : (
                          <>
                            {insights
                              .filter(
                                item =>
                                  item.status === 'suggestion' &&
                                  (props.searchVal !== null ? item.company_name.includes(props.searchVal) : true),
                              )
                              .map((item, index) => (
                                <Grow
                                  in={true}
                                  key={item.company_id}
                                  style={{ opacity: 1, transformOrigin: '0, 0, 0' }}
                                  {...(true ? { timeout: 750 * (index + 1) } : {})}
                                >
                                  <div>
                                    <KanbanItem key={item.company_id} id={item.company_id}>
                                      <CustomerCard
                                        cardDetails={item}
                                        changeCardStatus={changeCardStatus}
                                        changeInsightStatus={changeInsightStatus}
                                        changeNewStatus={changeNewStatus}
                                        handleUpdateInsight={handleUpdateInsight}
                                        handleUpdateTaskNote={handleUpdateTaskNote}
                                        handleUpdateConvert={handleUpdateConvert}
                                        handleDirectUrl={isUrlCompany}
                                      />
                                    </KanbanItem>
                                  </div>
                                </Grow>
                              ))}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {insights
                          .filter(
                            item =>
                              item.status === channel &&
                              (props.searchVal !== null ? item.company_name.includes(props.searchVal) : true),
                          )
                          .map((item, index) => (
                            <KanbanItem key={item.company_id} id={item.company_id}>
                              <CustomerCard
                                cardDetails={item}
                                changeCardStatus={changeCardStatus}
                                changeInsightStatus={changeInsightStatus}
                                changeNewStatus={changeNewStatus}
                                handleUpdateInsight={handleUpdateInsight}
                                handleUpdateTaskNote={handleUpdateTaskNote}
                                handleUpdateConvert={handleUpdateConvert}
                                handleDirectUrl={isUrlCompany}
                              />
                            </KanbanItem>
                          ))}
                      </>
                    )}
                  </div>
                )}
              </div>
            </KanbanColumn>
          ))}
        </section>
      </DndProvider>
    </main>
  );
};

export default Board;

const KanbanColumn = ({ status, changeCardStatus, children }) => {
  const ref = useRef(null);
  const allowDrop = () => {
    return status !== 'suggestion';
  };
  const [, drop] = useDrop({
    type: 'card',
    accept: 'card',
    canDrop: allowDrop,
    drop(item) {
      changeCardStatus(item.id, status);
    },
  });
  drop(ref);
  return <div ref={ref}> {children}</div>;
};

const KanbanItem = ({ id, children }) => {
  const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: { type: 'card', id },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.5 : 1;
  drag(ref);
  return (
    <>
      <div ref={ref} style={{ position: 'relative', opacity }}>
        {children}
      </div>
    </>
  );
};
