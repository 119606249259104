import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { useTheme, Box, Typography } from '@material-ui/core';
import { reportsStyles } from '../../reports.theme';

const DoughnutChart = ({ data: dataProp, blockName, ...rest }) => {
  const theme = useTheme();
  const styles = reportsStyles();

  const data = {
    datasets: dataProp.datasets.map(dataset => ({
      ...dataset,
      borderWidth: 3,
      borderColor: '#FFFFFF',
      hoverBorderColor: [theme.palette.high.main, theme.palette.medium.main, theme.palette.low.main],
      // hoverBorderColor: [theme.palette.high.main, theme.palette.low.main, theme.palette.low.main],
    })),
    labels: dataProp.labels,
    total: dataProp.total,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    legend: {
      display: false,
    },
    layout: {
      padding: 0,
    },
    tooltips: {
      enabled: true,
      displayColors: false,
      mode: 'index',
      intersect: false,
      caretSize: 10,
      yPadding: 20,
      xPadding: 20,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: '#FFFFFF',
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        label(tooltipItem, _data) {
          const label = _data.labels[tooltipItem.index];
          const value = _data.datasets[0].data[tooltipItem.index];

          return `${label}: ${value}%`;
        },
        title: function (tooltipItem, _data) {
          return;
        },
      },
    },
  };

  return (
    <>
      <Doughnut data={data} options={options} {...rest} />
      <Box display="flex" mt={1} justifyContent="center">
          <Box m={0.5} display="flex"><span className={styles.greenCircle}></span> <Typography variant="body1" className={styles.smallFont}> &nbsp; Upsells</Typography></Box>
          <Box m={0.5} display="flex"><span className={styles.yellowCircle}></span> <Typography variant="body1" className={styles.smallFont}> &nbsp; Renewals</Typography> </Box>
          <Box m={0.5} display="flex"><span className={styles.redCircle}></span> <Typography variant="body1" className={styles.smallFont}> &nbsp; Churns</Typography></Box>
      </Box>
    </>
  );
};

DoughnutChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
};

export default DoughnutChart;
