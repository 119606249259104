import { makeStyles } from '@material-ui/core';
import LoaderGif from '../../assets/loader.gif';

export const reportsStyles = makeStyles(theme => ({
  greenLabel: {
    backgroundColor: theme.palette.high.light,
    color: theme.palette.high.main,
    width: '86px',
    height: '20px',
    borderRadius: '15px',
    fontSize: '12px',
    fontWeight: '500',
    margin: 'auto',
    padding: '2px 2px 0px 2px',
    letterSpacing: '0.83px',
  },
  redLabel: {
    backgroundColor: theme.palette.low.light,
    color: theme.palette.low.main,
    width: '86px',
    height: '20px',
    borderRadius: '15px',
    fontSize: '12px',
    fontWeight: '500',
    margin: 'auto',
    padding: '2px 2px 0px 2px',
    letterSpacing: '0.83px',
  },
  yellowLabel: {
    backgroundColor: theme.palette.medium.light,
    color: theme.palette.medium.main,
    width: '86px',
    height: '20px',
    borderRadius: '15px',
    fontSize: '12px',
    fontWeight: '500',
    margin: 'auto',
    padding: '2px 2px 0px 2px',
    letterSpacing: '0.83px',
  },
  blueLabel: {
    backgroundColor: '#CDE0FF',
    color: '#0061FF',
    width: '180px',
    height: '20px',
    borderRadius: '15px',
    fontSize: '12px',
    fontWeight: '500',
    margin: 'auto',
    padding: '2px 2px 0px 2px',
    letterSpacing: '0.83px',
  },
  greenCircle: {
    display: 'inline-block',
    height: '8px',
    width: '8px',
    backgroundColor: theme.palette.high.main,
    borderRadius: '50%',
    marginTop: '4px',
  },
  yellowCircle: {
    display: 'inline-block',
    height: '8px',
    width: '8px',
    backgroundColor: theme.palette.medium.main,
    borderRadius: '100%',
    marginTop: '4px',
  },
  redCircle: {
    display: 'inline-block',
    height: '8px',
    width: '8px',
    backgroundColor: theme.palette.low.main,
    borderRadius: '100%',
    marginTop: '4px',
  },
  smallFont: {
    color: theme.palette.text.secondary,
    fontFamily: 'Roboto',
    fontSize: '12px',
    letterSpacing: '-0.04px',
    lineHeight: '18px',
    textAlign: 'left',
  },
  dot: {
    height: '8px',
    width: '8px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '8px',
  },
  filterBtn: {
    color: '#0061FF',
    backgroundColor: 'white !important',
    boxShadow: 'none !important',
  },
  filterCount: {
    backgroundColor: '#0061FF',
    width: '24px',
    height: '24px',
    marginLeft: '8px',
    fontSize: '14px',
  },
  chartDataloader: {
    backgroundImage: `url(${LoaderGif})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    margin: 'auto',
    height: '50px',
    width: '50px',
    zIndex: '1',
    backgroundSize: 'contain',
    marginTop: '100px',
  },
  loader: {
    backgroundImage: `url(${LoaderGif})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '50px',
    width: '50px',
    zIndex: '1',
    backgroundSize: 'contain',
    top: 'calc(50vh - 25px)',
    left: 'calc(50vw - 25px)',
    position: 'absolute',
  },
  newChartLoader: {
    backgroundImage: `url(${LoaderGif})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '50px',
    width: '50px',
    zIndex: '1',
    backgroundSize: 'contain',
    top: '60%',
    left: '50%',
    position: 'absolute',
  },
  reportFooter: {
    backgroundColor: '#230045',
    borderRadius: '15px',
    height: '152px',
    padding: '24px',
  },
  reportFooterImg: {
    width: '180px',
    height: '42px',
    margin: 'auto',
  },
  snackbar: {
    minWidth: '100px',
    marginTop: '55px',
  },
}));
