import React from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  dialogPaper: {
    maxWidth: '496px',
    maxHeight: '542px',
  },
  dialogPadding: {
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  dataExportImg: {
    maxHeight: '280px',
  },
});

const ExportDialog = ({ openDialog, ...rest }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(openDialog);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paper: classes.dialogPaper }}>
      <img src={require('./../../../assets/data-export.png')} alt="Data Export" className={classes.dataExportImg} />
      <Box p={4}>
        <Typography variant="h4" color="textPrimary">Great Job!</Typography>
        <Box pt={2} pb={4}>
          <Typography variant="body2" color="textSecondary">
            Your data is in the process of getting transferred to Involve. This could take up to a few hours. You can
            safely close out of this window if you want. We will send you an email once the data transfer is complete.
          </Typography>
        </Box>
        <DialogActions style={{ padding: '0px' }}>
          <Button onClick={handleClose} color="primary" type="submit" variant="contained">
            OK
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default ExportDialog;
