import axios from 'axios';
import { attachToken } from '../../utils/auth';

export function getAllReports(callback, errorcallback) {
  return axios(
    attachToken({
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/report/get_all',
      data: {},
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function createReport(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/report/create',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function deleteReport(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/report/delete',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function viewReport(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/report/view_report',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function getReportsConfig(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/report/get_config',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}
export function updateReportConfig(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/report/update_config',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function getBlockStats(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/report/get_stats_data',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function getChartData(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/report/get_chart_data',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function createChart(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/report/create_chart',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function createBlock(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/report/create_block',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function getNumberRecords(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/report/get_number_records',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function getMetrics(callback, errorcallback) {
  return axios(
    attachToken({
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/report/get_metrics',
      data: {},
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function updateWidget(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/report/update_widget',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}
