import update from 'immutability-helper';
import cloneDeep from 'lodash/cloneDeep';
import { updateCardAPI, updateInsightAPI, updateTaskNoteAPI } from './InsightsAPI';

export function updateCard(insights, id, status) {
  let card = insights.find(card => card.company_id === id);
  const cardIndex = insights.indexOf(card);
  card = { ...card, status };
  let newCards = update(insights, {
    [cardIndex]: { $set: card },
  });
  let requestData = {
    company_id: card.company_id,
    company_name: card.company_name,
    status: status,
  };
  updateCardAPI(requestData).then(response => {
    if (response.data.hasOwnProperty('Error')) {
      console.log('We ran into an error');
    } else {
      console.log('successfully updated');
    }
  });
  return newCards;
}

export function updateInsight(insights, id, itemDetails) {
  let card = insights.find(card => card.company_id === id);
  const cardIndex = insights.indexOf(card);
  let newInsights = cloneDeep(insights);

  let requestData = {
    company_id: id,
    company_name: card.company_name,
    insights: [itemDetails],
  };
  let updatedInsights = updateInsightAPI(requestData).then(response => {
    if (response.data.hasOwnProperty('Error')) {
      console.log('We ran into an error');
    } else {
      let newActionItems = newInsights[cardIndex].insights;
      for (var i = 0; i < newActionItems.length; i++) {
        if (newActionItems[i].item_id === itemDetails.item_id) {
          newActionItems[i].is_converted_to_task = itemDetails.is_converted_to_task;
          newActionItems[i].is_dismissed = itemDetails.is_dismissed;
          newActionItems[i].is_new = itemDetails.is_new;
        }
      }
    }
    return newInsights;
  });
  return updatedInsights;
}

export function updateTaskNote(insights, id, type, action, itemDetails) {
  let card = insights.find(card => card.company_id === id);
  const cardIndex = insights.indexOf(card);
  let newInsights = cloneDeep(insights);

  let requestData = {
    company_id: id,
    company_name: card.company_name,
    action: action,
    type: type,
    item_id: action === 'create' ? null : itemDetails.item_id,
    description: itemDetails.description,
    is_completed: action === 'create' ? false : itemDetails.is_completed,
    is_deleted: action === 'create' ? false : itemDetails.is_deleted,
    due_date: itemDetails.due_date ? itemDetails.due_date : null,
  };
  let updatedInsights = updateTaskNoteAPI(requestData).then(response => {
    if (response.data.hasOwnProperty('Error')) {
      console.log('We ran into an error');
    } else if (response.data.hasOwnProperty('item_id')) {
      if (type === 'task') {
        if (action === 'create') {
          let tempTask = {
            item_id: response.data.item_id,
            description: itemDetails.description,
            is_completed: false,
            is_deleted: false,
            due_date: itemDetails.due_date ? itemDetails.due_date : '',
          };

          newInsights[cardIndex].tasks.push(tempTask);
        } else {
          let newActionItems = newInsights[cardIndex].tasks;
          for (var i = 0; i < newActionItems.length; i++) {
            if (newActionItems[i].item_id === itemDetails.item_id) {
              newActionItems[i].description = itemDetails.description;
              newActionItems[i].is_completed = itemDetails.is_completed;
              newActionItems[i].is_deleted = itemDetails.is_deleted;
              newActionItems[i].due_date = itemDetails.due_date ? itemDetails.due_date : null;
            }
          }
        }
      } else if (type === 'note') {
        if (action === 'create') {
          let tempTask = {
            item_id: response.data.item_id,
            description: itemDetails.description,
            is_deleted: false,
          };
          newInsights[cardIndex].notes.push(tempTask);
        } else {
          let newActionItems = newInsights[cardIndex].notes;
          for (var j = 0; j < newActionItems.length; j++) {
            if (newActionItems[j].item_id === itemDetails.item_id) {
              newActionItems[j].description = itemDetails.description;
              newActionItems[j].is_deleted = itemDetails.is_deleted;
            }
          }
        }
      }
    }
    return newInsights;
  });
  return updatedInsights;
}
