import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import { Box, Container, TextField, Link, SvgIcon } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import LoadingSpinner from '../LoadingSpinner';
import { getProfileInfo } from './AccountAPI.js';
import DividerWithText from './DividerWithText.js';
import { useHistory, useLocation } from 'react-router-dom';
import { USER_IMAGE_BUCKET } from "../../constants/AWSUrls";
import { AUTH0_DOMAINS } from "../../constants/Auth0Domains";
import { EMAIL_VALIDATION_REGEX } from '../../constants/ReferenceConstants';
import qs from 'query-string';


function LoginForm() {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isAuth0Loading, setIsAuth0Loading] = useState(false);
    const params = useLocation();

    

    const handleClick = event => {
        if (email !== '' && email.replace(/.*@/, "") in AUTH0_DOMAINS) {
            handleAzureLogin();
            return;
        }
        var baseURL = process.env.REACT_APP_API_BASE_URL + 'v1/auth';
        var apiUrl = baseURL + '/login';
        var bodyFormData = new FormData();
        var error = {};
        if (email === '') {
            error['mail'] = 'Please enter your email';
        } else if (!EMAIL_VALIDATION_REGEX.test(email)) {
            error['mail'] = 'Please enter a valid email address';
        }
        if (password === '') {
            error['password'] = 'Please enter your password';
        }
        if (Object.keys(error).length !== 0) {
            setErrorMessage(error);
            return;
        }
        setIsLoading(true);
        bodyFormData.append('email', email);
        bodyFormData.append('password', password);
        axios({
            method: 'post',
            url: apiUrl,
            data: bodyFormData,
            headers: {},
        }).then(function (response) {
            if (response.status === 200) {
                const token = response.data['jwt'];
                localStorage.setItem('token', token);
                localStorage.setItem('email', email);
                setErrorMessage('');
                getProfileInfo({ email: email }).then(response => {
                    if (response.data.Error) {
                        setErrorMessage('We have encountered an error. Please contact the involve.ai team to get this fixed');
                    } else {
                        localStorage.setItem('company_name', response.data.company_name);
                        localStorage.setItem('role', response.data.role);
                        console.log("test");
                        if (response.data.profile_picture !== "")
                            localStorage.setItem('user_image',USER_IMAGE_BUCKET + response.data.profile_picture);
                        else
                            localStorage.removeItem('user_image')
                        history.push('/customers', {
                            nps: response.data.feedback_reminder,
                            company_name: response.data.company_name,
                        });
                    }
                });
            } else {
                setIsLoading(false);
                if (response && response.data && response.data.message) {
                    setErrorMessage(response.data.message);
                } else {
                    setErrorMessage('Error with status ' + response.statusText);
                }
                console.log(response);
            }
        })
        .catch(function (error) {
            setIsLoading(false);
            let response = error.response;
            if (response && response.data && response.data.message) {
                setErrorMessage(response.data.message);
            } else {
                setErrorMessage('Error with status ' + response.statusText);
            }
            //handle error
            console.log(response);
        });
    };

    const handleAzureLogin = event => {
        var baseURL = process.env.REACT_APP_API_BASE_URL + 'v1/auth';
        var connection  = 'MSCI-AD-SSO';
        if (email !== '' && email.replace(/.*@/, "") in AUTH0_DOMAINS) {
            connection = AUTH0_DOMAINS[email.replace(/.*@/, "")];
        }
        var apiUrl = baseURL + '/auth0_redirect_link?connection=' + connection;
        axios.get(apiUrl).then((res) => {
            if (res.status === 200) {
                window.location = res.data;
            }
        });

    };

    const handleGoogleLogin = event => {
        var baseURL = process.env.REACT_APP_API_BASE_URL + 'v1/auth';
        var apiUrl = baseURL + '/auth0_redirect_link?connection=google-oauth2';
        axios.get(apiUrl).then((res) => {
            if (res.status === 200) {
                window.location = res.data;
            }
        });

    };

    useEffect(() => {
        const parsedQs = qs.parse(params.search);
        if (parsedQs && parsedQs.code) {
            setIsAuth0Loading(true);
            var baseURL = process.env.REACT_APP_API_BASE_URL + 'v1/auth';
            var apiUrl = `${baseURL}/auth0_redirect?code=${parsedQs.code}`;
            axios.get(apiUrl).then((response) => {
                const token = response.data['jwt'];
                const email = response.data['username'];
                localStorage.setItem('logout_url', response.data['logout_url']);
                if (token) {
                    localStorage.setItem('token', token);
                    localStorage.setItem('email', email);
                    setErrorMessage('');
                    getProfileInfo({ email: email }).then(response => {
                        if (response.data.Error) {
                            setErrorMessage('We have encountered an error. Please contact the involve.ai team to get this fixed');
                        } else {
                            localStorage.setItem('company_name', response.data.company_name);
                            history.push('/customers', {
                                nps: response.data.feedback_reminder,
                                company_name: response.data.company_name,
                            });
                        }
                    });
                } else {
                    setErrorMessage(response.data['message']);
                    setIsAuth0Loading(false);
                }
            }).catch(err => {
                console.log(err.response);
                setErrorMessage('Login Failed');
                setIsAuth0Loading(false);
            });
        }

    }, []);

    return (
        <Container style={{ marginLeft: '45px' }}>
            <Box>
                <div>
                    {errorMessage !== '' ? (
                        <Alert severity="error" style={{ maxWidth: '400px' }} p={1}>
                            {' '}
                            {typeof errorMessage === 'string'
                                ? errorMessage
                                : Object.keys(errorMessage).map((key, index) => {
                                    return <li>{errorMessage[key]}</li>;
                                })}
                        </Alert>
                    ) : null}
                    <TextField
                        fullWidth
                        autoFocus
                        label="Email Address"
                        margin="normal"
                        name="email"
                        type="email"
                        defaultValue=""
                        variant="outlined"
                        style={{ maxWidth: '432px' }}
                        onChange={e => setEmail(e.target.value)}
                    />
                    { email === '' || !(email.replace(/.*@/, "") in AUTH0_DOMAINS) ? (
                    <TextField
                        fullWidth
                        label="Password"
                        margin="normal"
                        name="password"
                        type="password"
                        defaultValue=""
                        variant="outlined"
                        style={{ maxWidth: '432px' }}
                        onChange={e => setPassword(e.target.value)}
                    />
                    ) : null }
                </div>
            </Box>
            <Box mt={1}></Box>
            <Link underline="always" style={{ fontSize: '14px' }} href={'forgot-password'}>
                Forgot Password
            </Link>
            <Box mt={3} style={{ maxWidth: '432px'}}>
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <Button
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={event => handleClick(event)}
                        style={{ maxWidth: '432px', height: '50px' }}
                    >
                        LOGIN
                    </Button>
                )}
            </Box>
            <Box mt={3}>
                <DividerWithText>or</DividerWithText>
            </Box>
            <Box mt={3}>
                {isAuth0Loading ? (
                    <LoadingSpinner />
                ) : (
                    <Button
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="outlined"
                        onClick={event => handleAzureLogin(event)}
                        style={{ maxWidth: '432px', height: '50px' }}
                        startIcon={<SvgIcon>
                            <path d="M3 12V6.75l6-1.32v6.48L3 12m17-9v8.75l-10 .15V5.21L20 3M3 13l6 .09v6.81l-6-1.15V13m17 .25V22l-10-1.91V13.1l10 .15z" />
                        </SvgIcon>}
                    >
                        Login with Azure
                    </Button>)
                }
            </Box>
            {/* <Box mt={3}>
                {isAuth0Loading ? (
                    <LoadingSpinner />
                ) : (
                    <Button
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="outlined"
                        onClick={event => handleGoogleLogin(event)}
                        style={{ maxWidth: '432px', height: '50px' }}
                        startIcon={<SvgIcon
                            viewBox='0 0 48 48'
                        >
                            <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"/>
                            <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"/>
                            <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"/>
                            <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"/>
                            <path fill="none" d="M0 0h48v48H0z"/>

                        </SvgIcon>}
                    >
                        Login with Google
                    </Button>)
                }
            </Box> */}
        </Container>
    );
}

export default LoginForm;
