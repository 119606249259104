import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Divider, Chip, IconButton, Grid } from '@material-ui/core';
import { EMAIL_VALIDATION_REGEX } from '../../constants/ReferenceConstants';
import styles from './customer.module.css';
import TrackedContactDialog from './TrackedContactDialog';
import { useStyles } from './TrackedContacts.theme';
import { getContactData, updateContactData } from './CustomerAPI.js';
import { getContactUserRoles } from './CustomerAPI.js';
import { deleteContact } from './CustomerAPI.js';
import EditIcon from '@material-ui/icons/Edit';
import ImagePlaceHolder from './ImagePlaceholder';

function ContactRow(props) {
  const [editFlag, setEditFlag] = React.useState(false);
  const classes = useStyles();

  return (
    <Box display="flex" mt={2} mb={2} onMouseEnter={() => setEditFlag(true)} onMouseLeave={() => setEditFlag(false)}>
      <Box ml={2}>
        <Typography variant="subtitle2" color="textPrimary" style={{ width: '220px' }}>
          {props.contact.full_name}
        </Typography>
        {props.contact.position !== '' &&
          <Typography variant="subtitle2" color="textSecondary" style={{ width: '220px', marginTop: '8px', overflowWrap: 'anywhere' }}>
            {props.contact.position}
          </Typography>
        }
        <Typography
          variant="subtitle2"
          style={{ width: '220px', color: '#006FFF', marginTop: '8px', overflowWrap: 'anywhere' }}
        >
          {props.contact.email}
        </Typography>
      </Box>
      <Box ml={2}>
        <Typography variant="subtitle2" color="textPrimary" style={{ width: '170px' }}>
          {props.contact.user_role}
        </Typography>
      </Box>
      <Box>
        <Typography variant="subtitle2" color="textPrimary" style={{ width: '100px' }}>
          {props.contact.last_touch}
        </Typography>
        {props.contact.status !== 'active' && (
          <Chip
            label="Inactive"
            disabled
            key={props.contact._id}
            classes={{
              root: classes.chipRoot,
              label: classes.chipLabel,
            }}
          />
        )}
      </Box>
      {editFlag && (
        <IconButton  className={classes.smallEditIcon} size="small" style={{ marginLeft: '8px', alignItems: 'flex-end'}}>
          <EditIcon
            onClick={() => {
              props.editFun(props.contact);
            }}
          />
        </IconButton>
      )}
    </Box>
  );
}
const CustomerTrackedContacts = ({ className, customer, companyName, ...rest }) => {
  const classes = useStyles();
  const [loadingFlag, setLoadingFlag] = useState(true);
  const [openDialogFlag, setOpenDialogFlag] = useState(false);
  const [contactInfo, setContactInfo] = useState([]);
  const [contactsRoles, setContactsRoles] = useState([]);
  const [errorFlag, setErrorFlag] = useState(false);
  const [submittedFlag, setSubmittedFlag] = useState(false);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [position, setPosition] = useState('');
  const [role, setRole] = useState('');
  const [status, setStatus] = useState('active');
  const [contactId, setContactId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [newContactFlag, setNewContactFlag] = useState(false);
  const [contactRetrievalError, setContactRetrievalError] = useState('');
  const [contactUserRolesRetrievalError, setContactUserRolesRetrievalError] = useState('');

  const handleAdd = () => {
    setOpenDialogFlag(true);
    setNewContactFlag(true);
    setFullName('');
    setEmail('');
    setPosition('');
    setRole(contactsRoles.length!==0 ? contactsRoles[0].name : '');
    setStatus('active');
    setContactId('');
  };

  const closeDialogFun = () => {
    setOpenDialogFlag(false);
    setSubmittedFlag(false);
    setErrorMessage('');
  };

  const handleEdit = props => {
    setOpenDialogFlag(true);
    setFullName(props.full_name);
    setEmail(props.email);
    setPosition(props.position);
    setRole(props.user_role);
    setStatus(props.status);
    setContactId(props._id);
    setNewContactFlag(false);
  };

  useEffect(() => {
    getContactData({
      company_name: companyName,
      location: customer.location,
      branch_name: customer.branch_name
    }).then(response => {
      setLoadingFlag(false);
      if (response.data.Error) {
        setContactRetrievalError(
          'There was an error retrieving your tracked contacts. Please contact the involve.ai team to get this fixed',
        );
      } else {
        setContactInfo(response.data.response);
      }
    });
    getContactUserRoles({
    }).then(response => {
      setLoadingFlag(false);
      if (response.data.Error) {
        setContactUserRolesRetrievalError(
          'There was an error retrieving users roles. Please contact the involve.ai team to get this fixed',
        );
      } else {
        setContactsRoles(response.data.data);
      }
    });

  }, [companyName, customer]);

  const handleSave = () => {
    var error = {};
    if (email === '') {
      error['mail'] = 'Please enter your email';
    } else if (!EMAIL_VALIDATION_REGEX.test(email)) {
        error['mail'] = 'Please enter a valid email address';
    }
    if(role ==='')
      error['role'] = 'Please select a role';

    if (Object.keys(error).length !== 0) {
        setErrorMessage(error);
        return;
    }
    updateContactData({
      _id: contactId,
      full_name: fullName,
      company_name: companyName,
      email: email,
      position: position,
      user_role: role,
      status: status,
      branch_name: customer.branch_name,
      location: customer.location
    }).then(response => {
      if (!response.data.Error) {
        setSubmittedFlag(true);
        setLoadingFlag(false);
        if (response.data.statusCode === 200) {
          getContactData({
            company_name: companyName,
          }).then(response => {
            setLoadingFlag(false);
            if (response.data.statusCode === 200) {
              setContactInfo(response.data.response);
            } else {
              setErrorFlag(true);
            }
          });
        } else {
          setErrorFlag(true);
        }
      } else {
        setErrorFlag(true);
        setErrorMessage(
          'There was an error in saving your contact information. Please contact the involve.ai team to get this fixed.',
        );
      }
    });
  };

  const handleDelete = () => {
    deleteContact({
      _id: contactId
    }).then(response => {
      if (!response.data.Error) {
        setSubmittedFlag(true);
        setLoadingFlag(false);
        if (response.data.statusCode === 200) {
          getContactData({
            company_name: companyName,
          }).then(response => {
            setLoadingFlag(false);
            if (response.data.statusCode === 200) {
              setContactInfo(response.data.response);
            } else {
              setErrorFlag(true);
            }
          });
        } else {
          setErrorFlag(true);
        }
      } else {
        setErrorFlag(true);
        setErrorMessage(
          'There was an error deleting your contact information. Please contact the involve.ai team to get this fixed.',
        );
      }
    });
  };

  return (
    <Box className={classes.root} py={3} pb={1}>
      <Typography variant="subtitle1" color="textPrimary">Tracked Contacts</Typography>

      {contactRetrievalError !== '' && (
        <ImagePlaceHolder
        message={contactRetrievalError}
        imageUrl={require('./../../assets/error-general.png')}
        altText={'Error'} />
      )}
      {loadingFlag && (
        <Box mt={2} style={{ alignSelf: 'center' }}>
          <div className={styles.accountHistoryloader}> </div>
        </Box>
      )}
      {(contactRetrievalError === '' && !loadingFlag) && (
        <>
          <Box display="flex" mt={1}>
            <Typography variant="body2" style={{ marginTop: '8px' }} color="textSecondary">
              Points of contact we found in the latest account history.
            </Typography>
            <Box flexGrow="1" />
            {contactInfo.length > 0 && (
              <Button variant="contained" color="primary" onClick={handleAdd}>
                Add
              </Button>
            )}

            <TrackedContactDialog
              newContact={newContactFlag}
              openDialog={openDialogFlag}
              closeDialogFun={closeDialogFun}
              fullName={fullName}
              status={status}
              email={email}
              position={position}
              role={role}
              setFullName={setFullName}
              setEmail={setEmail}
              setPosition={setPosition}
              setRole={setRole}
              setStatus={setStatus}
              handleSave={handleSave}
              handleDelete={handleDelete}
              submittedFlag={submittedFlag}
              errorMessage={errorMessage}
              errorFlag={errorFlag}
              contactsRoles={contactsRoles}
            />
          </Box>

          {contactInfo.length > 0 ? (
            <>
              <Box display="flex" mt={1} className={classes.contactsGridHead}>
                <Typography variant="body2" color="textSecondary" className={classes.ml16} style={{ width: '220px', marginTop: '8px' }}>
                  {' '}
                  Contact{' '}
                </Typography>

                <Typography variant="body2" color="textSecondary" className={classes.ml16} style={{ width: '170px', marginTop: '8px' }}>
                  {' '}
                  Role
                </Typography>

                <Typography variant="body2" color="textSecondary" style={{ marginTop: '8px' }}>
                  {' '}
                  Last Touch
                </Typography>
              </Box>
              <Box className={classes.contactInfo}>
                {contactInfo.map(contact => (
                  <Box key={contact._id}>
                    <ContactRow contact={contact} editFun={handleEdit} />
                    <Divider />
                  </Box>
                ))}
              </Box>
            </>
          ) : (
            <Box mt={1}>
              <Divider />
              <Grid container justify="center">
                <Box style={{ maxWidth: '323px' }} mt={4}>
                  <Box display="flex" justify="center" mb={3} style={{ justifyContent: 'center' }}>
                    <img
                      src={require('./../../assets/contacts-empty.png')}
                      alt="Empty Contacts"
                      style={{ width: '96px', height: '96px' }}
                    />
                  </Box>
                  <Typography gutterBottom variant="body2" align="center" color="textSecondary">
                    There are no tracked contacts for this customer yet. Contacts will appear as soon as you integrate
                    more data sources or when you manually add it.
                  </Typography>
                  <Box display="flex" justify="center" style={{ justifyContent: 'center' }}>
                    <Button variant="contained" color="primary" onClick={handleAdd} style={{ marginTop: '24px' }}>
                      Add
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default CustomerTrackedContacts;
