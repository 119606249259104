import axios from 'axios';
import { attachToken } from '../../../utils/auth';
import {USER_PROFILE_ENDPOINT, USER_UPDATE_IMAGE_ENDPOINT, UPDATE_PASSWORD_ENDPOINT,UPDATE_USER_INFO} from '../../../constants/ApiEndpoints'

export function getAccountInfo(requestData,callback, errorcallback) {
    return axios(
      attachToken({
        method: 'post',
        url: process.env.REACT_APP_API_BASE_URL + USER_PROFILE_ENDPOINT,
        data: requestData,
        headers: {},
      }),
    )
      .then(function (response) {
        return response;
      })
      .catch(function (response) {
        console.log(response);
      });
}

export function setUserImage(requestData,callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + USER_UPDATE_IMAGE_ENDPOINT,
      data: requestData,
      headers: {"Content-Type": "multipart/form-data"},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
      return response.response;
    });
}

  
export function setAccountInfo(requestData,callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + UPDATE_USER_INFO,
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
      return response.response;
    });
}


export function setUpdatePassword(requestData,callback, errorcallback) {
  return axios(
        attachToken({
          method: 'post',
          url: process.env.REACT_APP_API_BASE_URL + UPDATE_PASSWORD_ENDPOINT,
          data: requestData,
          headers: {},
        }),
      )
        .then(function (response) {
          return response;
        })
        .catch(function (response) {
          console.log(response);
          return response.response;
        });
  }
  
