import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Highlighter from 'react-highlight-words';
import { historyStyles } from './history.theme';

const useStyles = makeStyles(theme => ({
  customerAttributeValue: {
    color: '#263238',
    fontFamily: 'Roboto',
    fontSize: '12px',
    letterSpacing: '0.1px',
    lineHeight: '20px',
    textAlign: 'left',
    textTransform: 'uppercase',
    fontWeight: '600',
    marginTop: '12px',
    marginLeft: '16px',
  },
  low: {
    color: '#D32F2F !important',
    backgroundColor: '#FEEBEE',
    padding: '4px',
  },
  medium: {
    color: '#F67C01 !important',
    backgroundColor: '#FFF3E0',
    padding: '4px',
  },
  high: {
    color: '#09BC71 !important',
    backgroundColor: '#E7F8F1',
    padding: '4px',
  },
  doesNotExist: {
    backgroundColor: '#F5F5F5 !important',
    padding: '4px',
  },
  iconClasses: {
    width: '16px',
    height: '16px',
    margin: '2px 8px 2px 0px',
    color: '#546E7A',
  },
}));

const HistoryKeyIndicators = ({ className, keyIndictorData, searchTextArr, ...rest }) => {
  const classes = useStyles();
  const styles = historyStyles();
  const supportTicket =
    keyIndictorData.data === 'Number of Support Tickets' || keyIndictorData.data === 'Severity of Support Tickets'
      ? true
      : false;
  const attributeVal = attrVal => {
    switch (attrVal) {
      case 'Low':
        if (supportTicket) {
          return <span className={classes.high}>{attrVal}</span>;
        } else {
          return <span className={classes.low}>{attrVal}</span>;
        }
      case 'Medium':
        return <span className={classes.medium}>{attrVal}</span>;
      case 'High':
        if (supportTicket) {
          return <span className={classes.low}>{attrVal}</span>;
        } else {
          return <span className={classes.high}>{attrVal}</span>;
        }
      case 'Does not Exist':
        return <span className={classes.doesNotExist}>{attrVal}</span>;
      default:
        return <span className={classes.doesNotExist}>{attrVal}</span>;
    }
  };

  return (
    <>
      <Box display="flex">
        <Box>
          <Typography variant="subtitle1" style={{ marginLeft: '16px' }} color="textPrimary">
            {keyIndictorData.data}
          </Typography>
          <Typography variant="body2" color="textSecondary" className={classes.customerAttributeValue}>
            {attributeVal(keyIndictorData.value)}
          </Typography>
        </Box>
        <Box flexGrow="1" />
        <Typography variant="body2" style={{ fontSize: '12px' }} color="textSecondary">
          {keyIndictorData.timestamp}
        </Typography>
      </Box>
      <Box display="flex" style={{ marginTop: '14px', marginLeft: '8px' }}>
        {keyIndictorData.text && (
          <Box ml={1}>
            {keyIndictorData.text.map(salesforceList => (
              <Box display="flex" mb={1} id={salesforceList}>
                <InfoIcon className={classes.iconClasses} />
                <Typography variant="body2" color="textSecondary">
                <Highlighter
                  highlightClassName={styles.highlightClass}
                  searchWords={searchTextArr}
                  autoEscape={true}
                  textToHighlight={salesforceList}
                />
                </Typography>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </>
  );
};

export default HistoryKeyIndicators;
