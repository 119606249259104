import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplayIcon from '@material-ui/icons/Replay';
import { deleteUserRoles, updateUserRolesPermissions, resendActivationMail } from './UserRoleApi';
import { userRolesStyles } from './userRolesDataTable.theme';
import EditUserDialog from './editUserDialog';
import RemoveUsersDialog from './removeUserDialog';

const StyledTableCell = withStyles(theme => ({
  head: {
    color: '#546E7A',
  },
  body: {
    color: '#263238',
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: '20px',
    textAlign: 'left',
    border: 'none',
  },
}))(TableCell);

const removeUserText = "Are you sure you want to remove this user?"


const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
  tableHead: {
    color: '#546E7A',
    backgroundColor: 'white',
    textAlign: 'left',
    fontSize: '12px',
    lineHeight: '14px',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
});

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#FAFBFF',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#FFFFFF',
    },
  },
  TableCell: {
    textAlign: 'left',
    lineHeight: '20px'
  },
}))(TableRow);

const UserRoleDataTable = (props) => {
  const classes = useStyles();
  const [rowsPerPage] = React.useState(5);
  const styles = userRolesStyles();
  const [openDialogFlag, setOpenDialogFlag] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [openDialogRemoveFlag, setOpenDialogRemoveFlag] = useState(false);
  const [userToDelete, setUserToDelete] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [userPermissions, setUserPermissions] = useState({});
  const [allColumns] = useState(JSON.parse(localStorage.getItem('columnsSimpleView')));

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, 10 - 1 * rowsPerPage);
  const clientStatusFilter = {
    id: "client_status",
    label: "Client Status",
    sort_type: "alphabetical",
    data_type: "string"
  };

  const permissionRow = (filterName, permission) => {
    let individualFilter = allColumns.find(o => o.id === filterName);
    if (filterName === 'client_status') {
      individualFilter = clientStatusFilter;
    }
    if (filterName === 'probability_to_renew') {
      individualFilter.data_type = 'number';
    }
    if (individualFilter) {
      const label = individualFilter.label;
      let text = '';
      switch (individualFilter.data_type) {
        case 'string':
          text = permission.join(", ");
          break;
        case 'number':
          let dollarPrefix = individualFilter.label.includes('$') ? '$' : '';
          if (permission.length === 2) {
            text = dollarPrefix;
            if (permission[0] === null || permission[1] === null) {
              if (permission[0] !== null) {
                text += 'from ' + permission[0].toLocaleString();
              }
              if (permission[1] !== null) {
                text += 'up to ' + permission[1].toLocaleString();
              }
            } else {
              text += permission[0].toLocaleString() + ' - ' + dollarPrefix + permission[1].toLocaleString();
            }
          } else {
            text = permission.toLocaleString();
          }
          break;
        case 'date':
          text = permission.length === 2 ? formatDate(permission[0]) + ' - ' + formatDate(permission[1]) : '';
          break;
        default:
          break;
      }
      return (<div>
        <Typography variant="body2" color="textSecondary" display="inline"><>{label}</> : </Typography> <Typography variant="body2" display="inline"><>{text}</></Typography></div>);
    }
    return '';
  };

  const formatDate = date => {
    if (date == null) {
      return '';
    }
    return new Intl.DateTimeFormat('en').format(new Date(date));
  }
  const handleEdit = (row) => {
    setUserEmail(row.email);
    setUserRole(row.role);
    setUserPermissions(row.permissions)

    setOpenDialogFlag(true);
  };

  const handleSaveChanges = (permissions) => {
    //validations
    var error = {};
    let tempPermissions = Object.assign({}, permissions);

    Object.keys(tempPermissions).map((key, index) => {
      let permission = tempPermissions[key];
      let individualFilter = allColumns.find(o => o.id === key);
      if (key === 'client_status') {
        individualFilter = clientStatusFilter;
      }
      switch (individualFilter.data_type) {
        case 'number':
          if (!isNaN(permission[0]) && permission[0] != null && !isNaN(permission[1]) && permission[1] != null ) {
            if (Number(permission[0]) > Number(permission[1]))
              error[individualFilter.id + 'MinMax'] = individualFilter.label + ' To must be greater than or equal to From';
          } else {
            if (isNaN(permission[0]) || permission[0] == null) {
              error[individualFilter.id + 'Min'] = individualFilter.label + " from must be a number"
            }
            if (isNaN(permission[1]) || permission[1] == null) {
              error[individualFilter.id + 'Max'] = individualFilter.label + " to must be a number"
            }
          }
          break;
          case 'date':
            if(permission[0]!=null && permission[1]!=null)
            {
              if(permission[1] < permission[0])
                error[individualFilter.id + 'MinMax'] = individualFilter.label + ' To must be greater than or equal to From';
            }else{
              error[individualFilter.id + 'values'] = individualFilter.label + ' must be valid dates';
            }
            break;
          default: break;
      }
    });

    if (Object.keys(error).length !== 0) {
      setErrorMessage(error);
      return;
    }    

    let requestData = {
      email: userEmail,
      permissions: tempPermissions,
      role: userRole
    }

    props.setLoadingFlag(true);
    updateUserRolesPermissions(requestData).then(response => {
      if (response.data.hasOwnProperty('Error')) {
        //setErrorFlag(true);
      } else {
        props.setPageNumber(1);
      }
      props.handleQueryChange();
    });
  }

  const closeDialogFun = () => {
    setOpenDialogFlag(false);
    setErrorMessage('');
    setUserPermissions({});
  };

  const openDialogRemove = (row) => {
    setUserToDelete(row)
    setOpenDialogRemoveFlag(true);
  }

  const closeDialogRemove = (row) => {
    setOpenDialogRemoveFlag(false);
  }

  const handleChangePage = (newPage) => {
    props.setPageNumber(newPage);
  };

  const handleDelete = () => {
    let requestData = {
      email: userToDelete.email,
      company_name: userToDelete.company_name
    }
    props.setLoadingFlag(true);
    deleteUserRoles(requestData).then(response => {
      if (response.data.hasOwnProperty('Error')) {
        //setErrorFlag(true);
      } else {
        props.setPageNumber(1);        
      }
      props.handleQueryChange();
    });
  };

  const handleResendEmail = (row) => {
    let requestData = {
      email: row.email
    }
    props.setLoadingFlag(true);
    resendActivationMail(requestData).then(response => {
      if (response.data.hasOwnProperty('Error')) {
        //setErrorFlag(true);
      } else {
      }
      props.setLoadingFlag(false);
    });
  };

  return (
    <>
      <Box>
        <TableContainer component={Paper} id="dataSimTable" className={styles.dataSimTable}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow style={{ height: 32 }}>
                <TableCell style={{ width: 360 }} className={classes.tableHead}>Email</TableCell>
                <TableCell style={{ width: 100 }} className={classes.tableHead}>Role</TableCell>
                <TableCell style={{}} className={classes.tableHead}>Permissions</TableCell>
                <TableCell style={{ width: 160 }} className={classes.tableHead}>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                //props.userRoles
                props.userRoles.map(row => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell style={{ width: 360 }} component="th" scope="row">
                      {row.email}
                    </StyledTableCell>
                    <StyledTableCell style={{ width: 100 }} align="right">
                      {row.role === "admin" ? "Admin" : "User"}
                    </StyledTableCell>
                    <StyledTableCell style={{}} align="right">
                      {
                        row.permissions && Object.keys(row.permissions).map((key, index) => (
                          permissionRow(key, row.permissions[key])
                        ))
                      }
                    </StyledTableCell>
                    <StyledTableCell style={{ width: 120 }} align="right">
                      {row.status === "activated" ? (<span className={styles.textActivated}>ACTIVATED</span>) : (<span className={styles.textPending}>PENDING</span>)}
                    </StyledTableCell>
                    <TableCell style={{ border: "none" }} align={'right'}>
                      {
                        row.status === 'activated' ?
                          <IconButton className={classes.smallEditIcon} size="small">
                            <EditIcon
                              onClick={() => {
                                handleEdit(row);
                              }}
                            />
                          </IconButton>
                          :
                          <IconButton className={classes.smallEditIcon} size="small">
                            <ReplayIcon
                              onClick={() => {
                                handleResendEmail(row);
                              }}
                            />
                          </IconButton>
                      }
                      <IconButton className={classes.smallEditIcon} size="small">
                        <DeleteIcon
                          onClick={() => {
                            openDialogRemove(row);
                          }}
                        />
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Pagination
            color="primary"
            count={props.totalPages}
            page={props.pageNumber}
            onChange={(e, value) => handleChangePage(value)}
            shape="rounded"
            showFirstButton
            showLastButton
          />
          <EditUserDialog
            openDialog={openDialogFlag}
            closeDialogFun={closeDialogFun}
            userRole={userRole}
            setUserRole={setUserRole}
            handleSaveChanges={handleSaveChanges}
            onlyEditPermissions={false}
            errorMessage={errorMessage}
            userPermissions={userPermissions}
          />
          <RemoveUsersDialog
            openDialog={openDialogRemoveFlag}
            closeDialogFun={closeDialogRemove}
            removeText={removeUserText}
            handleDelete={handleDelete}
          />
        </TableContainer>
      </Box>
    </>

  );
};

export default UserRoleDataTable;