import React from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  dialogPaper: {
    maxWidth: '496px',
    maxHeight: '542px',
  },
  dialogPadding: {
    paddingLeft: '32px',
    paddingRight: '32px',
  },
});

const ZendeskExportDialog = ({ openDialog, handleCloseDialog, exportFunc, ...rest }) => {
  const classes = useStyles();

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog} classes={{ paper: classes.dialogPaper }}>
      <Box p={4}>
        <Typography variant="h4" color="textPrimary">Confirm Data Export</Typography>
        <Box pt={2} pb={4}>
          <Typography variant="body2" color="textSecondary">
            Are you sure you want to continue? You won’t be able to see zendesk tickets in the account history if
            "Tickets" are not exported.
          </Typography>
        </Box>

        <DialogActions style={{ padding: '0px' }}>
          <Button onClick={exportFunc} type="submit" variant="contained">
            Continue anyway
          </Button>
          <Box flexGrow={1} />
          <Button onClick={handleCloseDialog} color="primary" variant="contained">
            Back
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default ZendeskExportDialog;
