import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Box, Container, Grid, TextField, Checkbox, Link} from '@material-ui/core';
import { EMAIL_VALIDATION_REGEX } from '../../constants/ReferenceConstants';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";

const useStyles = {
    acceptTermsText:{
        color: "#546E7A",
        fontFamily: "Roboto",
        fontSize: "14px",
        letterSpacing: "0.1px",
        lineHeight: "20px",
        textAlign: "left",
        marginLeft: "-10px"
    }
};

function SignUpForm(props){
    const [state, setState] = useState({
        email: '',
        firstname: '',
        lastname:'',
        companyname:'',
        termsChecked: false
    })
    const errors = useState({
        email: '',
        firstname: '',
        lastname:'',
        companyname:'',
        termsNotChecked: false
    })[0]
    const [showError, setShowError] = useState(false)

    const errorTexts = {
        email: 'Please enter your email',
        firstname: 'Please enter your first name',
        lastname:'Please enter your last name',
        companyname:'Please enter your company name'
    }

    const updateLicense = ()=>{
        const license = document.getElementById("license")
        if(!license.checked){
            errors["termsNotChecked"] = true
            license.style = {"color": "red"}
        }else{
            errors["termsNotChecked"] = false
            license.style = {"color": "blue"}
        }
    }
  const validateFields = (forSubmit=false) =>{
      var valid = true
      setShowError(false)
      for(const field in state){
        if(state[field].length === 0){
            if(forSubmit) {
                errors[field] = "Required"
                valid = false
                setShowError(true)
            }
        }
        else {
            if(forSubmit) {
                errors[field] = ""
            }
        }
      }
      const license = document.getElementById("license")
      if(!license.checked && forSubmit){
          errors["termsNotChecked"] = true
          license.style = {"color": "red"}
          valid = (valid && state.termsChecked)
      }else{
          errors["termsNotChecked"] = false
          license.style = {"color": "blue"}
      }
      if (!(EMAIL_VALIDATION_REGEX.test(state.email)))
      {
          if(forSubmit){
              setShowError(true)
              errors["email"] = "Not valid email format"
          }
          valid = false
      }else{
          errors["email"] = ''
      }
      return valid
  }

  const handleClick = (event)=>{
    if(!validateFields(true)) {
        setState({...state});
        return
    }
    for(let key in state)
    {
       props.user[key] = state[key];
    }
    props.nextStep();
    }
    const { classes } = props;
    return(<>
        <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            mb={3}
        >
            <div style={{width:"100%"}}>
                <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h3"
                    align="center"
                    style={{color: "#263238",
                        fontFamily: "Roboto",
                        fontSize: "24px",
                        letterSpacing: "-0.06px",
                        lineHeight: "28px"}}
                >
                    Sign Up
                </Typography>

            </div>
        </Box>
      <Container style={{marginLeft: "45px"}}>
          {showError ?
              (<Alert severity="error" style={{maxWidth: "400px"}} p={1}> {Object.keys(errors).map((key, index) => {return ( key === "termsNotChecked" || errors[key].length === 0)?'': (<li>{errorTexts[key]}</li>)})}</Alert>)
              : (null)
          }
        <Grid container style={{"display": "table-row"}}>
            <Grid item xs={12} sm={6} style={{"display": "table-cell"}}>
                <TextField
                    fullWidth
                    id='first_name'
                    label="First Name"
                    margin="normal"
                    name="name"
                    defaultValue = ""
                    variant="outlined"
                    value={state.firstname}
                    style={{maxWidth: "180px", marginRight: "25px"}}
                    onChange={(e) => setState({...state, firstname:e.target.value})}
                    // error = {this.errors["firstname"]}
                    // helperText={this.errors["firstname"]}
                />
            </Grid>
            <Grid item xs={12} sm={6} style={{"display": "table-cell"}}>
                <TextField
                    fullWidth
                    id= 'last_name'
                    label="Last Name"
                    margin="normal"
                    name="name"
                    defaultValue = ""
                    variant="outlined"
                    style={{ width: "225px"}}
                    value={state.lastname}
                    onChange={(e) => setState({...state, lastname:e.target.value})}
                    // error = {this.errors["lastname"]}
                    // helperText={this.errors["lastname"]}
                />
            </Grid>
        </Grid>
        <TextField
            fullWidth
            label="Company Name"
            margin="normal"
            name="company"
            type="text"
            defaultValue=''
            variant="outlined"
            style={{maxWidth: "432px"}}
            value={state.companyname}
            onChange={(e) => setState({...state, companyname:e.target.value})}
            // error = {this.errors["companyname"]}
            // helperText={this.errors["companyname"]}
        />
        <TextField
            fullWidth
            label="Email"
            margin="normal"
            name="email"
            type="email"
            defaultValue=''
            variant="outlined"
            style={{maxWidth: "432px"}}
            value={state.email}
            onChange={(e) => setState({...state, email:e.target.value})}
            // error = {this.errors["email"]}
            // helperText={this.errors["email"]}
        />

        <Typography
            className={classes.acceptTermsText}
        >
        <Checkbox
            id={"license"}
            color={"primary"}
            defaultChecked={state.termsChecked}
            onChange={(e) => {setState({...state, termsChecked:e.target.checked});updateLicense();}}
            style = {errors["termsNotChecked"]? {color: "red"}: {}}
        />
            I have read the {' '}
           <Link href="https://docs.google.com/document/d/1qc7MWde5-PmnyglQT15WkeFH-eobJ66suuU-on1CECc/edit?usp=sharing"
              underline = 'always'
              target="_blank"
           >Terms & Conditions
        </Link>
        </Typography>
        {/*
        <Box
            alignItems="center"
            display="flex"
            mt={2}
            ml={-1}>

            <Checkbox
                name="policy"
                color='primary'
            />

            <Typography
                variant="body2"
                color="textPrimary">
                I have read the
                {' '}
                <Link
                    component="a"
                    href="https://docs.google.com/document/d/1qc7MWde5-PmnyglQT15WkeFH-eobJ66suuU-on1CECc/edit"
                    target="_blank"
                    underline = 'always'
                >
                    Terms and Conditions
                </Link>
            </Typography>
        </Box> */}

        <Box mt={2}>
            <Button
            color="primary"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            style = {{marginTop:"-5px", backgroundColor:"#0061FF",
                    borderRadius: "4px",
                    maxWidth: "432px"
                    }}
            onClick={(event) => handleClick(event)}
            >
                Sign Up
            </Button>
        </Box>
    </Container>
    <Box my={3}>
    </Box>
    <Container style={{marginLeft: "45px", marginTop:"-10px"}}>
        <Typography
            variant="body2"
            color="textSecondary"
            style={{
                letterSpacing: "0.01px"}}
        >
            Already have an account?
            {' '}
            <Link
                href="/login"
                underline = 'always'
            >
                Login
            </Link>
        </Typography>
    </Container>
    </>
    )
}

SignUpForm.propTypes = {
    classes: PropTypes.object,
    user: PropTypes.any
};

export default withStyles(useStyles)(SignUpForm)
