import React from 'react';
import { Box, Typography } from '@material-ui/core';
import Highlighter from 'react-highlight-words';
import { historyStyles } from './history.theme';
import { insightsStyles } from '../insights/insights.theme';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const Insight = ({ ...props }) => {
  const styles = insightsStyles();

  return (
    <>
    <Box display="flex" mb={1} id={props.info.title}>
      <Typography variant="body2" color="textSecondary">
        <Highlighter
          highlightClassName={styles.highlightClass}
          searchWords={props.searchTextArr}
          autoEscape={true}
          textToHighlight={props.info.title}
        />
      </Typography>
      <Box flexGrow="1"/>
      <Typography variant="body2" style={{ fontSize: '12px' }} color="textSecondary">
        {props.info.timestamp}
      </Typography>
    </Box>

      <Box display="flex" style={{ width: '100%' }}>
        <img
          src={require('../../assets/insight.png')}
          alt="Insight"
          style={{ width: '16px', height: '15px', marginRight: '12px' }}
        />

        <Typography variant="subtitle1" color="textPrimary" style={{ paddingRight: '16px' }}>
          {props.info.description}
          {props.info.key_indicator_value === 'High' ? (
            <ArrowUpwardIcon
              className={
                props.info.key_indicator === 'number_of_support_tickets' ||
                props.info.key_indicator === 'severity_of_support_tickets'
                  ? styles.redArrow
                  : styles.greenArrow
              }
            />
          ) : (
            <>
              {props.info.key_indicator_value === 'Low' ? (
                <ArrowDownwardIcon
                  className={
                    props.info.key_indicator === 'number_of_support_tickets' ||
                    props.info.key_indicator === 'severity_of_support_tickets'
                      ? styles.greenArrow
                      : styles.redArrow
                  }
                />
              ) : null}
            </>
          )}
        </Typography>

      </Box>
      <Box ml={3.5}>
        {props.info.items.map((item, index) => (
          <Box display="flex">
            <Typography variant="subtitle2" color="textPrimary">
              - &nbsp;
            </Typography>
            <Typography variant="subtitle2" color="textPrimary">
              {item}
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  );
};

const HistoryInsights = ({ className, historyData, searchTextArr, ...rest }) => {
  const styles = historyStyles();

  return (
    <Box mb={2}>
      <Box display="flex">
        <Typography variant="subtitle1" style={{ marginLeft: '16px' }} color="textPrimary">
          {historyData.data}
        </Typography>

        <Box flexGrow="1" />
        <Typography variant="body2" style={{ fontSize: '12px' }} color="textSecondary">
          {historyData.timestamp}
        </Typography>
      </Box>
      {historyData.source ==="account_insight" ? (<Box ml={2} mt={2} >
          {historyData.history.map((hist, i) => (
            <Box mb={2} key={i} id={i}>
            <Insight info={hist} searchTextArr={searchTextArr}/>
            </Box>
          ))}
        </Box>):(
        <Box ml = {1} mt={1} >
          {historyData.history && (
            <Box ml={1}>
              {historyData.history.map((hist, i) => (
                <Box id={hist} key={"hist" + i} mb={2}>
                  <Box display="flex" mb={1} id={hist}>
                    <Typography variant="body2" color="textSecondary">
                      <Highlighter
                        highlightClassName={styles.highlightClass}
                        searchWords={searchTextArr}
                        autoEscape={true}
                        textToHighlight={hist.description}
                      />
                    </Typography>
                    <Box flexGrow="1"/>
                    <Typography variant="body2" style={{ fontSize: '12px', minWidth:'53px' }} color="textSecondary">
                      {hist.timestamp}
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="textPrimary">
                    <Highlighter
                      highlightClassName={styles.highlightClass}
                      searchWords={searchTextArr}
                      autoEscape={true}
                      textToHighlight={hist.value}
                    />
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}

    </Box>
  );
};

export default HistoryInsights;
