import React, { useState } from 'react';
import {
  Box,
  Typography,
  Container,
  Button
} from '@material-ui/core';
import SecondaryNav from './../SecondaryNav';
import WelcomeDialog from './WelcomeDialog';
import ReportsList from './ReportsList';
import AddNewDialog from './AddNewDialog';

const Reports = () => {
  const [addNewFlag, setAddNewFlag] = useState(false);

  const addNewReport = () => {
    setAddNewFlag(true);
  }
  const handleCloseCreateDialog = () => {
    setAddNewFlag(false);
  }
  return (
    <>
      <SecondaryNav />
      <WelcomeDialog openDialog={true}/>
      <AddNewDialog openDialogFlag={addNewFlag} handleClose = {handleCloseCreateDialog} />
      <Container
        style={{
          height: 'calc(100vh - 70px)',
          marginTop: '64px',
          overflow: 'overlay',
          paddingLeft: '24px',
          paddingRight: '16px',
          maxWidth: '100%',
        }}
        className="hideScroll"
      >
        <Box mt={4} mb={3} display="flex">
          <Typography variant="h4" color="textPrimary">
            Reports
          </Typography>
          <Box flexGrow="1"/>
          <Button onClick={addNewReport} color="primary" type="submit" variant="contained" size="large">
            ADD NEW REPORT
          </Button>
        </Box>

        <ReportsList />
      </Container>
    </>
  );
};

export default Reports;
