import React from 'react';
import { Box, Typography, Grid, Button} from '@material-ui/core';

const ImagePlaceHolder = ({ message, imageUrl, altText, ...rest }) => {

  return (
    <Grid container justify="center">
      <Box style={{ maxWidth: '323px' }} mt={4}>
        <Box display="flex" justify="center" mb={3} style={{ justifyContent: 'center' }}>
          <img src={imageUrl} alt={altText} style={rest.width ? ({ width: rest.width, height: rest.height }) : { width: '96px', height: '96px' }} />
        </Box>
        <Typography gutterBottom variant="body2" color="textSecondary" align="center">
          {message}
        </Typography>
        {rest.buttonLabel && (
          <Box display="flex" justify="center" mb={3} style={{ justifyContent: 'center' }} mt={2}>
          <Button onClick={rest.buttonFunction} color="primary" type="submit" variant="contained" size="large">
            {rest.buttonLabel}
          </Button>
          </Box>
        )}
      </Box>
    </Grid>
  );
};
export default ImagePlaceHolder;
