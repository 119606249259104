import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';

const StyledAvatar = withStyles(theme => ({
  root: {
    width: '40px',
    height: '40px',
    backgroundColor: '#F5F5F5',
  },
  img: {
    width: '23px',
    height: '24px',
    objectFit: 'contain'
  },
}))(Avatar);

const CustomAvatar = ({ className, src, alt, ...rest }) => {
  return <StyledAvatar alt={alt} src={src} style={{ backgroundColor: '#F5F5F5' }} />;
};

export default CustomAvatar;
