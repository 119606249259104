import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Bar } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/core';
import { Box, Typography } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
}));

const Chart = ({ data: dataProp, labels, yLabels, className, color, chartTitle, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const calcWidth = dataProp.length * 10;
  const data = {
    datasets: [
      {
        backgroundColor: color,
        data: dataProp,
        maxBarThickness: 10,
        barPercentage: 1.0,
        categoryPercentage: 1.0,
      },
    ],
    labels,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cornerRadius: 20,
    redraw: true,
    legend: {
      display: false,
    },
    layout: {
      padding: {
        left: 30,
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider,
          },
          ticks: {
            mirror: true,
            padding: 40,
            suggestedMax: 3,
            beginAtZero: true,
            display: true,
            fontColor: '#A1ABB3',
            // callback: function (value, index, values) {
            //   return yLabels[value];
            // },
          },
        },
      ],
    },
    tooltips: {
      enabled: true,
      mode: 'single',
      intersect: false,
      caretSize: 10,
      yPadding: 10,
      xPadding: 10,
      borderWidth: 1,
      displayColors: false,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.background.default,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        legend: () => {},
        title: () => {},
        label: tooltipItem => {
          const label = `${tooltipItem.xLabel} : ${tooltipItem.yLabel}`;
          return label;
        },
      },
    },
  };

  return (
    <Box mb={4}>
    {chartTitle &&
      (<Box mb={2}>
      <Typography variant="subtitle2" style={{fontWeight: '500'}} color="textPrimary"> {chartTitle}</Typography>
      </Box>
    )}
    <div className={clsx(classes.root, className)} {...rest}>
    <PerfectScrollbar>
    <Box
    minWidth={calcWidth}
    maxWidth={100000}
    minHeight={200}
    p={2}
  >
      <Bar data={data} options={options}/>
      </Box>
      </PerfectScrollbar>
    </div>
    </Box>
  );
};

Chart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
};

export default Chart;
