import React, { useEffect, useState } from 'react';
import { Box, Typography, Container, Button } from '@material-ui/core';
import SecondaryNav from './../SecondaryNav';
import ImagePlaceHolder from './../customers/ImagePlaceholder';
import { getRules, getViews, getEditableColumns } from './AutomationAPI';
import { Link } from 'react-router-dom';
import RulesList from './RulesList';

const Automation = () => {
  const [rulesList, setRulesList] = useState([]);
  const [viewsList, setViewsList] = useState([]);
  const [columnsList, setColumnsList] = useState([]);
  const [loadingFlag, setLoadingFlag] = useState(true);
  const [errorFlag, setErrorFlag] = useState(false);

  const [isRulesLoading, setIsRulesLoading] = useState(true);
  const [isViewsLoading, setIsViewsLoading] = useState(true);
  const [isColumnsLoading, setIsColumnsLoading] = useState(true);

  useEffect(() => {
    if(isRulesLoading && isViewsLoading && isColumnsLoading){
      setLoadingFlag(true);
    }else{
      setLoadingFlag(false);
    }
  }, [isRulesLoading, isViewsLoading, isColumnsLoading])
  useEffect(() => {
    getRules()
      .then(function (response) {
        setIsRulesLoading(false);
        if (response.data.data) {
          setRulesList(response.data.data);
        } else {
          setErrorFlag(true);
        }
      })
      .catch(function (response) {
        setErrorFlag(true);
      });
    getViews()
      .then(function (response) {
        setIsViewsLoading(false);
        if (response.data.data) {
          setViewsList(response.data.data);
        } else {
          setErrorFlag(true);
        }
      })
      .catch(function (response) {
        setIsViewsLoading(false);
        setErrorFlag(true);
      });
      getEditableColumns()
        .then(function (response) {
          setIsColumnsLoading(false);
          if (response.data.data) {
            setColumnsList(response.data.data);
          } else {
            setErrorFlag(true);
          }
        })
        .catch(function (response) {
          setIsColumnsLoading(false);
          setErrorFlag(true);
        });
  }, []);

  return (
    <>
      <SecondaryNav />
      <Container
        style={{
          height: 'calc(100vh - 70px)',
          marginTop: '64px',
          overflow: 'overlay',
          paddingLeft: '24px',
          paddingRight: '16px',
          maxWidth: '100%',
        }}
        className="hideScroll"
      >
        <Box mt={4} mb={3} display="flex">
          <Box>
            <Typography variant="h4" color="textPrimary">
              Automation
            </Typography>
            <Box mt={1} />
            <Typography variant="body2" color="textSecondary">
              Automate workflows with rules.
            </Typography>
          </Box>
          <Box flexGrow="1" />
          {rulesList.length > 0 && (
            <Button
              component={Link}
              to="/automation/create"
              color="primary"
              type="submit"
              variant="contained"
              style={{height: '42px'}}
            >
              CREATE RULE
            </Button>
          )}
        </Box>
        {errorFlag && (
          <ImagePlaceHolder
            message={'Something went wrong. Please contact the involve.ai team to get this fixed.'}
            imageUrl={require('./../../assets/error-general.png')}
            altText={'Error'}
          />
        )}
        {rulesList.length <= 0 && !errorFlag && !loadingFlag && (
          <Box>
            <ImagePlaceHolder
              message={'You do not have any rules yet. Go ahead and create a new one.'}
              imageUrl={require('./../../assets/automation-empty.png')}
              altText={'No Rules'}
            />
            <Box display="flex" justify="center" mb={3} style={{ justifyContent: 'center' }} mt={2}>
              <Button
                component={Link}
                to="/automation/create"
                color="primary"
                type="submit"
                variant="contained"
                size="large"
              >
                Create Rule
              </Button>
            </Box>
          </Box>
        )}

        {rulesList.length > 0 && !errorFlag && !loadingFlag && <RulesList viewsList={viewsList} rulesList={rulesList} columnsList={columnsList}/>}
      </Container>
    </>
  );
};

export default Automation;
