import React, { useRef, useState } from 'react';
import {
  AppBar,
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    color: theme.palette.text.primary,
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
    zIndex: theme.zIndex.drawer + 100,
    height: '64px',
    backgroundColor: 'white',
  },
  toolbar: {
    height: 12,
  },
  link: {
    color: '#263238',
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: '20px',
    textAlign: 'left',
    padding: '20px',
  },
  logo: {
    maxWidth: '136px',
    maxHeight: '36px',
  },
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
  selectedTab: {
    color: '#263238 !important',
    borderBottom: '2px solid #006FFF',
    paddingBottom: '20px',
  },
  topNavLinks: {
    color: '#546E7A',
    textDecoration: 'none',
    fontSize: '14px',
    marginLeft: '50px',
    fontFamily: 'Roboto',
  },
}));

const SecondaryNav = ({ onMobileNavOpen, userImg }) => {
  const classes = useStyles();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('company_name');
    localStorage.removeItem('user_image');
    let logout_url = localStorage.getItem('logout_url');
    if (logout_url !== null && logout_url !== '') {
      localStorage.removeItem('logout_url');
      window.location = logout_url;
      return;
    }
    try {
      handleClose();
    } catch (err) {
      console.error(err);
    }
    window.location = '/login';
  };

  const handleSettings = async () => {
    handleClose();
    window.location = '/usersettings/account';
  }


  const avatarImage = userImg ? userImg : localStorage.getItem('user_image') ? localStorage.getItem('user_image') : "";

  return (
    <AppBar className={classes.root} color="transparent">
      <Toolbar style={{ minHeight: '64px' }}>
        <img src={require('./../assets/involve.png')} alt="logo" className={classes.logo} />
        <Nav.Item>
          <Nav.Link
            as={NavLink}
            to="/customers"
            key="customers"
            activeClassName={classes.selectedTab}
            className={classes.topNavLinks}
          >
            Customers
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            as={NavLink}
            to="/insights"
            className={classes.topNavLinks}
            activeClassName={classes.selectedTab}
            key="insights"
          >
            Insights
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={NavLink}
            to="/automation"
            className={classes.topNavLinks}
            activeClassName={classes.selectedTab}
            key="automation"
          >
            Automation
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            as={NavLink}
            to="/reports"
            className={classes.topNavLinks}
            activeClassName={classes.selectedTab}
            key="reports"
          >
            Reports
          </Nav.Link>
        </Nav.Item>

        {localStorage.getItem('role') === 'admin' &&
        <Nav.Item>
          <Nav.Link
            as={NavLink}
            to="/integrations"
            className={classes.topNavLinks}
            activeClassName={classes.selectedTab}
            key="integrations"
          >
            Integrations
          </Nav.Link>
        </Nav.Item>}
        <Box ml={2} flexGrow={1} />
        <Box ml={2}>
          <>
            <Box display="flex" alignItems="center" component={ButtonBase} onClick={handleOpen} ref={ref}>
              <Avatar
                alt="User"
                className={classes.avatar}
                 src={avatarImage}
              />
              <ArrowDropDownIcon></ArrowDropDownIcon>
              <Hidden smDown>
                <Typography variant="h6" color="inherit"></Typography>
              </Hidden>
            </Box>
            <Menu
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              keepMounted
              PaperProps={{ className: classes.popover }}
              getContentAnchorEl={null}
              anchorEl={ref.current}
              open={isOpen}
            >

              <MenuItem onClick={handleSettings}>Settings</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default SecondaryNav;
