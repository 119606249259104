import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import Login from './components/account/Login';
import ForgotPassword from './components/account/ForgotPassword';
import Register from './components/account/Signup';
import DataSource from './components/integrations/datasources/DataSource';
import DataExport from './components/integrations/datasources/DataExport';
import DataExportFail from './components/integrations/datasources/DataExportFail';
import SalesforceIntegrations from './components/integrations/salesforce/SalesforceIntegrations';
import OutlookIntegrations from './components/integrations/outlook/OutlookIntegrations';
import ZendeskIntegration from './components/integrations/zendesk/ZendeskIntegration';
import CsvUpload from './components/integrations/csv/CsvUpload';
import Customers from './components/customers/Customers';
import Insights from './components/insights/index';
import Automation from './components/automation/index';
import EditRule from './components/automation/updateRule/index';
import Reports from './components/reports/index';
import EditReports from './components/reports/edit/index';
import ViewReports from './components/reports/view/index';
import { PrivateRoute } from './utils/auth';
import Feedback from './components/customers/PredictionFeedback';
import UserSettings from './components/usersettings/index';
import AssignRoles from './components/usersettings/assingRoles';
import ActivateAccount from './components/account/activateAccount';
import AccountSettings from './components/usersettings/account';

const flattenRoutes = routesPath =>
  routesPath.map(route => [route.routes ? flattenRoutes(route.routes) : [], route]).flat(Infinity);

const routes = [
  {
    path: ['/', '/login'],
    component: Login,
    key: 'login',
    routes: [
      {
        path: '/integrations',
        component: DataSource,
        key: 'dataSource',
        needAuth: true,
        routes: [
          {
            path: '/integrations/salesforce',
            component: SalesforceIntegrations,
            needAuth: true,
            key: 'salesforce',
            routes: [
              {
                path: '/integrations/salesforce/dataexport',
                component: DataExport,
                needAuth: true,
                key: 'salesforceExport',
              },
              {
                path: '/integrations/salesforce/dataexportfail',
                component: DataExportFail,
                needAuth: true,
                key: 'salesforceExportFail',
              },
            ],
          },
          {
            path: '/integrations/outlook',
            component: OutlookIntegrations,
            key: 'outlook',
            needAuth: true,
            routes: [
              {
                path: '/integrations/outlook/dataexport',
                component: DataExport,
                needAuth: true,
                key: 'outlookDataExport',
              },
              {
                path: '/integrations/outlook/dataexportfail',
                component: DataExportFail,
                needAuth: true,
                key: 'outlookDataExportFail',
              },
            ],
          },
          {
            path: '/integrations/zendesk',
            component: ZendeskIntegration,
            needAuth: true,
            key: 'zendesk',
            routes: [
              {
                path: '/integrations/zendesk/dataexport',
                component: DataExport,
                needAuth: true,
                key: 'zendeskExport',
              },
              {
                path: '/integrations/zendesk/dataexportfail',
                component: DataExportFail,
                needAuth: true,
                key: 'zendeskExportFail',
              },
            ],
          },
          {
            path: '/integrations/csv',
            component: CsvUpload,
            needAuth: true,
            key: 'csv',
            routes: [
              {
                path: '/integrations/csv/dataexport',
                component: DataExport,
                needAuth: true,
                key: 'csvExport',
              },
              {
                path: '/integrations/csv/dataexportfail',
                component: DataExportFail,
                needAuth: true,
                key: 'csvExportFail',
              },
            ],
          },
        ],
      },
      {
        path: '/activate-account/:activationCode',
        component: ActivateAccount,
        key: 'activateAccount',
      },
      {
        path: '/signup',
        component: Register,
        key: 'signup',
      },
      {
        path: '/forgot-password',
        component: ForgotPassword,
        key: 'forgotPassword',
      },
      {
        path: '/customers',
        component: Customers,
        needAuth: true,
        key: 'customers',
      },
      {
        path: '/insights',
        component: Insights,
        needAuth: true,
        key: 'insights',
      },
      {
        path: '/automation',
        component: Automation,
        needAuth: true,
        key: 'automation',
        routes: [
          {
            path: '/automation/create',
            component: EditRule,
            needAuth: true,
            key: 'CreateRule',
          },
          {
            path: '/automation/edit/',
            component: EditRule,
            needAuth: true,
            key: 'EditRule',
          },
          {
            path: '/automation/copy/',
            component: EditRule,
            needAuth: true,
            key: 'CopyRule',
          }
        ],
      },
      {
        path: '/reports',
        component: Reports,
        needAuth: true,
        key: 'reports',
        routes: [
          {
            path: '/reports/edit/:reportId',
            component: EditReports,
            needAuth: true,
            key: 'EditReports',
          },
          {
            path: '/reports/view/:companyName/:reportId',
            component: ViewReports,
            needAuth: false,
            key: 'ViewReports',
          }
        ],
      },
      {
        path: '/feedback',
        component: Feedback,
        needAuth: false,
        key: 'feedback',
      },
      {
        path: '/usersettings/account',
        component: AccountSettings,
        needAuth: true,
        key: 'accountsettings',
        routes: [
          {
            path: '/usersettings/assignroles',
            component: AssignRoles,
            needAuth: true,
            key: 'assignroles'
          },
          {
            path: '/usersettings/users',
            component: UserSettings,
            needAuth: true,
            key: 'usersettings',
          }
        ]
      },
    ],
  },
];

export default function App() {
  // renders the first one that matches the current URL.
  // using "exact" keyword is important, to route the right path with right component
  // (otherwise = the route and sub route are displayed on same page)
  return (
    <Router>
      {flattenRoutes(routes).map(route =>
        route.needAuth !== true ? (
          <Route path={route.path} exact component={route.component} key={route.key} />
        ) : (
          <PrivateRoute path={route.path} exact component={route.component} key={route.key} />
        ),
      )}
    </Router>
  );
}
