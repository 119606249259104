import React, { useState } from 'react';
import { Box, Typography, Button } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  boxContent: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    overflowY: 'scroll',
    marginTop: '16px',
    padding: '16px',
  },
}));

const GeneralInfoActivity = ({ ...props }) => {
  const [showActivity, setShowActivity] = useState(false);
  const classes = useStyles();

  return (
    <>
      {props.cardDetails.task_activity.length > 0 && (
        <Box mt={2}>
          <Button variant="contained" onClick={() => setShowActivity(!showActivity)}>
            {showActivity ? 'Hide Activity' : 'Show Activity'}
          </Button>
          {showActivity && (
            <Box className={classes.boxContent}>
              {props.cardDetails.task_activity.map((activity, index) => (
                <Box key={index} mb={2}>
                  <Typography variant="body2" color="textPrimary">
                    {activity.description} &nbsp; {activity.value}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {activity.timestamp}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
export default GeneralInfoActivity;
