import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DoughnutChart from './DoughnutChart';
import GroupedBarChart from './GroupedBarChart';
import StatsGrid from './StatsGrid';
import { Box, Grid, Typography, Container } from '@material-ui/core';
import { getBlockStats, getChartData } from '../../ReportsAPI';
import { reportsStyles } from './../../reports.theme';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles({
  chart: {
    height: 200,
    marginTop: '32px'
  },
});


export default function Widget({ id, blockConfig, duration, recordCount, filters, className, ...rest }) {
  const classes = useStyles();
  const styles = reportsStyles();

  const calcConfig = blockConfig.components.filter(item => item.type === 'calculation');
  const barConfig = blockConfig.components.filter(item => item.type === 'bar');
  const doughnutConfig = blockConfig.components.filter(item => item.type === 'doughnut');
  const [statsData, setStatsData] = useState([]);
  const [doughnutData, setDoughnutData] = useState({});
  const [historicalStats, setHistoricalStats] = useState({});
  const [statsDataLoading, setStatsDataLoading] = useState(true);
  const [chartDataLoading, setChartDataLoading] = useState(true);
  const { reportId } = useParams();

  useEffect(() => {
    setStatsDataLoading(true);
    getBlockStats({ block: blockConfig.name, filters: filters, report_id: reportId }).then(response => {
      if (response.data.hasOwnProperty('data')) {
        setStatsData(response.data.data);
        let tempResponse = [...response.data.data.values];
        let tempData = {
          datasets: [
            {
              data: response.data.data.in_percentage,
              backgroundColor: ['#16C37E', '#F78803', '#D32F2F'],
            },
          ],
          labels: tempResponse.splice(0, 3),
          total: response.data.data.values[3],
        };

        setDoughnutData(tempData);
        setStatsDataLoading(false);
      } else {
        setStatsDataLoading(false);
      }
    });
  }, [filters]);

  useEffect(() => {
    setChartDataLoading(true);
    if (barConfig.length > 0) {
      getChartData({
        block: blockConfig.name,
        metrics: barConfig[0].metrics,
        duration: duration,
        filters: filters,
        report_id: reportId
      }).then(response => {
        if (response.data.data) {
          let tempObj = {};
          tempObj.labels = response.data.data[0].dates;
          response.data.data.forEach((item, i) => {
            tempObj[item.metric] = item.values;
          });
          setHistoricalStats(tempObj);
          setChartDataLoading(false);
        } else {
          setChartDataLoading(false);
        }
      });
    }
  }, [duration, filters]);

  return (
    <>
        <Box pl={2} pt={2}>
        <Typography variant="subtitle1">{calcConfig[0].name}</Typography>
      </Box>
      <Box ml={2} mt={1}>
        <Typography variant="body2" color="textSecondary">
          for {recordCount} customers
        </Typography>
      </Box>
      <Container maxWidth={false} style={{ paddingLeft: '16px' }}>
        {(statsDataLoading || chartDataLoading) && <div className={styles.chartDataloader}> </div>}
        {!statsDataLoading && !chartDataLoading && (
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ marginBottom: '10px' }}>
              <StatsGrid data={statsData} blockName={blockConfig.name} />
            </Grid>
            {doughnutConfig.length > 0 && (
              <Grid item lg={4} xl={3} xs={12}>
                <Typography variant="subtitle1">{doughnutConfig[0].name}</Typography>
                <Box pt={2} pb={2} style={{ height: '70%' }}>
                  <DoughnutChart data={doughnutData} blockName={blockConfig.name} />
                </Box>
              </Grid>
            )}

            {barConfig.length > 0 && (
              <Grid item lg={8} xl={9} xs={12} style={{ overflow: 'auto' }}>
                <Typography variant="subtitle1" style={{ marginBottom: '16px' }}>
                  {barConfig[0].name}
                </Typography>
                <GroupedBarChart className={classes.chart} data={historicalStats} blockName={blockConfig.name}/>
              </Grid>
            )}
          </Grid>
        )}
      </Container>
    </>
  );
}
