import React, { useState } from 'react';
import {  Typography, Drawer, List, ListItemText, Link } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core';
import MuiListItem from "@material-ui/core/ListItem";

export const userSettingsMenuStyles = makeStyles((theme) => ({
    
    drawer: {
        width: 240,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 240,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
   
    
    cursorPointer: {
        cursor: 'pointer',
    },
 
}));



const ListItem = withStyles({
  root: {
    color: '#263238',
    "&$selected": {
      color: '#263238',
      background:'none',
      borderLeft: '2px solid #0061FF',
      "& .MuiListItemIcon-root": {
          
      }
    },
    "&$selected:hover": {
     
      "& .MuiListItemIcon-root": {
       
      }
    },
    "&:hover": {
      "& .MuiListItemIcon-root": {
      }
    }
  },
  selected: {}
})(MuiListItem);

const UserSettingsMenu = (props) =>{
  const classes = userSettingsMenuStyles();
  const location = window.location.pathname === '/usersettings/account' ? 0: 1
  const [selected, setSelected] = useState(location);
  return (
    <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"        
      >
        <div className={classes.toolbar}/>
        <Typography variant="h3" style={{ marginTop: '32px', marginLeft: '24px' }}>Settings</Typography>
        <List style={{marginLeft: '48px'}}>
          <ListItem button onClick={() => setSelected(0)}  selected={selected === 0} style={{ textDecoration: 'none' }} component={Link} href="/usersettings/account">
             <ListItemText primary='Account' />
          </ListItem>
          {localStorage.getItem('role') === 'admin' &&
            <ListItem button  onClick={() => setSelected(1)} selected={selected === 1}  style={{ textDecoration: 'none' }} component={Link}  href="/usersettings/users">
              <ListItemText primary='User & Roles' />
            </ListItem>
          }
        </List>
    </Drawer>
  );
}

export default UserSettingsMenu;