import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Typography, Divider, InputAdornment, SvgIcon, TextField , Checkbox, Popper} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { Search as SearchIcon } from 'react-feather';
import axios from 'axios';
import { attachToken } from '../../utils/auth';
import Pagination from '@material-ui/lab/Pagination';
import styles from './customer.module.css';
import ImagePlaceHolder from './ImagePlaceholder';
import HistoryRow from './HistoryRow';
import Autocomplete from '@material-ui/lab/Autocomplete';

const CustomAutocomplete = withStyles({

  root: {
    width: '132px',
    '&.MuiAutocomplete-option': {
      fontSize: '14px'
    },

      "& .MuiFormLabel-root.MuiInputLabel-marginDense":{
          transform: 'translate(11px, 14px) scale(1)',
          fontSize: '14px'
      },
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
         transform: 'translate(14px, -6px) scale(0.75)'
      }


  },

  inputRoot: {
    height:'42px',
  },
  option:{
    paddingLeft:'0px'
  }

})(Autocomplete);


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
    maxHeight: 'calc(100vh - 320px)',
  },
  historyInfo: {
    overflowY: 'auto',
    marginTop: '16px',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D8D8D8',
    },
  },
  filterHeight: {
    height:'42px',
    width:'200px'
  },
  typeAutocomplete:{
    height:'42px',
    width:'132px'
  },
  keywordAutocomplete:{
    height:'42px',
    width:'200px'
  },
  oval: {
    borderRadius: '100%',
    width: '8px',
    height: '8px'
  },
  ovalDiv: {
    display: 'inline-flex',
    paddingRight: '6px'
  },
  popper:{
    width: '250px'
  }
}));



const CustomerHistory = ({ className, customer, ...rest }) => {
  const classes = useStyles();
  const [accountHistoryData, setAccountHistoryData] = useState([]);
  const [errorFlag, setErrorFlag] = useState(false);
  const [page, setPage] = React.useState(1);
  const [loadingFlag, setLoadingFlag] = useState(true);
  const [accountHistoryEmptyFlag, setAccountHistoryEmptyFlag] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filterType, setFilterType] = useState ([]);
  const [typeSearch, setTypeSearch] = useState([]);
  const [keywords, setKeywords] = useState ([]);
  const [keywordsSearch, setKeywordsSearch] = useState([]);
  const [selectAllKeywords, setSelectAllKeywords] = useState("Select All");
  const [selectAllType, setSelectAllType] = useState("Select All");


  const applyFilters = () => {
    return accountHistoryData.filter(historyData => {
      let matches = true;
      let properties = [];
      if (searchText !== '') {
        properties = ['data', 'value', 'timestamp', 'source', 'subject', 'interaction', 'text', 'type', 'summary', 'sentiment', 'history'];

        let containsQuery = false;
        properties.forEach(property => {
          if (typeof historyData[property] === 'string') {
            if (historyData[property].toLowerCase().includes(searchText.toLowerCase())) {
              containsQuery = true;
            }
          } else if (historyData.hasOwnProperty(property)) {
            if (property === 'text') {
              historyData[property].forEach(textVar => {
                if (textVar.toLowerCase().includes(searchText.toLowerCase())) {
                  containsQuery = true;
                }
              });
            }
            if (property === 'interaction') {
              let emailProps = ['sender', 'timestamp', 'body'];
              historyData[property].forEach(email => {
                emailProps.forEach((item, i) => {
                  if (email[item].toLowerCase().includes(searchText.toLowerCase())) {
                    containsQuery = true;
                  }
                });
              });
            }
            if(property === "history") {

              let histProps = ['description', 'key_indicator', 'key_indicator_value', 'timestamp', 'title'];
              historyData[property].forEach(hist => {
                histProps.forEach((item, i) => {
                  if(hist.hasOwnProperty(item)){
                    if (hist[item].toLowerCase().includes(searchText.toLowerCase())) {
                      containsQuery = true;
                    }
                  }

                });
              });
            }
          }
        });
        if (!containsQuery) {
          matches = false;
        }
      }
      return matches;
    });
  };

  const applyFiltersType = (filteredHistoryData) => {
    return filteredHistoryData.filter((e)=> {return e.interaction;}).filter((t) => typeSearch.includes(t.type) );
  }

  const applyFiltersKeywords = (filteredHistoryData) => {
    return filteredHistoryData.filter((e)=> {return e.interaction && e.keywords.length > 0 }).filter ((o) => o.keywords.some((subElement) => keywordsSearch.map(x=> x.label).includes(subElement.label)));
  }

  let filteredHistoryData = applyFilters();
  if (typeSearch.length > 0)
    filteredHistoryData = applyFiltersType(filteredHistoryData);
  if (keywordsSearch.length>0) {
    filteredHistoryData = applyFiltersKeywords(filteredHistoryData);
  }

  let totalPages = Math.ceil(filteredHistoryData.length / 20);

  const handleSearchChange = (event, value) => {
    event.persist();
    setSearchText(event.target.value);
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    let apiUrl = process.env.REACT_APP_API_BASE_URL + 'v1/table/history';
    axios(
      attachToken({
        method: 'post',
        url: apiUrl,
        data: {
          company_name: customer.company_name,
          location: customer.location,
          branch_name: customer.branch_name
        },
        headers: {},
      }),
    )
      .then(function (response) {
        setLoadingFlag(false);
        if (response.data.Error) {
          setErrorFlag(
            true
          );
        } else {
          setAccountHistoryData(response.data.response);
          let filterTypesArray = [...new  Set (response.data.response.filter((e)=> {return e.interaction;}).map(data => data.type))].sort();
          filterTypesArray.unshift ("Select All");
          setFilterType (filterTypesArray);
          let keywordArray = Array.prototype.concat
            .apply([],response.data.response
            .filter((e)=> {return e.interaction && e.keywords.length > 0;})
            .map(x=> x.keywords)).map(x=>  ({label : x.label, positive : x.positive}));
          keywordArray = keywordArray.filter((v,i,a)=>a.findIndex(t=>(t.label === v.label && t.positive===v.positive))===i)
          keywordArray.sort((x, y) => x.positive !== y.positive ?
                                      (x.positive ? -1 : 1) :
                                        x.label.localeCompare( y.label ));
          keywordArray.unshift ({label:"Select All", positive:true});

          setKeywords(keywordArray);

          if (response.data.response.length === 0) {
            setAccountHistoryEmptyFlag(true);
          }
        }
      })
      .catch(function (response) {
        setLoadingFlag(false);
        setErrorFlag(true);
      });
  }, [customer]);





  const selectKeywords = (e,value) => {
    if (value.length === keywords.length -1  &&  !value.map(x=> x.label).includes("Select All")) {
      value = keywords;
      setSelectAllKeywords("Deselect All");
    } else if (value.length === 1  && value.map(x=> x.label).includes("Select All")){
        value = [];
        setSelectAllKeywords("Select All");
    }
    setKeywordsSearch(value);
    setPage(1);
  }

  const selectFilterType = (e,value) => {
    if (value.length === filterType.length -1  &&  !value.includes("Select All")) {
      value = filterType;
      setSelectAllType("Deselect All");
    } else if (value.length === 1  && value.includes("Select All")){
        value = [];
        setSelectAllType("Select All");
    }
    setTypeSearch(value);
    setPage(1);
  }

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" style={{color: '#0061FF'}} />;
  const indeterminateCheckBoxIcon =  <IndeterminateCheckBoxIcon fontSize="small" style={{color: '#0061FF'}} />;


  const PopperAutocomplete = function (props) {

    return ( <Popper {...props} style={{witdth:'250px'}} placement="bottom-start">


      </Popper>);
  };

  const SelectAllKeywords = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectAllKeywords === 'Select All'){
      setSelectAllKeywords("Deselect All");
      setKeywordsSearch(keywords);
    }
    else {
      setSelectAllKeywords("Select All");
      setKeywordsSearch([]);
    }
  }

  const SelectAllTypes = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectAllType === 'Select All'){
      setSelectAllType("Deselect All");
      setTypeSearch(filterType);
    }
    else {
      setSelectAllType("Select All");
      setTypeSearch([]);
    }
  }

  return (
    <Box className={classes.root} py={3} pb={1}>
      <Typography variant="subtitle1" color="textPrimary">History</Typography>
      {errorFlag && (
        <ImagePlaceHolder
        message={'Something went wrong. Please contact the involve.ai team to get this fixed.'}
        imageUrl={require('./../../assets/error-general.png')}
        altText={'Error'} />
      )}
      {loadingFlag && (
        <Box mt={2} style={{ alignSelf: 'center' }}>
          <div className={styles.accountHistoryloader}> </div>
        </Box>
      )}

      {accountHistoryData.length !== 0 && (
        <>
          <Box mt={1} mb={2} display="flex">
            <Box mr={1}>
            <TextField
              className={classes.queryField}
              InputProps={{
                classes: {
                  root: classes.filterHeight,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleSearchChange}
              placeholder="Search..."
              value={searchText}
              variant="outlined"
              size="small"
            />
            </Box>
            <Box mr={1}>
            {filterType.length > 1 ?
              <CustomAutocomplete
               disableClearable = {true}
                size="small"
                multiple
                onChange={(e, value) => selectFilterType(e, value)}
                disableCloseOnSelect
                className = {classes.typeAutocomplete}
                options={filterType}
                value={typeSearch}
                getOptionLabel={(option) => option}
                renderInput={(params) => <TextField {...params} label={`Types ${typeSearch.length === 0 ? "" : typeSearch.length - (typeSearch.includes("Select All")? 1 : 0)  }`}  variant="outlined" />}
                renderTags={() => {}}
                renderOption={(option, { selected }) => (
                  option === 'Select All' ?
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        indeterminateIcon={indeterminateCheckBoxIcon}
                        indeterminate={
                          typeSearch.length > 0 && typeSearch.length !== filterType.length }


                        style={{ marginRight: '-2px' }}
                        checked={selected}
                        onClick={(e) => SelectAllTypes(e)}
                      />
                      <span style={{fontSize: '14px'}} >{selectAllType} </span>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: '-2px' }}
                        checked={selected}
                      />
                      <span style={{fontSize: '14px'}} >{option} </span>

                    </React.Fragment>
                )}
              />
              : null
            }
          </Box>
          <Box>
            {keywords.length > 1  ?
              <CustomAutocomplete
                disableClearable = {true}
                size="small"
                multiple
                onChange={(e, value) => selectKeywords(e, value)}
                disableCloseOnSelect
                className = {classes.keywordAutocomplete}
                options={keywords}
                value={keywordsSearch}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => <TextField {...params} label={`Sentiment Indicators ${keywordsSearch.length === 0 ? "" : keywordsSearch.length - (keywordsSearch.map(x => x.label).includes("Select All"))  }`} variant="outlined" />}
                PopperComponent={PopperAutocomplete}
                renderTags={() => {}}
                renderOption={(option, { selected }) => (
                    option.label === 'Select All' ?
                    <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                        indeterminateIcon={indeterminateCheckBoxIcon}
                        indeterminate={
                          keywordsSearch.length > 0 &&  keywordsSearch.length !== keywords.length }

                      style={{ marginRight: '-2px' }}
                      checked={selected}
                      onClick={(e) => SelectAllKeywords(e)}
                    />
                    <span style={{fontSize: '14px'}} >{selectAllKeywords} </span>
                    <Divider></Divider>

                      </React.Fragment>:
                      <React.Fragment> <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: '-2px' }}
                      checked={selected}
                    />
                    <div className={classes.ovalDiv}>
                      <span className={classes.oval} style={{backgroundColor: option.positive ?  '#09BC71': '#D32F2F'}}></span>
                    </div>
                    <span style={{fontSize: '14px'}} >{option.label} </span>


                  </React.Fragment>
                )}
              />
              : null
            }
          </Box>
          </Box>
          <Divider />
          {filteredHistoryData.length > 0 ? (
            <>
              <Box className={classes.historyInfo}>
                {filteredHistoryData.slice((page - 1) * 20, page * 20 + 20).map((history, i) => (
                  <Box key={i}>
                    <HistoryRow historyData={history} searchText={searchText} keywordsSearch={keywordsSearch} />
                  </Box>
                ))}
              </Box>
              <Box mt={2} style={{ alignSelf: 'center' }}>
                <Pagination count={totalPages} page={page} onChange={handlePageChange} />
              </Box>
            </>
          ) : (
            <ImagePlaceHolder
              message={'No results. Try another search.'}
              imageUrl={require('./../../assets/history-no-results.png')}
              altText={'No Results History'}
            />
          )}
        </>
      )}

      {accountHistoryEmptyFlag && (
        <Box mt={1}>
          <Divider />
          <ImagePlaceHolder
            message={'Customer history will appear here once we get more data about this customer.'}
            imageUrl={require('./../../assets/history-empty.png')}
            altText={'Empty History'}
          />
        </Box>
      )}
    </Box>
  );
};

export default CustomerHistory;
