import React, { useReducer, useEffect } from 'react';
import JoyRide, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

const TOUR_STEPS = [
  {
    target: '#company_name',
    content: 'Review your clients on the basis of name, who owns the contact, product name and deal size.',
    disableBeacon: true,
    placement: 'top',
  },
  {
    target: '#probability_to_renew',
    content:
      'Understand the probability to renew based on key leading indicators for churn. More information on each of the categories can be found here: www.involve.ai/support.',
    placement: 'left',
  },
  {
    target: '#dataSimTable',
    content: 'Know where the metric lies against the benchmarks the AI algorithm have set across your client base.',
    placement: 'top',
  },
  {
    target: '#dataSimTable tr:last-child th:nth-last-child(2)',
    content: 'Evaluate client risks based on probability to renew as predicted by involve.ai',
    placement: 'top',
  },
  {
    target: '#searchFilters',
    content: 'Search for a particular client, category or timeframe with ease.',
  },
];

const INITIAL_STATE = {
  key: new Date(),
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: TOUR_STEPS,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'START':
      return { ...state, run: true };
    case 'RESET':
      return { ...state, stepIndex: 0 };
    case 'STOP':
      return { ...state, run: false };
    case 'NEXT_OR_PREV':
      return { ...state, ...action.payload };
    case 'RESTART':
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      };
    default:
      return state;
  }
};

const PageGuide = () => {
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

  useEffect(() => {
    if (!localStorage.getItem('tour')) {
      localStorage.setItem('tour', true);
      dispatch({ type: 'START' });
    }
  }, []);

  const callback = data => {
    const { action, index, type, status } = data;
    // const checkIndex = (index === size - 1);
    const el = document.querySelector('.react-joyride__spotlight');

    if (el && (index === 0 || index === 1 || index === 3)) {
      let tempHeight = document.querySelector('#company_name').clientHeight + 'px';
      el.style.height = tempHeight;
      el.style.maxHeight = '88px';
      el.style.top = '200px';
      if (index === 0) {
        el.style.width = parseInt(el.style.width) - 30 + 'px';
      }
    }

    if (action === ACTIONS.CLOSE || (status === STATUS.SKIPPED && tourState.run) || status === STATUS.FINISHED) {
      dispatch({ type: 'STOP' });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      dispatch({
        type: 'NEXT_OR_PREV',
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };

  return (
    <>
      <JoyRide
        {...tourState}
        callback={callback}
        showSkipButton={false}
        spotlightPadding={10}
        disableScrollParentFix={true}
        styles={{
          overlay: {
            backgroundColor: '#D8D8D8',
            mixBlendMode: 'multiply',
          },
          options: {
            arrowColor: 'white',
            backgroundColor: 'white',
            primaryColor: '#0061FF',
            textColor: '#263238',
            zIndex: 1500,
            fontFamily: 'Roboto',
          },
          tooltipContainer: {
            textAlign: 'left',
            color: '#263238',
            fontFamily: 'Roboto',
            fontSize: '14px',
            letterSpacing: '0.1px',
            lineHeight: '20px',
          },

          buttonBack: {
            backgroundColor: '#F5F5F5',
            borderRadius: '4px',
            width: '87px',
            height: '42px',
            marginRight: 10,
            color: '#263238',
            fontFamily: 'Roboto',
            fontSize: '13px',
            letterSpacing: '0.3px',
            lineHeight: '22px',
          },
          buttonNext: {
            backgroundColor: '#0061FF',
            borderRadius: '4px',
            width: '89px',
            height: '42px',
            color: '#FFFFFF',
            fontFamily: 'Roboto',
            fontSize: '15px',
            letterSpacing: '0.3px',
            lineHeight: '26x',
            outline: 'none',
          },
          spotlight: {
            backgroundColor: 'white',
          },
        }}
        locale={{
          back: 'PREV',
          close: 'CLOSE',
          last: 'DONE',
          next: 'NEXT',
          skip: 'SKIP',
        }}
      />
    </>
  );
};

export default PageGuide;
