import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CustomerFeedback from './CustomerFeedback';
import { Box, Divider, Tab, Tabs, Typography } from '@material-ui/core';
import CustomerGeneralInfo from './CustomerGeneralInfo';
import CustomerKeyIndicators from './CustomerKeyIndicators';
import CustomerProbToRenew from './CustomerProbToRenew';
import CustomerHistory from './CustomerHistory';
import CustomerTrackedContacts from './CustomerTrackedContacts';
import { getTrendData } from './CustomerAPI.js';
import LoaderGif from '../../assets/loader.gif';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '550px',
    color: theme.palette.text.secondary,
    height: '100%',
  },
  tabs: {
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
    },
  },
  keyIndicatorsloader:{
    backgroundImage: `url(${LoaderGif})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '50px',
    width: '50px',
    zIndex: '1',
    backgroundSize: 'contain',
    left: '50%',
    position: 'relative',
    marginTop: '50px'
  }
}));

const CustomTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: '#546E7A',
    '&:hover': {
      opacity: 1,
    },
    '&$selected': {
      color: '#263238',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {},
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

const CustomerInformation = ({ className, customer,setCustomer, ...rest }) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState('generalInfo');
  const [loadingFlag, setLoadingFlag] = useState(true);
  const [errorFlag, setErrorFlag] = useState(false);
  const [trendInfo, setTrendInfo] = useState([]);

  const tabs = [
    { value: 'generalInfo', label: 'General Info' },
    { value: 'keyIndicators', label: 'Key Indicators' },
    { value: 'history', label: 'History' },
    { value: 'contacts', label: 'Tracked Contacts' },
  ];

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  React.useEffect(() => {
    let requestData = {
      company_name: customer.company_name,
      branch_name: customer.branch_name,
      location: customer.location,
    };
    if (customer.hasOwnProperty('account_id')) {
      requestData = {
        account_id: customer.account_id,
        company_name: customer.company_name,
      };
    }
    getTrendData(requestData).then(response => {
      setLoadingFlag(false);
      if (response.data.hasOwnProperty('response') && response.data.response !== null) {
        setTrendInfo(response.data.response);
      } else if (response.data.hasOwnProperty('Error')) {
        setErrorFlag(true);
      }
    });
  }, [customer]);

  return (
    <Box className={classes.root} pl={2} pr={2}>
      <Typography variant="body2" p={2} color="textSecondary">
        involve.ai Customer Health
      </Typography>

      <Box style={{ maxWidth: '322px' }} mt={1}>
        <CustomerProbToRenew customer={customer} />
      </Box>
      <Box mt={1}>
        <CustomerFeedback customer={customer} />
      </Box>

      <Box mt={1}>
        <Tabs
          onChange={handleTabsChange}
          scrollButtons="auto"
          value={currentTab}
          textColor="primary"
          variant="scrollable"
          indicatorColor="primary"
          className={classes.tabs}
        >
          {tabs.map(tab => (
            <CustomTab key={tab.value} label={tab.label} value={tab.value} style={{ minWidth: '125px' }} />
          ))}
        </Tabs>
      </Box>
      <Divider />

      <Box>
        {currentTab === 'generalInfo' && <CustomerGeneralInfo customer={customer} errorFlag={errorFlag} />}
        {currentTab === 'keyIndicators' && (
          <>
            {loadingFlag ? (
                <div className={classes.keyIndicatorsloader}> </div>
            ) : (
              <CustomerKeyIndicators customer={customer} trend={trendInfo} errorFlag={errorFlag} setCustomer={setCustomer} />
            )}
          </>
        )}
        {currentTab === 'history' && <CustomerHistory customer={customer} />}
        {currentTab === 'contacts' && (
          <CustomerTrackedContacts customer={customer} companyName={customer.company_name} />
        )}
      </Box>
    </Box>
  );
};

export default CustomerInformation;
