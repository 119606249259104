import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LoadingSpinner from '../../LoadingSpinner';
import Alert from '@material-ui/lab/Alert';
import { Box, Checkbox, Container, Divider, TextField, InputAdornment, SvgIcon } from '@material-ui/core';
import { salesforceStyles } from './salesforce.theme';
import styles from './salesforce.module.css';
import { Search as SearchIcon } from 'react-feather';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { FixedSizeList } from 'react-window';

const StepTwo = ({
  handleBack,
  searchTables,
  handleSearchTablesChange,
  cancelFunction,
  errorMessage,
  isLoading,
  tablesList,
  filteredTablesList,
  selectedTables,
  handleSelectTable,
  exportTables,
  ...props
}) => {
  const classes = salesforceStyles();
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(window.innerHeight - 380);
  }, []);

  const Row = ({ index, style }) => (
    <Box display="flex" key={filteredTablesList[index]} id={filteredTablesList[index]} style={style}>
      <Checkbox
        className={classes.checkBoxDimensions}
        checkedIcon={<CheckBoxIcon />}
        checked={selectedTables.indexOf(filteredTablesList[index]) !== -1}
        color="primary"
        classes={{
          root: classes.dropdownMultiSelect,
        }}
        id={filteredTablesList[index]}
        onChange={e => {
          handleSelectTable(filteredTablesList[index]);
        }}
      />
      <Typography
        variant="subtitle2"
        color="textPrimary"
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
      >
        {filteredTablesList[index]}
      </Typography>
    </Box>
  );

  return (
    <Container maxWidth="sm">
      <Typography color="textPrimary" gutterBottom variant="h3" align="center">
        Which tables you want to export?
      </Typography>
      <Box align="center">
        <Typography gutterBottom align="center" variant="body2" color="textSecondary">
          Select tables you want to give access to.
        </Typography>
      </Box>

      {errorMessage !== '' && (
        <Alert severity="error" style={{ maxWidth: '100%' }}>
          {errorMessage}
        </Alert>
      )}

      <Box display="flex" mt={2} mb={2.5}>
        <Box flexGrow={1} />
        <TextField
          className={classes.searchField}
          InputProps={{
            classes: {
              root: classes.filterDimensions,
            },
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          placeholder="Search table name..."
          variant="outlined"
          size="small"
          onChange={handleSearchTablesChange}
        />
      </Box>

      <Divider />

      <FixedSizeList className="List" height={height} itemCount={filteredTablesList.length} itemSize={50} width={550}>
        {Row}
      </FixedSizeList>

      <Box className={classes.fixedButtons}>
        <Divider />
        <Box mb={2} />
        <Typography component={'div'} className={styles.btnExportTypo}>
          <Button variant="contained" onClick={cancelFunction} className={classes.backButton}>
            Cancel
          </Button>
          <Box flexGrow={1} />
          <Button
            variant="contained"
            onClick={handleBack}
            className={classes.backButton}
            style={{ marginRight: '10px' }}
          >
            Back
          </Button>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <Button variant="contained" color="primary" onClick={exportTables}>
              Next
            </Button>
          )}
        </Typography>
      </Box>
    </Container>
  );
};

export default StepTwo;
