import React, { useState, useRef } from 'react';
import SecondaryNav from './../SecondaryNav';
import { Box, Typography, Container, Divider, Button } from '@material-ui/core';
import AssignRolesTable from './assignRolesTable';
import { userRolesStyles } from './userRolesDataTable.theme';
import { useLocation } from 'react-router-dom';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InviteUsersDialog from './inviteUsersDialog';
import { sendInvites } from './UserRoleApi';
import InvitesSentDialog from './invitesSentDialog';
import UserSettingsMenu from './UserSettingsMenu';

const AssignRoles = (props) => {
    const classes = userRolesStyles();
    const [roles, setRoles] = useState([]);
    const location = useLocation();
    const [openDialogInviteFlag, setOpenDialogInviteFlag] = useState(false);
    const [openDialogInviteSentFlag, setOpenDialogInviteSentFlag] = useState(false);
    const [enteredEmails, setEnteredEmails] = useState([]);
    const [errorMessageInvite, setErrorMessageInvite] = useState('');

    React.useEffect(() => {
        let mailsRoles = [];
        location.state.mails.map(email => (
            mailsRoles.push({
                email: email,
                permissions: {},
                userRole: 'user'
            })
        ));
        setRoles(mailsRoles);
    }, [location]);

    const timeOut = useRef(null);

    const closeDialogInviteFun = () => {
        setEnteredEmails([]);
        setErrorMessageInvite('');
        setOpenDialogInviteFlag(false);
    };

    const openDialogInviteFun = () => {
        setOpenDialogInviteFlag(true);
    };

    const openDialogInviteSentFun = () => {
        setOpenDialogInviteSentFlag(true);
    };

    const closeDialogInviteSentFun = () => {
        setOpenDialogInviteSentFlag(false);
    };

    const handleCancel = async () => {
        window.location = '/usersettings/users';
    };

    const addInvites = () => {
        let mailsRoles = roles;
        var error = {};
        if (enteredEmails.length === 0)
            error['mailsCount'] = 'Please add at least one email';

        if (Object.keys(error).length !== 0) {
            setErrorMessageInvite(error);
            return;
        }
        enteredEmails.map(email => (
            mailsRoles.push({
                email: email,
                permissions: {},
                userRole: 'user'
            })
        ));
        setRoles(mailsRoles);
        closeDialogInviteFun();
    }

    const handleSendInvites = () => {
        let requestData = {
            roles: roles
        }
        sendInvites(requestData).then(response => {
            if (response.data.hasOwnProperty('Error')) {
            } else {
                //display dialog
                openDialogInviteSentFun();
                clearTimeout(timeOut.current);
                timeOut.current = setTimeout(() => {
                    window.location = '/usersettings/users';
                }, 3000);
            }
        });
    }

    return (
        <div className={classes.root}>
            <SecondaryNav />
            <UserSettingsMenu></UserSettingsMenu>
            <Container
                style={{
                    height: 'calc(100vh - 70px)',
                    marginTop: '64px',
                    overflow: 'overlay',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    maxWidth: '100%',
                }}
                className="hideScroll"
            >
                <Box display="flex">
                    <Box flexGrow={1} paddingBottom={2}>
                        <Typography variant="h5" style={{ color: '#263238', marginTop: '32px', paddingLeft: '16px' }} color="textPrimary">
                            <span>Assign Roles and Permissions to New Users </span>
                        </Typography>
                    </Box>
                </Box>
                <Divider></Divider>
                <AssignRolesTable
                    userRoles={roles}
                    setUserRoles={setRoles}
                ></AssignRolesTable>
                <Button
                    variant="text"
                    startIcon={<AddCircleOutlineIcon />}
                    color="primary"
                    onClick={() => openDialogInviteFun()}
                    className={classes.buttonBox}
                >
                    ADD MORE USERS
                </Button>
                <Box flexGrow="1">
                    <Button onClick={handleCancel} variant="contained" size="large" className={classes.buttonBox}>
                        Cancel
                    </Button>
                    <Button onClick={handleSendInvites} color="primary" type="submit" variant="contained" size="large" className={classes.buttonBox}>
                        Send Invites
                    </Button>
                </Box>
                <InviteUsersDialog
                    openDialog={openDialogInviteFlag}
                    closeDialogFun={closeDialogInviteFun}
                    enteredEmails={enteredEmails}
                    setEnteredEmails={setEnteredEmails}
                    addInvites={addInvites}
                    errorMessage={errorMessageInvite}
                />
                <InvitesSentDialog
                    openDialog={openDialogInviteSentFlag}
                    closeDialogFun={closeDialogInviteSentFun}
                ></InvitesSentDialog>

            </Container>
        </div>

    );
};
export default AssignRoles;
