import React from 'react';
import { Box, makeStyles, Typography, Button, Dialog, DialogActions } from '@material-ui/core';

const useStyles = makeStyles({
  dialogPaper: {
    maxWidth: '496px',
  },
});

const RemoveUsersDialog = props => {
  const classes = useStyles();

  return (
    <Dialog open={props.openDialog} onClose={props.closeDialogFun} classes={{ paper: classes.dialogPaper }}>
      {props.automationEnabledView ? (
        <Box p={4}>
          <Typography variant="h4" color="textPrimary">
            Delete View
          </Typography>
          <Box pt={2} pb={1.5}>
            <Typography variant="body2" color="textPrimary">
              This View is associated with one or more automation rules and therefore cannot be deleted.
            </Typography>
          </Box>
          <DialogActions style={{ padding: '0px' }}>
            <Box flexGrow="1" />
            <Button color="primary" type="submit" variant="contained" size="large" onClick={props.closeDialogFun}>
              Got It
            </Button>
          </DialogActions>
        </Box>
      ) : (
        <Box p={4}>
          <Typography variant="h4" color="textPrimary">
            Delete This View
          </Typography>
          <Box pt={2} pb={4}>
            <Typography variant="body2" color="textPrimary">
              Are you sure you want to delete this view?
            </Typography>
          </Box>
          <DialogActions style={{ padding: '0px' }}>
            <Button onClick={props.closeDialogFun} variant="contained" size="large">
              Cancel
            </Button>
            <Box flexGrow="1" />
            <Button color="primary" type="submit" variant="contained" size="large" onClick={props.handleDelete}>
              Remove
            </Button>
          </DialogActions>
        </Box>
      )}
    </Dialog>
  );
};

export default RemoveUsersDialog;
