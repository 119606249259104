import axios from 'axios';
import { attachToken } from '../../utils/auth';

export function getRules(callback, errorcallback) {
  return axios(
    attachToken({
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/automation/get_rules',
      data: {},
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function getViews(callback, errorcallback) {
  return axios(
    attachToken({
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/automation/get_views',
      data: {},
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function getEditableColumns(callback, errorcallback) {
  return axios(
    attachToken({
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/automation/get_editable_columns',
      data: {},
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function createRule(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/automation/create_rule',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function updateRule(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/automation/update_rule',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function deleteRule(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'delete',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/automation/delete_rule',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function disableRule(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/automation/disable_rule',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}
