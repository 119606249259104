import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Box, Typography, Link } from '@material-ui/core/';
import ChartProbToRenew from './ChartProbToRenew';
import GeneralInfoInsights from './GeneralInfoInsights';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 320px)',
    overflowY: 'auto',
  },
  customerAttribute: {
    color: '#546E7A',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: '20px',
    textAlign: 'left',
  },
  customerAttributeValue: {
    color: '#263238',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: '20px',
    textAlign: 'left',
  },
  customerProductCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  productIcon: {
    verticalAlign: 'middle',
  },
  greenCircle: {
    display: 'inline-block',
    height: '8px',
    width: '8px',
    backgroundColor: '#09BC71',
    borderRadius: '50%',
  },
  redCircle: {
    display: 'inline-block',
    height: '8px',
    width: '8px',
    backgroundColor: '#D32F2F',
    borderRadius: '100%',
  },
}));

const RowsList = [
  { id: 'customer_id', label: 'Customer ID' },
  { id: 'company_name', label: 'Customer Name' },
  { id: 'branch_name', label: 'Branch Name' },
  { id: 'parent_name', label: 'Parent Account' },
  { id: 'location', label: 'City, State' },
  { id: 'territory', label: 'Territory' },
  { id: 'opportunity_name', label: 'Opportunity Name' },
  { id: 'account_classification', label: 'Account Type' },
  { id: 'csm_name', label: 'Customer Owner' },
  { id: 'service_delivery_manager', label: 'Service Delivery Manager' },
  { id: 'primary_executive_partner', label: 'Primary Executive Partner' },
  { id: 'onboarding_manager', label: 'Onboarding Manager' },
  { id: 'dsr_name', label: 'District Sales Representative' },
  { id: 'number_of_employees', label: 'Number of Employees' },
  { id: 'active_contracts', label: 'Active contracts' },
  { id: 'customer_type', label: 'Customer Type' },
  { id: 'bill_area', label: 'Bill Area' },
  { id: 'city', label: 'City' },
  { id: 'state', label: 'State' },
  { id: 'country', label: 'Country' },
  { id: 'billing_cycle', label: 'Billing Cycle' },
  { id: 'last_paid_amount', label: 'Last Paid Amount' },
  { id: 'last_paid_date', label: 'Last Paid Date' },
  { id: 'account_aging_bucket', label: 'Account Aging Bucket' },
  { id: '30_days', label: 'Aging Bucket: 30 Days' },
  { id: '60_days', label: 'Aging Bucket: 60 Days' },
  { id: '90_days', label: 'Aging Bucket: 90 Days' },
  { id: 'current_deposit_amount', label: 'Current Deposit Amount ($)' },
  { id: 'billing_rate', label: 'Billing Rate'},
  { id: 'date_payment_method_added', label: 'Date Payment Method Added' },
  { id: 'throttle_limit', label: 'Throttle Limit' },
  { id: 'customer_plan', label: 'Customer Plan' },
  { id: 'gmv', label: 'GMV' },
  { id: 'opportunity_category', label: 'Opportunity Category' },
  { id: 'onboarding_stage', label: 'Onboarding Stage' },
  { id: 'product_name', label: 'Product(s)' },
  { id: 'product_code', label: 'Product Codes'},
  { id: 'product_pricing', label: 'Product Pricing ($)' },
  { id: 'product_status', label: 'Product Status' },
  { id: 'domain', label: 'Domain Type' },
  { id: 'industry', label: 'Industry' },
  { id: 'advertisers', label: 'Advertisers' },
  { id: 'audiences', label: 'Audiences' },
  { id: 'drugs', label: 'Drugs' },
  { id: 'tier', label: 'Customer Tiers ' },
  { id: 'platform_offering_type', label: 'Offering Type', tooltipBody: 'Platform offering types' },
  { id: 'license_type', label: 'License Type', tooltipBody: 'Types of customer license' },
  { id: 'license_status', label: 'License Status', tooltipBody: 'Customer license status' },
  { id: 'num_licenses', label: 'Number of Licenses', tooltipBody: 'Number of licenses' },
  { id: 'license_amount_initial', label: 'First-Year License Amount', tooltipBody: 'Net total license amount for first year' },
  { id: 'license_amount_total', label: 'Total License Amount', tooltipBody: 'Gross total amount for licenses' },
  { id: 'acv', label: 'ACV', tooltipBody: 'Annual Contract Value' },
  { id: 'asset_name', label: 'Asset Name' },
  { id: 'asset_status', label: 'Asset Status' },
  { id: 'product_feature_set', label: 'Product Feature Set' },
  { id: 'contract_type', label: 'Contract Type' },
  { id: 'contract_status', label: 'Contract Status' },
  { id: 'production_actions', label: 'Production Actions Entitlements' },
  { id: 'production_forms', label: 'Production Forms Entitlements' },
  { id: 'production_workflows', label: 'Production Workflows Entitlements' },
  { id: 'document_generations', label: 'Document Generations Entitlements' },
  { id: 'NWC_o365_Utilization', label: 'NWC O365 Utilization' },
  { id: 'contract_length', label: 'Contract Length' },
  { id: 'entitlements', label: 'Entitlements' },
  { id: 'world_geo_region', label: 'World Geo Region' },
  { id: 'vertical', label: 'Vertical' },
  { id: 'service_type', label: 'Service Type' },
  { id: 'product', label: 'Subscription Type' },
  { id: 'tool', label: 'Active Tools' },
  { id: 'tool_weekly', label: 'Weekly Tools' },
  { id: 'delta_tool', label: 'Active Tools Delta' },
  { id: 'sticky_tool', label: 'Sticky Tool' },
  { id: 'galaxy', label: 'Galaxy' },
  { id: 'mrr', label: 'MRR ($)' },
  { id: 'annual_revenue', label: 'Annual Revenue ($)' },
  { id: 'deal_size', label: 'Revenue ($)' },
  { id: 'renewal_tcv', label: 'Renewal TCV ($)' },
  { id: 'tcv', label: 'TCV ($)' },
  { id: 'renewal_date', label: 'Renewal Date' },
  { id: 'activity_period', label: 'Activity Period' },
  { id: 'customer_maturity', label: 'Customer Maturity' },
  { id: 'client_status', label: 'Customer Status' },
  { id: 'finance_risk', label: 'Finance Risk' },
  { id: 'ordered_part_condition', label: 'Ordered Part Condition' },
];

function GeneralInfoRow(rowProps) {
  const classes = useStyles();

  return (
    <>
      {rowProps.customer.hasOwnProperty(rowProps.id) && (
        <Grid container item xs={12} spacing={3} key={"general_info_" + rowProps.id} id={"general_info_" + rowProps.id}>
          <Grid item xs={4} className={classes.customerAttribute}>
            {rowProps.label}
          </Grid>
          {typeof rowProps.customer[rowProps.id] === 'string' ? (
            <Grid item xs={8} className={classes.customerAttributeValue}>
              {rowProps.customer[rowProps.id]}
            </Grid>
          ) : (
            <Grid item xs={8} className={classes.customerAttributeValue}>
              {rowProps.customer[rowProps.id].toLocaleString('en-US', {maximumFractionDigits: 10})}
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
}

export default function CustomerGeneralInfo({ className, customer, trend, errorFlag, ...props }) {
  const classes = useStyles();
  const [showMoreProductName, setShowMoreProductName] = useState(false);
  return (
    <Box className={classes.root} py={3} pb={1}>
      <Box mb={2}>
        <Typography variant="subtitle1" color="textPrimary">
          Customer Information
        </Typography>
      </Box>

      <Box style={{ width: '540px' }}>
        <Grid container spacing={1}>
          {RowsList.map((row, i) => (
            <>
              {(row.id === 'product_name' && customer.hasOwnProperty("product_name"))? (
                <Grid container item xs={12} spacing={3} key={"general_info_" + row.id} id={"general_info_" + row.id}>
                  <Grid item xs={4} className={classes.customerAttribute}>
                    Product(s)
                  </Grid>
                  {showMoreProductName ? (
                    <Grid item xs={8} className={classes.customerAttributeValue}>
                      {customer.product_name}
                      {customer.product_name.length > 20 && (
                        <Box>
                          <Link
                            component="button"
                            variant="body2"
                            color="textSecondary"
                            underline="none"
                            onClick={() => {
                              setShowMoreProductName(false);
                            }}
                          >
                            HIDE ALL
                            <ArrowDropUpIcon color="primary" className={classes.productIcon} />
                          </Link>
                        </Box>
                      )}
                    </Grid>
                  ) : (
                    <Grid item xs={8} className={classes.customerAttributeValue + ' ' + classes.customerProductCell}>
                      {customer.product_name}
                      {customer.product_name.length > 50 && (
                        <Box>
                          <Link
                            component="button"
                            variant="body2"
                            color="textSecondary"
                            underline="none"
                            onClick={() => {
                              setShowMoreProductName(true);
                            }}
                          >
                            SHOW ALL <ArrowDropDownIcon className={classes.productIcon} color="primary" />
                          </Link>
                        </Box>
                      )}
                    </Grid>
                  )}
                </Grid>
              ) : (
                <>
                  {row.id === 'client_status' && customer.hasOwnProperty("client_status") ? (
                    <Grid container item xs={12} spacing={3} key={"general_info_" + row.id} id={"general_info_" + row.id}>
                      <Grid item xs={4} className={classes.customerAttribute}>
                        Customer Status
                      </Grid>
                      <Grid item xs={8} className={classes.customerAttributeValue}>
                        {customer.client_status === 'Churned' ? (
                          <span className={classes.redCircle}></span>
                        ) : (
                          <span className={classes.greenCircle}></span>
                        )}
                        &nbsp;&nbsp;{customer.client_status}
                      </Grid>
                    </Grid>
                  ) : (
                    <GeneralInfoRow customer={customer} key ={"general_info_" + row.id} id={row.id} label={row.label} />
                  )}
                </>
              )}
            </>
          ))}
        </Grid>
      </Box>
      <ChartProbToRenew  customer={customer} />
      <GeneralInfoInsights customer={customer} />
    </Box>
  );
}
