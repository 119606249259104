import React, { useEffect, useState, useCallback } from 'react';
import { Box, Typography, IconButton, Popover, MenuList, MenuItem, Divider, TextField,
Button,
Checkbox, } from '@material-ui/core/';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import classes from '../insights/insights.module.css';
import { insightsStyles } from '../insights/insights.theme';
import { getInsightAPI } from '../insights/InsightsAPI.js';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { updateInsight, updateTaskNote } from '../insights/KanbanFunctions';
import GeneralInfoTasks from './GeneralInfoTasks';
import GeneralInfoActivity from './GeneralInfoActivity';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const Insight = ({ company_id, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const styles = insightsStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [hasDueDate, setHasDueDate] = useState(props.info.hasOwnProperty('due_date'));
  const [description, setDescription] = useState(props.info.description + ' ' + props.info.items.join(' '));
  const [selectedDate, setSelectedDate] = React.useState(props.info.hasOwnProperty('due_date') && props.info.due_date!== "" ? props.info.due_date : new Date().toISOString().substring(0, 10) );
  const [dateInDateFormat, setDateInDateFormat] = React.useState(props.info.hasOwnProperty('due_date') && props.info.due_date!== "" ? new Date( new Date(props.info.due_date).getTime() - new Date (props.info.due_date).getTimezoneOffset() * -60000) : new Date());
  const handleDateChange = date => {
    setSelectedDate(date.toISOString().substring(0, 10));
    setDateInDateFormat(date);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleDismissClick = item_id => {
    handleClose();
    props.handleUpdateInsight(company_id, {
      item_id: props.info.item_id,
      is_new: false,
      is_dismissed: true,
      is_converted_to_task: props.info.is_converted_to_task,
      description: props.info.description,
    });
  };

  const handleConvertToTask = item_id => {
    handleClose();
    setIsEditing(true);
  };

  const handleAdd = () => {
    props.handleUpdateConvert(
      company_id,
      'task',
      'create',
      {
        description: description,
        due_date: hasDueDate ? selectedDate : null,
      },
      {
        item_id: props.info.item_id,
        is_new: false,
        is_dismissed: false,
        is_converted_to_task: true,
        description: props.info.description,
      },
    );
    handleClose();
    setIsEditing(false);
  };

  return (
    <>
      {isEditing ? (
        <Box mt={2} mb={1}>
          <Box mb={1}>
            <Typography variant="subtitle1" color="textPrimary">
              Convert to Task
            </Typography>
          </Box>
          <TextField
            id="outlined-textarea"
            placeholder="Add a new task"
            multiline
            rows={3}
            fullWidth
            variant="outlined"
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          <Box display="flex" mt={2}>
            <Checkbox
              checked={hasDueDate}
              name="checked"
              color="primary"
              style={{ paddingLeft: '0px', paddingTop: '0px', height: '18px', width: '18px' }}
              onChange={() => setHasDueDate(!hasDueDate)}
            />
            <Typography variant="subtitle2" color="textPrimary">
              Add due date
            </Typography>
          </Box>
          {hasDueDate && (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                minDate={new Date()}
                value={dateInDateFormat}
                onChange={(d) => handleDateChange(d)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          )}
          <Box mt={2} mb={1} display="flex">
            <Button variant="contained" style={{ marginRight: '16px' }} onClick={() => setIsEditing(false)}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={() => handleAdd()}>
              Add
            </Button>
          </Box>
        </Box>
      ) : (
    <Box style={props.info.is_dismissed ? ({opacity: '50%'}) : (null)}>
      <Box mt={2} display="flex" style={{ width: '100%' }}>
        <img
          src={require('../../assets/insight.png')}
          alt="Insight"
          style={{ width: '16px', height: '15px', marginRight: '12px' }}
        />

        <Typography variant="subtitle1" color="textPrimary" style={{ paddingRight: '16px' }}>
          {props.info.description}
          {props.info.key_indicator_value === 'High' ? (
            <ArrowUpwardIcon
              className={
                props.info.key_indicator === 'number_of_support_tickets' ||
                props.info.key_indicator === 'severity_of_support_tickets'
                  ? styles.redArrow
                  : styles.greenArrow
              }
            />
          ) : (
            <>
              {props.info.key_indicator_value === 'Low' ? (
                <ArrowDownwardIcon
                  className={
                    props.info.key_indicator === 'number_of_support_tickets' ||
                    props.info.key_indicator === 'severity_of_support_tickets'
                      ? styles.greenArrow
                      : styles.redArrow
                  }
                />
              ) : null}
            </>
          )}
        </Typography>

        <Box flexGrow="1" />
        <Typography variant="body2" color="textPrimary" style={{ paddingRight: '16px' }}>
          {props.info.is_new && <span className={styles.newInsightChip}>NEW</span>}
          {props.info.is_converted_to_task && <span className={styles.convertedToTaskChip}>CONVERTED TO TASK</span>}
          {props.info.is_dismissed && <span className={styles.dismissedChip}>DISMISSED</span>}
        </Typography>

        <IconButton style={{ height: '10px', width: '10px' }} onClick={handleClick} className={classes.moreIcon}>
          <MoreHorizIcon />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuList>
            <MenuItem onClick={() => handleConvertToTask(props.info.item_id)}>Convert to Task</MenuItem>
            <MenuItem onClick={() => handleDismissClick(props.info.item_id)}>Dismiss</MenuItem>
          </MenuList>
        </Popover>
      </Box>
      <Box ml={3.5}>
        {props.info.items.map((item, index) => (
          <Box display="flex">
            <Typography variant="subtitle2" color="textPrimary">
              - &nbsp;
            </Typography>
            <Typography variant="subtitle2" color="textPrimary">
              {item}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>

  )}
  </>
  );
};

const GeneralInfoInsights = ({ customer, ...props }) => {
  const [cardDetails, setCardDetails] = useState([]);
  const [loadingFlag, setLoadingFlag] = useState(true);


  useEffect(() => {
      getInsightAPI({ company_id: customer.account_id, company_name: customer.company_name }).then(response => {
        if (response.data.hasOwnProperty('data') && response.data.data !== null) {
          setCardDetails([response.data.data]);
          setLoadingFlag(false);
        }
      });
  }, [customer]);

  const handleUpdateInsight = useCallback(
    (id, itemDetails) => {
      updateInsight(cardDetails, id, itemDetails).then(function (response) {
        setCardDetails(response);
      });
    },
    [cardDetails],
  );

  const handleUpdateTaskNote = useCallback(
    (id, type, action, itemDetails) => {
      updateTaskNote(cardDetails, id, type, action, itemDetails).then(function (response) {
        setCardDetails(response);
      });
    },
    [cardDetails],
  );

  const handleUpdateConvert = useCallback(
    (id, type, action, itemDetails, insightDetails) => {
      updateTaskNote(cardDetails, id, type, action, itemDetails).then(function (response) {
        updateInsight(response, id, insightDetails).then(function (response) {
          setCardDetails(response);
        });
      });
    },
    [cardDetails],
  );

  return (
    <Box mt={2}>
      <Divider />
      <Box mb={2}/>
      <Typography variant="subtitle1" color="textPrimary">
        Insights
      </Typography>
      {loadingFlag ? (null) : (
        <>
          {cardDetails[0].hasOwnProperty('insights') && cardDetails[0].insights.length > 0 ? (
            <>
              {cardDetails[0].insights.map(info => (
                <Box key={info.item_id}>
                  <Insight
                    info={info}
                    company_id={customer.account_id}
                    changeInsightStatus={props.changeInsightStatus}
                    handleDismissInsight={props.handleDismissInsight}
                    handleUpdateInsight={handleUpdateInsight}
                    handleUpdateConvert={handleUpdateConvert}
                  />
                </Box>
              ))}
            </>
          ) : (
            <>
              <Box display="flex" justify="center" mt={3} mb={3} style={{ justifyContent: 'center' }}>
                <img
                  src={require('./../../assets/empty-insight.png')}
                  alt={'Empty'}
                  style={{ width: '118px', height: '96px' }}
                />
              </Box>
              <Typography gutterBottom variant="body2" color="textSecondary" align="center">
                There are no new insights.
              </Typography>
            </>
          )}
          {cardDetails[0].hasOwnProperty('tasks') && <GeneralInfoTasks customer={customer} handleUpdateTaskNote={handleUpdateTaskNote} cardDetails={cardDetails[0]} company_id={customer.account_id}/>}
          {cardDetails[0].task_activity.length>0 && <GeneralInfoActivity cardDetails={cardDetails[0]} />}
        </>
      )}


    </Box>
  );
};
export default GeneralInfoInsights;
