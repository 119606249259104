import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Box, TextField, Typography, Button, Dialog, DialogActions, Chip } from '@material-ui/core';
import { EMAIL_VALIDATION_REGEX } from '../../constants/ReferenceConstants';
import Alert from '@material-ui/lab/Alert';
import { userRolesDialogStyles } from './dialogs.theme'

const useStyles = makeStyles({
    dialogPaper: {        
    },
});

const InviteUsersDialog = props => {
    const classes = useStyles();
    const styles = userRolesDialogStyles();    
    const [typedEmail, setValue] = useState('');
    const [errorMailFormat, setErrorMailFormat] = useState('');


    const handleDeleteChip = email => {
        const newEntertedEmails = [...props.enteredEmails];
        newEntertedEmails.splice(newEntertedEmails.indexOf(email), 1);
        props.setEnteredEmails(newEntertedEmails);
    }

    const handleTextChange = e => {
        if (e.target.value !== "," && e.target.value !== "Enter") {
            setValue(e.target.value);
        }
        if (e.key === "," || e.key === "Enter") {
            if (e.target.value !== '') {
                if (EMAIL_VALIDATION_REGEX.test(e.target.value)) {
                    const newEntertedEmails = [...props.enteredEmails];
                    if (newEntertedEmails.push(e.target.value));
                    props.setEnteredEmails(newEntertedEmails);
                    setValue('');
                    setErrorMailFormat('');
                } else {
                    setErrorMailFormat('Please enter a valid email address');
                }
            }
        }
    }    

    return (
        <Dialog open={props.openDialog} onClose={props.closeDialogFun} classes={{ paper: classes.dialogPaper }}>
            <Box p={4}>
                <Typography variant="h4" color="textPrimary">Invite Users</Typography>

                <Box pt={2}>
                    <Typography variant="subtitle1" color="textSecondary">Enter one or multiple emails of users you want to add to the system.</Typography>
                </Box>

                {props.errorMessage !== '' || errorMailFormat !== "" ? (
                    <Box mt={1} mb={1}>
                        <Alert severity="error">
                            {' '}
                            {typeof props.errorMessage === 'string'
                                ? props.errorMessage
                                : Object.keys(props.errorMessage).map((key, index) => {
                                    return <li>{props.errorMessage[key]}</li>;
                                })}
                            {errorMailFormat && <li>{errorMailFormat}</li>}
                        </Alert>
                    </Box>
                ) : null}

                <Box mb={2}>
                    <TextField id="outlined-full-width" label="Email(s)"
                        placeholder={props.enteredEmails.length === 0 ? "Enter one or multiple emails separated by comma..." : ""}
                        style={{}} fullWidth margin="normal" InputProps={{
                            startAdornment: props.enteredEmails.map(email => (
                                <Chip key={email} label={email} onDelete={() => handleDeleteChip(email)} className={styles.chips} />
                            ))

                        }}
                        variant="outlined" value={typedEmail}
                        onChange={(e) => handleTextChange(e)}
                        onKeyPress={(e) => handleTextChange(e)}
                    />
                </Box>
                <DialogActions style={{ padding: '0px' }}>
                    <Button onClick={props.closeDialogFun} variant="contained" size="large">
                        Cancel
                    </Button>
                    <Box flexGrow="1" />
                    <Button color="primary" type="submit" variant="contained" size="large" onClick={props.addInvites}>
                        Add
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};
export default InviteUsersDialog;