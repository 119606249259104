import { makeStyles } from '@material-ui/core';
import LoaderGif from '../../assets/loader.gif';

export const ruleStyles = makeStyles(theme => ({
  addNewBtn: {
    color: theme.palette.text.secondary,
    border: '1px dashed rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    height: '58px',
    width: '100%',
    letterSpacing: '0.1px',
  },
  backdrop: {
    backgroundColor: '#FAFAFA',
  },
  addedBlock: {
    backgroundColor: '#F5F5F5',
    borderRadius: '4px',
    padding: '8px 16px',
  },
  inputHeight: {
    height: '42px',
    marginLeft: '8px',
  },
  ruleBox: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
  },
  addNewBlock: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
  },
  loader: {
    backgroundImage: `url(${LoaderGif})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '50px',
    width: '50px',
    zIndex: '1',
    backgroundSize: 'contain',
    top: 'calc(50vh - 25px)',
    left: 'calc(50vw - 25px)',
    position: 'absolute',
  },
}));
