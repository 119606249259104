import { makeStyles } from '@material-ui/core';

export const accountStyles = makeStyles((theme) => ({
    marginTop: {
      marginTop: '34px'
    },
    containerLeft: {
      marginLeft: '120px'  
    }

}));