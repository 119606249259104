import React, { useState, useEffect } from 'react';
import { makeStyles, Box, Checkbox, Popover } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { createBlock } from '../ReportsAPI';

const useStyles = makeStyles({
  popoverBox: {
    border: '2px solid #C4C4C4',
    padding: '8px',
    borderRadius: '5px',
  },
});

const CreateNewBlockPopover = ({ id, open, anchorEl, onClose, BlockData, ...props }) => {
  const classes = useStyles();

  const checkConfigExists = (data, entry, deletedEntry) => {
    if (data['blocks'].length > 0) {
      const bool = data['blocks'].filter(item => item.layout === 'full-width' && item.name === entry).length > 0;
      if (deletedEntry.length > 0 && bool && deletedEntry === entry) {
        return false;
      } else {
        return bool;
      }
    }
    return false;
  };

  const [ifRevenue, setIfRevenue] = useState(checkConfigExists(BlockData, 'revenue', ''));
  const [ifCustomer, setIfCustomer] = useState(checkConfigExists(BlockData, 'customer', ''));

  useEffect(() => {
    // Using props['checkEntry'] as a hack to render only when deleted(either customer or revenue)
    if (props['blockDeleted'].length > 0 && props['blockDeleted'] === 'customer') {
      setIfCustomer(checkConfigExists(BlockData, props['blockDeleted'], props['blockDeleted']));
    } else if (props['blockDeleted'].length > 0 && props['blockDeleted'] === 'revenue') {
      setIfRevenue(checkConfigExists(BlockData, props['blockDeleted'], props['blockDeleted']));
    }
  }, [props['checkEntry']]);

  const handleSelect = e => {
    createBlock({
      report_id: BlockData.report_id,
      block: e,
    }).then(response => {
      if (response.status === 200) {
        onClose();
        props.setNewChart({
          layout: 'full-width',
          id: response.data.id,
          components: response.data.components,
          name: response.data.name,
        });
        e === 'customer' ? setIfCustomer(true) : setIfRevenue(true);
      } else {
        console.log(response.data.Error);
      }
    });
  };
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box display={'grid'} className={classes.popoverBox}>
        <FormControlLabel
          control={
            <Checkbox
              checkedIcon={<CheckBoxIcon />}
              checked={ifRevenue}
              color="primary"
              id={'revenue'}
              onChange={
                !ifRevenue
                  ? e => {
                      handleSelect(e.target.id);
                    }
                  : undefined
              }
            />
          }
          label="Revenue Analytics"
        />

        <FormControlLabel
          control={
            <Checkbox
              checkedIcon={<CheckBoxIcon />}
              checked={ifCustomer}
              color="primary"
              id={'customer'}
              onChange={
                !ifCustomer
                  ? e => {
                      handleSelect(e.target.id);
                    }
                  : undefined
              }
            />
          }
          label="Customers Analytics"
        />
      </Box>
    </Popover>
  );
};
export default CreateNewBlockPopover;
