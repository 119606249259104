import React, { useState, useEffect } from 'react';
import axios from 'axios';
import qs from 'query-string';
import { filter, isEmpty, map } from 'lodash';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StepConnector from '@material-ui/core/StepConnector';
import { Search } from '@material-ui/icons';
import {
  Box,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  InputAdornment,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import SecondaryNav from '../../SecondaryNav';
import LoadingSpinner from '../../LoadingSpinner';
import PropTypes from 'prop-types';
import { attachToken } from '../../../utils/auth';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  queryField: {
    // width: 500,
  },
  icon: {
    width: '32px',
    height: '32px',
  },
  nested: {
    paddingLeft: theme.spacing(6),
  },
}));

const Connector = withStyles({
  line: {
    borderStyle: 'dashed',
    borderColor: '#D8D8D8',
    borderBottomWidth: '0px',
    borderLeftWidth: '0px',
    borderRightWidth: '0px',
    borderTopWidth: '1px',
    marginTop: '5px',
  },
})(StepConnector);

const { REACT_APP_API_BASE_URL } = process.env;

const OutlookDataSelector = props => {
  const [selectAll, setSelectAll] = useState(false);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const [data, setData] = useState([]);

  const loadData = state => {
    const apiUrl = `${REACT_APP_API_BASE_URL}v1/connector/outlook_export?state=${state}`;
    setLoading(true);
    axios(
      attachToken({
        method: 'GET',
        url: apiUrl,
        headers: {},
      }),
    ).then(response => {
      const nextData = map(response.data, elem => ({
        ...elem,
        id: elem.address,
        checked: false,
      }));
      setLoading(false);
      setData(nextData);
    });
  };

  useEffect(() => {
    const { params } = props;
    if (params.state) {
      loadData(params.state);
    }
  }, [props]);

  const handleSearch = value => {
    setSearch(value);
    if (isEmpty(value)) {
      const nextData = map(data, elem => ({ ...elem, active: false }));
      setData(nextData);
    } else {
      const searchLowerCase = value.toLowerCase();
      const filteredData = map(data, elem => {
        const nameIncludes = elem.name && elem.name.toLowerCase().includes(searchLowerCase);
        const addressIncludes = elem.address.toLowerCase().includes(searchLowerCase);
        return { ...elem, active: nameIncludes || addressIncludes };
      });
      setData(filteredData);
    }
  };

  const handleSelectAll = checked => {
    setSelectAll(checked);

    const updatedData = isEmpty(search)
      ? map(data, elem => ({ ...elem, checked }))
      : map(data, elem => (elem.active ? { ...elem, checked } : elem));
    setData(updatedData);
  };

  const handleToggle = (nodeId, checked = false) => {
    const updatedData = map(data, elem => {
      return elem.id === nodeId || elem.parent === nodeId
        ? {
            ...elem,
            checked,
          }
        : elem;
    });
    setData(updatedData);
  };

  const handleExport = () => {
    // TODO: Send the selected nodes to back-end.
    setLoading(true);
    const apiUrl = `${REACT_APP_API_BASE_URL}v1/connector/outlook_export`;
    const { params } = props;
    const selectedData = filter(data, { checked: true });
    const selectedEmails = map(selectedData, 'address');

    const postData = {
      state: params.state,
      emails: selectedEmails,
    };

    axios(
      attachToken({
        method: 'POST',
        url: apiUrl,
        data: postData,
        headers: {},
      }),
    ).then(response => {
      setLoading(false);
      window.location = '/integrations/outlook/dataexport?source=outlook';
    });
  };

  const checkedNodes = filter(data, 'checked');
  const activeData = isEmpty(search) ? data : filter(data, { active: true });

  return (
    <Container p={2} style={{ maxWidth: '880px', marginTop: '20px' }}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                disabled={loading}
                checked={selectAll}
                onChange={evt => handleSelectAll(evt.target.checked)}
                name="selectAll"
                style={{marginLeft: "4px"}}
              />
            }
            label="Select All"
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            disabled={loading}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={evt => handleSearch(evt.target.value)}
            placeholder="Search..."
            value={search}
            variant="outlined"
            size="small"
            style={{marginLeft: "110px"}}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider/>
        </Grid>
        {loading ? (
          <Container style={{ padding: '30px' }}>
            <LoadingSpinner />
          </Container>
        ) : (
          <List className={classes.root}>
            {map(activeData, node => (
              <ListItem key={node.id} button>
                <ListItemIcon>
                  <Checkbox
                    color="primary"
                    edge="start"
                    checked={node.checked}
                    tabIndex={-1}
                    disableRipple
                    onChange={evt => handleToggle(node.id, evt.target.checked)}
                    inputProps={{ 'aria-labelledby': `checkbox-${node.id}` }}
                  />
                </ListItemIcon>
                <ListItemText id={node.id} primary={node.name} secondary={node.address} />
              </ListItem>
            ))}
          </List>
        )}
      </Grid>
      <br/>
      <Divider/>
      <br/>
      <Typography component={'div'} style={{ display: 'flex', paddingTop: '10px' }}>
        <Button disabled={loading} variant="contained" onClick={props.onCancel} className={classes.backButton}>
          Cancel
        </Button>
        <Box flexGrow={1} />
        <Button
          variant="contained"
          onClick={evt => {
            window.location = '/integrations/outlook';
          }}
          disabled={loading}
          className={classes.backButton}
          style={{ marginRight: '10px' }}
        >
          Back
        </Button>
        <Button disabled={isEmpty(checkedNodes) || loading} variant="contained" color="primary" onClick={handleExport}>
          Export
        </Button>
      </Typography>
    </Container>
  );
};

export const OutlookIntegrations = props => {
  const SECOND_STEP_ID = '1';
  const FIRST_STEP_ID = '0';

  const handleCancel = () => {
    window.location.pathname = '/integrations';
  };

  const { classes } = props;

  const params = useLocation();
  const parsedQs = qs.parse(params.search);
  const activeStep = parseInt(parsedQs.step || FIRST_STEP_ID);

  if (parsedQs.step !== SECOND_STEP_ID) {
    setTimeout(() => {
      window.location = `${REACT_APP_API_BASE_URL}v1/connector/outlook_connect`;
    }, 500);
  }

  return (
    <Container>
      <SecondaryNav />
      <Container style={{ height: 'calc(100vh - 70px)', marginTop: '64px', overflow: 'scroll' }} className="hideScroll">
        <Box display="flex" justifyContent="center" width="100%" style={{ marginBottom: '30px', marginTop: '32px' }}>
          <Container className={classes.root} style={{}}>
            <Container style={{ maxWidth: '262px' }}>
              <Stepper activeStep={activeStep} alternativeLabel connector={<Connector />}>
                <Step key="1">
                  <StepLabel StepIconProps={{ classes: { root: classes.icon } }}></StepLabel>
                </Step>
                <Step key="2">
                  <StepLabel StepIconProps={{ classes: { root: classes.icon } }}></StepLabel>
                </Step>
              </Stepper>
            </Container>
            <Container>
              <Container>
                {activeStep === 0 && (
                  <Container maxWidth="sm">
                    <Typography color="textPrimary" gutterBottom variant="h3" align="center">
                      Redirecting to Microsoft Outlook to login
                    </Typography>
                    <Box align="center">
                      <Typography gutterBottom align="center" variant="body2" color="textSecondary">
                        You will be redirected back here once login is complete.
                      </Typography>
                    </Box>
                    <LoadingSpinner />
                  </Container>
                )}
                {activeStep === 1 && (
                  <Container style={{maxWidth:'680px'}} maxWidth="sm">
                    <Typography color="textPrimary" gutterBottom variant="h3" align="center">
                      What do you want to export?
                    </Typography>
                    <Box align="center">
                      <Typography gutterBottom align="center" variant="body2" color="textSecondary">
                        Select departments or people you want to give access to.
                      </Typography>
                    </Box>
                    <Container>
                      <OutlookDataSelector onCancel={handleCancel} params={parsedQs} />
                    </Container>
                  </Container>
                )}
              </Container>
            </Container>
          </Container>
        </Box>
      </Container>
    </Container>
  );
};
OutlookIntegrations.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(useStyles)(OutlookIntegrations);
