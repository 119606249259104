import React from 'react';
import { Box, makeStyles, Typography, Button, Dialog, DialogActions } from '@material-ui/core';

const useStyles = makeStyles({
    dialogPaper: {
        maxWidth: '496px',
        maxHeight: '220px',
    },
});

const RemoveUsersDialog = props => {
    const classes = useStyles();

    return (
        <Dialog open={props.openDialog} onClose={props.closeDialogFun} classes={{ paper: classes.dialogPaper }}>
            <Box p={4}>
                    <Typography variant="h4" color="textPrimary">Remove User</Typography>
                
                <Box pt={2} pb={4}>
                    <Typography variant="label" color="textSecondary">{props.removeText}</Typography>
                </Box>
                <DialogActions style={{ padding: '0px' }}>
                    <Button onClick={props.closeDialogFun} variant="contained" size="large">
                        Cancel
                    </Button>
                    <Box flexGrow="1" />
                    <Button color="primary" type="submit" variant="contained" size="large" onClick={props.handleDelete}>
                        Remove
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
}

export default RemoveUsersDialog;