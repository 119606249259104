import React, { useState, useEffect } from 'react';
import Chart from './Chart';
import { useTheme } from '@material-ui/core';

const yLabels = {
  0: ' ',
  1: 'Low',
  2: 'Medium',
  3: 'High',
};

export default function CategoricalChart({ className, trend, supportTicket, ...rest }) {
  const [data, setData] = useState([]);
  const [colors, setColors] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    let tempDataArr = [];
    let tempColorArr = [];

    if (supportTicket) {
      for (var i = 0; i <= trend.values.length; i++) {
        let tempColor;
        let tempData;
        switch (trend.values[i]) {
          case 'High':
            tempColor = supportTicket ? theme.palette.low.main : theme.palette.high.main;
            tempData = 3;
            break;
          case 'Medium':
            tempColor = theme.palette.medium.main;
            tempData = 2;
            break;
          case 'Low':
            tempColor = supportTicket ? theme.palette.high.main : theme.palette.low.main;
            tempData = 1;
            break;
          default:
            tempColor = '';
            tempData = 0;
            break;
        }
        tempDataArr.push(tempData);
        tempColorArr.push(tempColor);
      }
      setData(tempDataArr);
    } else {
      trend.values.forEach((item, i) => {
        let tempColor =
          item >= 70
            ? theme.palette.high.main
            : item < 70 && item >= 45
            ? theme.palette.medium.main
            : theme.palette.low.main;
        tempColorArr.push(tempColor);
      });
      setData(trend.values);
    }
    setColors(tempColorArr);
  }, [trend, supportTicket, theme]);

  return (
    <div style={{ position: 'relative' }}>
      {data.length !== 0 && trend.length !== 0 && (
        <Chart data={data} labels={trend.dates} yLabels={supportTicket ? yLabels : 'use_num'} colors={colors} />
      )}
    </div>
  );
}
