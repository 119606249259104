import React, { useState } from 'react';
import {Box, Container, TextField, Tooltip, Typography} from '@material-ui/core';
import NavBar from '../NavBar'
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import {methodIcons} from './account.constants'
import {accountStyles} from './account.theme';
import LoadingSpinner from "../LoadingSpinner";
import axios from "axios";
import {useParams} from "react-router-dom";
  

const ActivateAccount = () => {
    const classes = accountStyles();
    const user = useState({})[0];
    let { activationCode } = useParams();

    return (
        <div>
            <NavBar/>
            <div className={classes.banner} style={{display:"none"}}>
                <Container maxWidth="md">
                    <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="center"
                    >
                        <Box
                            alignItems="center"
                            display="flex"
                        >
                            <Tooltip title="">
                                <img
                                    alt="Auth0"
                                    className={classes.logo}
                                    src={methodIcons['LOGO']}
                                />
                            </Tooltip>
                        </Box>
                    </Box>
                </Container>
            </div>
            <Container
                className={classes.cardContainer}
                maxWidth="sm"
            >
                <div>                    
                    <CreatePasswordForm activationCode={activationCode} user={user}/>                
                </div>
            </Container>
        </div>
    );
};

export default ActivateAccount;

const createPassword = (user, success_fn) => {
    var apiUrl = process.env.REACT_APP_API_BASE_URL + "v1/auth/create_password";
    var bodyFormData = new FormData();
    bodyFormData.append('password', user.password)
    bodyFormData.append('activation_code',user.activationCode)
    axios({
        method: 'post',
        url: apiUrl,
        data: bodyFormData,
        headers: {}
    })
        .then(function (response) {
            if (response.status === 200){
                //handle success
                console.log(response);
                if(success_fn) {
                    success_fn(response);
                }
            }
            else{
                //handle success
                console.log(response);
            }
        })
        .catch(function (error) {
            //handle error
            console.log(error.response);
            // window.location = '/dataexportfail'
        });
}

const CreatePasswordForm = (props) => {
    const [isLoading, setLoading] = React.useState(false)
    const [password, setPassword] = React.useState('')
    const [password2, setPassword2] = React.useState('')
    const [errorMessage, setErrorMessage] = React.useState('')
    const [errors, setErrors] = React.useState({})
    const validatePassword = (check_type="password1", value="")=> {
        var password_check =  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/;
        if(check_type === "password1") {
            setPassword(value)
            if (!value.match(password_check)) {
                setErrorMessage("Password must be at least 8 characters long and include at least 1 digit and 1 special character.")
                setErrors({"password": "Password must be at least 8 characters long and include at least 1 digit and 1 special character."})
            } else if (password2 !== '' && password2 !== value) {
                errors["password2"] = "Password does not match"
                setErrors({"password2": "Password does not match"})
            }else{
                errors["password2"] = errors["password"] = ''
                setErrorMessage('')
            }
        }
        else{
            setPassword2(value)
            if (value !== '' && value !== password) {
                errors["password2"] = "Password does not match"
                setErrors({"password2": "Password does not match"})
            }else{
                errors["password2"] = errors["password"] = ''
                setErrorMessage('')
            }
        }
    }    
    return (<>
        <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            mb={3}
        >
            <div style={{width:"100%"}}>
                <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h3"
                    align="center"

                >
                    Create Password
                </Typography>
            </div>
        </Box>
        <Box m = {2} />
        <Container style={{marginLeft: "45px"}} >
            <Typography
                variant="body2"
                color="textSecondary"
                style={{
                    width: "362px"
                }}
            >                
                Password must be at least 8 characters long and include at least 1 digit and 1 special character.
            </Typography>
            <Box m = {2} />
            {(errorMessage !== '') ?
                (<Alert severity="error" style={{maxWidth: "400px", marginBottom: "10px"}} p={1}> {errorMessage}</Alert>)
                : (null)
            }
            <Box style={{marginTop:"-10px"}}>
                <div>
                    <TextField
                        fullWidth
                        label="Password"
                        margin="normal"
                        name="password"
                        type="password"
                        defaultValue=''
                        variant="outlined"
                        style={{maxWidth: "432px"}}
                        onChange={(e)=>{validatePassword("password1", e.target.value);}}
                        error = {errors["password"]}
                    />
                    <TextField
                        fullWidth
                        label="Confirm Password"
                        margin="normal"
                        name="password2"
                        type="password"
                        defaultValue=''
                        variant="outlined"
                        style={{maxWidth: "432px", marginTop: "10px"}}
                        onChange={(e)=>{validatePassword("password2", e.target.value);}}
                        error = {errors["password2"]}
                    />

                </div>

            </Box>
            <Box mt={3}>
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{maxWidth: "432px", marginTop:"-10px"}}
                    onClick={()=>{
                        setErrorMessage('')
                        var password_check =  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/;
                        if(password2 !== password){
                            setErrorMessage("Password does not match")
                        }else if(!password.match(password_check)){
                            setErrorMessage("Password must be at least 8 characters long and include at least 1 digit and 1 special character.")
                        }else {
                            setLoading(true)
                            setErrorMessage("")
                            props.user.password = password
                            props.user.activationCode = props.activationCode
                            createPassword(props.user, (response) => {
                                window.location = "/login"
                                setLoading(false)
                            })
                        }
                    }
                    }
                >
                    Save
                </Button>)}
            </Box>
        </Container>
    </>);
};