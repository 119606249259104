import React, { useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Box, Divider, SvgIcon, TextField, Typography, TablePagination, Avatar, Menu, Hidden, MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PropTypes from 'prop-types';
import { Search as SearchIcon } from 'react-feather';
import PageGuide from './PageGuide';
import CustomerInformation from './CustomerInformation';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import styles from './customer.module.css';
import { useStyles } from './customerDataTable.theme';
import DataTableSimpleView from './DataTableSimpleView';
import DataTableAdvancedView from './DataTableDetailedView';
import FilterListIcon from '@material-ui/icons/FilterList';
import Filters from './Filters.js';
import { dataTableSearch } from './CustomerAPI.js';
import ImagePlaceHolder from './ImagePlaceholder';
import { getViews, insertView, deleteView, setDefault, updateView, updateFiltersView } from './ViewApi';
import ViewDialog from './ViewDialog';
import NestedMenuItem from 'material-ui-nested-menu-item';
import RemoveViewDialog from './removeViewDialog';


const CustomerDataTable = ({
  className,
  customers,
  selectedCustomerName,
  totalRecords,
  setCustomers,
  setTotalRecords,
  setLoadingFlag,
  errorFlag,
  setErrorFlag,
  filterOptions,
  dataColumns,
  setDataColumns,
  dataColumnsDetailed,
  setDataColumnsDetailed,
  collapsedDetails,
  ...props
}) => {
  const classes = useStyles();
  const [query, setQuery] = useState(selectedCustomerName);
  const [showMoreInfo, setshowMoreInfo] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [savedFilters, setSavedFilters] = useState({});
  const [newViewFilters, setNewViewFilters] = useState({});

  localStorage.removeItem('searchedCustomer');

  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('renewal_date');
  const [page, setPage] = useState(0);
  const [showFilters, setShowFilters] = useState(false);
  const [views, setViews] = useState([]);
  const [selectedView, setSelectedView] = useState();
  const [isOpen, setOpen] = useState(false);
  const anchorRef = useRef(null);

  //view
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [selectedSimpleViewColumns, setSelectedSimpleViewColumns] = useState([]);
  const [selectedDetailedViewColumns, setSelectedDetailedViewColumns] = useState([]);
  const [firstView, setFirstView] = useState('simple');
  const [viewName, setViewName] = useState('');
  const [includeFilters, setIncludeFilters] = useState(true);
  const [defatultView, setDefaultView] = useState(false);
  const [viewToDelete, setViewToDelete] = useState({});
  const [openDialogRemoveFlag, setOpenDialogRemoveFlag] = useState(false);
  const [automationEnabledView, setAutomationEnabledView] = useState(false);
  const [newView, setNewView] = useState(true)
  const [idView, setIdView] = useState('')
  const [allColumns] = useState(JSON.parse(localStorage.getItem('columnsSimpleView')));
  const initialInsertedView = '';
  const [insertedViewId, setInsertedViewId] = useState(initialInsertedView);
  const [errorMessage, setErrorMessage] = useState('');

  //dialog delete view
  //#region dialog delete view
  const openDialogRemove = (row) => {
    if (row.automation_enabled === true) {setAutomationEnabledView(true)}else{
      setViewToDelete(row);
    }
    setOpenDialogRemoveFlag(true);
  }

  const closeDialogRemove = (row) => {
    setAutomationEnabledView(false)
    setOpenDialogRemoveFlag(false);
  }

  const handleDelete = () => {
    let requestData = {
      id: viewToDelete._id,
    }
    setLoadingFlag(true);
    deleteView(requestData).then(response => {
      if (response.data.hasOwnProperty('Error')) {
        //setErrorFlag(true);
      } else {
        loadViews();
      }
    });
    closeDialogRemove();
    setLoadingFlag(false);
  };

  //#endregion

  const handleSetDefault = (rowId) => {
    let requestData = {
      id: rowId,
    }
    setLoadingFlag(true);
    setDefault(requestData).then(response => {
      if (response.data.hasOwnProperty('Error')) {
        //setErrorFlag(true);
      } else {
        loadViews();
      }
    });
    setLoadingFlag(false);
  };


  //dialog create/edit view
  const closeDialogView = () => {
    setErrorMessage('');
    setViewName('');
    setDefaultView(false);
    setViewDialogOpen(false);
    setNewView(true);
    setIdView('');
  }

  const openDialogView = () => {
    setViewDialogOpen(true);
  }

  const openNewDialogView = () => {
    //set initial values
    setSelectedSimpleViewColumns(JSON.parse(localStorage.getItem('columnsSimpleView')));
    setSelectedDetailedViewColumns(JSON.parse(localStorage.getItem('columnsDetailedView')).filter(column => !column.emptyColumnNameFlag || column.emptyColumnNameFlag === false));

    openDialogView();
  }

  const handleSaveView = () => {
    //validation
    var error = {};
    if (viewName === '')
      error['nameValue'] = 'Please enter a view name'

    if (selectedSimpleViewColumns.length === 0)
      error['simpleCount'] = 'Please select at least one column on simple view'

    if (selectedDetailedViewColumns.length === 0)
      error['detailedCount'] = 'Please select at least one column on detailed view'

    if (Object.keys(error).length !== 0) {
      setErrorMessage(error);
      return;
    }

    let requestSave = {
      name: viewName,
      is_default: defatultView,
      first_view: firstView,
      simple_columns: selectedSimpleViewColumns,
      detailed_columns: selectedDetailedViewColumns,
    }

    setLoadingFlag(true);
    if (newView) {
      requestSave.filters = includeFilters ? newViewFilters : {};
      insertView(requestSave).then(response => {
        if (response.data.hasOwnProperty('Error')) {
          //setErrorFlag(true);
        } else {
          let createdView = response.data.data;
          requestSave._id = createdView;
          localStorage.setItem('insertedViewId', createdView);
          setInsertedViewId(createdView);
          setSelectedView(requestSave);
        }
        setLoadingFlag(false);
      });
    } else {
      requestSave._id = idView;
      if(includeFilters){
        requestSave.filters = Object.assign({}, savedFilters);
      }

      updateView(requestSave).then(response => {
        if (response.data.hasOwnProperty('Error')) {
          //setErrorFlag(true);
        } else {
        }
        setLoadingFlag(false);
      });
    }
  }

  const handleSaveFiltersView = filters => {
    setShowFilters(false);
    let tempFilters = Object.assign({}, filters);
    setSavedFilters(tempFilters);

    let requestSave = {
      _id: selectedView._id,
      filters: tempFilters
    }
    setLoadingFlag(true);

    updateFiltersView(requestSave).then(response => {
      if (response.data.hasOwnProperty('Error')) {
        //setErrorFlag(true);
      } else {
      }
      setLoadingFlag(false);
    });

  }

  const handleEdit = (row) => {
    setOpen(false);
    setNewView(false);
    setViewName(row.name);
    setDefaultView(row.is_default);
    setFirstView(row.first_view);
    setSelectedSimpleViewColumns(row.simple_columns);
    setSelectedDetailedViewColumns(row.detailed_columns);
    setIdView(row._id);

    openDialogView();
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleQueryChange = () => {
    setPage(0);
    getTableData({ newPage: 0, searchText: query });
  };

  const handleDisplayMoreInfo = selectedCustomerObj => {
    setIsLoading(true);
    setSelectedCustomer(selectedCustomerObj);
  };

  React.useEffect(() => {
    if (selectedCustomer && selectedCustomer.length !== 0) {
      toggleDrawer(true);
      setIsLoading(false);
      setshowMoreInfo(true);

      //to modify tooltip of editable columns
      if (showMoreInfo) {
        let index = customers.findIndex(x => selectedCustomer.company_name === x.company_name);
        if (index > -1) {
          let customersList = customers;
          customersList[index] = selectedCustomer;
          setCustomers(customersList);
        }

      }
    }
  }, [selectedCustomer]);

  React.useEffect(() => {
    loadViews();
  }, []);

  const prevOpen = React.useRef(isOpen);
  React.useEffect(() => {
    if (prevOpen.current === true && isOpen === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = isOpen;
  }, [isOpen]);

  React.useLayoutEffect(() => {
    const setTablesData = () => {
      if (selectedView !== undefined && selectedView !== {}) {
        if (selectedView.simple_columns !== undefined && selectedView.simple_columns.length !== 0) {
          setDataColumns(JSON.parse(localStorage.getItem('columnsSimpleView')).filter(column => selectedView.simple_columns.findIndex(col => col.id === column.id) !== -1));
        } else {
          setDataColumns(JSON.parse(localStorage.getItem('columnsSimpleView')));
        }
        if (selectedView.detailed_columns !== undefined && selectedView.detailed_columns.length !== 0) {
          setDataColumnsDetailed(JSON.parse(localStorage.getItem('columnsDetailedView')).filter(column => (column.emptyColumnNameFlag || selectedView.detailed_columns.findIndex(col => col.id === column.id) !== -1)));
        } else {
          setDataColumnsDetailed(JSON.parse(localStorage.getItem('columnsDetailedView')));
        }
        if (selectedView.filters !== undefined) {
          setSavedFilters(selectedView.filters);
          handleApplyFilters(selectedView.filters);
        }

        props.setAdvancedViewFlag(selectedView.first_view === 'detailed')
      } else {
        props.setAdvancedViewFlag(false);
        setDataColumns(JSON.parse(localStorage.getItem('columnsSimpleView')));
        setDataColumnsDetailed(JSON.parse(localStorage.getItem('columnsDetailedView')));
        setSavedFilters({});
        handleApplyFilters();
      }

    };
    setTablesData();


  }, [selectedView]);

  const existsDefaultView = () => {
    return views.filter(v => v.is_default).length > 0;
  }

  const loadViews = () => {
    getViews().then(response => {
      if (response.data.hasOwnProperty('Error')) {
        //setErrorFlag(true);
      } else {
        setViews(response.data.data);
        var viewId = localStorage.getItem('insertedViewId');
        let defaultView = response.data.data.find(view => view.is_default);
        if (viewId === '' || viewId === undefined || viewId == null) {
          setSelectedView(defaultView);
        } else {
          let insertedView = response.data.data.find(view => view._id === viewId);
          localStorage.removeItem('insertedViewId');
          if (insertedView == null) {
            insertedView = defaultView;
          }
          setSelectedView(insertedView);
          setInsertedViewId('');
        }
      }
    });
  }

  const toggleDrawer = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setshowMoreInfo(open);
    if (!open) {
      setSelectedCustomer();
      localStorage.removeItem('accountHistory');
    }
  };

  const getTableData = ({ newPage, rows, searchText, orderUpdated, orderByUpdated, savedFiltersUpdated }) => {
    setIsLoading(true);
    setErrorFlag(false);
    let requestData = {
      search: searchText ? searchText : query,
      advanced_search: savedFiltersUpdated ? savedFiltersUpdated : savedFilters,
      view: props.advancedViewFlag ? 'detailed' : 'simple',
      page_number: newPage + 1,
      rows_per_page: rows ? rows : rowsPerPage,
      sorting: {
        column_name: orderByUpdated ? orderByUpdated : orderBy,
        direction: orderUpdated ? orderUpdated : order,
      },
    };
    dataTableSearch(requestData).then(response => {
      if (response.data.hasOwnProperty('Error')) {
        setErrorFlag(true);
      } else {
        setCustomers(response.data.data);
        setTotalRecords(response.data.total);
      }
      setIsLoading(false);
    });
  };

  const handleChangePage = (event, newPage) => {
    setIsLoading(true);
    setPage(newPage);
    getTableData({ newPage: newPage });
  };

  const handleChangeRowsPerPage = event => {
    setIsLoading(true);
    let tempRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(tempRowsPerPage);
    setPage(0);
    getTableData({ newPage: 0, rows: tempRowsPerPage });
  };

  const createSortHandler = (property, order) => event => {
    setOrder(order);
    setOrderBy(property);
    setPage(0);
    getTableData({ newPage: 0, orderUpdated: order, orderByUpdated: property });
  };

  const handleApplyFilters = filters => {
    setShowFilters(false);
    let tempFilters = Object.assign({}, filters);
    setSavedFilters(tempFilters);
    setPage(0);
    getTableData({ newPage: 0, savedFiltersUpdated: tempFilters });
  };

  const handleSaveAsNew = filters => {
    let tempFilters = Object.assign({}, filters);
    setNewViewFilters(tempFilters);
    openNewDialogView();
  }

  return (
    <Box>
      <RemoveViewDialog
        openDialog={openDialogRemoveFlag}
        closeDialogFun={closeDialogRemove}
        handleDelete={handleDelete}
        automationEnabledView = {automationEnabledView}
      ></RemoveViewDialog>
      <PageGuide />
      {isLoading && <div className={styles.loader}> </div>}
      {showMoreInfo && (
        <Drawer
          BackdropProps={{ invisible: false }}
          open={showMoreInfo}
          anchor={'right'}
          onClose={toggleDrawer(false)}
          id="customerInfo"
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" p={2} style={{ maxWidth: '555px' }}>
            <Typography variant="h4" color="textPrimary">
              {selectedCustomer.company_name}
            </Typography>
            <IconButton onClick={toggleDrawer(false)}>
              <SvgIcon>
                <CloseIcon />
              </SvgIcon>
            </IconButton>
          </Box>
          <CustomerInformation customer={selectedCustomer} setCustomer={setSelectedCustomer} />
        </Drawer>
      )}
      <Box display="flex" alignItems="center" id="searchFilters" flexWrap="wrap">
        <Box mr={1} m={2} ml={0} mb={1} display="flex">
          <TextField
            className={classes.queryField}
            InputProps={{
              classes: {
                root: classes.filterHeight,
              },
            }}
            onChange={e => setQuery(e.target.value)}
            onKeyPress={ev => {
              if (ev.key === 'Enter') {
                handleQueryChange();
                ev.preventDefault();
              }
            }}
            placeholder="Search..."
            value={query}
            variant="outlined"
            size="small"
          />
          <IconButton aria-label="search" className={classes.searchButton} onClick={handleQueryChange}>
            <SearchIcon />
          </IconButton>
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem style={{ margin: '16px' }} />
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Box mr={1} m={2} ml={0} mb={1} display="flex" alignItems="center" flexWrap="wrap">
              <Typography variant="body2" color="textSecondary">View</Typography>
              <Box ml={2} display="flex" height={42} alignItems="center" flexWrap="wrap" style={{ border: "1px solid rgba(0, 0, 0, 0.23)", padding: "0 7px 0 14px", borderRadius: "4px", cursor: "pointer" }} onClick={handleToggle} ref={anchorRef}>
                <Typography variant="body2" m={1} width={120}>{selectedView != null ? (selectedView.name + (selectedView.is_default ? ' (Default)' : '')) : "All Customers" + (existsDefaultView() ? '' : '(Default)')}</Typography>
                <ArrowDropDownIcon></ArrowDropDownIcon>
                <Hidden smDown>
                  <Typography variant="h6" color="inherit"></Typography>
                </Hidden>

                <Menu
                  anchorOrigin={{
                    vertical: 'bottom',
                  }}
                  keepMounted
                  PaperProps={{ className: classes.popover }}
                  getContentAnchorEl={null}
                  anchorEl={anchorRef.current}
                  open={isOpen}
                >
                  <NestedMenuItem
                    label={"All Customers" + (existsDefaultView() ? '' : '(Default)')}
                    onClick={() => { setSelectedView(undefined) }}
                    key={Math.random()}
                    className={classes.label}
                    parentMenuOpen={isOpen}
                    width="100%"
                    rightIcon={<MoreHorizIcon />}
                    style={{ justifyContent: "space-between" }}
                  >
                    <MenuItem key={Math.random()} className={classes.label} onClick={() => { handleSetDefault('') }}>Make Default</MenuItem>
                  </NestedMenuItem>
                  {views.map(view => (
                    <NestedMenuItem
                      key={view.id}
                      onClick={() => setSelectedView(view)}
                      className={classes.label}
                      label={view.name + (view.is_default ? ' (Default)' : '')}
                      parentMenuOpen={isOpen}
                      width="100%"
                      rightIcon={<MoreHorizIcon />}
                      style={{ justifyContent: "space-between" }}>
                      <MenuItem key={Math.random()} className={classes.label} onClick={() => { handleSetDefault(view._id) }}>Make Default</MenuItem>
                      <MenuItem key={Math.random()} className={classes.label} onClick={() => { handleEdit(view) }}>Edit</MenuItem>
                      <MenuItem key={Math.random()} className={classes.label} onClick={() => { openDialogRemove(view) }}>Delete</MenuItem>
                    </NestedMenuItem>
                  ))}
                  <MenuItem className={classes.label} width="100%" onClick={openNewDialogView}>Add New...</MenuItem>
                </Menu>
              </Box>
            </Box>
          </div>
        </ClickAwayListener>
        <Divider orientation="vertical" variant="middle" flexItem style={{ margin: '16px' }} />
        <Box mr={1} m={2} ml={0} mb={1} display="flex">
          <Button
            variant="contained"
            className={classes.filterBtn}
            style={{ marginRight: '8px' }}
            onClick={() => setShowFilters(true)}
            startIcon={<FilterListIcon />}
          >
            FILTERS
            {Object.keys(savedFilters).length > 0 && (
              <Avatar className={classes.filterCount}>{Object.keys(savedFilters).length}</Avatar>
            )}
          </Button>
        </Box>
        <Drawer anchor={'right'} open={showFilters} onClose={() => setShowFilters(!showFilters)}>
          <Box display="flex" justifyContent="space-between" alignItems="center" p={2} style={{ maxWidth: '555px' }}>
            <Box flexGrow="1" />
            <IconButton onClick={() => setShowFilters(!showFilters)}>
              <SvgIcon>
                <CloseIcon />
              </SvgIcon>
            </IconButton>
          </Box>
          <Filters
            filterOptions={filterOptions}
            handleApplyFilters={handleApplyFilters}
            savedFilters={savedFilters}
            dataColumns={allColumns}
            handleSaveAsNew={handleSaveAsNew}
            handleSaveFiltersView={handleSaveFiltersView}
            selectedView={selectedView}
          />
        </Drawer>
      </Box>

      <Divider className={classes.divider} />
      {!errorFlag && customers.length > 0 && (
        <>
          <ViewDialog
            openDialog={viewDialogOpen}
            closeDialogFun={closeDialogView}
            newView={newView}
            errorMessage={errorMessage}
            simpleViewColumns={JSON.parse(localStorage.getItem('columnsSimpleView'))}
            selectedSimpleViewColumns={selectedSimpleViewColumns}
            setSelectedSimpleViewColumns={setSelectedSimpleViewColumns}
            detailedViewColumns={JSON.parse(localStorage.getItem('columnsDetailedView')).filter(column => !column.emptyColumnNameFlag || column.emptyColumnNameFlag === false)}
            selectedDetailedViewColumns={selectedDetailedViewColumns}
            setSelectedDetailedViewColumns={setSelectedDetailedViewColumns}
            firstView={firstView}
            setFirstView={setFirstView}
            viewName={viewName}
            setViewName={setViewName}
            setDefaultView={setDefaultView}
            defatultView={defatultView}
            includeFilters={includeFilters}
            setIncludeFilters={setIncludeFilters}
            handleSave={handleSaveView}
          />
          {props.advancedViewFlag ? (
            <DataTableAdvancedView
              customerTableData={customers}
              firstRowValues={customers[0]}
              showMoreInfo={handleDisplayMoreInfo}
              createSortHandler={createSortHandler}
              orderBy={orderBy}
              order={order}
              dataColumns={dataColumnsDetailed}
              collapsedDetails={collapsedDetails}
              setCustomers={setCustomers}
            />
          ) : (
            <DataTableSimpleView
              customerTableData={customers}
              firstRowValues={customers[0]}
              showMoreInfo={handleDisplayMoreInfo}
              createSortHandler={createSortHandler}
              orderBy={orderBy}
              order={order}
              dataColumns={dataColumns}
              setCustomers={setCustomers}
            />
          )}
          <Box style={{ height: '40px' }}>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={totalRecords}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </>
      )}
      {!errorFlag && customers.length === 0 && (
        <ImagePlaceHolder
          message={'No results. Try another search.'}
          imageUrl={require('./../../assets/history-no-results.png')}
          altText={'No Results History'}
        />
      )}

      {errorFlag && (
        <ImagePlaceHolder
          message={'Something went wrong. Please contact the involve.ai team to get this fixed.'}
          imageUrl={require('./../../assets/error-general.png')}
          altText={'Error'}
        />
      )}
    </Box>
  );
};

CustomerDataTable.propTypes = {
  className: PropTypes.string,
};

export default CustomerDataTable;
