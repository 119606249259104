import React, { useState } from 'react';
import { Box, Typography, Container, Button, Divider } from '@material-ui/core';
import SecondaryNav from './../../SecondaryNav';
import { DropzoneArea } from 'material-ui-dropzone';
import styles from './csv.module.css';
import { uploadFile } from './CsvUploadAPI.js';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';

const CsvUpload = () => {
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  let history = useHistory();

  const cancelFunction = () => {
    window.location.pathname = '/integrations';
  };

  const onChangeHandler = uploadedFiles => {
    setErrorMessage('');
    setFiles(uploadedFiles);
  };

  const uploadFiles = event => {
    if(files.length>0){
    uploadFile(files)
      .then(response => {
        if (response.data.hasOwnProperty('Error')) {
          setErrorMessage("There was an error uploading the file. Try uploading file(s) again or contact the involve.ai team.");
        } else if (response.data.body === 'success') {
          history.push({
            pathname: '/integrations',
            state: { status: 'dataExport' },
          });
        }
      })
      .catch(() => {
        setErrorMessage("There was an error uploading the file. Try uploading file(s) again or contact the involve.ai team.");
      });
    }else{
      setErrorMessage("Select at least one file to upload")
    }
  };

  return (
    <Container>
      <SecondaryNav />
      <Container style={{ height: 'calc(100vh - 70px)', marginTop: '64px', overflow: 'scroll' }} className="hideScroll">
        <Box mt={7} justifyContent="center" className={styles.boxContainer}>
          <Box mt={3} mb={4} style={{textAlign: 'center'}}>
            <Typography variant="h4" color="textPrimary">
              Upload CSV file from computer
            </Typography>
            {errorMessage!== '' && <Alert severity="error" style={{ marginTop: '16px' }}>
              {errorMessage}
            </Alert>}
          </Box>
          <DropzoneArea
            onChange={onChangeHandler}
            showPreviewsInDropzone={false}
            showPreviews={true}
            useChipsForPreview={true}
            previewText={false}
            dropzoneParagraphClass= {styles.dropzoneParagraphClass}
            dropzoneClass ={styles.dropzoneClass}
            previewChipProps={{
              classes: {
                root: styles.chipsStyles
              }

            }}
            previewGridClasses ={{
              container : styles.previewGridContainer,
              image: styles.previewImg,
              item: styles.previewImg
            }}
            filesLimit={10}
            dropzoneText="Drag and drop the files here or click to upload"
            maxFileSize={314572800} // Increase upload file to 300Mb = 200 * 1024 * 1024
            acceptedFiles={[
              '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values',
            ]}
            className={styles.uploadArea}

          />
          <Box mt={2}/>
          {files.length> 0 && <Divider />}
          <Box display="flex" mt={2}>
            <Button variant="contained" style={{ marginRight: '8px' }} onClick={cancelFunction}>
              Cancel
            </Button>
            <Box flexGrow="1" />
            <Button variant="contained" color="primary" onClick={uploadFiles}>
              Upload
            </Button>
          </Box>
        </Box>
      </Container>
    </Container>
  );
};

export default CsvUpload;
