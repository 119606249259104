import React from 'react';
import { Box, Typography, Dialog, IconButton, SvgIcon, Link, Divider } from '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Close';
import ProbToRenew from './ProbToRenew';
import { insightsStyles } from './insights.theme';
import classes from './insights.module.css';
import Insights from './Insights';
import Tasks from './Tasks';
import Notes from './Notes';

const CardOpen = ({ ...props }) => {
  const styles = insightsStyles();

  return (
    <>
      <Dialog open={props.showDetailedCard} onClose={props.handleCloseDialog} classes={{ paper: styles.dialogPaper }} id="insights-detailed-card">
        <Box display="flex" mb={2}>
          <Typography variant="h4" color="textPrimary">
            {props.cardDetails.company_name}
          </Typography>
          <Box flexGrow="1" />
          <IconButton onClick={props.handleCloseDialog} style={{ padding: '0px' }}>
            <SvgIcon>
              <CloseIcon />
            </SvgIcon>
          </IconButton>
        </Box>
        <Box display="flex" mb={1}>
          <Typography variant="body2" color="textSecondary" style={{ marginRight: '8px' }}>
            involve.ai Customer Health:
          </Typography>
          <ProbToRenew probability_to_renew={props.cardDetails.probability_to_renew} />

          <img
            src={require('../../assets/icon_history.png')}
            alt="History"
            style={{ width: '16px', height: '14px', margin: '0 8px 0 32px' }}
          />
          <Link
            variant="body2"
            underline="hover"
            color="textSecondary"
            style={{ cursor: 'pointer' }}
            onClick={props.toggleDrawer(true)}
          >
            View Account Details
          </Link>
        </Box>
        <Divider />
        <Insights
          cardDetails={props.cardDetails}
          changeInsightStatus={props.changeInsightStatus}
          classes={classes}
          setShowDetailedCard={props.setShowDetailedCard}
          handleDismissInsight={props.handleDismissInsight}
          handleUpdateInsight={props.handleUpdateInsight}
          handleUpdateTaskNote={props.handleUpdateTaskNote}
          handleUpdateConvert={props.handleUpdateConvert}
        />
        <Tasks
          cardDetails={props.cardDetails}
          changeInsightStatus={props.changeInsightStatus}
          classes={classes}
          setShowDetailedCard={props.setShowDetailedCard}
          handleUpdateTaskNote={props.handleUpdateTaskNote}
        />
        <Notes
          cardDetails={props.cardDetails}
          changeInsightStatus={props.changeInsightStatus}
          classes={classes}
          setShowDetailedCard={props.setShowDetailedCard}
          handleUpdateTaskNote={props.handleUpdateTaskNote}
        />
      </Dialog>
    </>
  );
};
export default CardOpen;
