import { makeStyles } from '@material-ui/core';

export const userRolesStyles = makeStyles((theme) => ({
    appBar: {
        width: `calc(100% - 240px)`,
        marginLeft: 240,
    },
    drawer: {
        width: 240,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 240,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        // backgroundColor: theme.palette.background.default,
        spacing: 3,
    },
    root: {
        backgroundColor: '#FFFFFF',
        display: 'flex',
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    searchButton: {
        borderRadius: '4px',
        backgroundColor: '#0061FF',
        width: '56px',
        height: '42px',
        color: '#FFFFFF',
        marginLeft: '8px',
        '&:hover': {
            backgroundColor: '#0061FF',
        },
    },
    permissions: {
        fontWeight: 'bold'
    },
    textPending: {
        backgroundColor: '#FFF3E0',
        color: '#F57C00',
        fontSize: '12px',
        fontWeight: '500',
        margin: '4px 4px 0 0px',
        padding: '4px 8px 4px 8px',
    },
    textActivated: {
        backgroundColor: theme.palette.high.light,
        color: theme.palette.high.main,
        fontSize: '12px',
        fontWeight: '500',
        margin: '4px 4px 0 0px',
        padding: '4px 8px 4px 8px',
    },
    buttonBox: {
        margin: theme.spacing(2)
    },
    filterHeight: {
        height: '42px',
        fontSize: '14px',
      },

    // buttonDialogCancel: {
    //     marginLeft: theme.spacing(2)
    // },
    // // buttonDialogConfirm: {
    // //     margin: theme.spacing(2)
    // // },
    // removeDialog:{
    //     minWidth: '496px',
    //     minHeight: '220px',
    // }
}));