import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, Container, Button, TextField, Divider } from '@material-ui/core';
import SecondaryNav from './../../SecondaryNav';
import ImagePlaceHolder from './../../customers/ImagePlaceholder';
import { getViews, getEditableColumns, createRule, updateRule } from './../AutomationAPI';
import { Link, useHistory, useLocation  } from 'react-router-dom';
import Triggers from './Triggers';
import Actions from './Actions';
import Repeat from './Repeat';
import LoadingSpinner from './../../LoadingSpinner';
import { ruleStyles } from './../rule.theme';

const labels = {
  create: 'Create Rule',
  update: 'Edit Rule',
};
const Automation = () => {
  const styles = ruleStyles();
  const history = useHistory();
  const location = useLocation();
  const isNew = location.pathname.includes('/automation/edit') ? false : true;
  const isCopied = location.pathname.includes('/automation/copy') ? true : false;
  let existingValues = isNew && !isCopied ? [] : location.state.details;

  // Loading flags
  const [loadingFlag, setLoadingFlag] = useState(true);
  const [isViewsLoading, setIsViewsLoading] = useState(true);
  const [isColumnsLoading, setIsColumnsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if(isViewsLoading && isColumnsLoading){
      setLoadingFlag(true);
    }else{
      setLoadingFlag(false);
    }
  }, [isViewsLoading, isColumnsLoading])


  const [errorFlag, setErrorFlag] = useState(false);

  const [viewsList, setViewsList] = useState([]);
  const [columnsList, setColumnsList] = useState([]);

  const [ruleName, setRuleName] = useState(isNew && !isCopied ? '' : (isCopied?   "Copy of " +existingValues.name : existingValues.name));

  // Trigger component
  const [selectedViews, setSelectedViews] = useState(isNew && !isCopied? [] : existingValues.triggers.views);

  // Action component
  const [selectedColumns, setSelectedColumns] = useState(isNew && !isCopied ? [] : existingValues.actions);

  //Repeat component
  const [selectedDuration, setSelectedDuration] = useState(isNew && !isCopied?'days' : existingValues.triggers.schedule.period);
  const [repeatVal, setRepeatVal] = useState(isNew && !isCopied? null : existingValues.triggers.schedule.every);
  const [repeatFlag, setRepeatFlag] = useState(isNew && !isCopied? false : (existingValues.triggers.schedule.every > 0));

  useEffect(() => {
    getViews()
      .then(function (response) {
        setLoadingFlag(false);
        if (response.data.data) {
          setViewsList(response.data.data);
          setIsViewsLoading(false);
        } else {
          setErrorFlag(true);
          setIsViewsLoading(false);
        }
      })
      .catch(function (response) {
        setErrorFlag(true);
      });
      getEditableColumns()
        .then(function (response) {
          if (response.data.data) {
            setColumnsList(response.data.data);
            setIsColumnsLoading(false);
          } else {
            setErrorFlag(true);
            setIsColumnsLoading(false);
          }
        })
        .catch(function (response) {
          setIsColumnsLoading(false);
          setErrorFlag(true);
        });
  }, []);

  const handleCreateRule = () => {
    setIsUpdating(true);
    let requestJSON = {
      is_enabled: true,
      rule_name: ruleName,
      triggers: {
        views: selectedViews,
        schedule: {
          every: repeatVal ? parseInt(repeatVal): 0,
          period: selectedDuration,
        },
      },
      actions: selectedColumns,
    };
    if(isNew || isCopied){
      createRule(requestJSON)
        .then(function (response) {
          setLoadingFlag(false);
          if (response.data.status_code === 200) {
             history.push("/automation")
          } else {
            setErrorFlag(true);
          }
        })
        .catch(function (response) {
          setErrorFlag(true);
        });
    }else{
      requestJSON._id = existingValues._id;
      updateRule(requestJSON)
        .then(function (response) {
          setLoadingFlag(false);
          if (response.data.status_code === 200) {
             history.push("/automation")
          } else {
            setErrorFlag(true);
          }
        })
        .catch(function (response) {
          setErrorFlag(true);
        });
    }

  };
  return (
    <>
      <SecondaryNav />
      <Container
        style={{
          height: 'calc(100vh - 70px)',
          marginTop: '64px',
          overflow: 'overlay',
          paddingLeft: '24px',
          paddingRight: '16px',
          maxWidth: '100%',
        }}
      >
      {loadingFlag && (
        <div className={styles.loader}> </div>
      )}
        {errorFlag ? (
          <ImagePlaceHolder
            message={'Something went wrong. Please contact the involve.ai team to get this fixed.'}
            imageUrl={require('./../../../assets/error-general.png')}
            altText={'Error'}
          />
        ) : (
          <Grid container spacing={0} alignItems="center" justify="center">
            <Grid item xs={6}>
              {' '}
              <Typography
                variant="h4"
                color="textPrimary"
                style={{ marginTop: 24, marginBottom: 24, textAlign: 'center' }}
              >
                {isNew || isCopied ?
                <>{labels.create}</> : <>{labels.update}</>}
              </Typography>
              <TextField
                fullWidth
                autoFocus
                label="Rule Name"
                name="rule_name"
                type="rule_name"
                value={ruleName}
                variant="outlined"
                onChange={e => setRuleName(e.target.value)}
              />
              {!isViewsLoading && <Triggers viewsList={viewsList} selectedViews={selectedViews} setSelectedViews={setSelectedViews} />}
              {columnsList.length > 0 && !isColumnsLoading && <Actions columnsList={columnsList} selectedColumns= {selectedColumns} setSelectedColumns={setSelectedColumns}/>}
              <Box mt={2} />
              <Divider />
              <Repeat
                repeatVal={repeatVal}
                setRepeatVal={setRepeatVal}
                selectedDuration={selectedDuration}
                setSelectedDuration={setSelectedDuration}
                repeatFlag={repeatFlag}
                setRepeatFlag={setRepeatFlag}
              />
              <Box display="flex" mt={5}>
                <Button component={Link} to="/automation/" variant="contained" size="large">
                  Cancel
                </Button>
                <Box flexGrow={1} />
                  { isUpdating ? <LoadingSpinner /> :
                (<Button variant="contained" color="primary" onClick={handleCreateRule} disabled={ruleName==="" || selectedViews.length<=0 || selectedColumns.length<=0}>
                {isNew ?
                <>Create</> : <>Update</>}
                </Button>)}
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
};

export default Automation;
