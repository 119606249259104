import React, { useState } from 'react';
import {Button, TextField, Box, Grid }from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import {setUpdatePassword } from './AccountSettingsApi';
import LoadingSpinner from '../../LoadingSpinner';

const useStyles = makeStyles((theme) => ({
    margin:{
        marginTop:'16px'
    },
    buttonAccount: {
        padding:'8px 24px',
        height:'42px'
    }
  }));


const UpdateUserPassword = (props) => {
    const classes = useStyles();
    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [errorMessage, setErrorMessage] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
  
  
    const validatePassword = (check_type="password", value="")=> {
        var password_check =  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/;
        var errors = {};
        setSuccessMessage ('');
        if(check_type === "password") {
            setPassword(value)
            if (!value.match(password_check)) {
                errors["password"] = "Password must be at least 8 characters long and include at least 1 digit and 1 special character.";
            } else if (password2 !== '' && password2 !== value) {
                errors["password2"] = "Password does not match"
            } else{
                errors = {};
            }
        }
        else{
            setPassword2(value)
            if (!password.match(password_check)) {
                errors["password"] = "Password must be at least 8 characters long and include at least 1 digit and 1 special character.";
            } if (value !== '' && value !== password) {
                errors["password2"] = "Password does not match"
            } else{
                errors = {};
            }
        }
        if (Object.keys(errors).length > 0 )
            setErrorMessage (errors);
        else {
            setErrorMessage('')
        }    
    }

    const validateFields = () => {
        var errors = {};

        setSuccessMessage ('');
        if (currentPassword === "") {
            errors["currentPassword"] = "Please fill in current password";
        } 
        if (password === "")
            errors["password"] = "Please fill in new password";
        if (password2 === "")
            errors["password2"] = "Please fill in confirm password";
        if (currentPassword !== "" && password === currentPassword) {
            errors["pasword"] = "New password must be different from current password";
        }
        setErrorMessage (errors);
        return errors;
    }

    const updatePassword = (e) => {
        e.preventDefault();
        props.setLoadingFlag(true);
        var errors = validateFields ();
        if (Object.keys(errors).length > 0 || Object.keys(errorMessage).length > 0) {
            props.setLoadingFlag(false);
            return;
        }
            let requestData = {
            email: localStorage.getItem("email"),
            old_password:currentPassword,
            password: password
        }
        setSuccessMessage ('');
        setIsSubmitting(true);
        setUpdatePassword (requestData).then(response => {
            if (response.data.hasOwnProperty('message')) {
                if (response.data.message === "Old password is wrong")
                    setErrorMessage ("Invalid current password. Please try again.");
                else
                    setErrorMessage ("Something went wrong. Please contact the involve.ai team to get this fixed."); 
            } else {
                setSuccessMessage ('Your changes were applied');
                setPassword('');
                setPassword2('');
                setCurrentPassword('');
            }
            setIsSubmitting(false);
        });
        props.setLoadingFlag (false);;
    }

    return (
        <div className={classes.margin}>
            <Grid container  alignItems="center" spacing={1}>    
                    {Object.keys(errorMessage).length > 0 ? (
                            <Alert severity="error" style={{ maxWidth: '535px' }} p={1}>
                                {' '}
                                {typeof errorMessage === 'string'
                                    ? errorMessage
                                    : Object.keys(errorMessage).map((key, index) => {
                                        return <li>{errorMessage[key]}</li>;
                                    })}
                            </Alert>
                    ) : null}
                    {successMessage !== "" ? (
                            <Alert severity="success" style={{ maxWidth: '535px' }} p={1}>
                                {successMessage}
                            </Alert>
                    ) : null}

                <Grid item container xs={12}>
                    <Grid item xs={6} >
                        <TextField
                            fullWidth
                            label="Current Password"
                            margin="normal"
                            name="currentPassword"
                            type="password"
                            defaultValue={currentPassword}
                            value={currentPassword}
                            variant="outlined"
                            onChange={e => setCurrentPassword(e.target.value)}
                        />
                </Grid>
                </Grid>
                <Grid item container xs={12}>
                <Grid  item xs={6} >
                        <TextField
                            fullWidth
                            label="New Password"
                            margin="normal"
                            name="password1"
                            type="password"
                            defaultValue=""
                            value={password}
                            variant="outlined"
                            onChange={e => validatePassword('password',e.target.value)}
                        />
                </Grid>
                </Grid>
                <Grid item container xs={12}>
                <Grid  item xs={6} >

                        <TextField
                            fullWidth
                            label="Confirm Password"
                            margin="normal"
                            name="password2"
                            type="password"
                            defaultValue=""
                            value={password2}
                            variant="outlined"
                            onChange={e => validatePassword('password2',e.target.value)}
                        />
                </Grid>
                </Grid>
                <Grid>
                    <Box mt={2}>
                        {isSubmitting ? 
                            <LoadingSpinner></LoadingSpinner> :  <Button
                            color="primary"
                            size="medium"
                            type="submit"
                            variant="contained"
                            className={classes.buttonAccount}
                            onClick = {(e) => updatePassword(e)}
                        >
                        UPDATE
                        </Button>}
                    </Box>
                </Grid> 
            </Grid>     
        </div>
    )
}

export default UpdateUserPassword;
