import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 320px)',
  },
  contactInfo: {
    overflowY: 'auto',
    marginTop: '16px',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D8D8D8',
    },
  },
  contactsGridHead: {
    backgroundColor: '#F9F9F9',
    borderRadius: '4px',
    width: '552px',
    height: '32px',
  },
  ml16: {
    marginLeft: '16px',
  },
  chipRoot: {
    backgroundColor: '#ECEFF1',
    height: '20px',
    width: '66px',
    marginTop: '8px',
  },
  chipLabel: {
    padding: '0px',
  },
  smallEditIcon: {
    '& svg': {
      fontSize: '16px',
    },
    height: '16px',
    width: '16px',
  },
});
