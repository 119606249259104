import React, { useState, useEffect } from 'react';
import { makeStyles, Box, TextField, DialogTitle, DialogContent, Popover } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useHistory } from 'react-router-dom';
import { PasswordGenerator as pg } from '../common/PasswordGenerator';

import { createReport } from './ReportsAPI';

const useStyles = makeStyles({
  dialogPaper: {
    maxWidth: '496px',
    maxHeight: '542px',
  },
  dialogPadding: {
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  dataExportImg: {
    maxHeight: '280px',
  },
});

const DuplicateReport = ({ report, openDialogFlag, handleClose, ...rest }) => {
  const classes = useStyles();
  const [name, setName] = useState(report.name);
  const [password, setPassword] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [passwordSuggestion, setPasswordSuggestion] = useState('');
  let history = useHistory();

  useEffect(() => {
    setPasswordSuggestion(pg(10));
  }, []);

  const handleClosePop = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleCreateNew = () => {
    createReport({
      name: name ? name : report.name,
      password: password,
      duplicate_report_id: report.report_id,
      default_template: false,
      timestamp: new Date(),
    })
      .then(function (response) {
        history.push(`/reports/edit/${response.data.report_id}`);
      })
      .catch(function (response) {});
  };

  const handleRefresh = () => {
    let newPassword = pg(10);
    setPasswordSuggestion(newPassword);
  };
  const handleSaveAutoPass = e => {
    setPassword(passwordSuggestion);
    handleClosePop();
  };

  return (
    <Dialog open={openDialogFlag} onClose={handleClose} classes={{ paper: classes.dialogPaper }}>
      <DialogTitle id="add-new-report-dialog" style={{ paddingBottom: '0px' }}>
        Duplicate Report
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          autoFocus
          label="Report Name"
          margin="normal"
          name="report_name"
          type="text"
          defaultValue={report.name}
          variant="outlined"
          onChange={e => setName(e.target.value)}
        />
        <TextField
          fullWidth
          label="Password"
          margin="normal"
          name="report_password"
          type="text"
          value={password}
          variant="outlined"
          autoComplete="off"
          onChange={e => setPassword(e.target.value)}
          onPointerDown={e => setAnchorEl(e.currentTarget)}
          aria-describedby={id}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePop}
          onKeyDown={handleClosePop}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          disableAutoFocus
          disableEnforceFocus
          PaperProps={{
            style: { width: '415px', padding: '15px', border: '1px solid #C4C4C4' },
          }}
        >
          <Typography className={classes.popoverTypo}>involve.ai generated a strong password</Typography>
          <div className={classes.popoverDiv}>
            <Button
              style={{ float: 'left', textTransform: 'none' }}
              onClick={handleSaveAutoPass}
              className={classes.button}
            >
              {passwordSuggestion}
            </Button>
            <Button
              style={{ float: 'right' }}
              color="primary"
              onClick={handleRefresh}
              className={classes.button}
              startIcon={<RefreshIcon />}
            >
              Refresh
            </Button>
          </div>
        </Popover>
      </DialogContent>

      <DialogActions style={{ justifyContent: 'flex-start', padding: '8px 24px 16px 24px' }}>
        <Button onClick={handleClose} variant="contained">
          Cancel
        </Button>
        <Box flexGrow="1" />
        <Button
          onClick={handleCreateNew}
          color="primary"
          type="submit"
          variant="contained"
          disabled={name === '' || password === ''}
          aria-label="duplicate-report"
        >
          Duplicate
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DuplicateReport;
