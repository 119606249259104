import React from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { Box, CardContent, Container, Typography } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import SecondaryNav from './../../SecondaryNav';
import styles from './datasources.module.css';
 
const SOURCE_TITLE = {
  salesforce: 'Salesforce',
  outlook: 'Microsoft Outlook',
};

const DataExport = () => {
  const params = useLocation();
  const parsedQs = qs.parse(params.search);
  const source = parsedQs.source;
  const title = SOURCE_TITLE[source] || 'Salesforce';

  return (
    <div>
      <SecondaryNav />

      <Container className={styles.cardContainer}>
        <CardContent align="center">
          <CardMedia className={styles.mediaExport} image={require('./../../../assets/success.png')} />
        </CardContent>
        <Box alignItems="center" display="flex" justifyContent="space-between" mb={4} />
        <Typography color="textPrimary" gutterBottom variant="h4" align="center">
          Successfully Connected to {title}
        </Typography>
        <Box align="center">
          <Typography variant="body2" color="textSecondary" align="center">
            You can safely close this tab. We will inform you by email when the process is done.
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default DataExport;
