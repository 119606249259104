import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import { reportsStyles } from '../../reports.theme';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    padding: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`,
      },
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  label: {
    marginLeft: theme.spacing(1),
  },
  overline: {
    marginTop: theme.spacing(1),
  },
}));

const StatsGrid = ({ className, blockName, data, ...rest }) => {
  const classes = useStyles();
  const styles = reportsStyles();

  return (
    <Box className={clsx(classes.root, className)} {...rest} width="100%">
      <Grid alignItems="center" container justify="space-between">
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <div className={styles.greenLabel}>UPSELLS</div>
          <Typography variant="h2" color="textPrimary">
            {data.values[0]}
          </Typography>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <div className={styles.yellowLabel}>RENEWALS</div>
          <Typography variant="h2" color="textPrimary">
            {data.values[1]}
          </Typography>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <div className={styles.redLabel}>CHURNS</div>
          <Typography variant="h2" color="textPrimary">
            {data.values[2]}
          </Typography>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          {blockName === 'revenue' ? (
            <div className={styles.blueLabel}>NET DOLLAR RETENTION</div>
          ) : (
            <div className={styles.blueLabel}>NET LOGO RETENTION</div>
          )}
          <Typography component="span" variant="h2" color="textPrimary">
            {data.values[4]}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

StatsGrid.propTypes = {
  className: PropTypes.string,
};

export default StatsGrid;
