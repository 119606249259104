import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Divider, Chip, Grid } from '@material-ui/core/';
import DetailedChart from './DetailedChart';
import CircleIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  selectedChip: {
    backgroundColor: '#ECEFF1',
    borderRadius: '16.5px',
    height: '32px',
  },
  unselectedChips: {
    backgroundColor: '#ECEFF1',
    borderRadius: '16.5px',
    height: '32px',
    opacity: '50%',
  },
}));


const keyIndicators = [
  { id: 'product_usage', label: 'Product Usage', color: '#0061FF' },
  { id: 'nps', label: 'NPS/CSAT', color: '#3F51B5' },
  { id: 'interaction_frequency', label: 'Interaction Frequency', color: '#67A82E' },
  { id: 'number_of_support_tickets', label: 'Number of Support Tickets', color: '#009688' },
  // { id: 'severity_of_support_tickets', label: 'Severity of Support Tickets', color: '#FFC106' },
  { id: 'renewal_sentiment', label: 'Customer Sentiment', color: '#90A4AE' },
  { id: 'customer_pulse', label: 'Customer Owner Pulse', color: '#F44336' },
  { id: 'current_balance_category', label: 'Current Balance', color: '#5850EC' },
  { id: 'current_due_category', label: 'Current Due', color: '#009688' },
  { id: 'customer_status_category', label: 'Customer Status Category', color: '#FFC106' },
];

export default function KeyIndicatorsTrending({ className, trend, errorFlag, customer, ...props }) {
  const classes = useStyles();
  const [selectedTrendData, setSelectedTrendData] = useState([]);
  const [selectedChip, setSelectedChip] = useState((customer.hasOwnProperty('product_usage')? 'product_usage' : 'current_balance_category'));
  const [color, setColor] = useState((customer.hasOwnProperty('product_usage')? '#0061FF': '#5850EC'));

  useEffect(() => {

      let tempArr = [];
      for (var i=0; trend[0][selectedChip] !== undefined && i<trend[0][selectedChip].length; i++){
        let tempData = [];
        for (var k = 0; k < trend[0][selectedChip][i].values.length; k++) {
          tempData.push(trend[0][selectedChip][i].values[k]);
        }
        tempArr.push(tempData);
      }

      setSelectedTrendData(tempArr);

  }, [selectedChip, trend]);

  const handleChipClick = chip => {
    setSelectedChip(chip);
    // setSelectedTrendData(trend[0][chip].values);
    let tempArr = [];
    for (var i=0; i< trend[0][chip].length; i++){
      let tempData = [];
      for (var k = 0; k < trend[0][chip][i].values.length; k++) {
        tempData.push(trend[0][chip][i].values[k]);
      }
      tempArr.push(tempData);
    }

    setSelectedTrendData(tempArr);
    if (localStorage.getItem('syntheticDataFlag') !== 'true') {
      keyIndicators.some(function (keyIndicator) {
        if (keyIndicator['id'] === chip) {
          setColor(keyIndicator.color);
        }
        return keyIndicator['id'] === chip;
      });
    }
  };
  return (
    <div className={classes.root}>
      <Divider style={{ marginTop: '24px', marginBottom: '24px' }} />
      {errorFlag && (
        <Typography variant="body2" color="textSecondary">
          It looks like we encountered an error. Please contact the involve.ai team to get this fixed.
        </Typography>
      )}

      <Typography variant="subtitle1" color="textPrimary">Key Indicators Trending</Typography>
      <Box mb={2} />
      <Box ml={-0.5}>
        {keyIndicators.map(item => (
          <span key={item.id}>
            {trend[0][item.id] && customer.hasOwnProperty(item.id) && customer[item.id] !== 'Exclude' && (
              <Chip
                label={item.label}
                key={item.id}
                style={{ margin: '4px 4px 8px 4px' }}
                className={item.id !== selectedChip ? classes.unselectedChips : selectedChip}
                icon={<CircleIcon style={{ color: item.color }} />}
                onClick={() => handleChipClick(item.id)}
                clickable
              ></Chip>
            )}
          </span>
        ))}
      </Box>
      <Box mb={2} />

      {selectedTrendData.length > 0 && (
        <>
        {selectedTrendData.map( (trendVal, i) => (
          <Box>
            {trendVal.length > 0 ? (<DetailedChart data={trendVal} labels={trend[0][selectedChip][i].dates} color={color} chartTitle ={trend[0][selectedChip][i].label}/>): (
              <Grid container justify="center">
                <Box style={{ maxWidth: '323px' }} mt={4}>
                  <Box display="flex" justify="center" mb={3} style={{ justifyContent: 'center' }}>
                    <img
                      src={require('./../../assets/chart-empty.png')}
                      alt="Empty History"
                      style={{ width: '96px', height: '96px' }}
                    />
                  </Box>
                  <Typography gutterBottom variant="body2" color="textSecondary" align="center">
                    There is nothing here yet. Consider connecting more data sources to see this trend.
                  </Typography>
                </Box>
              </Grid>
            )}
          </Box>
        ))}
        </>
      )}

    </div>
  );
}
