import React, { useState } from 'react';
import SecondaryNav from './../../SecondaryNav';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import axios from 'axios';
import { Box, Container } from '@material-ui/core';
import { attachToken } from '../../../utils/auth';
import ZendeskLogin from './ZendeskLogin';
import ZendeskExport from './ZendeskExport';
import { zendeskStyles } from './zendesk.theme';
import styles from './zendesk.module.css';
import { useHistory } from 'react-router-dom';
import ZendeskExportDialog from './ZendeskExportDialog';

const Connector = withStyles({
  line: {
    borderStyle: 'dashed',
    borderColor: '#D8D8D8',
    borderBottomWidth: '0px',
    borderLeftWidth: '0px',
    borderRightWidth: '0px',
    borderTopWidth: '1px',
    marginTop: '5px',
  },
})(StepConnector);

const ZendeskIntegration = () => {
  const classes = zendeskStyles();
  let history = useHistory();
  const [email, setEmail] = useState('');
  const [subdomain, setSubdomain] = useState('');
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [tables, setTables] = useState([]);
  const [selectedAllTables, setSelectedAllTables] = useState(false);
  const [selectTableError, setSelectTableError] = useState(false);
  const [authenticationError, setAuthenticationError] = useState(false);
  const [selectedTables, setSelectedTables] = useState([]);
  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [searchTable, setSearchTable] = useState('');
  const [displayTables, setDisplayTables] = useState([]);
  const [openExportDialog, setOpenExportDialog] = useState(false);

  const cancelFunction = () => {
    window.location.pathname = '/integrations';
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleChange = e => {
    setValue(e.target.value);
    setErrorMessage('');
  };

  /**
   *
   * API calls / FormData
   *
   */

  function bodyFormBuilder() {
    var bodyFormData = new FormData();
    bodyFormData.append('subdomain', subdomain);
    bodyFormData.append('email', email);
    bodyFormData.append('security_token', token);
    return bodyFormData;
  }

  const handleClick = event => {
    if (subdomain === '' || email === '' || token === '') {
      setAuthenticationError(true);
    } else {
      var apiUrl = process.env.REACT_APP_API_BASE_URL + 'v1/connector/zendesk_auth';
      var bodyFormData = bodyFormBuilder();
      setIsLoading(true);
      setAuthenticationError(false);
      axios(
        attachToken({
          method: 'post',
          url: apiUrl,
          data: bodyFormData,
          headers: {},
        }),
      )
        .then(function (response) {
          if (response.data.statusCode) {
            if (response.data.statusCode === 200) {
              //handle success
              setIsLoading(false);
              setTables(response.data.body.objects);
              setDisplayTables(response.data.body.objects);
              setSelectedAllTables(false);
              response.data.body.objects.includes("Tickets") ? setSelectedTables(["Tickets"]) : setSelectedTables([]);
              handleNext();
            } else {
              setIsLoading(false);
              setAuthenticationError(true);
            }
          } else {
            window.location = '/integrations/zendesk/dataexportfail';
          }
        })
        .catch(function (response) {
          //handle error
          console.log(response);
          window.location = '/integrations/zendesk/dataexportfail';
        });
    }
  };

  const exportDataAPI = () => {
    var apiUrl = process.env.REACT_APP_API_BASE_URL + 'v1/connector/zendesk_export';
    var bodyFormData = bodyFormBuilder();
    bodyFormData.append('objects', selectedTables);
    setOpenExportDialog(false);
    setIsLoading(true);
    axios(
      attachToken({
        method: 'post',
        url: apiUrl,
        data: bodyFormData,
        headers: {},
      }),
    )
      .then(function (response) {
        if (response.data.statusCode === 200) {
          //handle success
          history.push({
            pathname: '/integrations',
            state: { status: 'dataExport' },
          });
        } else {
          history.push({
            pathname: '/integrations/zendesk',
          });
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        window.location.pathname = 'integrations/zendesk';
      });
  };
  const exportData = () => {
    if (selectedTables.length === 0) {
      setSelectTableError(true);
      setErrorMessage('You must select at least one table for the export to continue');
    } else {
      if (selectedTables.includes('Tickets')) {
        exportDataAPI();
      } else {
        setOpenExportDialog(true);
      }
    }
  };

  const handleCloseDialog = () => {
    setOpenExportDialog(false);
  };

  /**
   *
   * Select Tables functions
   *
   */

  const selectAllTables = e => {
    if (e.target.checked) {
      let tempSelected = selectedTables.concat(displayTables);
      setSelectedTables(tempSelected);
      setSelectedAllTables(true);
      setSelectTableError(false);
    } else {
      setSelectedTables([]);
      setSelectedAllTables(false);
    }
  };

  const selectOneTable = (event, selectedTable) => {
    var tempSelectedTables = [...selectedTables];
    if (tempSelectedTables.includes(selectedTable)) {
      var index = tempSelectedTables.indexOf(selectedTable);
      if (index !== -1) {
        tempSelectedTables.splice(index, 1);
        setSelectedTables(tempSelectedTables);
      }
    } else {
      tempSelectedTables.push(selectedTable);
      setSelectedTables(tempSelectedTables);
      setSelectTableError(false);
    }
    if (tempSelectedTables.length === tables.length) {
      setSelectedAllTables(true);
    } else {
      setSelectedAllTables(false);
    }
  };

  const selectTable = (event, val) => {
    if (val) {
      setValue(val);
      setErrorMessage('');
    }
  };

  const handleSearch = value => {
    setSearchTable(value);
    let tempTable = tables.filter(table => {
      if (table.toLowerCase().includes(value.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    });
    setDisplayTables(tempTable);
    let tempSelectAll = tempTable.every(el => selectedTables.includes(el));
    setSelectedAllTables(tempSelectAll);
  };

  return (
    <Container>
      <SecondaryNav />
      {openExportDialog && (
        <ZendeskExportDialog
          openDialog={openExportDialog}
          exportFunc={exportDataAPI}
          handleCloseDialog={handleCloseDialog}
        />
      )}
      <Container style={{ height: 'calc(100vh - 70px)', marginTop: '64px', overflow: 'scroll' }} className="hideScroll">
        <Box justifyContent="center" className={styles.boxContainer}>
          <Container className={classes.root} style={{}}>
            <Container style={{ maxWidth: '262px' }}>
              <Stepper activeStep={activeStep} alternativeLabel connector={<Connector />}>
                <Step key="0">
                  <StepLabel StepIconProps={{ classes: { root: classes.icon } }}></StepLabel>
                </Step>
                <Step key="1">
                  <StepLabel StepIconProps={{ classes: { root: classes.icon } }}></StepLabel>
                </Step>
              </Stepper>
            </Container>
            <Container>
              <Container>
                {activeStep === 0 && (
                  <ZendeskLogin
                    authenticationError={authenticationError}
                    isLoading={isLoading}
                    handleClick={handleClick}
                    cancelFunction={cancelFunction}
                    setEmail={setEmail}
                    setSubdomain={setSubdomain}
                    setToken={setToken}
                    email ={email}
                    subdomain={subdomain}
                    token ={token}
                  />
                )}
                {activeStep === 1 && (
                  <ZendeskExport
                    errorMessage={errorMessage}
                    selectedTables={selectedTables}
                    selectOneTable={selectOneTable}
                    selectTable={selectTable}
                    selectAllTables={selectAllTables}
                    selectedAllTables={selectedAllTables}
                    selectTableError={selectTableError}
                    tables={displayTables}
                    handleChange={handleChange}
                    handleBack={handleBack}
                    exportData={exportData}
                    value={value}
                    cancelFunction={cancelFunction}
                    isLoading={isLoading}
                    handleSearch={handleSearch}
                    searchTable={searchTable}
                  />
                )}
              </Container>
            </Container>
          </Container>
        </Box>
      </Container>
    </Container>
  );
};

export default ZendeskIntegration;
