import React, { useState, useCallback } from 'react';
import SecondaryNav from './../../SecondaryNav';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import axios from 'axios';
import { Box, Container } from '@material-ui/core';
import { attachToken } from '../../../utils/auth';
import SalesforceLogin from './SalesforceLogin';
import StepThree from './StepThree';
import StepTwo from './StepTwo';
import { salesforceStyles } from './salesforce.theme';
import styles from './salesforce.module.css';
import { useHistory } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
const Connector = withStyles({
  line: {
    borderStyle: 'dashed',
    borderColor: '#D8D8D8',
    borderBottomWidth: '0px',
    borderLeftWidth: '0px',
    borderRightWidth: '0px',
    borderTopWidth: '1px',
    marginTop: '5px',
  },
})(StepConnector);

const SalesforceIntegrations = () => {
  const classes = salesforceStyles();
  let history = useHistory();
  const [domain, setDomain] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [authenticationError, setAuthenticationError] = useState(false);
  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loginErrorMessage, setLoginErrorMessage] = useState(null);
  const [tablesList, setTablesList] = useState([]);

  const [salesforceObjData, setSalesforceObjData] = useState([]);

  const [selectedTables, setSelectedTables] = useState([]);
  const [searchTables, setSearchTables] = useState('');
  const [filteredTablesList, setFilteredTablesList] = useState([]);

  // Common functions
  const cancelFunction = () => {
    window.location.pathname = '/integrations';
  };

  const handleNext = () => {
    setErrorMessage('');
    setSearchTables('');
    setActiveStep(activeStep + 1);
  };

  function bodyFormBuilder() {
    var bodyFormData = new FormData();
    bodyFormData.append('domain', domain);
    bodyFormData.append('username', email);
    bodyFormData.append('password', password);
    bodyFormData.append('security_token', token);
    return bodyFormData;
  }

  const handleClick = event => {
    var apiUrl = process.env.REACT_APP_API_BASE_URL + 'v1/connector/sf_auth';
    var bodyFormData = bodyFormBuilder();

    setIsLoading(true);
    setAuthenticationError(false);

    axios(
      attachToken({
        method: 'post',
        url: apiUrl,
        data: bodyFormData,
        headers: {},
      }),
    )
      .then(function (response) {
        if (response.data.body.action) {
          if (response.data.body.action === 'LoginFailed') {
            setIsLoading(false);
            setLoginErrorMessage('Incorrect credentials');
            setAuthenticationError(true);
          } else if (response.data.body.action === 'UserPermissionIssue') {
            setIsLoading(false);
            setLoginErrorMessage(response.data.Error);
            setAuthenticationError(true);
          } else if (response.data.body.action === 'AccessFailed') {
            setIsLoading(false);
            handleNext();
          } else {
            //handle success
            setTablesList(response.data.body.tables);
            setFilteredTablesList(response.data.body.tables);
            setIsLoading(false);
            handleNext();
          }
        } else {
          window.location = '/integrations/salesforce/dataexportfail';
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        window.location = '/integrations/salesforce/dataexportfail';
      });
  };

  // Step 2 functions
  const handleSearchTablesChange = (event, value) => {
    event.persist();
    setSearchTables(event.target.value);

    let tempArr = tablesList.filter(item => item.toLowerCase().includes(event.target.value.toLowerCase()));
    setFilteredTablesList(tempArr);
  };

  const handleSelectTable = useCallback(
    value => {
      setErrorMessage('');
      let tempSelectedTables = [...selectedTables];
      if (!tempSelectedTables.includes(value)) {
        tempSelectedTables.push(value);
      } else {
        tempSelectedTables.splice(tempSelectedTables.indexOf(value), 1);
      }
      setSelectedTables(tempSelectedTables);
    },
    [selectedTables],
  );

  const exportTables = () => {
    var apiUrl = process.env.REACT_APP_API_BASE_URL + 'v1/connector/sf_objects';

    if (selectedTables.length <= 0) {
      setErrorMessage('Select at least one table to export');
    } else {
      setIsLoading(true);
      let reuestJson = {
        domain: domain,
        username: email,
        password: password,
        security_token: token,
        tables: selectedTables,
      };

      axios(
        attachToken({
          method: 'post',
          url: apiUrl,
          data: reuestJson,
          headers: {},
        }),
      )
        .then(function (response) {
          setIsLoading(false);
          if (response.data.hasOwnProperty('Error')) {
            setErrorMessage(response.data.Error);
          } else {
            let tempObj = response.data.body.objects.map(item => ({
              table_name: item.table_name,
              columns: item.columns,
              original_columns: [...item.columns],
              selected_columns: [],
            }));
            setSalesforceObjData(tempObj);
            handleNext();
          }
        })
        .catch(function (response) {
          history.push({
            pathname: '/integrations',
            state: { status: 'dataExport' },
          });
        });
    }
  };

  const handleBacktoStep1 = () => {
    setActiveStep(activeStep - 1);
    setEmail('');
    setPassword('');
    setToken('');
    setDomain('');
  };

  // Step 3 functions
  const handleSelect = (id, type, details) => {
    let newSalesforceObjData = cloneDeep(salesforceObjData);
    if (type === 'column') {
      for (var i = 0; i < newSalesforceObjData.length; i++) {
        if (newSalesforceObjData[i].table_name === details) {
          if (!newSalesforceObjData[i].selected_columns.includes(id)) {
            newSalesforceObjData[i].selected_columns.push(id);
          } else {
            newSalesforceObjData[i].selected_columns.splice(newSalesforceObjData[i].selected_columns.indexOf(id), 1);
          }
          break;
        }
      }
    } else if (type === 'table') {
      for (var j = 0; j < newSalesforceObjData.length; j++) {
        if (newSalesforceObjData[j].table_name === id) {
          if (newSalesforceObjData[j].selected_columns.length > 0) {
            newSalesforceObjData[j].selected_columns = [];
          } else {
            newSalesforceObjData[j].selected_columns = details;
          }
          break;
        }
      }
    }
    setSalesforceObjData(newSalesforceObjData);
  };

  const handleSearchStep3 = (event, value) => {
    event.persist();
    let tempSearchText = event.target.value;
    setSearchTables(tempSearchText);
    let newSalesforceObjData = cloneDeep(salesforceObjData);

    newSalesforceObjData.forEach((dataObj, i) => {
      if (tempSearchText === '') {
        newSalesforceObjData[i].columns = [...newSalesforceObjData[i].original_columns];
      } else {
        newSalesforceObjData[i].columns = [];
        if (dataObj.table_name.toLowerCase().includes(tempSearchText.toLowerCase())) {
          newSalesforceObjData[i].columns = [...newSalesforceObjData[i].original_columns];
        } else {
          newSalesforceObjData[i].original_columns.forEach((column, j) => {
            if (column.toLowerCase().includes(tempSearchText.toLowerCase())) {
              newSalesforceObjData[i].columns.push(column);
            }
          });
        }
      }
    });

    setSalesforceObjData(newSalesforceObjData);
  };

  const handleChange = e => {
    setValue(e.target.value);
    setErrorMessage('');
  };

  const handleBacktoStep2 = () => {
    setActiveStep(activeStep - 1);
  };

  const exportData = filteredData => {
    var apiUrl = process.env.REACT_APP_API_BASE_URL + 'v1/connector/sf_export';
    let tempVar = [];
    let isAnySelected = false;
    salesforceObjData.forEach((item, i) => {
      if (item.selected_columns.length > 0) {
        isAnySelected = true;
        let tempObj = {
          table_name: item.table_name,
          columns: item.selected_columns,
        };
        tempVar.push(tempObj);
      }
    });
    if (!isAnySelected) {
      setErrorMessage('Select at least one table/column to export');
    } else {
      setIsLoading(true);
      let reuestJson = {
        domain: domain,
        username: email,
        password: password,
        security_token: token,
        tables: tempVar,
      };

      axios(
        attachToken({
          method: 'post',
          url: apiUrl,
          data: reuestJson,
          headers: {},
        }),
      )
        .then(function (response) {
          setIsLoading(false);
          if (response.data.hasOwnProperty('Error')) {
            setErrorMessage(response.data.Error);
          } else {
            //handle success
            history.push({
              pathname: '/integrations',
              state: { status: 'dataExport' },
            });
          }
        })
        .catch(function (response) {
          //handle error
          console.log(response);
          window.location.pathname = 'integrations/salesforce/dataexport';
          history.push({
            pathname: '/integrations',
            state: { status: 'dataExport' },
          });
        });
    }
  };

  return (
    <Container>
      <SecondaryNav />
      <Container style={{ height: 'calc(100vh - 70px)', marginTop: '64px', overflow: 'visible' }}>
        <Box justifyContent="center" className={styles.boxContainer}>
          <Container className={classes.root} style={{}}>
            <Container style={{ maxWidth: '400px' }}>
              <Stepper activeStep={activeStep} alternativeLabel connector={<Connector />}>
                <Step key="0">
                  <StepLabel StepIconProps={{ classes: { root: classes.icon } }}></StepLabel>
                </Step>
                <Step key="1">
                  <StepLabel StepIconProps={{ classes: { root: classes.icon } }}></StepLabel>
                </Step>
                <Step key="2">
                  <StepLabel StepIconProps={{ classes: { root: classes.icon } }}></StepLabel>
                </Step>
              </Stepper>
            </Container>
            <Container>
              <Container>
                {activeStep === 0 && (
                  <SalesforceLogin
                    authenticationError={authenticationError}
                    isLoading={isLoading}
                    handleClick={handleClick}
                    cancelFunction={cancelFunction}
                    setDomain={setDomain}
                    setEmail={setEmail}
                    setPassword={setPassword}
                    setToken={setToken}
                    loginErrorMessage={loginErrorMessage}
                  />
                )}
                {activeStep === 1 && (
                  <StepTwo
                    errorMessage={errorMessage}
                    handleBack={handleBacktoStep1}
                    cancelFunction={cancelFunction}
                    isLoading={isLoading}
                    tablesList={tablesList}
                    filteredTablesList={filteredTablesList}
                    selectedTables={selectedTables}
                    searchTables={searchTables}
                    handleSearchTablesChange={handleSearchTablesChange}
                    handleSelectTable={handleSelectTable}
                    exportTables={exportTables}
                  />
                )}
                {activeStep === 2 && (
                  <StepThree
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    handleChange={handleChange}
                    handleBack={handleBacktoStep2}
                    value={value}
                    cancelFunction={cancelFunction}
                    isLoading={isLoading}
                    salesforceObjData={salesforceObjData}
                    exportData={exportData}
                    handleSelect={handleSelect}
                    searchTables={searchTables}
                    handleSearchStep3={handleSearchStep3}
                  />
                )}
              </Container>
            </Container>
          </Container>
        </Box>
      </Container>
    </Container>
  );
};

export default SalesforceIntegrations;
