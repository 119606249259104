import React, {useState} from 'react';
import {Button, Box, TextField, Grid }from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import LoadingSpinner from '../../LoadingSpinner';
import {setAccountInfo} from './AccountSettingsApi';

const useStyles = makeStyles((theme) => ({
    margin:{
        marginTop:'34px',
        
    },
    buttonAccount: {
        padding:'8px 24px',
        height:'42px'
    }
}));

const UpdateUserInfo = (props) => {
    const classes = useStyles();
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
  

    const handleClick = async (e) => {
        e.preventDefault();
        setSuccessMessage('');
        const error = validate();
        
        if (Object.keys(error).length > 0)
            return;
        else {
            setIsSubmitting(true);
            let requestData = {
                    "email": props.userEmail,
                    "first_name": props.firstName,
                    "last_name": props.lastName
            }

            await  setAccountInfo(requestData).then(response => {
                if (response === undefined) 
                    setErrorMessage ("Something went wrong. Please contact the involve.ai team to get this fixed.");
                else if (response.data.hasOwnProperty('message')) {
                    setErrorMessage ("Something went wrong. Please contact the involve.ai team to get this fixed.");
                } else {
                    setSuccessMessage ("User information has been updated");
                }
                setIsSubmitting(false);
            });
        }
    }

    const validate= () => {
        var error = {};
     
        if (props.firstName === "") {
            error["firstname"]='Please enter your first name';
        }
        if (props.firstName.length > 150) {
            error["firstname"]='Please enter a shorter first name';
        } 
        if (props.lastName === "") {
            error["lastName"]='Please enter your last Name';
        } 
        if (props.lastName.length > 150) {
            error["lastName"]='Please enter a shorter last name';
        } 
        if (Object.keys(error).length > 0)
            setErrorMessage (error);
        else 
            setErrorMessage ('');
        return error;

    }

    return (
        <div className={classes.margin}>
            <Grid container alignItems="center" spacing={1}>    
                    {errorMessage !== '' ? (
                            <Alert severity="error"  p={1}>
                                {' '}
                                {typeof errorMessage === 'string'
                                    ? errorMessage
                                    : Object.keys(errorMessage).map((key, index) => {
                                        return <li>{errorMessage[key]}</li>;
                                    })}
                            </Alert>
                    ) : null}
                    {successMessage !== "" ? (
                            <Alert severity="success"  p={1}>
                                {successMessage}
                            </Alert>
                    ) : null}

                <Grid item xs={12} container spacing={1}>
                    <Grid item xs={3}>
                    <TextField
                        fullWidth
                        autoFocus
                        label="First Name"
                        margin="normal"
                        name="firstName"
                        type=""
                        value={props.firstName}
                        //defaultValue={props.firstName}
                        variant="outlined"
                        onChange={(e)=> props.setFirstName(e.target.value)}
                    />
                    </Grid>
                    <Grid item xs={3} >
                    <TextField
                        fullWidth
                        autoFocus
                        label="Last Name"
                        margin="normal"
                        name="lastName"
                        type=""
                        value={props.lastName}
                        //defaultValue = {props.lastName}
                        variant="outlined"
                        onChange={(e)=> props.setLastName(e.target.value)}
                    />
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                <TextField
                    fullWidth
                    autoFocus
                    disabled
                    label="Email"
                    margin="normal"
                    name="email"
                    type="email"
                    defaultValue={props.userEmail}
                    variant="outlined"
                    InputProps={{
                        readOnly: true,
                    }}
                />
                </Grid>
                <Grid item xs={12} >
                    <Box mt={2}>
                        {isSubmitting ? 
                            <LoadingSpinner></LoadingSpinner> : 
                            <Button
                                color="primary"
                                size="medium"
                                type="submit"
                                variant="contained"
                                className={classes.buttonAccount}
                                onClick={(e) => handleClick(e)}
                            >
                            SAVE
                            </Button>
                        }
                    </Box>
                </Grid>
            </Grid> 
        </div>

    )
}

export default UpdateUserInfo;