import React from 'react';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { IconButton, makeStyles } from '@material-ui/core';
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  SvgIcon,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Divider
} from '@material-ui/core/';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles({
  dialogPaper: {
    maxWidth: '496px',
    maxHeight: '640px',
  },
  submittedDialog: {
    width: '400px',
    height: '329px',
  },
  formControl: {
    width: '100%',
    marginTop: '16px',
    marginBottom: '8px',
  },
  smallEditIcon:{
    color: '#546E7A'
  },  
});

function makeItems(data) {
  const items = [];

  data.forEach((role, index) => {
      items.push(
        <MenuItem value={role.name}>{role.name}</MenuItem>
      );      
      items.push(
        <MenuItem style={{color: '#546E7A', fontSize: '14px', marginTop: '8px'}} disabled>{role.description}</MenuItem>        
      );
      if (data[index + 1] !== undefined) {
        items.push(
          <Divider style={{marginTop: '16px', marginBottom: '16px', color: '#EEEEEE'}} />
        );
      }
    })  
  return items;
}

const TrackedContactDialog = props => {
  const classes = useStyles();
  return (
    <Dialog open={props.openDialog} onClose={props.closeDialogFun} classes={{ paper: classes.dialogPaper }}>
      {props.submittedFlag ? (
        <>
          <Box alignItems="center" className={classes.submittedDialog}>
            <Box pt={10} pb={3} pl={20}>
              <SvgIcon style={{ width: 80, height: 80, alignSelf: 'center' }}>
                <CheckCircleIcon color="primary" />
              </SvgIcon>
            </Box>
            <Typography color="textPrimary" gutterBottom variant="h4" align="center">
              Thank You!
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
              Your point of contact information has been saved.
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Box p={4}>
            {props.newContact ? (
              <Typography variant="h4" color="textPrimary">Add Point of Contact</Typography>
            ) : (
              <Typography variant="h4" color="textPrimary">Edit Point of Contact</Typography>
            )}
            <Box pt={2} pb={props.newContact ? 4 : 0}>
              {props.errorMessage !== '' ? (
                        <Alert severity="error" style={{ maxWidth: '400px' }} p={1}>
                            {' '}
                            {typeof props.errorMessage === 'string'
                                ? props.errorMessage
                                : Object.keys(props.errorMessage).map((key, index) => {
                                    return <li>{props.errorMessage[key]}</li>;
                                })}
                        </Alert>
                    ) : null}
              <TextField
                fullWidth
                label="Full Name  (optional)"
                margin="normal"
                name="full_name"
                type="text"
                variant="outlined"
                style={{ maxWidth: '432px' }}
                value={props.fullName}
                onChange={e => props.setFullName(e.target.value)}
              />
              <TextField
                fullWidth
                label="Email *"
                margin="normal"
                name="email"
                type="text"
                variant="outlined"
                style={{ maxWidth: '432px' }}
                value={props.email}
                onChange={e => props.setEmail(e.target.value)}
              />
              <TextField
                fullWidth
                label="Position (optional)"
                margin="normal"
                name="position"
                type="text"
                variant="outlined"
                style={{ maxWidth: '432px' }}
                value={props.position}
                onChange={e => props.setPosition(e.target.value)}
              />
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Role *</InputLabel>
                <Select
                  labelId="contactRole"
                  id="contactRole"
                  value={props.role}
                  onChange={e => props.setRole(e.target.value)}
                  label="Role *"
                  
                >
                  {makeItems(props.contactsRoles)}
                </Select>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Status * </InputLabel>
                <Select
                  labelId="contactStatus"
                  id="contactStatus"
                  value={props.status}
                  onChange={e => props.setStatus(e.target.value)}
                  label="Status * "
                >
                  <MenuItem value={'active'}>Active</MenuItem>
                  <MenuItem value={'inactive'}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {!props.newContact &&
              <Box pb={3}>
                <IconButton onClick={props.handleDelete} className={classes.smallEditIcon} size="small" >
                  <DeleteIcon />
                    <Typography color="textSecondary" display="initial" variant="subtitle2">Delete Contact</Typography>
                </IconButton>
              </Box>
            }
            <DialogActions style={{ padding: '0px' }}>
              <Button onClick={props.closeDialogFun} variant="contained" size="large">
                Cancel
              </Button>
              <Box flexGrow="1" />
              <Button onClick={props.handleSave} color="primary" type="submit" variant="contained" size="large">
                Save
              </Button>
            </DialogActions>
          </Box>
        </>
      )}
    </Dialog>
  );
};
export default TrackedContactDialog;
