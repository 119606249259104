import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  dialogPaper: {
    maxWidth: '496px',
    maxHeight: '542px',
  },
  dialogPadding: {
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  dataExportImg: {
    maxHeight: '280px',
  },
});

const WelcomeDialog = ({ openDialog, ...rest }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(!localStorage.getItem('reportWelcome')? true : false);
  useEffect(() => {
    if (!localStorage.getItem('reportWelcome')) {
      localStorage.setItem('reportWelcome', true);
    }
  }, []);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paper: classes.dialogPaper }}>
      <img src={require('./../../assets/welcome-reports.png')} alt="Data Export" className={classes.dataExportImg} />
      <Box p={4}>
        <Typography variant="h4" color="textPrimary">Welcome to Reports</Typography>
        <Box pt={2} pb={4}>
          <Typography variant="body2" color="textSecondary">
            Create, customize and share analytics with your colleagues and customers.
          </Typography>
        </Box>
        <DialogActions style={{ padding: '0px', justifyContent: 'flex-start' }}>
          <Button onClick={handleClose} color="primary" type="submit" variant="contained" size="large">
            Get Started
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default WelcomeDialog;
