import React, { useState } from 'react';
import {Button  , Avatar, Grid, Box}from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import {setUserImage } from './AccountSettingsApi';
import { MAX_IMAGE_SIZE } from '../../../constants/ReferenceConstants';
import {USER_IMAGE_BUCKET} from '../../../constants/AWSUrls';
import LoadingSpinner from '../../LoadingSpinner';

const useStyles = makeStyles((theme) => ({
    selectImage: {
        backgroundSize:'100%',
        borderRadius:'100%',
        width:'80px',
        height:'80px',
    },
    margin:{
        marginTop:'16px'
    },
    buttonAccount: {
        //padding:'8px 26px',
        height:'42px'
    }
  }));


const UpdateUserImage = (props) => {
    const classes = useStyles();
    const [errorMessage, setErrorMessage] = useState('');
    const [imageUploading, setImageUploading] = useState(false);
    const [imageDeleting, setImageDeleting] = useState(false);

    const handleUpload = async e => {
        e.preventDefault();
        if (e.target.files[0].size > (MAX_IMAGE_SIZE * 1000000)) {
            setErrorMessage (`Image size is bigger than ${MAX_IMAGE_SIZE}MB`);
            return;
        }

        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        let json_data = {"email": props.userEmail, 
                        "is_deleted": false};
        formData.append("json_data", JSON.stringify(json_data));
        setImageUploading(true);
        await setUserImage (formData).then(response => {
            if (response === undefined) 
               setErrorMessage ("Something went wrong. Please contact the involve.ai team to get this fixed.");
            else if (response.data.hasOwnProperty('message')) {
                setErrorMessage ("Something went wrong. Please contact the involve.ai team to get this fixed.");
            } else {
                const image = USER_IMAGE_BUCKET + response.data + '?' + (new Date()).getTime();
                props.setUserImg(image) ;
                localStorage.setItem("user_image",image);
            }
            setImageUploading(false);
          });
    };

    const handleDelete = async (e) => {
        e.preventDefault();
        
        const formData = new FormData();
        let json_data = {"email": props.userEmail, 
                        "is_deleted": true};
        formData.append("json_data", JSON.stringify(json_data));
        setImageDeleting(true);
        await setUserImage (formData).then(response => {
            if (response === undefined) 
               setErrorMessage ("Something went wrong. Please contact the involve.ai team to get this fixed.");
            else if (response.data.hasOwnProperty('message')) {
                setErrorMessage ("Something went wrong. Please contact the involve.ai team to get this fixed.");
            } else {
                localStorage.removeItem("user_image");
                props.setUserImg("");
            }
            setImageDeleting(false);
        });
    };
   
    return (
       <div className={classes.margin}>
             <Grid container alignItems="center" spacing={1}>    
                <Grid container item>
                    {errorMessage !== "" ? (
                            <Box mt={1} mb={1}>
                                <Alert severity="error">
                                    {' '}
                                    {errorMessage && <li>{errorMessage}</li>}
                                </Alert>
                            </Box>
                        ) : null}
                </Grid>
                <Grid item xs={1}>
                    <Avatar alt="User"
                        className={classes.selectImage}
                        src={props.userImg}
                    />
                </Grid>
                <Grid item xs={1} >   
                    {imageUploading ? <LoadingSpinner></LoadingSpinner> :
                    (<div>
                        <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        onChange={(e)=> handleUpload(e)}
                        />
                        <label htmlFor="raised-button-file">
                        <Button variant="contained" 
                                component="span" 
                                color="primary" 
                                size="medium"  
                                className={classes.buttonAccount} 
                                onClick={()=>setErrorMessage("")}>
                            Upload
                        </Button>
                        </label> 
                    </div>)}

                    
                </Grid>
                <Grid item xs={2}>
                    {imageDeleting ? <LoadingSpinner></LoadingSpinner> : <Button
                        variant="contained"
                        className={classes.buttonAccount}
                        onClick={(e) => handleDelete(e)}
                    >
                        DELETE PHOTO
                    </Button>}
                </Grid>
            </Grid>
        </div>
    )
}

export default UpdateUserImage;