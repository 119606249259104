import React, { useState, useEffect } from 'react';
import { Box, Typography, Container, Divider, Button } from '@material-ui/core';
import Overview from './Overview';
import { getReportsConfig, getNumberRecords } from './../ReportsAPI';
import cloneDeep from 'lodash/cloneDeep';
import { reportsStyles } from './../reports.theme';
import { useParams } from 'react-router-dom';

const Reports = () => {
  const styles = reportsStyles();
  const { reportId } = useParams();
  const [configData, setConfigData] = useState([]);
  const [initialLayout, setInitialLayout] = useState([]);
  const [loadingFlag, setLoadingFlag] = useState(true);
  const [duration, setDuration] = useState('monthly');
  const [savedFilters, setSavedFilters] = useState({});
  const [recordCount, setRecordCount] = useState(0);

  useEffect(() => {
    getReportsConfig({
      report_id: reportId,
    }).then(response => {
      getNumberRecords({
        report_id: reportId,
      }).then(response => {
        setRecordCount(response.data.count);
      });
      setConfigData(response.data.data);
      setSavedFilters(response.data.data.filters);
      setDuration(response.data.data.duration);

      let tempInitialLayout = { lg: [], md: [], sm: [] };
      let tempBlockData = cloneDeep(response.data.data.blocks);
      let lgX,
        lgY = 0;
      let mdX,
        mdY = 0;
      let smX,
        smY = 0;
      tempBlockData.forEach((item, i) => {
        if (item.layout === 'full-width') {
          tempInitialLayout.lg.push({ i: item.id, x: 0, y: lgY, w: 12, h: 9, minH: 9, maxH: 16 });
          tempInitialLayout.md.push({ i: item.id, x: 0, y: mdY, w: 12, h: 14, minH: 9 });
          tempInitialLayout.sm.push({ i: item.id, x: 0, y: smY, w: 12, h: 20, minH: 9 });
          lgY = lgY + 9;
          mdY = mdY + 14;
          smY = smY + 20;
          lgX = 0;
          mdX = 0;
          smX = 0;
        } else {
          tempInitialLayout.lg.push({ i: item.id, x: lgX, y: lgY, w: 6, h: 6.5 });
          tempInitialLayout.md.push({ i: item.id, x: 0, y: mdY, w: 12, h: 6.5 });
          tempInitialLayout.sm.push({ i: item.id, x: smX, y: smY, w: 6, h: 6.5 });
          lgY = lgY + 6.5;
          lgX = lgX === 0 ? lgX + 6 : 0;
          mdY = mdY + 6.5;
          mdX = mdX === 0 ? mdX + 6 : 0;
          smY = smY + 6.5;
          smX = smX === 0 ? smX + 6 : 0;
        }
      });
      setInitialLayout(tempInitialLayout);
      setLoadingFlag(false);
    });
  }, [reportId]);

  return (
    <>
      <Box p={3} display="flex" justifyContent="center">
        <Typography variant="h4" color="textPrimary">
          {configData.name}
        </Typography>
      </Box>
      <Divider />
      <Container
        style={{
          height: 'calc(100vh - 70px)',
          padding: '8px 16px 16px 24px',
          maxWidth: '100%',
        }}
        className="hideScroll"
      >
        {loadingFlag ? (
          <div className={styles.loader}> </div>
        ) : (
          <>
            {recordCount > 0 && (
              <Box ml={-1}>
                <Overview
                  configData={configData}
                  initialLayout={initialLayout}
                  duration={duration}
                  filters={savedFilters}
                  recordCount={recordCount}
                />
                <Divider />
                <Box display="flex" className={styles.reportFooter} m= {1}>
                  <img
                    src={require('./../../../assets/involve-light.png')}
                    alt="logo"
                    className={styles.reportFooterImg}
                  />
                  <Divider orientation="vertical" variant="middle" flexItem style={{ margin: '24px' }} />
                  <Box alignSelf="center">
                    <Typography variant="body2" style={{ color: '#FFFFFF', marginBottom: '16px' }}>
                      involve.ai is an AI early warning system to supercharge customer teams with actionable insights
                      and automation, so they can reduce churn, increase upsells, and improve customer experience.
                      involve.ai is built with love in Santa Monica, California, and with team members in San Diego, San
                      Francisco, Austin, New Delhi, Manilla, Buenos Aires, Ontario, and St. Petersburg, Russia just to
                      name a few.
                    </Typography>
                    <Button variant="contained" color="primary" target="_blank" href="https://www.involve.ai/">
                      Learn more
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default Reports;
