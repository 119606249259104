import React, { useState, useEffect } from 'react';
import { Box, Typography, Container, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SecondaryNav from './../SecondaryNav';
import Board from './Board';
import { getInsights } from './InsightsAPI';
import classes from './insights.module.css';

const Insights = () => {
  const [loadingFlag, setLoadingFlag] = useState(true);
  const [insightsData, setInsightsData] = useState([]);
  const [loadedFlag, setLoadedFlag] = useState(false);
  const [searchVal, setSearchVal] = useState(null);
  const [searchText, setTextVal] = useState('');
  const [cards, setCards] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    getInsights().then(response => {
      setInsightsData(response.data.data);
      setFilteredData(response.data.data);
      setCards(response.data.data.map(item => item.company_name).sort());
      setLoadingFlag(false);
      setLoadedFlag(true);
    });
  }, []);

  return (
    <div>
      <SecondaryNav />
      <Container
        style={{
          height: 'calc(100vh - 70px)',
          marginTop: '64px',
          overflow: 'overlay',
          paddingLeft: '24px',
          paddingRight: '16px',
          maxWidth: '100%',
        }}
        className="hideScroll"
      >
        <Box mt={4}>
          <Typography variant="h4" color="textPrimary">
            Insights
          </Typography>
        </Box>
        <Box mt={1.5} mb={2} display="flex">
          <Typography variant="body2" color="textSecondary">
            See actionable insights, convert them to tasks or create your own, and stay up to date on your priority
            customers.
          </Typography>
          <Box flexGrow="1" />
          <Autocomplete
            id="insights-search"
            options={cards}
            getOptionLabel={option => option}
            style={{ width: 300 }}
            renderInput={params => (
              <TextField
                {...params}
                label={searchText.length > 0 || searchVal ? '' : 'Search'}
                variant="outlined"
                InputLabelProps={{ shrink: false }}
                onChange={event => setTextVal(event.target.value)}
              />
            )}
            value={searchVal}
            onChange={(event, value) => {
              setTextVal('');
              setSearchVal(value);
            }}
          />
        </Box>
        <Box>
          {!loadedFlag && loadingFlag ? (
            <div className={classes.loader}> </div>
          ) : (
            <>
              {filteredData.length > 0 ? (
                <Board insightsData={insightsData} searchVal={searchVal} />
              ) : (
                <Grid container justify="center">
                  <Box style={{ maxWidth: '323px' }} mt={4}>
                    <Box display="flex" justify="center" mb={3} style={{ justifyContent: 'center' }}>
                      <img
                        src={require('./../../assets/insights-empty.png')}
                        alt={'Empty'}
                        style={{ width: '131px', height: '126px' }}
                      />
                    </Box>
                    <Typography gutterBottom variant="body2" color="textSecondary" align="center">
                      There are no insights yet. Please visit Customers page and come back later.
                    </Typography>
                  </Box>
                </Grid>
              )}
            </>
          )}
        </Box>
      </Container>
    </div>
  );
};

export default Insights;
