import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    outerContainer: {
      maxWidth: '432px',
    },
    innerContainer: {
      display: "flex",
      alignItems: "center",
      margin: 'auto',
      maxWidth: '130px'
    },
    border: {
      borderBottom: "1px solid gray",
      opacity: 0.3,
      width: "100%"
    },
    content: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      fontWeight: 500,
      fontSize: 18,
      opacity: 0.6,
      color: "black"
    }
}));

const DividerWithText = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.outerContainer}>
            <div className={classes.innerContainer}>
                <div className={classes.border} />
                <span className={classes.content}>{children}</span>
                <div className={classes.border} />
            </div>
        </div>
    );
};

export default DividerWithText;