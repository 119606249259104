import React, { useState } from 'react';
import { Collapse, makeStyles } from '@material-ui/core';
import {
    Box, MenuItem, Select, Typography, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText,
    Button, FormControl
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { getDataTableConfig } from './UserRoleApi';

import Filters from './Filters.js';

const useStyles = makeStyles({
    dialogPaper: {
        // maxWidth: '496px',
        // maxHeight: '640px',
    },
    formControl: {
        width: '70%',
        marginTop: '16px',
        marginBottom: '8px',
    },
    labelText: {
        fontSize: '14px',
        letterSpacing: '0.1px',
        lineHeight: '20px',
        alignSelf: 'center',
        paddingRight: '8px'
    },
    select: {
        height: '42px',
        width: '100%'
    }
});

const EditUserDialog = props => {
    const classes = useStyles();
    const [userPermissionCollapse, setuserPermissionCollapse] = useState(false);
    const [test] = useState('')
    //permissions
    const [allColumns] = useState(JSON.parse(localStorage.getItem('columnsSimpleView')));
    const [permissions, setPermissions] = useState({});
    const [filterOptions, setFilterOptions] = useState([]);

    React.useEffect(() => {
        getAutosuggestsData();
    }, [test])

    const getAutosuggestsData = () => {
        getDataTableConfig().then(response => {
            if (response.data.hasOwnProperty('Error')) {
            } else {
                //replace
                setFilterOptions(response.data.columns);
            }
        });
    }

    const descriptionElementRef = React.useRef(null);
    const dialogContentRef = React.useRef(null);
    React.useEffect(() => {
        if (props.errorMessage !== '') {
            const { current: dialogContent } = dialogContentRef;
            dialogContent.scrollTop = 0;
        }
        if (props.openDialog) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [props.openDialog, props.errorMessage]);

    return (
        <div>
            <Dialog open={props.openDialog} onClose={props.closeDialogFun} classes={{ paper: classes.dialogPaper }}>
                {props.onlyEditPermissions === false &&
                <DialogTitle id="scroll-dialog-title">                    
                        <React.Fragment>
                            <Box>
                                <Typography variant="h4" style={{ color: '#263238' }} >User Role</Typography>
                            </Box>
                            <Box>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        id="userRole"
                                        value={props.userRole}
                                        onChange={e => {
                                            props.setUserRole(e.target.value);
                                            setuserPermissionCollapse(!userPermissionCollapse);
                                        }}
                                        className={classes.select}
                                    >
                                        <MenuItem value="admin">Admin</MenuItem>
                                        <MenuItem value="user">User</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </React.Fragment>
                    
                </DialogTitle>
                }
                <DialogContent dividers={props.userRole !== 'admin'} ref={dialogContentRef}>
                    <Collapse in={props.userRole !== 'admin'}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}>
                            <Box pt={3}>
                                <Typography variant="h4" style={{ color: '#263238' }}>User Permissions</Typography>
                                <Typography variant="caption" color="textSecondary" style={{ fontSize: '14px' }}>Add, edit, and remove data filters to manage permission for this user.</Typography>
                            </Box>
                            <Box>
                                {props.errorMessage !== '' ? (
                                    <Alert severity="error" style={{ maxWidth: '400px' }} p={1}>
                                        {' '}
                                        {typeof props.errorMessage === 'string'
                                            ? props.errorMessage
                                            : Object.keys(props.errorMessage).map((key, index) => {
                                                return <li>{props.errorMessage[key]}</li>;
                                            })}
                                    </Alert>
                                ) : null}
                            </Box>
                            {props.userRole === 'user' &&
                                <Filters
                                    filterOptions={filterOptions}
                                    savedFilters={props.userPermissions}
                                    dataColumns={allColumns}                                    
                                    setPermissions={setPermissions}
                                />
                            }
                        </DialogContentText>
                    </Collapse>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.closeDialogFun} variant="contained" size="large">
                        Cancel
                    </Button>
                    <Box flexGrow="1" />
                    <Button onClick={() => props.handleSaveChanges(permissions)} color="primary" type="submit" variant="contained" size="large">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default EditUserDialog;
