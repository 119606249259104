import React, { useState } from 'react';
import {Box, Container, TextField, Tooltip, Typography} from '@material-ui/core';
import NavBar from '../NavBar'
import { EMAIL_VALIDATION_REGEX } from '../../constants/ReferenceConstants';
import SignUpForm from './SignUpForm'
import StepWizard from "react-step-wizard";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import {methodIcons} from './account.constants'
import {accountStyles} from './account.theme';
import LoadingSpinner from "../LoadingSpinner";

const ConfirmEmail = (props) => {
    const classes = accountStyles();
    const [errorMessage, setErrorMessage] = React.useState('')
    const [email, setEmail] = React.useState(props.user.email)
    const [isLoading, setLoading] = React.useState(false)

    React.useEffect(()=>{
         document.getElementById("email").value = props.user.email
         setEmail(props.user.email)
     }, [props.user.email])

    return (<>
        <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            mb={2}
        >
            <div style={{width:"100%"}}>
                <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                    align="center"
                >
                    Email Verification
                </Typography>
            </div>
        </Box>
        <Container style={{marginLeft: "45px"}} >
            <Box m = {2} />
            <Typography
                variant="body2"
                color="textSecondary"
                style={{
                    width: "362px"
                }}
            >
                Please confirm your email address.
            </Typography>
            {(errorMessage !== '') ?
                (<Alert severity="error" style={{maxWidth: "400px"}} p={1}> {errorMessage}</Alert>)
                : (null)
            }
            <Box style={{marginTop:"5px"}}>
                <div>
                    <TextField
                        fullWidth
                        autoFocus
                        label="Email"
                        margin="normal"
                        name="email"
                        variant="outlined"
                        id="email"
                        style={{maxWidth: "432px"}}
                        onChange={(e) => {setEmail(e.target.value)}}
                    />

                </div>

            </Box>
            <Box mt={2}></Box>
            <Box mt={1}>
                <Container style={{ display: "flex", maxWidth: "680px", marginLeft:"-25px"}}>
                    <Button variant="contained"
                            onClick={props.previousStep}
                            className={classes.backButton}
                    >
                        Cancel
                    </Button>
                    <Box flexGrow={1} />
                    {isLoading ? (
                        <LoadingSpinner />
                    ) : (
                    <Button
                        color="primary"
                        size="large"
                        type="submit"
                        variant="contained"
                        style={{maxWidth: "432px", marginRight: "25px"}}
                        onClick={function () {
                            setErrorMessage('')
                            if(email !== props.user.email) {
                                setErrorMessage("This email address does not match with the email address on the previous page. Please try again.")
                                return
                            }
                            setLoading(false)
                            signUp(props.user, (response)=>{
                                    props.user.verification_token = response.data.verification_token;
                                    props.nextStep()
                                    setLoading(false)
                                },(response) =>{
                                    if(response.data && response.data.message)
                                        setErrorMessage(response.data.message)
                                    else{
                                        setErrorMessage("Error with Status " + response.statusText)
                                    }
                                    setLoading(false)
                                }
                            )}}
                    >
                        Send
                    </Button>)}
                </Container>
            </Box>
        </Container>
    </>);
};



export const verifyEmail = (user, success_fn, failure_fn) => {
    var apiUrl = process.env.REACT_APP_API_BASE_URL + "v1/auth/verify_email";
    var bodyFormData = new FormData();
    if(user.verification_code === "") {
        failure_fn({"data":{"message":"Please enter verification code"}})
        return
    }
    bodyFormData.append('verification_code', user.verification_code)
    bodyFormData.append('verification_token', user.verification_token)
    axios({
        method: 'post',
        url: apiUrl,
        data: bodyFormData,
        headers: {}
    })
        .then(function (response) {
            if (response.status === 200){
                //handle success
                console.log(response);
                if(success_fn) {
                    success_fn(response);
                }
            }
            else{
                //handle success
                failure_fn(response)
                console.log(response);
            }
        })
        .catch(function (error) {
            failure_fn(error.response)
            //handle error
            console.log(error);
            // window.location = '/dataexportfail'
        });
}


export const resendVerificationCode = (user, success_fn) => {
    var apiUrl = process.env.REACT_APP_API_BASE_URL + "v1/auth/resend_verification_code";
    var bodyFormData = new FormData();
    bodyFormData.append('verification_token',user.verification_token)
    axios({
        method: 'post',
        url: apiUrl,
        data: bodyFormData,
        headers: {}
    })
        .then(function (response) {
            if (response.status === 200){
                //handle success
                console.log(response);
                if(success_fn) {
                    success_fn(response);
                }
            }
            else{
                //handle success
                console.log(response);
            }
        })
        .catch(function (error) {
            //handle error
            console.log(error.response);
            // window.location = '/dataexportfail'
        });
}


export const SendCode = (props) => {
    const classes = accountStyles();
    const [verificationCode, setVerificationCode] = React.useState("")
    const [errorMessage, setErrorMessage] = React.useState('')
    const [isLoading, setLoading] = React.useState(false)

    return (<>
        <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            mb={2}
        >
            <div style={{width:"100%"}}>
                <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                    align="center"
                >
                    Email Verification
                </Typography>
            </div>
        </Box>
        <Typography
            variant="body2"
            color="textSecondary"
            style={{marginLeft: "65px", marginBottom: "6px",
                width: "432px"
            }}
        >
            Please enter the 6 digit verification code we just sent to your email to complete verification.
        </Typography>
        {(errorMessage !== '') ?
            (<Alert severity="error" style={{maxWidth: "405px", marginLeft: "65px"}} p={1}> {errorMessage}</Alert>)
            : (null)
        }
        <Box>
            <div style={{ display: "flex", maxWidth: "680px", marginLeft: "65px"}}>
                <TextField
                    autoFocus
                    label="Verification Code"
                    margin="normal"
                    name="code"
                    defaultValue=''
                    variant="outlined"
                    value={verificationCode}
                    style={{maxWidth: "200px"}}
                    onChange={(e)=>{setVerificationCode(e.target.value)}}
                />
                <Box />
                <Button variant="contained"
                        className={classes.backButton}
                        style = {{ height:"40px", margin: "23px"}}
                        onClick={()=>{
                            setErrorMessage('')
                            resendVerificationCode(props.user, (response)=>{
                                    props.user.verification_token = response.data.verification_token
                                    // code sent
                                }
                            )}}
                >
                    Send Again
                </Button>
            </div>

        </Box>
        <Box mt={2}></Box>
        <Box mt={3}>

            {isLoading ? (
                <LoadingSpinner />
            ) : (
            <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                style={{maxWidth: "432px", marginTop:"-15px", marginLeft: "65px"}}
                onClick={()=>{
                    setLoading(true)
                    setErrorMessage('')
                    props.user.verification_code = verificationCode;
                    verifyEmail(props.user, (response)=>{
                            props.user.verification_token = response.data.verification_token
                            setErrorMessage('')
                            props.nextStep()
                            setLoading(false)
                        },(response) => {
                            if(response.data && response.data.message) {
                                setErrorMessage(response.data.message)
                            }else{
                                setErrorMessage("Error with status " + response.statusText)
                            }
                            setLoading(false)
                        }
                    )}}
            >
                Verify
            </Button>)}
        </Box>
    </>);
};



const createPassword = (user, success_fn) => {
    var apiUrl = process.env.REACT_APP_API_BASE_URL + "v1/auth/create_password";
    var bodyFormData = new FormData();
    bodyFormData.append('password', user.password)
    bodyFormData.append('verification_token',user.verification_token)
    axios({
        method: 'post',
        url: apiUrl,
        data: bodyFormData,
        headers: {}
    })
        .then(function (response) {
            if (response.status === 200){
                //handle success
                console.log(response);
                if(success_fn) {
                    success_fn(response);
                }
            }
            else{
                //handle success
                console.log(response);
            }
        })
        .catch(function (error) {
            //handle error
            console.log(error.response);
            // window.location = '/dataexportfail'
        });
}


const CreatePassword = (props) => {
    const [isLoading, setLoading] = React.useState(false)
    const [password, setPassword] = React.useState('')
    const [password2, setPassword2] = React.useState('')
    const [errorMessage, setErrorMessage] = React.useState('')
    const [errors, setErrors] = React.useState({})
    const validatePassword = (check_type="password1", value="")=> {
        var password_check =  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/;
        if(check_type === "password1") {
            setPassword(value)
            if (!value.match(password_check)) {
                setErrorMessage("Password must be at least 8 characters long and include at least 1 digit and 1 special character.")
                setErrors({"password": "Password must be at least 8 characters long and include at least 1 digit and 1 special character."})
            } else if (password2 !== '' && password2 !== value) {
                errors["password2"] = "Password does not match"
                setErrors({"password2": "Password does not match"})
            }else{
                errors["password2"] = errors["password"] = ''
                setErrorMessage('')
            }
        }
        else{
            setPassword2(value)
            if (value !== '' && value !== password) {
                errors["password2"] = "Password does not match"
                setErrors({"password2": "Password does not match"})
            }else{
                errors["password2"] = errors["password"] = ''
                setErrorMessage('')
            }
        }
    }
    return (<>
        <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            mb={3}
        >
            <div style={{width:"100%"}}>
                <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h3"
                    align="center"

                >
                    Create Password
                </Typography>
            </div>
        </Box>
        <Box m = {2} />
        <Container style={{marginLeft: "45px"}} >
            <Typography
                variant="body2"
                color="textSecondary"
                style={{
                    width: "362px"
                }}
            >
                Password must be at least 8 characters long and include at least 1 digit and 1 special character.
            </Typography>
            <Box m = {2} />
            {(errorMessage !== '') ?
                (<Alert severity="error" style={{maxWidth: "400px", marginBottom: "10px"}} p={1}> {errorMessage}</Alert>)
                : (null)
            }
            <Box style={{marginTop:"-10px"}}>
                <div>
                    <TextField
                        fullWidth
                        label="Password"
                        margin="normal"
                        name="password"
                        type="password"
                        defaultValue=''
                        variant="outlined"
                        style={{maxWidth: "432px"}}
                        onChange={(e)=>{validatePassword("password1", e.target.value);}}
                        error = {errors["password"]}
                    />
                    <TextField
                        fullWidth
                        label="Confirm Password"
                        margin="normal"
                        name="password2"
                        type="password"
                        defaultValue=''
                        variant="outlined"
                        style={{maxWidth: "432px", marginTop: "10px"}}
                        onChange={(e)=>{validatePassword("password2", e.target.value);}}
                        error = {errors["password2"]}
                    />

                </div>

            </Box>
            <Box mt={3}>
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{maxWidth: "432px", marginTop:"-10px"}}
                    onClick={()=>{
                        setErrorMessage('')
                        var password_check =  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/;
                        if(password2 !== password){
                            setErrorMessage("Password does not match")
                        }else if(!password.match(password_check)){
                            setErrorMessage("Password must be at least 8 characters long and include at least 1 digit and 1 special character.")
                        }else {
                            setLoading(true)
                            setErrorMessage("")
                            props.user.password = password
                            createPassword(props.user, (response) => {
                                window.location = "/login"
                                setLoading(false)
                            })
                        }
                    }
                    }
                >
                    Save
                </Button>)}
            </Box>
        </Container>
    </>);
};


const signUp = (user, success_fn, failure_fn) => {
    var apiUrl = process.env.REACT_APP_API_BASE_URL + "v1/auth/signup";
    var bodyFormData = new FormData();
    if(user.email === "") {
        failure_fn({"data":{"message":"Please enter your email"}})
        return
    }
    if (!(EMAIL_VALIDATION_REGEX.test(user.email))) {
        failure_fn({"data":{"message":"Please enter a valid email address"}})
        return
    }
    bodyFormData.append('email', user.email)
    bodyFormData.append('first_name',user.firstname)
    bodyFormData.append('last_name', user.lastname)
    bodyFormData.append('company_name', user.companyname)
    axios({
        method: 'post',
        url: apiUrl,
        data: bodyFormData,
        headers: {}
    })
        .then(function (response) {
            if (response.status === 200){
                //handle success
                console.log(response);
                if(success_fn) {
                    success_fn(response);
                }
            }
            else{
                failure_fn(response)
                //handle success
                console.log(response);
            }
        })
        .catch(function (error) {
            failure_fn(error.response)
            //handle error
            console.log(error);
            // window.location = '/dataexportfail'
        });
}

const RegisterView = () => {
    const classes = accountStyles();
    const user = useState({})[0];

    return (
        <div>
            <NavBar/>
            <div className={classes.banner} style={{display:"none"}}>
                <Container maxWidth="md">
                    <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="center"
                    >
                        <Box
                            alignItems="center"
                            display="flex"
                        >
                            <Tooltip title="">
                                <img
                                    alt="Auth0"
                                    className={classes.logo}
                                    src={methodIcons['LOGO']}
                                />
                            </Tooltip>
                        </Box>
                    </Box>
                </Container>
            </div>
            <Container
                className={classes.cardContainer}
                maxWidth="sm"
            >
                <div>
                <StepWizard initialStep={1} >
                    <SignUpForm user={user} />
                    <ConfirmEmail user={user} />
                    <SendCode user={user} />
                    <CreatePassword  user={user}/>
                </StepWizard>
                </div>
            </Container>
        </div>
    );
};

export default RegisterView;
