import axios from 'axios';
import { attachToken } from '../../utils/auth';

export function getUserRoles(requestData,callback, errorcallback) {
    return axios(
      attachToken({
        method: 'post',
        url: process.env.REACT_APP_API_BASE_URL + 'v1/user/user_roles',
        data: requestData,
        headers: {},
      }),
    )
      .then(function (response) {
        return response;
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  export function deleteUserRoles(requestData,callback, errorcallback) {
    return axios(
      attachToken({
        method: 'post',
        url: process.env.REACT_APP_API_BASE_URL + 'v1/user/delete_user',
        data: requestData,
        headers: {},
      }),
    )
      .then(function (response) {
        return response;
      })
      .catch(function (response) {
        console.log(response);
      });
  }
  
  export function updateUserRolesPermissions(requestData,callback, errorcallback) {
    return axios(
      attachToken({
        method: 'post',
        url: process.env.REACT_APP_API_BASE_URL + 'v1/user/update_user_permissions',
        data: requestData,
        headers: {},
      }),
    )
      .then(function (response) {
        return response;
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  export function resendActivationMail(requestData,callback, errorcallback) {
    return axios(
      attachToken({
        method: 'post',
        url: process.env.REACT_APP_API_BASE_URL + 'v1/user/resend_activation',
        data: requestData,
        headers: {},
      }),
    )
      .then(function (response) {
        return response;
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  export function sendInvites(requestData,callback, errorcallback) {
    return axios(
      attachToken({
        method: 'post',
        url: process.env.REACT_APP_API_BASE_URL + 'v1/user/send_invites',
        data: requestData,
        headers: {},
      }),
    )
      .then(function (response) {
        return response;
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  export function getDataTableConfig(callback, errorcallback) {
    return axios(
      attachToken({
        method: 'get',
        url: process.env.REACT_APP_API_BASE_URL + 'v1/table/config',
        data: {},
        headers: {},
      }),
    )
      .then(function (response) {
        return response;
      })
      .catch(function (response) {
        console.log(response);
      });
  }