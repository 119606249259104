import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {
  Box,
  IconButton,
  Popover,
  MenuList,
  MenuItem,
  Drawer,
  SvgIcon,
  Chip,
  Avatar,
  Tooltip,
  Grid,
} from '@material-ui/core';
import classes from './insights.module.css';
import ProbToRenew from './ProbToRenew';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MessageDialog from './MessageDialog';
import CardOpen from './CardOpen';
import { getCustomerDetails } from '../customers/CustomerAPI';
import CustomerInformation from '../customers/CustomerInformation';
import CloseIcon from '@material-ui/icons/Close';
import { insightsStyles } from './insights.theme';
import { useHistory } from 'react-router-dom';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useTheme } from '@material-ui/core';

const useMediaQuery = () => {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
};

const CustomerCard = ({ changeCardStatus, changeInsightStatus, ...props }) => {
  const styles = insightsStyles();
  let history = useHistory();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [showDetailedCard, setShowDetailedCard] = useState(
    props.handleDirectUrl != null && props.handleDirectUrl === props.cardDetails.company_id ? true : false,
  );
  const [dialogType, setDialogType] = useState('');
  const [showMoreInfo, setshowMoreInfo] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({ company_name: '' });
  const [dismissInsightObj, setDismissInsightObj] = useState({});
  const [hoverStatus, setHover] = useState(false);
  const [dueDateCount, setDueDateCount] = useState([0, 0]);
  const [width] = useMediaQuery();
  const textElementRef = useRef();

  const toggleDrawer = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (!open) {
      setSelectedCustomer({ company_name: '' });
      setShowDetailedCard(true);
    } else {
      let requestData = {
        company_id: props.cardDetails.company_id,
        company_name: props.cardDetails.company_name,
      };
      getCustomerDetails(requestData).then(response => {
        if (response.data.hasOwnProperty('Error')) {
          console.log('We ran into an error');
        } else {
          setSelectedCustomer(response.data.data[0]);
          setShowDetailedCard(false);
        }
      });
    }
    setshowMoreInfo(open);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = e => {
    handleClose();
    setDialogType('company');
    setOpenDialog(true);
    setShowDetailedCard(false);
  };

  const handleCloseDialog = event => {
    setShowDetailedCard(false);
    setOpenDialog(false);
  };

  const handleCloseDetailedInsightsDialog = event => {
    setShowDetailedCard(false);
    setOpenDialog(false);
    props.changeNewStatus({ company_id: props.cardDetails.company_id });
    history.push(`/insights`);
  };

  const handlePrimaryButtonClick = () => {
    setOpenDialog(false);
    if (dialogType === 'company') {
      changeCardStatus(props.cardDetails.company_id, 'deleted');
    } else if (dialogType === 'insight') {
      changeInsightStatus(dismissInsightObj);
      setShowDetailedCard(true);
    }
  };

  const handleDismissInsight = ({ company_id, item_id }) => {
    setShowDetailedCard(false);
    setOpenDialog(true);
    setDialogType('insight');
    setDismissInsightObj({
      company_id: company_id,
      item_id: item_id,
      action: 'dismissed',
      is_dismissed: true,
    });
  };

  const compareSize = () => {
    const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    setHover(compare);
  };

  const checkDateDifference = time => {
    const date1 = new Date(time);
    const date2 = new Date();
    const diffTime = date1 - date2;
    const diffDays = diffTime / (1000 * 60 * 60 * 24);
    return Math.ceil(diffDays);
  };

  const countDueDates = tasks => {
    var findTask = tasks.filter(function (result) {
      return !result.is_completed && !result.is_deleted && result.hasOwnProperty('due_date') && result.due_date !== '';
    });
    var due = 0;
    var pastStore = {};
    for (let i = 0; i < findTask.length; i++) {
      if (findTask[i].due_date === new Date().toISOString().substring(0, 10)) {
        due += 1;
      } else {
        let dayDifference = checkDateDifference(findTask[i].due_date);
        if (dayDifference < 0) {
          dayDifference = Math.abs(dayDifference);
          pastStore[dayDifference] = pastStore[dayDifference] ? pastStore[dayDifference] + 1 : 1;
        }
      }
    }
    var lists = Object.entries(pastStore);
    setDueDateCount([lists, due]);
  };

  useEffect(() => {
    if (width) {
      compareSize();
    }
    countDueDates(props.cardDetails.tasks);
  }, [width, props.cardDetails]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const isNew = props.cardDetails.insights.filter(item => item.is_new === true).length > 0 ? true : false;
  return (
    <>
      <Card className={classes.dataCardContainer} id="insights-card">
        <CardContent
          className={classes.cardContentTitle}
          onClick={e => {
            setShowDetailedCard(true);
            history.push(`/insights?company=${props.cardDetails.company_id}`);
          }}
          style={{ cursor: 'pointer', paddingRight: '0px' }}
          id="insights_card"
        >
          <Box display="flex">
            <Tooltip
              title={props.cardDetails.company_name}
              interactive
              disableHoverListener={!hoverStatus}
              style={{ fontSize: '2em' }}
            >
              <Typography
                ref={textElementRef}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: width / 5,
                }}
                gutterBottom
                variant="h5"
                component="h2"
              >
                {props.cardDetails.company_name}
              </Typography>
            </Tooltip>
            <Box flexGrow="1" />
            {isNew && (
              <Chip
                size="small"
                label="NEW"
                className={styles.newInsightCardChip}
                avatar={
                  <Avatar src={require('./../../assets/icon_insight.png')} style={{ height: '16px', width: '16px' }} />
                }
              />
            )}
          </Box>
          <Box display="flex">
            <ProbToRenew probability_to_renew={props.cardDetails.probability_to_renew} />
            <Box flexGrow="1" />
            <Tooltip
              title={
                <React.Fragment>
                  {dueDateCount[0].length > 0 &&
                    dueDateCount[0].map(view => (
                      <div>
                        <Typography style={{ fontSize: '12px' }}>{view[1]} task</Typography>
                        <Typography style={{ fontSize: '12px' }}>Past Due - {view[0]} days</Typography>
                      </div>
                    ))}

                  {dueDateCount[1] > 0 && (
                    <div style={{ marginTop: '8px' }}>
                      <Typography style={{ fontSize: '12px' }}>{dueDateCount[1]} task</Typography>
                      <Typography style={{ fontSize: '12px' }}>Due Today </Typography>
                    </div>
                  )}
                </React.Fragment>
              }
              placement="top-start"
              arrow
            >
              <div style={{ display: 'flex' }}>
                {dueDateCount[0].length > 0 && (
                  <Avatar className={styles.pastDateAvatar}>
                    <AccessTimeIcon style={{ height: '16px', width: '16px', color: theme.palette.low.main }} />
                  </Avatar>
                )}

                {dueDateCount[1] > 0 && (
                  <Avatar className={styles.dueDateAvatar}>
                    <AccessTimeIcon style={{ height: '16px', width: '16px', color: theme.palette.medium.main }} />
                  </Avatar>
                )}
              </div>
            </Tooltip>

            {openDialog && (
              <MessageDialog
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
                dialogType={dialogType}
                handlePrimaryButtonClick={handlePrimaryButtonClick}
              />
            )}
          </Box>
        </CardContent>

        <CardContent style={{ paddingLeft: '0px' }}>
          <IconButton className={classes.moreIcon} onClick={handleClick} style={{ marginTop: '-12px' }}>
            <MoreHorizIcon />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuList>
              <MenuItem
                onClick={e => {
                  handleMenuClick(e);
                }}
              >
                Delete
              </MenuItem>
            </MenuList>
          </Popover>
        </CardContent>
      </Card>
      {showDetailedCard && (
        <CardOpen
          showDetailedCard={showDetailedCard}
          handleCloseDialog={handleCloseDetailedInsightsDialog}
          cardDetails={props.cardDetails}
          handleDismissInsight={handleDismissInsight}
          changeInsightStatus={changeInsightStatus}
          toggleDrawer={toggleDrawer}
          setShowDetailedCard={setShowDetailedCard}
          handleUpdateInsight={props.handleUpdateInsight}
          handleUpdateTaskNote={props.handleUpdateTaskNote}
          handleUpdateConvert={props.handleUpdateConvert}
        />
      )}
      {showMoreInfo && (
        <Drawer
          BackdropProps={{ invisible: false }}
          open={showMoreInfo}
          anchor={'right'}
          onClose={toggleDrawer(false)}
          id="customerInfo"
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" p={2} style={{ maxWidth: '555px' }}>
            <Typography variant="h4" color="textPrimary">
              {selectedCustomer.company_name}
            </Typography>
            <IconButton onClick={toggleDrawer(false)}>
              <SvgIcon>
                <CloseIcon />
              </SvgIcon>
            </IconButton>
          </Box>
          <CustomerInformation customer={selectedCustomer} />
        </Drawer>
      )}
    </>
  );
};
export default CustomerCard;
