import React from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  dialogPaper: {
    maxWidth: '496px',
    maxHeight: '542px',
  },
  dialogPadding: {
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  dataExportImg: {
    maxHeight: '280px',
  },
});

const SOURCE_CONFIG = {
  sync: {
    title: 'Sync Now',
    buttonName: 'Sync Now',
    message:
      'Last data sync has been processed few days ago. It might take a long time to load and analyze it again. Are you sure you want to proceed and sync it now? ',
  },
  disconnect: {
    title: 'Disconnect Integration',
    buttonName: 'Disconnect',
    message:
      'Are you sure you want to disconnect? This will stop all future data transfers. You will be required to reestablish this connection to restart scheduled data transfers.',
  },
};

const IntegrationsDialog = props => {
  const classes = useStyles();
  const [open] = React.useState(props.openDialog);
  const { name } = props;
  const source = SOURCE_CONFIG[name];

  return (
    <Dialog open={open} onClose={props.closeDialog} classes={{ paper: classes.dialogPaper }}>
      <Box p={4}>
        <Typography variant="h4" color="textPrimary">{source.title}</Typography>
        <Box pt={2} pb={4}>
          {props.lastUpdate ? (
            <Typography variant="body2" color="textSecondary">
              Last data sync has been processed {props.lastUpdate} days ago. It might take a long time to load and
              analyze it again. Are you sure you want to proceed and sync it now?
            </Typography>
          ) : (
            <Typography variant="body2" color="textSecondary">{source.message}</Typography>
          )}
        </Box>
        <DialogActions style={{ padding: '0px' }}>
          <Button onClick={props.closeDialog} variant="contained">
            Cancel
          </Button>
          <Box flexGrow="1" />
          <Button onClick={props.primaryFunction} color="primary" type="submit" variant="contained">
            {source.buttonName}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default IntegrationsDialog;
