import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LoadingSpinner from '../../LoadingSpinner';
import Alert from '@material-ui/lab/Alert';
import { Box, Checkbox, Container, Divider, TextField, InputAdornment } from '@material-ui/core';
import { zendeskStyles } from './zendesk.theme';
import styles from './zendesk.module.css';
import { Search } from '@material-ui/icons';

const ZendeskExport = ({
  handleChange,
  handleBack,
  cancelFunction,
  errorMessage,
  selectOneTable,
  selectAllTables,
  selectTable,
  selectedAllTables,
  selectTableError,
  selectedTables,
  isLoading,
  exportData,
  tables,
  value,
  handleSearch,
  searchTable,
  ...rest
}) => {
  const classes = zendeskStyles();

  return (
    <Container maxWidth="sm">
      <Typography color="textPrimary" gutterBottom variant="h3" align="center">
        What do you want to export?
      </Typography>

      <Container className={styles.addTablesContainer}>
        <Box align="center">
          <Typography gutterBottom align="center" variant="body2" color="textSecondary">
            Select the tables and columns that you want to give access to.
          </Typography>
        </Box>
        {selectTableError ? (
          <Alert severity="error" style={{ maxWidth: '100%' }}>
            You must select at least one table for the export to continue
          </Alert>
        ) : null}

        <Box display="flex" alignItems="center" mt={3} mb={2}>
          <Checkbox color="primary" checked={selectedAllTables} onChange={selectAllTables} />
          <Typography variant="subtitle2" color="textPrimary"> Select All</Typography>
          <Box flexGrow={1} />
          <TextField
            disabled={isLoading}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            value={searchTable}
            placeholder="Search table name"
            onChange={evt => handleSearch(evt.target.value)}
            variant="outlined"
            size="small"
          />
        </Box>

        <Divider className={classes.divider} />
        {tables.map(table => (
          <Box key={table} display="flex" alignItems="center" >
            <Checkbox
              color="primary"
              checked={selectedTables.includes(table)}
              onChange={event => selectOneTable(event, table)}
            />
            <Typography variant="subtitle2" color="textPrimary"> {table} </Typography>
          </Box>
        ))}
      </Container>
      <Container style={{ maxWidth: '480px' }}>
        <Divider className={classes.divider} p={2} />
      </Container>

      <Container p={2} className={styles.btnExportContainer}>
        <Typography component={'div'} className={styles.btnExportTypo}>
          <Button variant="contained" onClick={cancelFunction} className={classes.backButton}>
            Cancel
          </Button>
          <Box flexGrow={1} />
          <Button
            variant="contained"
            onClick={handleBack}
            className={classes.backButton}
            style={{ marginRight: '10px' }}
          >
            Back
          </Button>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <Button variant="contained" color="primary" onClick={exportData}>
              Export
            </Button>
          )}
        </Typography>
      </Container>
    </Container>
  );
};

export default ZendeskExport;
