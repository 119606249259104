import React from "react";
import {AppBar, makeStyles, Toolbar} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      color: theme.palette.text.primary,
      boxShadow: 'none',
      borderBottom: `1px solid ${theme.palette.divider}`,
      zIndex: theme.zIndex.drawer + 100,
      backgroundColor: "white"
    },
    toolbar: {
      height: 12
    },
    link: {
      fontWeight: theme.typography.fontWeightMedium
    },
    logo: {
        margin: `auto`,
        textAlign: `center`,
        maxWidth: `50%`,
        maxHeight: `50%`,
        
      }
  }));


  const NavBar = ({ onMobileNavOpen }) => {
    const classes = useStyles();
  
  return (
    <AppBar  className={classes.root} color='transparent'>
        <Toolbar className={classes.toolbar}>
            <img    style={classes.logoHorizontallyCenter}
                    className={classes.logo}
                    src={require('./../assets/involve.png')}                   
                    alt="logo" />
        </Toolbar>
    </AppBar>
  );
};



export default NavBar;
