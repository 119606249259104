import React, {useState} from 'react';
import LoadingSpinner from '../../LoadingSpinner';
import Alert from '@material-ui/lab/Alert';
import {Box, Container, TextField, FormControlLabel, Switch, Typography, Button} from '@material-ui/core';
import {salesforceStyles} from './salesforce.theme';
import styles from './salesforce.module.css';

const SalesforceLogin = ({ setDomain, setEmail, setPassword, setToken, className, authenticationError, isLoading, cancelFunction, handleClick, loginErrorMessage, ...rest }) => {
    const classes = salesforceStyles();
    const [sandboxFlag, setSandboxFlag] = useState(false);

    return (
        <Container maxWidth="sm">
            <Typography color="textPrimary" gutterBottom variant="h3" align="center">
            Connect to Salesforce
            </Typography>
            <Box align="center">
                <Typography gutterBottom align="center" variant="body2" color="textSecondary">
                    First you need to log into Salesforce.
                </Typography>
            </Box>

            <Typography className={classes.instructions} component={'div'}>
                <Container style={{maxWidth: "480px"}}>
                    {authenticationError &&
                    (<Alert severity="error" style={{maxWidth: "100%"}} p={1}>{loginErrorMessage}</Alert>)
                    }
                    <Box display="flex">
                    <Typography variant="subtitle1" color="textSecondary" style={{fontWeight: '400', marginTop: '12px'}}>Connect to Salesforce Sandbox</Typography>
                    <Box flexGrow={1}/>
                    <FormControlLabel
                      value="sandbox"
                      control={<Switch color="primary" />}
                      label=""
                      labelPlacement="start"
                      onChange={e => setSandboxFlag(!sandboxFlag)}
                    />
                    </Box>
                    {sandboxFlag && (
                      <TextField
                      fullWidth
                      autoFocus
                      label="Domain URL"
                      margin="normal"
                      name="email"
                      type="email"
                      defaultValue=''
                      variant="outlined"
                      className={styles.textFieldLogin}
                      onChange={(e) => setDomain(e.target.value) }
                      />
                    )}
                    <TextField
                    fullWidth
                    autoFocus
                    label="Email Address"
                    margin="normal"
                    name="email"
                    type="email"
                    defaultValue=''
                    variant="outlined"
                    className={styles.textFieldLogin}
                    onChange={(e) => setEmail(e.target.value) }
                    />
                    <TextField
                    fullWidth
                    label="Password"
                    margin="normal"
                    name="password"
                    type="password"
                    defaultValue=''
                    variant="outlined"
                    className={styles.textFieldLogin}
                    onChange={(e) => setPassword(e.target.value) }
                    />
                    <TextField
                    fullWidth
                    label="Security Token"
                    margin="normal"
                    name="secret_token"
                    type="secret_token"
                    defaultValue=''
                    variant="outlined"
                    className={styles.textFieldLogin}
                    onChange={(e) => setToken(e.target.value)}
                    />
                    <Box>
                    <a underline = 'always' target="_blank" rel="noopener noreferrer" href="https://d3v-involveai.zendesk.com/hc/en-us/articles/360048974952-How-to-Find-the-Salesforce-Security-Token">
                        How to find your security token?
                    </a>
                    </Box>
                <Box mt={3}/>
                </Container>
            </Typography>

            <Container className={styles.btnLoginContainer}>
                <Button variant="contained" onClick={cancelFunction} className={classes.backButton}>
                    Cancel
                </Button>
                <Box flexGrow={1} />
                { isLoading ? <LoadingSpinner /> :
                    <Button variant="contained" color="primary" onClick={(event) => handleClick(event)}>
                    Next
                    </Button>
                }
            </Container>
        </Container>
    );
};

export default SalesforceLogin;
