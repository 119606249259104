import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box, Button, Dialog, Slider, Tooltip, TextField, SvgIcon, Container, Link } from '@material-ui/core/';
import axios from 'axios';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NavBar from '../NavBar';
import ImagePlaceHolder from './ImagePlaceholder';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#F9F9F9',
    borderRadius: '4px',
    maxWidth: '552px',
    height: '66px',
    marginTop: '16px',
    boxShadow: 'none',
  },
});

const useStylesTooltip = makeStyles(theme => ({
  arrow: {
    fontSize: '14px',
    width: 17,
    '&::before': {
      border: '1px solid #EEEEEE',
      backgroundColor: '#fff',
      boxSizing: 'border-box',
    },
  },
  tooltip: {
    backgroundColor: 'white',
    color: '#263238',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: '17px',
    textAlign: 'left',
    border: '1px solid #EEEEEE',
    borderRadius: 1,
    fontWeight: '400',
  },
}));

const FeedbackSlider = withStyles({
  root: {
    color: '#0061FF',
    height: 8,
    width: '99%',
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    marginTop: -8,
    marginLeft: -12,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    '&:focus, &:hover, &$active': {
      boxShadow: '#ccc 0 2px 3px 1px',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    color: '#d8d8d8',
    height: 8,
    borderRadius: 4,
  },
})(Slider);

export default function PredictionFeedback({ className, ...rest }) {
  const classes = useStyles();
  let custInfo = window.location.search.split('&value=');
  let probability_to_renew = parseInt(custInfo[1]);
  let company_name = custInfo[0].replace('?customer=', '').replace('%20', ' ');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [percentageValue, setPercentageValue] = useState(probability_to_renew);
  const [feedbackText, setFeedbackText] = useState('');
  const [sliderValue, setSliderValue] = useState(parseInt(probability_to_renew));
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  let tempErrMsg = "";
  if(isNaN(probability_to_renew) || company_name ==="" ){
    tempErrMsg = "Something went wrong. Please recheck the URL to submit feedback.";
  }
  const [errorMessage, setErrorMessage] = useState(tempErrMsg);

  const handleSubmit = event => {
    let optionSelected = 'None';
    event.target.parentElement.value === 'Yes' || event.target.value === 'Yes'
      ? (optionSelected = 'Yes')
      : (optionSelected = 'No');

    var apiUrl = process.env.REACT_APP_API_BASE_URL + 'v1/table/feedback';
    var feedbackData = {
      _id: 0,
      company_name: company_name,
      email: localStorage.getItem('email'),
      Value: percentageValue,
      Option_selected: optionSelected,
      Feedback: feedbackText,
    };

    axios({
      method: 'post',
      url: apiUrl,
      data: feedbackData,
      headers: {},
    })
      .then(function (response) {
        if (response.data.hasOwnProperty('Error')) {
          setErrorMessage("Something went wrong. Please contact the involve.ai team to get this fixed.");
        } else {
          setFeedbackSubmitted(true);
          setIsModalOpen(false);
          setPercentageValue(probability_to_renew);
          setFeedbackText('');
          setSliderValue(parseInt(probability_to_renew));
        }
      })
      .catch(function (response) {
        setErrorMessage("Something went wrong. Please contact the involve.ai team to get this fixed.");
      });
  };

  function ValueLabelComponent(props) {
    const { children, open, value } = props;

    const tooltipClasses = useStylesTooltip();
    let newTooltipValue = value + '%';
    return (
      <Tooltip
        open={open}
        enterTouchDelay={0}
        leaveDelay={100}
        placement="top"
        title={newTooltipValue}
        arrow
        classes={tooltipClasses}
      >
        {children}
      </Tooltip>
    );
  }

  const handleFeedbackNo = () => {
    setIsModalOpen(true);
  };
  return (
    <>
      <NavBar />
      <Container style={{ height: 'calc(100vh - 100px)', marginTop: '64px', overflow: 'visible' }}>
        <Box justifyContent="center" display="flex" style={{ width: '100%' }} mt={12}>
          {errorMessage !== ""  ? (
            <Box>
            <ImagePlaceHolder
              message={errorMessage}
              imageUrl={require('./../../assets/error-general.png')}
              altText={'Error'}
              width="140px"
              height="130px"
            />
            <Box mt={3} justifyContent="center" style={{textAlign: 'center'}}>
              <Link variant="body2" color="textSecondary" href="/customers" underline="always">
                Go to Homepage
              </Link>
            </Box>
            </Box>
          ) : (
            <>
              {feedbackSubmitted ? (
                <Box alignItems="center" m={1} mt={2} style={{ textAlign: 'center' }}>
                  <SvgIcon style={{ width: 80, height: 80 }}>
                    <CheckCircleIcon color="primary" />
                  </SvgIcon>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                    align="center"
                    style={{ marginTop: '24px' }}
                  >
                    Thank You!
                  </Typography>
                  <Box align="center" mt={3}>
                    <Typography variant="body2" color="textSecondary" align="center">
                      Your feedback has been submitted successfully
                    </Typography>
                  </Box>
                  <Box mt={3}>
                    <Link variant="body2" color="textSecondary" href="/customers" underline="always">
                      Go to Homepage
                    </Link>
                  </Box>
                </Box>
              ) : (
                <Box style={{ textAlign: 'center', whiteSpace: 'pre-wrap', overflowWrap: 'break-word',  maxWidth: '420px' }} mt={2}>
                  {!isNaN(probability_to_renew) && company_name !=="" && (
                    <>
                    <Typography variant="h4" color="textPrimary">
                      {company_name}
                    </Typography>
                      <Box mt={3}/>
                    <Typography variant="body2" p={2} color="textSecondary">
                      involve.ai Customer Health : {probability_to_renew}%
                    </Typography>
                      <Box mt={2}/>
                    <img
                      src={require('./../../assets/accuracy@2x.png')}
                      alt="Target symbol"
                      style={{ width: '140px', height: '130px' }}
                    />
                      <Box mt={3}/>
                      <Typography variant="body1">
                        Is this prediction accurate?
                      </Typography>

                      <Box display="flex" alignItems="center" mt={4}>
                        <Button
                          variant="contained"
                          className={classes.backButton}
                          style={{ marginRight: '8px' }}
                          onClick={handleFeedbackNo}
                        >
                          No
                        </Button>
                        <Box flexGrow={1} />
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginRight: '8px' }}
                          value="Yes"
                          onClick={handleSubmit}
                        >
                          Yes
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
              )}
            </>
          )}
        </Box>
      </Container>
      <Dialog maxWidth="sm" fullWidth onClose={() => setIsModalOpen(false)} open={isModalOpen}>
        <Box p={3}>
          <Typography gutterBottom variant="h3" color="textPrimary">
            Tell Us Why
          </Typography>
          <Typography gutterBottom variant="body2" color="textSecondary">
            What should the percentage be?
          </Typography>
          <Box mt={5} />
          <FeedbackSlider
            ValueLabelComponent={ValueLabelComponent}
            aria-label="custom thumb label"
            valueLabelDisplay="on"
            value={sliderValue}
            onChange={(event, val) => {
              setSliderValue(val);
              setPercentageValue(val + '%');
            }}
          />
          <Box mt={2} />
          <TextField
            id="outlined-textarea"
            label="Accuracy Feedback"
            placeholder="Please describe as detailed as possible"
            multiline
            rows={5}
            fullWidth
            variant="outlined"
            onChange={event => {
              setFeedbackText(event.target.value);
            }}
          />
          <Box display="flex" pt={2}>
            <Button variant="contained" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            <Box flexGrow={1} mr={2} />
            <Button variant="contained" color="primary" onClick={handleSubmit} value="No">
              Submit
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
