import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography, Divider, Avatar, Link, Chip } from '@material-ui/core';
import CustomAvatar from './CustomAvatar.js';
import HistoryKeyIndicators from './HistoryKeyIndicators';
import HistoryInsights from './HistoryInsights';
import NoteIcon from '@material-ui/icons/Note';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Highlighter from 'react-highlight-words';
import { historyStyles } from './history.theme';
import InvolveHighlighter from '../../mixins/InvolveHighlighter';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
    marginTop: '16px',
    marginRight: '16px',
  },
  lineBreak: {
    whiteSpace: 'pre-line',
    maxWidth: '400px',
  },
  emailContent: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    minHeight: '160px',
    maxHeight: '320px',
    overflowY: 'scroll',
  },
  content: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    minHeight: '100px',
    maxHeight: '320px',
    overflowY: 'scroll',
  },
  emailAvatar: {
    width: '32px',
    height: '32px',
    backgroundColor: '#F5F5F5',
    color: '#546E7A',
    fontSize: '14px',
  },
  customerAttributeValue: {
    color: '#263238',
    fontFamily: 'Roboto',
    fontSize: '12px',
    letterSpacing: '0.1px',
    lineHeight: '20px',
    textAlign: 'left',
    textTransform: 'uppercase',
    fontWeight: '600',
    marginTop: '12px',
    marginLeft: '16px',
  },
  low: {
    color: '#D32F2F !important',
    backgroundColor: '#FEEBEE',
    padding: '4px',
  },
  medium: {
    color: '#F67C01 !important',
    backgroundColor: '#FFF3E0',
    padding: '4px',
  },
  high: {
    color: '#09BC71 !important',
    backgroundColor: '#E7F8F1',
    padding: '4px',
  },
  doesNotExist: {
    backgroundColor: '#F5F5F5 !important',
    padding: '4px',
  },
  arrowIconClass: {
    verticalAlign: 'middle',
  },
  chipContainer: {
    padding: '8px 0px'
  }

}));

const CustomChip = withStyles({

  root: {
    color:'#66788A',
    backgroundColor: '#ECEFF1',
    fontSize: '12px',
    lineHeight: '18px',
    textAlign: 'left',
    marginRight: '6px',
    "&.active": {
      backgroundColor: 'rgba(0,97,255, 0.1)',
      color: '#0061FF'
    },
  },
})(Chip);


const imageUrls = {
  Salesforce: require('../../assets/icons/salesforce.png'),
  salesforce: require('../../assets/icons/salesforce.png'),
  Outlook: require('../../assets/icons/outlook.png'),
  Zendesk: require('../../assets/icons/zendesk_vert.png'),
  default: require('../../assets/icons/data_source.png'),
  account_insight: require('../../assets/icons/insight.png'),
  account_task: require('../../assets/icons/insight.png'),
  account_note: require('../../assets/icons/insight.png'),
  involve: require('../../assets/icons/involve.png'),
  customer_pulse_value: require('../../assets/icons/insight.png'),
  S3: require('../../assets/icons/data_source.png'),
};

const EmailData = ({ className, historyData, searchTextArr, keywordsSearch,  ...rest }) => {
  const classes = useStyles();
  const styles = historyStyles();
  const [showMoreFlag, setShowMoreFlag] = useState(false);
  const [text, setText] = useState([]);
  const [sentimentStyle, setSentimentStyle] = useState(styles.highlightClass);
  const [selectedKeyword, setSelectedKeyword] = useState ([]);
  const keywords = historyData.keywords;

   const attributeVal = attrVal => {
    switch (attrVal) {
      case 'Low':
        return <span className={classes.low}>{attrVal}</span>;
      case 'Medium':
        return <span className={classes.medium}>{attrVal}</span>;
      case 'High':
        return <span className={classes.high}>{attrVal}</span>;
      case 'Does not Exist':
        return <span className={classes.doesNotExist}>{attrVal}</span>;
      default:
        return <span className={classes.doesNotExist}>{attrVal}</span>;
    }
  };


  //if cames text from the search box
  useEffect(() => {
        setText (searchTextArr);
        setSentimentStyle (styles.highlightClass);
        setSelectedKeyword ([]);
  },[searchTextArr])

  useEffect(() => {
     let keywordArray = keywords.filter (x=> keywordsSearch.map(y=> y.label).includes(x.label));
     setSelectedKeyword(keywordArray.map (x => ({active:true, className: x.positive? styles.highlightPositiveClass : styles.highlightNegativeClass ,...x})));
     setText(keywordArray.filter(x=>x.text !=="").map(x=>x.text));
  },[keywordsSearch]);


  const handleKeywordClick = (item) => {

      let filteredKeywords = keywords.filter(x => x.label === item.label);
      let textArray = [...text].filter(x=> !searchTextArr.includes(x));
      let arraySelected = selectedKeyword.filter (x=> keywords.map(y=>y.label).includes(x.label));
      filteredKeywords.forEach(element => {

        if (arraySelected.filter(x=> x.label === element.label)[0]?.active ) {
          element.active = false;
          textArray = textArray.filter(x=> x !== element.text);
          arraySelected = arraySelected.filter(x=> x.label !== element.label);
        } else {
          if (!textArray.includes(element.text))
             textArray.push(element.text);
          element.active = true;
          arraySelected.push(element);
        }
      });
      setSelectedKeyword(arraySelected.map(x=> ({className: x.positive? styles.highlightPositiveClass : styles.highlightNegativeClass, ...x})));
      setText(textArray.filter(x=>x !==""));
  }

  return (
    <>
      <Typography variant="subtitle1" color="textPrimary" style={{ marginLeft: '16px', marginTop: '8px' }}>
      <Highlighter
        highlightClassName={styles.highlightClass}
        searchWords={['activated']}
        autoEscape={true}
        textToHighlight={historyData.subject}
      />
      </Typography>
      <Typography variant="body2" color="textSecondary" className={classes.customerAttributeValue}>
        {attributeVal(historyData.sentiment)}
      </Typography>
      {historyData.summary !== '' && (
        <>
          <Box display="flex" ml={2} mt={1}>
            <AssignmentIcon />
            <Typography variant="subtitle2"  color="textSecondary" style={{marginLeft: '8px', marginTop: '2px' }}>
              Smart Summary
            </Typography>
            <Box style={{ backgroundColor: '#e5efff' }} ml={1}>
              <Typography style={{ color: '#0061FF', fontSize: '12px', fontWeight: '500', margin: '4px 4px 0 4px' }}>
                BETA
              </Typography>
            </Box>
          </Box>
          <Box m={1} ml={6}>
            <Typography variant="subtitle2"  color="textSecondary">
              <Highlighter
                highlightClassName={styles.highlightClass}
                searchWords={searchTextArr}
                autoEscape={true}
                textToHighlight={historyData.summary}
              />
            </Typography>
            {!showMoreFlag && (
              <Box mt={1.5}>
                <Link
                  component="button"
                  variant="body2"
                  color="textSecondary"
                  underline="none"
                  onClick={() => {
                    setShowMoreFlag(true);
                  }}
                >
                  SHOW DETAILS <ArrowDropDownIcon className={classes.arrowIconClass} color="primary" />
                </Link>
              </Box>
            )}
          </Box>
        </>
      )}

      {(showMoreFlag || historyData.summary === '') && (
        <Box mt={2} ml={2}>
          {historyData.type === 'Email' ? (

            <div>
              {keywords ?
                <div>
                  <Divider style={{ marginTop: '16px' }} />
                  <div className={classes.chipContainer}>
                  {keywords.map ((element, index)=> (
                      <>
                      <CustomChip size="medium"
                              className={selectedKeyword.filter(x=> x.label === element.label)[0]?.active ?  'active' : ''}
                              label={element.label} key={element.label}  onClick={ () => handleKeywordClick (element)}
                              clickable
                          />
                          </>
                  ))}
                  </div>
                </div>
                : null}
              <div className={classes.emailContent}>
                {historyData.interaction.map((email, index) => (
                  <Box key={index}>
                    {index !== 0 && <Divider style={{ marginTop: '16px' }} />}
                    <Box display="flex" alignItems="center" m={2}>
                      {email.hasOwnProperty('sender') && email.sender !== '' && (
                        <>
                          <Avatar className={classes.emailAvatar}>{email.sender.split(' ').map(i => i.charAt(0))}</Avatar>
                          <Typography variant="subtitle2" color="textPrimary" style={{ marginLeft: '8px' }}>
                          <Highlighter
                            highlightClassName={styles.highlightClass}
                            searchWords={searchTextArr}
                            autoEscape={true}
                            textToHighlight={email.sender}
                          />
                          </Typography>
                        </>
                      )}
                      <Box flexGrow="1" />
                      <Typography variant="body2" color="textSecondary" style={{ fontSize: '12px' }}>
                      <Highlighter
                        highlightClassName={styles.highlightClass}
                        searchWords={searchTextArr}
                        autoEscape={true}
                        textToHighlight={email.timestamp}
                      />
                      </Typography>
                    </Box>
                    <Typography
                      variant="subtitle2"
                      style={{ margin: '16px'}}
                      className={classes.lineBreak}
                      color="textSecondary"
                    >

                        <InvolveHighlighter
                          highlightClassName={sentimentStyle}
                          searchWords={text}
                          autoEscape={true}
                          textToHighlight={email.body}
                          textToHighlightClassName = {selectedKeyword.map(x=> ({className: x.className, textToHighlight: x.text}))}

                          />

                      </Typography>
                  </Box>
                ))}
              </div>
            </div>
          ) : (
            <>
            {keywords ?
                <div>
                  <Divider style={{ marginTop: '16px' }} />
                  <div className={classes.chipContainer}>
                  {keywords.map ((element, index)=> (
                      <>
                      <CustomChip size="medium"
                              className={selectedKeyword.filter(x=> x.label === element.label)[0]?.active ?  'active' : ''}
                              label={element.label} key={element.label}  onClick={ () => handleKeywordClick (element)}
                              clickable
                          />
                          </>
                  ))}
                  </div>
                </div>
                : null}
            <div className={classes.content}>


              <Box display="flex" alignItems="center" m={2}>
                <Avatar style={{ height: '32px', width: '32px' }}>
                  {' '}
                  <NoteIcon style={{ height: '13px', width: '16px' }} />
                </Avatar>
                <Typography variant="subtitle2" style={{ marginLeft: '8px' }} color="textPrimary">
                  {historyData.type}
                </Typography>

                <Box flexGrow="1" />
                <Typography variant="body2" color="textSecondary" style={{ fontSize: '12px' }}>
                  {historyData.interaction[0].timestamp}
                </Typography>
              </Box>
              <Typography
                variant="subtitle2"
                style={{ margin: '16px'}}
                className={classes.lineBreak}
                 color="textSecondary"
              >
                <InvolveHighlighter
                          highlightClassName={sentimentStyle}
                          searchWords={text}
                          autoEscape={true}
                          textToHighlight={historyData.interaction[0].body}
                          textToHighlightClassName = {selectedKeyword.map(x=> ({className: x.className, textToHighlight: x.text}))}

                          />

              </Typography>
            </div>
            </>
          )}
          {historyData.summary !== '' && (
            <Box ml={2} mt={1.5}>
              <Link
                component="button"
                variant="body2"
                color="textSecondary"
                underline="none"
                onClick={() => {
                  setShowMoreFlag(false);
                }}
              >
                HIDE DETAILS <ArrowDropUpIcon className={classes.arrowIconClass} color="primary" />
              </Link>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

const HistoryRow = ({ className, historyData, searchText,keywordsSearch, ...rest }) => {
  const classes = useStyles();
  const highlightText = [searchText];
  return (
    <div className={classes.root}>
      <Grid container direction="row">
        <Grid item xs={1}>
          {imageUrls[historyData.source] === undefined ? (
            <CustomAvatar alt="Data_Source" src={imageUrls['default']} />
          ) : (
            <CustomAvatar alt="Data_Source" src={imageUrls[historyData.source]}/>
          )}
        </Grid>
        <Grid item xs={11}>
          {historyData.type === 'key_indicator' ? (
            <HistoryKeyIndicators keyIndictorData={historyData} searchTextArr={highlightText} />
          ) : (
            <>
            {historyData.type === 'insight' ? (<HistoryInsights historyData={historyData} searchTextArr={highlightText}  />) : (
              <EmailData historyData={historyData} searchTextArr={highlightText} keywordsSearch={keywordsSearch} />
            )}
            </>
          )}
        </Grid>
      </Grid>
      <Divider style={{ marginTop: '16px' }} />
    </div>
  );
};

export default HistoryRow;
