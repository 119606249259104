import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    width: 'fit-content',
    display: 'flex',
    padding: '16px',
    marginTop: '24px',
  },
  syncAllButton: {
    width: '119px',
    height: '34px',
    marginLeft: '16px',
  },
}));

export default function SyncAll(props) {
  const classes = useStyles();
  return (
    <div className={classes.root} style={{ visibility: 'hidden' }}>
      <img
        src={require('../../../assets/sync.png')}
        alt="Sync All"
        style={{ width: '32px', height: '32px', margin: 'auto' }}
      />
      <Box ml={2} style={{ height: 'fitContent' }}>
        <Typography variant="body2" color="textSecondary">Your data was synced {props.lastUpdate} days ago.</Typography>
        <Typography variant="body2" color="textSecondary">Next sync is scheduled for {props.nextUpdate}.</Typography>
      </Box>
      <Button variant="contained" color="primary" className={classes.syncAllButton} onClick={props.syncFunction}>
        SYNC ALL
      </Button>
    </div>
  );
}
