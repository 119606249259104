import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider } from '@material-ui/core/';
import DetailedChart from './DetailedChart';
import { useStyles } from './customerDataTable.theme';
import ImagePlaceHolder from './ImagePlaceholder';
import { getHealthTrendData } from './CustomerAPI.js';
import { useTheme } from '@material-ui/core';

export default function ChartProbToRenew({ className, customer, ...rest }) {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [colors, setColors] = useState([]);
  const [loadingFlag, setLoadingFlag] = useState(true);
  const [errorFlag, setErrorFlag] = useState(false);
  const styles = useStyles();

  useEffect(() => {
    let requestData = {
      company_name: customer.company_name,
      branch_name: customer.branch_name,
      location: customer.location,
    };
    if (customer.hasOwnProperty('account_id')) {
      requestData = {
        account_id: customer.account_id,
        company_name: customer.company_name,
      };
    }
    getHealthTrendData(requestData).then(response => {
      if (response.data.hasOwnProperty('response') && response.data.response !== null) {
        let tempColorArr = [];
        response.data.response[0].probability_to_renew[0].values.forEach((item, i) => {
          let tempColor = item >= 70 ? theme.palette.high.main : item < 70 && item >= 45 ? theme.palette.medium.main : theme.palette.low.main;
          tempColorArr.push(tempColor);
        });
        setColors(tempColorArr);
        setData(response.data.response[0].probability_to_renew[0]);
        setLoadingFlag(false);
      } else if (response.data.hasOwnProperty('Error')) {
        setErrorFlag(true);
      }
    });

    setLoadingFlag(false);
  }, [customer]);

  return (
    <Box>
      <Divider style={{ marginTop: '16px', marginBottom: '16px' }} />
      <Typography variant="subtitle1" color="textPrimary">
        involve.ai Customer Health
      </Typography>
      <Box mb={2} />
      {errorFlag ? (
        <Box>
          <Divider style={{ marginTop: '16px', marginBottom: '16px' }} />
          <Typography variant="body2" color="textSecondary">
            It looks like we encountered an error. Please contact the involve.ai team to get this fixed.
          </Typography>
        </Box>
      ) : (
        <>
          {loadingFlag ? (
            <Box mt={2} style={{ alignSelf: 'center' }}>
              <div className={styles.healthTrendloader}> </div>
            </Box>
          ) : (
            <>
              {data.values.length !== 0 ? (
                <DetailedChart data={data.values} labels={data.dates} color={colors} />
              ) : (
                <ImagePlaceHolder
                  message={'There is nothing here yet. Trend will be displayed after your next data refresh.'}
                  imageUrl={require('./../../assets/chart-empty.png')}
                  altText={'Empty state'}
                />
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
}
