import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { MenuItem, Select, TextField, Typography, IconButton } from '@material-ui/core';
import { userRolesStyles } from './userRolesDataTable.theme';
import RemoveUsersDialog from './removeUserDialog';

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#FAFBFF',
        },
        '&:nth-of-type(even)': {
            backgroundColor: '#FFFFFF',
        },
    },
    TableCell: {
        textAlign: 'left',
    },
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
    head: {
        color: '#546E7A',
    },
    body: {
        color: '#263238',
        fontSize: '14px',
        letterSpacing: '0.1px',
        lineHeight: '20px',
        textAlign: 'left',
        border: 'none',
    },
}))(TableCell);

const AssignRolesTableRow = (props) => {
    const classes = userRolesStyles();
    const [openDialogRemoveFlag, setOpenDialogRemoveFlag] = useState(false);
    const removeUserText = "Are you sure you want to remove this user from the invite list?"
    const [allColumns] = useState(JSON.parse(localStorage.getItem('columnsSimpleView')));

    const permissionRow = (filterName, permission) => {
        const individualFilter = allColumns.find(o => o.id === filterName);
        if (individualFilter) {
            const label = individualFilter.label;
            let text = '';
            switch (individualFilter.data_type) {
                case 'string':
                    text = permission.join(", ");
                    break;
                case 'number':
                    text = permission.length === 2 ? '$' + permission[0].toLocaleString() + ' - $' + permission[1].toLocaleString() : '';
                    break;
                case 'date':
                    text = permission.length === 2 ? formatDate(permission[0]) + ' - ' + formatDate(permission[1]) : '';
                    break;
                default:
                    break;
            }
            return (<div>
                <Typography variant="body2" color="textSecondary" display="inline"><>{label}</> : </Typography> <Typography variant="body2" display="inline"><>{text}</></Typography></div>);
        }
        return '';
    };

    const formatDate = date => {
        if (date == null) {
            return '';
        }
        return new Intl.DateTimeFormat('en').format(new Date(date));
    }

    const handleDelete = () => {
        props.deleteRole(props.rowIndex);
    }

    const openDialogRemove = () => {
        setOpenDialogRemoveFlag(true);
    }

    const closeDialogRemove = (row) => {
        setOpenDialogRemoveFlag(false);
    }

    const handleRoleChange = (selectedRole) => {
        if (selectedRole === 'admin')
            props.setAdminRole(props.rowIndex);
        else
            props.updateValue(props.rowIndex, "userRole", 'user');
    }

    return (
        <StyledTableRow key={props.rowIndex}>
            <StyledTableCell style={{ width: 360 }} component="th" scope="row">
                <TextField
                    fullWidth
                    variant="outlined"
                    value={props.email}
                    onChange={e => props.updateValue(props.rowIndex, "email", e.target.value)}
                />
            </StyledTableCell>
            <StyledTableCell style={{ width: 100 }} align="right">
                <Select
                    value={props.userRole}
                    onChange={e => handleRoleChange(e.target.value)}
                    variant="outlined"
                >
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="user">User</MenuItem>
                </Select>
            </StyledTableCell>
            <StyledTableCell style={{ width: 260 }} align="right">
                {
                    props.permissions && Object.keys(props.permissions).map((key, index) => (
                        permissionRow(key, props.permissions[key])
                    ))
                }
            </StyledTableCell>
            <TableCell style={{ border: "none" }} align={'right'}>
                {props.userRole === 'user' &&
                    <React.Fragment> <IconButton className={classes.smallEditIcon} size="small">
                        <EditIcon
                            onClick={() => {
                                props.handleEdit(props.rowIndex);
                            }}
                        />
                    </IconButton>
                    </React.Fragment>}
                <IconButton className={classes.smallEditIcon} size="small">
                    <DeleteIcon
                        onClick={() => {
                            openDialogRemove()
                        }}
                    />
                </IconButton>
            </TableCell>
            <RemoveUsersDialog
                openDialog={openDialogRemoveFlag}
                closeDialogFun={closeDialogRemove}
                removeText={removeUserText}
                handleDelete={handleDelete}
            />
        </StyledTableRow>
    );
};


export default AssignRolesTableRow;