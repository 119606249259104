import React, { useState } from 'react';
import { Box, Typography, IconButton, SvgIcon, Button, FormControl, Select } from '@material-ui/core/';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { ruleStyles } from './../rule.theme';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

const triggers = [
  {
    id: 1,
    name: 'When customer is part of the view ',
  },
];

const TriggerOptions = ({ ...props }) => {
  const styles = ruleStyles();
  let tempDefaultView = props.viewsList.filter(item=> item.is_default === true);
  const [selectedView, setSelectedView] = useState(tempDefaultView[0] ?  tempDefaultView[0]._id : 0);
  const [isAddNew, setIsAddNew] = useState(false);
  const handleViewChange = e => {
    setSelectedView(e.target.value);
  };

  const handleAdd = () => {
    selectedView === "" ? props.setSelectedViews([...props.selectedViews, 0]) : props.setSelectedViews([...props.selectedViews, selectedView]);
    setIsAddNew(false);
  }
  return (
    <Box>
      {isAddNew ? (
        <>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1" color="textPrimary">
              Add Trigger
            </Typography>
            <Box flexGrow="1" />

            <IconButton onClick={() => setIsAddNew(false)}>
              <SvgIcon>
                <CloseIcon />
              </SvgIcon>
            </IconButton>
          </Box>
          {triggers.map(option => (
            <Box p={1} display="flex" alignItems="center" key={option.id} name={option.id} className={styles.addNewBlock}>
              <Typography variant="body2" color="textPrimary">
                {option.name} &nbsp;
              </Typography>
              <FormControl variant="outlined" size="small">
                <Select native value={selectedView} onChange={e => handleViewChange(e)} style={{fontSize: '14px'}}>
                <option value={'0'} key={'0'} id={'0'}>{"All Customers"}</option>
                  {props.viewsList.map(view => (
                    <option value={view._id} key={view._id} id={view._id}>{view.name}</option>
                  ))}
                </Select>
              </FormControl>
              <Box flexGrow="1" />
              <Button variant="contained" color="primary" onClick={handleAdd}>
                Add
              </Button>
            </Box>
          ))}
        </>
      ) : (
        <Box mt={1}>
          <Button
            variant="text"
            className={styles.addNewBtn}
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => setIsAddNew(true)}
          >
            Add Trigger
          </Button>
        </Box>
      )}
    </Box>
  );
};

const Trigger = ({ ...props }) => {
  const [isEditing, setIsEditing] = useState(false);
  const styles = ruleStyles();
  let tempSelView = props.viewsList.filter(item => item._id === props.info);

  const [selectedView, setSelectedView] = useState(
    tempSelView && tempSelView[0] ? tempSelView[0] : { _id: 0, name: 'All Customers' },
  );
  const handleCancel = () => {
    setSelectedView(tempSelView && tempSelView[0] ? tempSelView[0] : { _id: 0, name: 'All Customers' })
    setIsEditing(false);
  };
  const handleViewChange = e => {
    let tempArr = props.viewsList.filter(item => item._id === e.target.value);
    setSelectedView(tempArr && tempArr[0] ? tempArr[0]: { _id: 0, name: 'All Customers' });
  };

  const handleSave = () => {
    let tempSelectedViews = [...props.selectedViews];
    tempSelectedViews[props.index] = selectedView._id;
    props.setSelectedViews(tempSelectedViews);
    setIsEditing(false);
  }

  const handleDelete = () => {
    let tempSelectedViews = [...props.selectedViews];
    tempSelectedViews.splice(tempSelectedViews.findIndex(item => item === selectedView._id), 1);
    props.setSelectedViews(tempSelectedViews);
  }

  return (
    <>
      {isEditing ? (
        <>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1" color="textPrimary">
            Edit Trigger
          </Typography>
          <Box flexGrow="1" />

          <IconButton onClick={ handleCancel}>
            <SvgIcon>
              <CloseIcon />
            </SvgIcon>
          </IconButton>
        </Box>
        <Box mt={1} p={1.5} display="flex" alignItems="center" border={1} borderColor="text.secondary">
          <Typography variant="body2" color="textPrimary">
            {triggers[0].name} &nbsp;
          </Typography>
          <FormControl variant="outlined" size="small">
            <Select native value={selectedView._id} onChange={e => handleViewChange(e)} style={{fontSize: '14px'}}>
              <option value={'0'} key={'0'} id={'0'}>{"All Customers"}</option>
              {props.viewsList.map(view => (
                <option value={view._id} key={view._id} id={view._id}>{view.name}</option>
              ))}
            </Select>
          </FormControl>
          <Box flexGrow="1" />
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Box>
        </>
      ) : (
        <Box p={1.5} display="flex" alignItems="center" className={styles.addedBlock}>
          <Typography variant="body2" color="textPrimary">
            {triggers[0].name}&nbsp;<b>{selectedView.name}</b>
          </Typography>
          <Box flexGrow="1" />
          <IconButton size="small" aria-label="edit-trigger" onClick={() => setIsEditing(true)}>
            <EditIcon  fontSize="small"/>
          </IconButton>
          <IconButton size="small" style={{ marginLeft: '8px', alignItems: 'flex-end' }} aria-label="delete-trigger" onClick={handleDelete}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </>
  );
};

const Triggers = ({ ...props }) => {

  return (
    <Box mt={2}>
        <Typography variant="subtitle1" color="textPrimary">
          Trigger
        </Typography>
        {props.selectedViews.map((view, index) => (
          <Box key={view} mt={1} id={view}>
            <Trigger info={view} viewsList={props.viewsList} setSelectedViews ={props.setSelectedViews} index ={index} selectedViews={props.selectedViews}/>
          </Box>
        ))}
        <TriggerOptions viewsList={props.viewsList} setSelectedViews ={props.setSelectedViews} selectedViews={props.selectedViews}/>
    </Box>
  );
};
export default Triggers;
