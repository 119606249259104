import React, { useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import AssignRolesTableRow from './assignRolesTableRow';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import { Box } from '@material-ui/core';
import EditUserDialog from './editUserDialog';


const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
        borderBottomWidth: 0
    },
    tableHead: {
        color: '#546E7A',
        backgroundColor: 'white',
        textAlign: 'left',
        fontSize: '12px',
        lineHeight: '14px',
        paddingTop: '8px',
        paddingBottom: '8px',
    },
    TableContainer: {
        borderBottomWidth: 0
    }
});

const AssignRolesTable = (props) => {
    const classes = useStyles2();
    const [openDialogEditFlag, setOpenDialogEditFlag] = useState(false);
    // const [selectedRole, setSelectedRole] = useState({})    
    const [selectedRoleIndex, setSelectedRoleIndex] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [userPermissions, setUserPermissions] = useState({});
    const [allColumns] = useState(JSON.parse(localStorage.getItem('columnsSimpleView')));

    const updateValue = (rowIndex, rowCol, value) => {
        let temp_state = [...props.userRoles];
        let temp_element = { ...temp_state[rowIndex] };
        temp_element[rowCol] = value;
        temp_state[rowIndex] = temp_element;
        props.setUserRoles(temp_state);
    }

    const setAdminRole = (rowIndex) => {
        let temp_state = [...props.userRoles];
        let temp_element = { ...temp_state[rowIndex] };
        temp_element['userRole'] = 'admin';
        temp_element['permissions'] = {};
        temp_state[rowIndex] = temp_element;
        props.setUserRoles(temp_state);
    }

    const deleteRole = (rowIndex) => {
        let temp_state = [...props.userRoles];
        temp_state.splice(rowIndex, 1);
        props.setUserRoles(temp_state);
    }

    const handleEdit = (rowIndex) => {
        setSelectedRoleIndex(rowIndex);
        let row = props.userRoles[rowIndex];
        setUserPermissions(row.permissions)

        openDialogEditFun();
    };

    const closeDialogEditFun = () => {
        setErrorMessage('');
        setOpenDialogEditFlag(false);
    };

    const openDialogEditFun = () => {
        setOpenDialogEditFlag(true);
    };

    const handleSaveChanges = (permissions) => {
        //validations
        var error = {};
        let tempPermissions = Object.assign({}, permissions);

        Object.keys(tempPermissions).map((key, index) => {
            let permission = tempPermissions[key];
            const individualFilter = allColumns.find(o => o.id === key);
            switch (individualFilter.data_type) {
                case 'number':
                    if (!isNaN(permission[0]) && permission[0] != null && !isNaN(permission[1]) && permission[1] != null) {
                        if (Number(permission[0]) > Number(permission[1]))
                            error[individualFilter.id + 'MinMax'] = individualFilter.label + ' To must be greater than or equal to From';
                    } else {
                        if (isNaN(permission[0]) || permission[0] == null) {
                            error[individualFilter.id + 'Min'] = individualFilter.label + " from must be a number"
                        }
                        if (isNaN(permission[1]) || permission[1] == null) {
                            error[individualFilter.id + 'Max'] = individualFilter.label + " to must be a number"
                        }
                    }
                    break;
                case 'date':
                    if (permission[0] != null && permission[1] != null) {
                        if (permission[1] < permission[0])
                            error[individualFilter.id + 'MinMax'] = individualFilter.label + ' To must be greater than or equal to From';
                    } else {
                        error[individualFilter.id + 'values'] = individualFilter.label + ' must be valid dates';
                    }
                    break;

                default:
                    break;
            }
        });

        if (Object.keys(error).length !== 0) {
            setErrorMessage(error);
            return;
        }

        updateValue(selectedRoleIndex, "permissions", tempPermissions);
        closeDialogEditFun();
    }


    return (
        <Box>
            <TableContainer component={Paper}>
                <Table className={classes.table} style={{ borderBottom: 'none' }}>
                    <TableHead>
                        <TableRow style={{ height: 32 }}>
                            <TableCell style={{ width: 360 }} className={classes.tableHead}>Email</TableCell>
                            <TableCell style={{ width: 100 }} className={classes.tableHead}>Role</TableCell>
                            <TableCell style={{}} className={classes.tableHead}>Permissions</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ borderBottom: 'none' }}>
                        {
                            props.userRoles.map((row, index) => (
                                // tablerow
                                <AssignRolesTableRow
                                    email={row.email}
                                    userRole={row.userRole}
                                    permissions={row.permissions}
                                    rowIndex={index}
                                    updateValue={updateValue}
                                    deleteRole={deleteRole}
                                    handleEdit={handleEdit}
                                    setAdminRole={setAdminRole}
                                />
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <EditUserDialog
                openDialog={openDialogEditFlag}
                closeDialogFun={closeDialogEditFun}
                userRole='user'
                handleSaveChanges={handleSaveChanges}
                onlyEditPermissions={true}
                errorMessage={errorMessage}
                userPermissions={userPermissions}
            />
        </Box>
    );
};

export default AssignRolesTable;