import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LoadingSpinner from '../../LoadingSpinner';
import Alert from '@material-ui/lab/Alert';
import { Box, Container, TextField } from '@material-ui/core';
import { zendeskStyles } from './zendesk.theme';
import styles from './zendesk.module.css';

const ZendeskLogin = ({
  ...rest
}) => {
  const classes = zendeskStyles();

  return (
    <Container maxWidth="sm">
      <Typography color="textPrimary" gutterBottom variant="h3" align="center">
        Connect Zendesk
      </Typography>
      <Box align="center">
        <Typography gutterBottom align="center" variant="body2" color="textSecondary">
          First you need to log into Zendesk.
        </Typography>
      </Box>

      <Typography className={classes.instructions} component={'div'}>
        <Container style={{ maxWidth: '480px' }}>
          {rest.authenticationError ? (
            <Alert severity="error" style={{ maxWidth: '100%' }} p={1}>
              Incorrect credentials
            </Alert>
          ) : null}
          <Box>
            <TextField
              label="Subdomain"
              margin="normal"
              name="subdomain"
              type="subdomain"
              defaultValue=""
              value= {rest.subdomain}
              variant="outlined"
              className={styles.textFieldLogin}
              style={{ width: '328px' }}
              onChange={e => rest.setSubdomain(e.target.value)}
            />
            <span style={{ textAlign: 'center', verticalAlign: 'middle', lineHeight: '79px', paddingLeft: '5px' }}>
              .zendesk.com
            </span>
          </Box>
          <TextField
            fullWidth
            autoFocus
            label="Email Address"
            margin="normal"
            name="email"
            type="email"
            defaultValue=""
            value={rest.email}
            variant="outlined"
            className={styles.textFieldLogin}
            onChange={e => rest.setEmail(e.target.value)}
          />
          <TextField
            fullWidth
            label="Security Token"
            margin="normal"
            name="secret_token"
            type="secret_token"
            defaultValue=""
            variant="outlined"
            value= {rest.token}
            className={styles.textFieldLogin}
            onChange={e => rest.setToken(e.target.value)}
          />
          <Box>
            <a
              underline="always"
              target="_blank"
              rel="noopener noreferrer"
              href="https://d3v-involveai.zendesk.com/hc/en-us/articles/360055357952"
            >
              How to find a security token
            </a>
          </Box>
          <Box mt={3} />
        </Container>
      </Typography>

      <Container className={styles.btnLoginContainer}>
        <Button variant="contained" onClick={rest.cancelFunction} className={classes.backButton}>
          Cancel
        </Button>
        <Box flexGrow={1} />
        {rest.isLoading ? (
          <LoadingSpinner />
        ) : (
          <Button variant="contained" color="primary" onClick={event => rest.handleClick(event)}>
            Next
          </Button>
        )}
      </Container>
    </Container>
  );
};

export default ZendeskLogin;
