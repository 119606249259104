import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, LinearProgress, Typography } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    color: '#263238',
  },
  barProgressColorLow: {
    backgroundColor: '#D32F2F',
  },
  barProgressColorMedium: {
    backgroundColor: '#F67C01',
  },
  barProgressColorHigh: {
    backgroundColor: '#09BC71',
  },
  barColorLow: {
    backgroundColor: '#FEEBEE !important',
  },
  barColorMedium: {
    backgroundColor: '#FFF3E0 !important',
  },
  barColorHigh: {
    backgroundColor: '#CAEEE3 !important',
  },
  arrowClass: {
    width: '10px',
    height: '10px',
  },
  increasedDelta: {
    color: '#09BC71',
    fontSize: '14px',
  },
  decreasedDelta: {
    color: '#D32F2F',
    fontSize: '14px',
  },
}));

const ProbToRenew = ({ className, probability_to_renew, ...rest }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography color="textPrimary" variant="body2">{probability_to_renew}%  </Typography>
      <Box style={{width: '60px'}} m={1}>
      {(probability_to_renew >= '70' || probability_to_renew === '100%') && (
        <LinearProgress
          classes={{
            barColorPrimary: classes.barProgressColorHigh,
          }}
          className={classes.barColorHigh}
          value={parseInt(probability_to_renew)}
          variant="determinate"
        />
      )}
      {probability_to_renew >= '45' && probability_to_renew < '70' && (
        <LinearProgress
          classes={{
            barColorPrimary: classes.barProgressColorMedium,
          }}
          className={classes.barColorMedium}
          value={parseInt(probability_to_renew)}
          variant="determinate"
        />
      )}
      {probability_to_renew < '45' && probability_to_renew !== '100%' && (
        <LinearProgress
          classes={{
            barColorPrimary: classes.barProgressColorLow,
          }}
          className={classes.barColorLow}
          value={parseInt(probability_to_renew)}
          variant="determinate"
        />
      )}
      </Box>
    </Box>
  );
};

export default ProbToRenew;
