import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Bar } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/core';
import {FormatNumber} from '../../../common/FormatNumber';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    // height: '230px',
    // width: '100%'
  },
}));

const Chart = ({ data: dataProp, blockName, className, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  function createDiagonalPattern(color = 'black') {
    let shape = document.createElement('canvas');
    shape.width = 10;
    shape.height = 10;
    let c = shape.getContext('2d');
    c.strokeStyle = color;
    c.lineWidth = 2;
    c.beginPath();
    c.moveTo(2, 0);
    c.lineTo(10, 8);
    c.stroke();
    c.beginPath();
    c.moveTo(0, 8);
    c.lineTo(2, 10);
    c.stroke();
    return c.createPattern(shape, 'repeat');
  }

  const data = {
    datasets: [
      {
        label: 'Likely to Upsell',
        backgroundColor: dataProp.likely_to_upsell.map((item, index) => {
          return index === dataProp.likely_to_upsell.length - 1
            ? createDiagonalPattern(theme.palette.high.main)
            : theme.palette.high.main;
        }),
        data: dataProp.likely_to_upsell,
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        borderColor: theme.palette.high.main,
        borderWidth: dataProp.likely_to_upsell.map((item, index) => {
          return index === dataProp.likely_to_upsell.length - 1 ? 1 : 0;
        }),
      },
      {
        label: 'Likely to Renew',
        backgroundColor: dataProp.likely_to_renew.map((item, index) => {
          return index === dataProp.likely_to_renew.length - 1
            ? createDiagonalPattern(theme.palette.medium.main)
            : theme.palette.medium.main;
        }),
        data: dataProp.likely_to_renew,
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        borderColor: theme.palette.medium.main,
        borderWidth: dataProp.likely_to_renew.map((item, index) => {
          return index === dataProp.likely_to_renew.length - 1 ? 1 : 0;
        }),
      },
      {
        label: 'Likely to Churn',
        backgroundColor: dataProp.likely_to_churn.map((item, index) => {
          return index === dataProp.likely_to_churn.length - 1
            ? createDiagonalPattern(theme.palette.low.main)
            : theme.palette.low.main;
        }),
        data: dataProp.likely_to_churn,
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        borderColor: theme.palette.low.main,
        borderWidth: dataProp.likely_to_churn.map((item, index) => {
          return index === dataProp.likely_to_churn.length - 1 ? 1 : 0;
        }),
      },
    ],
    labels: dataProp.labels,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cornerRadius: 20,
    legend: {
      display: false,
    },
    layout: {
      padding: 0,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider,
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            precision: 0,
            maxTicksLimit: 5,
            callback: function (value, index, values) {
              if (blockName === 'revenue') {
                return '$' + FormatNumber(value);
              } else {
                return FormatNumber(value);
              }
            },
          },
        },
      ],
    },
    tooltips: {
      enabled: true,
      displayColors: false,
      mode: 'single',
      intersect: false,
      caretSize: 10,
      yPadding: 20,
      xPadding: 20,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.background.default,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        title: () => {},
        label: tooltipItem => {
          let label = `${tooltipItem.yLabel}`;
          if (blockName === 'revenue') {
            return '$' + FormatNumber(label);
          } else {
            return FormatNumber(label);
          }
        },
      },
    },
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Bar data={data} options={options} />
    </div>
  );
};

Chart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
};

export default Chart;
