import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import DeleteIcon from '@material-ui/icons/Delete';
import DoughnutChart from './DoughnutChart';
import GroupedBarChart from './GroupedBarChart';
import StatsGrid from './StatsGrid';
import { Box, Grid, Typography, Container, TextField, Button } from '@material-ui/core';
import { getBlockStats, getChartData, updateWidget } from '../../ReportsAPI';
import { reportsStyles } from './../../reports.theme';
import { useParams } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    border: '1px solid #EEEEEE',
    borderRadius: '4px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem',
    paddingBottom: '0',
  },
  spacer: {
    flexGrow: 1,
  },
  body: {
    padding: '0.5rem',
    flexGrow: 1,
  },
  chart: {
    height: 200,
    marginTop: '32px',
  },
});

function TitleSection(props) {
  const [editFlag, setEditFlag] = useState(false);
  const [savedTitle, setSavedTitle] = useState(props.name);
  const [unsavedTitle, setUnsavedTitle] = useState(props.name);
  const handleSave = () => {
    setSavedTitle(unsavedTitle);
    updateWidget({
      report_id: props.reportId,
      component_id: props.id,
      name: unsavedTitle,
      action: 'update',
    }).then(response => {
      if (response.data.response && response.data.response === 'success') {
        // setErrorFlag(false);
        setEditFlag(false);
      } else {
        // setErrorFlag(true);
      }
    });
  };
  const cancelEdit = () => {
    setUnsavedTitle(savedTitle);
    setEditFlag(false);
  };
  return (
    <Box display="flex" alignItems="center">
      {editFlag ? (
        <Box display="flex">
          <TextField
            fullWidth
            autoFocus
            label=""
            size="small"
            margin="normal"
            name="report_name"
            type="text"
            defaultValue=""
            value={unsavedTitle}
            variant="outlined"
            onChange={e => setUnsavedTitle(e.target.value)}
            onKeyPress={ev => {
              if (ev.key === 'Enter') {
                handleSave();
                ev.preventDefault();
              }
            }}
          />
          <Box ml={2} alignSelf="center" pt={1} display="flex">
            <Button
              onClick={handleSave}
              color="primary"
              type="submit"
              variant="contained"
              size="small"
              style={{ height: '38px' }}
              aria-label="widget-rename-save"
            >
              Save
            </Button>
            <IconButton onClick={cancelEdit} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <>
          <Typography variant="subtitle1">{savedTitle}</Typography>{' '}
          <IconButton
            size="small"
            style={{ marginLeft: '8px', alignItems: 'flex-end' }}
            onClick={() => {
              setEditFlag(true);
            }}
          >
            <EditIcon size="small" />
          </IconButton>
        </>
      )}
    </Box>
  );
}

export default function Widget({ id, blockConfig, duration, recordCount, filters, onRemoveItem, className, ...rest }) {
  const classes = useStyles();
  const styles = reportsStyles();

  const calcConfig = blockConfig.components.filter(item => item.type === 'calculation');
  const barConfig = blockConfig.components.filter(item => item.type === 'bar');
  const doughnutConfig = blockConfig.components.filter(item => item.type === 'doughnut');
  const [statsData, setStatsData] = useState([]);
  const [doughnutData, setDoughnutData] = useState({});
  const [historicalStats, setHistoricalStats] = useState({});
  const [statsDataLoading, setStatsDataLoading] = useState(true);
  const [chartDataLoading, setChartDataLoading] = useState(true);
  const { reportId } = useParams();

  useEffect(() => {
    setStatsDataLoading(true);
    getBlockStats({ block: blockConfig.name, filters: filters, report_id: reportId }).then(response => {
      if (response.data.hasOwnProperty('data')) {
        setStatsData(response.data.data);
        let tempResponse = [...response.data.data.values];
        let tempData = {
          datasets: [
            {
              data: response.data.data.in_percentage,
              backgroundColor: ['#16C37E', '#F78803', '#D32F2F'],
            },
          ],
          labels: tempResponse.splice(0, 3),
          total: response.data.data.values[3],
        };

        setDoughnutData(tempData);
        setStatsDataLoading(false);
      } else {
        setStatsDataLoading(false);
      }
    });
  }, [filters]);

  useEffect(() => {
    setChartDataLoading(true);
    if (barConfig.length > 0) {
      getChartData({
        block: blockConfig.name,
        metrics: barConfig[0].metrics,
        duration: duration,
        filters: filters,
        report_id: reportId,
      }).then(response => {
        if (response.data.data) {
          let tempObj = {};
          tempObj.labels = response.data.data[0].dates;
          response.data.data.forEach((item, i) => {
            tempObj[item.metric] = item.values;
          });
          setHistoricalStats(tempObj);
          setChartDataLoading(false);
        } else {
          setChartDataLoading(false);
        }
      });
    }
  }, [duration, filters]);

  return (
    <>
      <div className={classes.header}>
        <DragHandleIcon style={{ color: '#546E7A', marginRight: '16px', cursor: 'grab' }} className="widget-handle" />
        <TitleSection name={calcConfig[0].name} reportId={reportId} id={calcConfig[0].component_id} />
        <div className={classes.spacer} />
        <IconButton aria-label="delete" onClick={() => onRemoveItem(id, calcConfig[0].name)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </div>
      <Box ml={6} mt={-1}>
        <Typography variant="body2" color="textSecondary">
          for {recordCount} customers
        </Typography>
      </Box>
      <Container maxWidth={false} style={{ paddingLeft: '48px' }}>
        {(statsDataLoading || chartDataLoading) && <div className={styles.chartDataloader}> </div>}
        {!statsDataLoading && !chartDataLoading && (
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ marginBottom: '10px' }}>
              <StatsGrid data={statsData} blockName={blockConfig.name} />
            </Grid>
            {doughnutConfig.length > 0 && (
              <Grid item lg={4} xl={3} xs={12}>
                <TitleSection name={doughnutConfig[0].name} reportId={reportId} id={doughnutConfig[0].component_id} />
                <Box pt={2} pb={2} style={{ height: '70%' }}>
                  <DoughnutChart data={doughnutData} blockName={blockConfig.name} />
                </Box>
              </Grid>
            )}

            {barConfig.length > 0 && (
              <Grid item lg={8} xl={9} xs={12} style={{ overflow: 'auto' }}>
                <TitleSection name={barConfig[0].name} reportId={reportId} id={barConfig[0].component_id} />
                <GroupedBarChart className={classes.chart} data={historicalStats} blockName={blockConfig.name} />
              </Grid>
            )}
          </Grid>
        )}
      </Container>
    </>
  );
}
