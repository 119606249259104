import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {Box} from '@material-ui/core/';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import View from '../view/ReportView.js'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PreviewModal = ({  open, handleClose, ...rest }) => {

  return (
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <Box display="flex" pl={3} pt={2} pr={2}>
        <Typography variant="h4" > Preview </Typography>
        <Box flexGrow="1"/>
        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
          <CloseIcon />
        </IconButton></Box>
        <View />
      </Dialog>
  );
}
export default PreviewModal;
