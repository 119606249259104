import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, LinearProgress } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    color: '#263238',
  },
  barProgressColorLow: {
    backgroundColor: '#D32F2F',
  },
  barProgressColorMedium: {
    backgroundColor: '#F67C01',
  },
  barProgressColorHigh: {
    backgroundColor: '#09BC71',
  },
  barColorLow: {
    backgroundColor: '#FEEBEE !important',
  },
  barColorMedium: {
    backgroundColor: '#FFF3E0 !important',
  },
  barColorHigh: {
    backgroundColor: '#CAEEE3 !important',
  },
  arrowClass: {
    width: '10px',
    height: '10px',
  },
  increasedDelta: {
    color: '#09BC71',
    fontSize: '14px',
  },
  decreasedDelta: {
    color: '#D32F2F',
    fontSize: '14px',
  },
}));

const CustomerProbToRenew = ({ className, customer, ...rest }) => {
  const classes = useStyles();

  const probDelta = customer.probability_to_renew_trend;
  return (
    <Box className={classes.root}>
      <Box display="flex">
        <span style={{paddingBottom: '4px'}}> {customer.probability_to_renew}% </span>
        <Box flexGrow="1" />
        {probDelta === 0 && <span className={classes.noChange}>{probDelta}</span>}
        {probDelta > 0 && (
          <span className={classes.increasedDelta}>
            <ArrowUpwardIcon className={classes.arrowClass} />+{probDelta}%
          </span>
        )}
        {probDelta < 0 && (
          <span className={classes.decreasedDelta}>
            <ArrowDownwardIcon className={classes.arrowClass} /> {probDelta}%
          </span>
        )}
      </Box>
      {(customer.probability_to_renew >= '70' || customer.probability_to_renew === '100%') && (
        <LinearProgress
          classes={{
            barColorPrimary: classes.barProgressColorHigh,
          }}
          className={classes.barColorHigh}
          value={parseInt(customer.probability_to_renew)}
          variant="determinate"
        />
      )}
      {customer.probability_to_renew >= '45' && customer.probability_to_renew < '70' && (
        <LinearProgress
          classes={{
            barColorPrimary: classes.barProgressColorMedium,
          }}
          className={classes.barColorMedium}
          value={parseInt(customer.probability_to_renew)}
          variant="determinate"
        />
      )}
      {customer.probability_to_renew < '45' && customer.probability_to_renew !== '100%' && (
        <LinearProgress
          classes={{
            barColorPrimary: classes.barProgressColorLow,
          }}
          className={classes.barColorLow}
          value={parseInt(customer.probability_to_renew)}
          variant="determinate"
        />
      )}
    </Box>
  );
};

export default CustomerProbToRenew;
