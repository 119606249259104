import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LoadingSpinner from '../../LoadingSpinner';
import Alert from '@material-ui/lab/Alert';
import { Box, Checkbox, Container, Divider, TextField, InputAdornment, SvgIcon, IconButton } from '@material-ui/core';
import { salesforceStyles } from './salesforce.theme';
import styles from './salesforce.module.css';
import { Search as SearchIcon } from 'react-feather';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const TablesWithColumn = React.memo(({ tableInfo, index, handleSelect, ...rest }) => {
  const classes = salesforceStyles();
  const [expandedFlag, setExpandedFlag] = useState(false);
  return (
    <>
      {tableInfo.columns.length > 0 && (
        <Box>
          <Box display="flex">
            <Checkbox
              className={classes.checkBoxDimensions}
              checkedIcon={<CheckBoxIcon />}
              checked={tableInfo.selected_columns.length === tableInfo.original_columns.length}
              indeterminateIcon={<IndeterminateCheckBoxIcon className={classes.checkBoxIndeterminate} />}
              indeterminate={
                tableInfo.selected_columns.length > 0 &&
                tableInfo.selected_columns.length !== tableInfo.original_columns.length
              }
              color="primary"
              classes={{
                root: classes.dropdownMultiSelect,
              }}
              id={tableInfo.table_name}
              onChange={e => {
                handleSelect(e.target.id, 'table', tableInfo.columns);
              }}
            />
            <Typography variant="subtitle2" style={{ margin: 'auto' }} color="textPrimary">
              {tableInfo.table_name}
            </Typography>
            <Box flexGrow={1} />
            <Box style={{ margin: 'auto' }}>
              <IconButton aria-label="expand row" size="small" onClick={() => setExpandedFlag(!expandedFlag)}>
                {expandedFlag ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            </Box>
          </Box>
          {expandedFlag && <ListOfColumns tableInfo={tableInfo} handleSelect={handleSelect} />}
          <Divider />
        </Box>
      )}
    </>
  );
});

const ListOfColumns = React.memo(({ tableInfo, handleSelect, ...rest }) => {
  const classes = salesforceStyles();

  return (
    <Box ml={2}>
      {tableInfo.columns.map((column, i) => (
        <Box display="flex" key={i}>
          <Checkbox
            className={classes.checkBoxDimensions}
            checkedIcon={<CheckBoxIcon />}
            checked={tableInfo.selected_columns.indexOf(column) !== -1}
            color="primary"
            classes={{
              root: classes.dropdownMultiSelect,
            }}
            id={column}
            key={column}
            onChange={e => {
              handleSelect(e.target.id, 'column', tableInfo.table_name);
            }}
          />
          <Typography variant="subtitle2" style={{ margin: 'auto' }} color="textPrimary">
            {column}
          </Typography>
          <Box flexGrow={1} />
        </Box>
      ))}
    </Box>
  );
});

const StepThree = ({
  handleBack,
  cancelFunction,
  errorMessage,
  isLoading,
  value,
  exportData,
  salesforceObjData,
  handleSelect,
  searchTables,
  handleSearchStep3,
  ...props
}) => {
  const classes = salesforceStyles();

  return (
    <Container maxWidth="sm">
      <Typography color="textPrimary" gutterBottom variant="h3" align="center">
        Which fields you want to export?
      </Typography>
      <Box align="center">
        <Typography gutterBottom align="center" variant="body2" color="textSecondary">
          Select columns you want to give access to. Please make sure to select the Primary key, Foreign keys and
          timestamps on all objects.
        </Typography>
      </Box>

      {errorMessage !== '' && (
        <Alert severity="error" style={{ maxWidth: '100%' }}>
          {errorMessage}
        </Alert>
      )}

      <Box display="flex" mt={2}>
        <Box flexGrow={1} />
        <TextField
          className={classes.searchField}
          InputProps={{
            classes: {
              root: classes.filterDimensions,
            },
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          placeholder="Search table or column name..."
          variant="outlined"
          size="small"
          onChange={handleSearchStep3}
        />
      </Box>
      <Box mt={2.5} />

      <Divider />
      <Box className={errorMessage === '' ? styles.exportList : styles.exportListSmaller}>
        {salesforceObjData.map((dataRow, i) => (
          <TablesWithColumn tableInfo={dataRow} index={i} key={i} handleSelect={handleSelect} />
        ))}
      </Box>
      <Box className={classes.fixedButtons}>
        <Divider />
        <Box mb={2} />
        <Typography component={'div'} className={styles.btnExportTypo}>
          <Button variant="contained" onClick={cancelFunction} className={classes.backButton}>
            Cancel
          </Button>
          <Box flexGrow={1} />
          <Button
            variant="contained"
            onClick={handleBack}
            className={classes.backButton}
            style={{ marginRight: '10px' }}
          >
            Back
          </Button>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                exportData();
              }}
            >
              Export
            </Button>
          )}
        </Typography>
      </Box>
    </Container>
  );
};

export default StepThree;
