import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { Box, Typography, Container, TextField, Button } from '@material-ui/core';
import { getChartData, updateWidget } from '../../ReportsAPI';
import GroupedChart from './GroupedChart';
import CategoricalChart from './CategoricalChart';
import { reportsStyles } from './../../reports.theme';
import ImagePlaceHolder from './../../../customers/ImagePlaceholder';
import EditIcon from '@material-ui/icons/Edit';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    border: '1px solid #EEEEEE',
    borderRadius: '4px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem',
  },
  spacer: {
    flexGrow: 1,
  },
  body: {
    padding: '0.5rem',
    flexGrow: 1,
  },
  chart: {
    height: 200,
  },
});

export default function ChartWidget({
  id,
  duration,
  blockConfig,
  recordCount,
  filters,
  onRemoveItem,
  className,
  ...rest
}) {
  const classes = useStyles();
  const styles = reportsStyles();
  const { reportId } = useParams();
  const barConfig = blockConfig.components.filter(item => item.type === 'bar');
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorFlag, setErrorFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [unsavedTitle, setUnsavedTitle] = useState(barConfig[0].name);
  const [savedTitle, setSavedTitle] = useState(barConfig[0].name);
  useEffect(() => {
    getChartData({
      block: '',
      metrics: barConfig[0].metrics,
      duration: duration,
      filters: filters,
      report_id: reportId
    }).then(response => {
      if (response.data.data) {
        setChartData(response.data.data);
        setErrorFlag(false);
      } else {
        setErrorFlag(true);
      }
      setIsLoading(false);
    });
  }, [duration, filters]);

  const handleSave = () => {
    setSavedTitle(unsavedTitle);
    updateWidget({
      report_id: reportId,
      id: id,
      name: unsavedTitle,
      action: 'update',
    }).then(response => {
      if (response.data.response && response.data.response === 'success') {
        setErrorFlag(false);
        setEditFlag(false);
      } else {
        setErrorFlag(true);
      }
      setIsLoading(false);
    });
  };

  const cancelEdit = () =>{
    setUnsavedTitle(savedTitle);
    setEditFlag(false);
  }
  return (
    <>
      <div className={classes.header}>
        <DragHandleIcon style={{ color: '#546E7A', marginRight: '16px', cursor: 'grab' }} className="widget-handle" />
        {editFlag ? (
          <Box display="flex">
            <TextField
              fullWidth
              autoFocus
              label=""
              size="small"
              margin="normal"
              name="report_name"
              type="text"
              defaultValue=""
              value={unsavedTitle}
              variant="outlined"
              onChange={e => setUnsavedTitle(e.target.value)}
              onKeyPress={ev => {
                if (ev.key === 'Enter') {
                  handleSave();
                  ev.preventDefault();
                }
              }}
            />
            <Box ml={2} alignSelf="center" pt={1} display="flex">
              <Button onClick={handleSave} color="primary" type="submit" size="small" variant="contained" aria-label="widget-rename-save" >
                Save
              </Button>
              <IconButton onClick={(e) => cancelEdit}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <>
            <Typography variant="subtitle1">{savedTitle}</Typography>{' '}
            <IconButton
            onClick={(e) => {
              setEditFlag(true);
            }}
              className={classes.smallEditIcon}
              size="small"
              style={{ marginLeft: '8px', alignItems: 'flex-end' }}
            >
              <EditIcon
                size="small"
              />
            </IconButton>
          </>
        )}

        <div className={classes.spacer} />
        <IconButton aria-label="delete" onClick={() => onRemoveItem(id)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </div>
      <Box ml={6} mt={-2}>
        <Typography variant="body2" color="textSecondary">
          average for {recordCount} customers
        </Typography>
      </Box>
      <Container maxWidth={false} style={{ paddingLeft: '48px', marginTop: '24px' }}>
        {isLoading ? (
          <div className={styles.chartDataloader}> </div>
        ) : (
          <>
            {errorFlag ? (
              <ImagePlaceHolder
                message={'Something went wrong. Please contact the involve.ai team to get this fixed.'}
                imageUrl={require('./../../../../assets/error-general.png')}
                altText={'Error'}
              />
            ) : (
              <>
                {barConfig[0].metrics[0] !== 'severity_of_support_tickets' &&
                  barConfig[0].metrics[0] !== 'probability_to_renew' && (
                    <GroupedChart fullDataSet={chartData} labels={chartData[0].dates} />
                  )}
                {barConfig[0].metrics[0] === 'severity_of_support_tickets' && (
                  <CategoricalChart trend={chartData[0]} labels={chartData[0].dates} supportTicket={true} />
                )}
                {barConfig[0].metrics[0] === 'probability_to_renew' && (
                  <CategoricalChart trend={chartData[0]} labels={chartData[0].dates} supportTicket={false} />
                )}
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
}
