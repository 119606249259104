import axios from 'axios';
import { attachToken } from '../../../utils/auth';

export function uploadFile(requestData, callback, errorcallback) {
  let formData = new FormData();
  formData.append('company_name', localStorage.getItem('company_name'));
  for (var i = 0; i < requestData.length; i++) {
    formData.append('csv_files', requestData[i]);
  }

  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/connector/csv_export',
      data: formData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}
