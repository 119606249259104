import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Link, TextField, Button, Divider, InputAdornment, Grow, Paper, Popper, MenuItem, MenuList, ClickAwayListener } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import cloneDeep from 'lodash/cloneDeep';

const CustomAutocomplete = withStyles({
  tag: {
    backgroundColor: '#ECEFF1',
    height: 24,
    position: 'relative',
    zIndex: 0,
    '& .MuiChip-label': {
      color: '#263238',
    },
    '& .MuiChip-deleteIcon': {
      color: '#B0BEC5',
    },
    '&:after': {
      content: '""',
      right: 10,
      top: 6,
      height: 12,
      width: 12,
      position: 'absolute',
      backgroundColor: 'white',
      zIndex: -1,
    },
  },
})(Autocomplete);

const useStyles = makeStyles(theme => ({
  root: {
    width: '550px',
    color: theme.palette.text.secondary,
    height: '100%',
    '& .MuiPaper-root': {
      backgroundColor: "#FFFFFF"
    }
  },
  tabs: {
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
    },
  },
  saveAsNewBtn: {
    color: '#0061FF',
    backgroundColor: 'white !important',
    boxShadow: 'none !important',
  },
  paper: {    
    backgroundColor: 'white !important'
  },
  menu: {
    backgroundColor: "#FFFFFF"
  },
}));

const Filters = ({ className, customer, filterOptions, handleApplyFilters, savedFilters, handleSaveAsNew, handleSaveFiltersView, ...props }) => {
  const classes = useStyles();
  const [filters, setFilters] = useState(cloneDeep(savedFilters));
  const [openSaveMenu, setOpenSaveMenu] = React.useState(false);
  const anchorRef = React.useRef(null);
  const filterList = props.dataColumns;
  const isCustomersPage = window.location.pathname.includes("/customers");

  const resetFunc = () => {
    Array.from(document.querySelector('#filters').querySelectorAll('input')).forEach(input => (input.value = ''));
    setFilters({});
  };
  const selectFilterVal = (event, val, filteredElement) => {
    const tempFilterVal = cloneDeep(filters);

    let tempFilter = tempFilterVal;
    if (tempFilter.hasOwnProperty(filteredElement)) {
      val.length > 0 ? (tempFilter[filteredElement].filter_values = val) : delete tempFilter[filteredElement];
    } else {
      tempFilter[filteredElement] = {
        filter_values: val,
        criteria: 'contains',
      };
    }
    setFilters(tempFilter);
  };

  const displayFilter = (filterProps, simpleViewColumns, filters, columnLabel) => {
    return (
      <Box mt={2}>
        <CustomAutocomplete
          multiple
          id={filterProps.id}
          options={filterProps.values}
          onChange={(e, value) => selectFilterVal(e, value, filterProps.id)}
          getOptionLabel={option => option}
          value={filters.hasOwnProperty(filterProps.id) ? filters[filterProps.id].filter_values : []}
          filterSelectedOptions
          renderInput={params => <TextField {...params} variant="outlined" label={columnLabel} />}
          ChipProps={{
            root: classes.filterChips,
          }}
        />
      </Box>
    );
  };

  const handleDateChange = (date, elementId) => {
    let splitVal = elementId.split(':');
    let filteredElementId = splitVal[0];

    const filterVal = filterOptions.find(o => o.id === filteredElementId);
    var dateVal = [filterVal.date_range.start_date, filterVal.date_range.last_date];
    if (filters.hasOwnProperty(filteredElementId)) {
      dateVal = filters[filteredElementId].filter_values;
    }

    dateVal[splitVal[1]] = date;
    if (dateVal[0] === null) {
      dateVal[0] = filterVal.date_range.start_date;
    }
    if (dateVal[1] === null) {
      dateVal[1] = filterVal.date_range.last_date;
    }

    const filterObj = {
      filter_values: dateVal,
      criteria: 'is_between',
    };
    const tempFilterVal = cloneDeep(filters);
    let tempFilter = tempFilterVal;
    tempFilter[filteredElementId] = filterObj;

    setFilters(tempFilter);
  };

  const formatDate = date => {
    if (date == null) {
      return '';
    }
    return new Intl.DateTimeFormat('en').format(new Date(date));
  }

  const handleRangeFilterChange = event => {
    let tempFilter = cloneDeep(filters);
    let filteredElementId = event.target.id.split(':');

    const filterVal = filterOptions.find(o => o.id === filteredElementId[0]);

    if (tempFilter.hasOwnProperty(filteredElementId[0])) {
      let existingFilterVal = tempFilter[filteredElementId[0]].filter_values;
      if (filteredElementId[1] === 'min') {
        existingFilterVal[0] = event.target.value === '' ? filterVal.range.min : parseFloat(event.target.value);
      } else {
        existingFilterVal[1] = event.target.value === '' ? filterVal.range.max : parseFloat(event.target.value);
      }
      tempFilter[filteredElementId[0]] = {
        filter_values: existingFilterVal,
        criteria: 'is_between',
      };
    } else {
      let tempArr = [];
      if (filteredElementId[1] === 'min') {
        tempArr = [parseFloat(event.target.value), filterVal.range.max];
      } else {
        tempArr = [filterVal.range.min, parseFloat(event.target.value)];
      }
      tempFilter[filteredElementId[0]] = {
        filter_values: tempArr,
        criteria: 'is_between',
      };
    }
    setFilters(tempFilter);
  };

  const dateFilter = (filterProps, simpleViewColumns, columnLabel) => {
    return (
      <Box mt={2}>
        <Typography>{columnLabel}</Typography>
        <Box display="flex" mt={0}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk={true}
              disableToolbar
              inputVariant="outlined"
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id={filterProps.id + ':min'}
              placeholder={formatDate(filterProps.date_range.start_date)}
              minDate={filterProps.date_range.start_date}
              maxDate={filterProps.date_range.last_date}
              value={filters.hasOwnProperty(filterProps.id) ? filters[filterProps.id].filter_values[0] : null}
              onChange={(d) => handleDateChange(d, filterProps.id + ':0')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>

          <Typography style={{ alignSelf: 'center', margin: '0 16px 0 16px' }}>to</Typography>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk={true}
              disableToolbar
              inputVariant="outlined"
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id={filterProps.id + ':max'}
              placeholder={formatDate(filterProps.date_range.last_date)}
              minDate={filterProps.date_range.start_date}
              maxDate={filterProps.date_range.last_date}
              value={filters.hasOwnProperty(filterProps.id) ? filters[filterProps.id].filter_values[1] : null}
              onChange={(d) => handleDateChange(d, filterProps.id + ':1')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Box>
      </Box>
    )
  }

  const rangeFilter = (filterProps, simpleViewColumns, columnLabel) => {
    return (
      <Box mt={2}>
        <Typography>{columnLabel}</Typography>
        <Box display="flex" mt={2}>
          <TextField
            id={filterProps.id + ':min'}
            variant="outlined"
            onChange={handleRangeFilterChange}
            placeholder={filterProps.range.min.toString()}
            label="Min"
            style={{ width: '160px' }}
            defaultValue={filters.hasOwnProperty(filterProps.id) ? filters[filterProps.id].filter_values[0] : null}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{columnLabel.includes('$') ? '$' : null}</InputAdornment>
              ),
            }}
          />
          <Typography style={{ alignSelf: 'center', margin: '0 16px 0 16px' }}>to</Typography>
          <TextField
            id={filterProps.id + ':max'}
            variant="outlined"
            onChange={handleRangeFilterChange}
            placeholder={filterProps.range.max.toString()}
            label="Max"
            style={{ width: '160px' }}
            defaultValue={filters.hasOwnProperty(filterProps.id) ? filters[filterProps.id].filter_values[1] : null}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{columnLabel.includes('$') ? '$' : null}</InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    );
  };

  const filterRow = (filterName, columnLabel, filterOptions, simpleViewColumns, filters) => {
    const individualFilter = filterOptions.find(o => o.id === filterName);
    return (
      <>
        {columnLabel !== undefined && individualFilter !== undefined && (
          <>
            {individualFilter.hasOwnProperty('values') && (
              <>{displayFilter(individualFilter, simpleViewColumns, filters, columnLabel)}</>
            )}
            {individualFilter.hasOwnProperty('range') && (
              <>{rangeFilter(individualFilter, simpleViewColumns, columnLabel)}</>
            )}
            {individualFilter.hasOwnProperty('date_range') && (
              <>{dateFilter(individualFilter, simpleViewColumns, columnLabel)}</>
            )}
          </>
        )}
      </>
    );
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(openSaveMenu);
  React.useEffect(() => {
    if (prevOpen.current === true && openSaveMenu === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = openSaveMenu;
  }, [openSaveMenu]);

  const handleCloseSaveMenu = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenSaveMenu(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenSaveMenu(false);
    }
  }

  const handleToggle = () => {
    setOpenSaveMenu((prevOpen) => !prevOpen);
  };

  const handleSaveAsNewView = (event) => {
    handleCloseSaveMenu(event);
    handleSaveAsNew(Object.assign({}, filters))
  }

  const handleSaveView = (event) => {
    handleCloseSaveMenu(event);
    handleSaveFiltersView(Object.assign({}, filters))
  }

  return (
    <Box className={classes.root} mr={3} ml={3}>
      <Box display="flex" mb={2}>
        <Typography variant="h4" color="textPrimary">
          Filters
        </Typography>
        <Box flexGrow="1" />
        {isCustomersPage &&
          <React.Fragment>            
            <Button
              variant="contained"
              className={classes.saveAsNewBtn}
              style={{ marginRight: '8px' }}
              ref={anchorRef}
              aria-controls={openSaveMenu ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick = {handleToggle}
            >
              SAVE
            </Button>           

            <Popper open={openSaveMenu} keepMounted anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex: 2}}>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom'}}
                >                  
                  <Paper classes={classes.paper} className={classes.paper}>
                    <ClickAwayListener onClickAway={handleCloseSaveMenu}>
                      <MenuList className={classes.menu} autoFocusItem={openSaveMenu} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                        <MenuItem className={classes.menu} onClick={handleSaveAsNewView}>Save as New View</MenuItem>
                        {props.selectedView != null && <MenuItem className={classes.menu} onClick={handleSaveView}>Save as Current View</MenuItem>}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>                  
                </Grow>
              )}
            </Popper>            
          </React.Fragment>

        }

        <Link component="button" variant="body2" color="textSecondary" underline="none" onClick={resetFunc}>
          CLEAR ALL
        </Link>
      </Box>
      <Divider />
      <Box mt={3} mb={2} key="filters" id="filters">
        <Box key={'client_status'}>
          {filterRow('client_status', 'Customer Status', filterOptions, filterList, filters)}
        </Box>
        {filterList.map((filterName) => (
          <>
            {filterName !== ' ' && (
              <Box key={filterName.id}>
                {filterRow(filterName.id, filterName.label, filterOptions, filterList, filters)}
              </Box>
            )}
          </>
        ))}
      </Box>
      <Divider />

      <Button
        variant="contained"
        color="primary"
        style={{ marginRight: '8px', marginTop: '16px' }}
        onClick={() => handleApplyFilters(Object.assign({}, filters))}
      >
        APPLY
      </Button>
    </Box>
  );
};

export default Filters;
