import React, { useState } from 'react';
import { Responsive as ResponsiveGridLayout } from 'react-grid-layout';
import { withSize } from 'react-sizeme';
import Widget from './Widget';

function Overview({ configData, duration, filters, recordCount, initialLayout, size: { width } }) {
  const items = initialLayout.lg.map(item => item.i);
  const [layouts, setLayouts] = useState(getFromLS('layouts') || initialLayout);
  const [compactType, setCompactType] = useState('vertical');

  const onLayoutChange = (_, allLayouts) => {
    setLayouts(allLayouts);
    setCompactType(prev => (prev === 'vertical' ? 'horizontal' : 'vertical'));
    setCompactType(prev => (prev === 'vertical' ? 'horizontal' : 'vertical'));
  };

  return (
    <>
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={50}
        width={width}
        onLayoutChange={onLayoutChange}
        isResizable={false}
        draggableHandle=".widget-handle"
        autoSize={true}
        direction={'grid'}
        compactType={compactType}
        isBounded={true}
        preventCollision={false}
      >
        {items.map(key => (
          <div key={key} className="widget" data-grid={{ w: 6, h: 7, minH: 7, maxH: 16, x: 0, y: Infinity }}>
            <Widget
              id={key}
              backgroundColor="#867ae9"
              configData={configData.blocks.filter(item => item.id === key)}
              duration={duration}
              filters={filters}
              recordCount={recordCount}
            />
          </div>
        ))}
      </ResponsiveGridLayout>
    </>
  );
}

export default withSize({ refreshMode: 'debounce', refreshRate: 60 })(Overview);

function getFromLS(key) {
  let ls = {};
  return ls[key];
}
