import axios from 'axios';
import { attachToken } from '../../utils/auth';

export function insertView(requestData, callback, errorcallback) {
    return axios(
      attachToken({
        method: 'post',
        url: process.env.REACT_APP_API_BASE_URL + 'v1/table/insert_view',
        data: requestData,
        headers: {},
      }),
    )
      .then(function (response) {
        return response;
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  export function updateView(requestData, callback, errorcallback) {
    return axios(
      attachToken({
        method: 'post',
        url: process.env.REACT_APP_API_BASE_URL + 'v1/table/update_view',
        data: requestData,
        headers: {},
      }),
    )
      .then(function (response) {
        return response;
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  export function updateFiltersView(requestData, callback, errorcallback) {
    return axios(
      attachToken({
        method: 'post',
        url: process.env.REACT_APP_API_BASE_URL + 'v1/table/update_filters_view',
        data: requestData,
        headers: {},
      }),
    )
      .then(function (response) {
        return response;
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  export function deleteView(requestData, callback, errorcallback) {
    return axios(
      attachToken({
        method: 'post',
        url: process.env.REACT_APP_API_BASE_URL + 'v1/table/delete_view',
        data: requestData,
        headers: {},
      }),
    )
      .then(function (response) {
        return response;
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  export function setDefault(requestData, callback, errorcallback) {
    return axios(
      attachToken({
        method: 'post',
        url: process.env.REACT_APP_API_BASE_URL + 'v1/table/set_default_view',
        data: requestData,
        headers: {},
      }),
    )
      .then(function (response) {
        return response;
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  export function getViews(callback, errorcallback) {
    return axios(
        attachToken({
          method: 'get',
          url: process.env.REACT_APP_API_BASE_URL + 'v1/table/get_views_user',
          data: {},
          headers: {},
        }),
      )
        .then(function (response) {
          return response;
        })
        .catch(function (response) {
          console.log(response);
        });
  }