import axios from 'axios';
import { attachToken } from '../../utils/auth';

export function getInsights(callback, errorcallback) {
  return axios(
    attachToken({
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/insight/get_all',
      data: {},
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function updateCardAPI(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/insight/update_card',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function updateInsightAPI(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/insight/update_insight',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function updateTaskNoteAPI(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/insight/update_task_note',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export function getInsightAPI(requestData, callback, errorcallback) {
  return axios(
    attachToken({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_URL + 'v1/insight/get_insight',
      data: requestData,
      headers: {},
    }),
  )
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      console.log(response);
    });
}
