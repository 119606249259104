import React, { useState } from 'react';
import { Responsive as ResponsiveGridLayout } from 'react-grid-layout';
import { withSize } from 'react-sizeme';
import Widget from './Widget';
import { updateWidget } from '../ReportsAPI';

function Overview({ configData, duration, filters, recordCount, initialLayout, size: { width }, ...props }) {
  const [items, setItems] = useState(initialLayout.lg.map(item => item.i));
  const [layouts, setLayouts] = useState(getFromLS('layouts') || initialLayout);
  const [compactType, setCompactType] = useState('vertical');
  const [onDeleteUpdate, setDeleteUpdate] = useState(false);
  const [itemDelete, setItemDelete] = useState('');

  const onLayoutChange = (currentLayout, allLayouts) => {
    if (onDeleteUpdate) {
      updateWidget({
        report_id: configData.report_id,
        id: itemDelete,
        action: 'delete',
      })
        .then(response => {
          if (response.data.response && response.data.response === 'success') {
            console.log('Deletion worked');
            updateLayout(currentLayout);
          } else {
            console.log('An error occured in the in deleting report card');
          }
        })
        .catch(function (response) {
          console.log(response);
        });
      setDeleteUpdate(false);
    }
    setLayouts(allLayouts);
    setCompactType(prev => (prev === 'vertical' ? 'horizontal' : 'vertical'));
  };
  const onRemoveItem = (itemId, name) => {
    if (name !== undefined) {
      props.blockDeleted({ name: name.toLowerCase() });
    }
    setDeleteUpdate(true);
    setItemDelete(itemId);
    setItems(items.filter(i => i !== itemId));
    setCompactType(prev => (prev === 'vertical' ? 'horizontal' : 'vertical'));
  };

  const sortLayout = layout => {
    layout.sort((a, b) => {
      if (a.y === b.y) {
        return a.x - b.x;
      } else {
        return a.y - b.y;
      }
    });
    const sortedLayout = layout.map(obj => obj.i);
    return sortedLayout;
  };
  const updateLayout = layout => {
    let layoutIds = sortLayout(layout);

    updateWidget({
      report_id: configData.report_id,
      list: layoutIds,
      action: 'reorder',
    })
      .then(response => {
        if (response.data.response && response.data.response === 'success') {
          console.log('layout Change Complete');
        } else {
          console.log('An error occured in the Report Update(Layout Change) API');
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  };

  return (
    <>
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={50}
        width={width}
        onLayoutChange={onLayoutChange}
        onDragStop={updateLayout}
        isResizable={false}
        draggableHandle=".widget-handle"
        autoSize={true}
        direction={'grid'}
        compactType={compactType}
        isBounded={true}
        preventCollision={false}
      >
        {items.map(key => (
          <div key={key} className="widget" data-grid={{ w: 6, h: 7, minH: 7, maxH: 16, x: 0, y: Infinity }}>
            <Widget
              id={key}
              onRemoveItem={onRemoveItem}
              backgroundColor="#867ae9"
              configData={configData.blocks.filter(item => item.id === key)}
              duration={duration}
              filters={filters}
              recordCount={recordCount}
            />
          </div>
        ))}
      </ResponsiveGridLayout>
    </>
  );
}

export default withSize({ refreshMode: 'debounce', refreshRate: 60 })(Overview);

function getFromLS(key) {
  let ls = {};
  return ls[key];
}
