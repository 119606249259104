import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, SvgIcon, FormControlLabel, Switch } from '@material-ui/core';
import { deleteRule, disableRule } from './AutomationAPI';
import { ruleStyles } from './rule.theme';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';
import MessageDialog from './MessageDialog';
import { ReactComponent as CopyIcon } from '../../assets/clone.svg';
import ImagePlaceHolder from './../customers/ImagePlaceholder';

const DisplayViewName = ({ ...props }) => {
  const viewDetails = props.viewsList.filter(item => item._id === props.view);
  const viewName = viewDetails && viewDetails[0] ? viewDetails[0].name : 'All Customers';
  return (
    <>
      <Typography variant="body2" color="textPrimary">
        <b>&nbsp;{viewName}</b>,
      </Typography>
    </>
  );
};

const DisplayColumnName = ({ ...props }) => {
  const columnDetails = props.columnsList.filter(item => item.id === props.column.column);
  const columnName = columnDetails && columnDetails[0] ? columnDetails[0].label : '';
  return (
    <>
      <Typography variant="body2" color="textPrimary">
        &nbsp;<b>{columnName} </b> to <b> {props.column.value},</b>
      </Typography>
    </>
  );
};

const Rule = ({ ...props }) => {
  const history = useHistory();
  const styles = ruleStyles();
  const ruleInfo = props.rule[0];
  const [enabledFlag, setEnabledFlag] = useState(ruleInfo.is_enabled);

  const handleDisable = () => {
    let requestJSON = {
       _id: ruleInfo._id,
       is_enabled: !enabledFlag
    }
    setEnabledFlag(!enabledFlag);
    disableRule(requestJSON)
      .then(function (response) {
        if (response.data.status_code !== 200) {
          props.setErrorFlag(true);
        }
      })
      .catch(function (response) {
        props.setErrorFlag(true);
      });
  }

  const handleEdit =() => {
    history.push({
           pathname: '/automation/edit',
           state: { ruleId: 'ruleInfo._id', details: ruleInfo }
       });
  }
  const handleCopy =() => {
    history.push({
           pathname: '/automation/copy/',
           state: { ruleId: 'ruleInfo._id', details: ruleInfo }
       });
  }
  return (
    <Box p={2} mb={2} alignItems="center" border={1} className={styles.ruleBox}>
      <Box display="flex">
        <Typography variant="subtitle1" color="textPrimary">
          {ruleInfo.name}
        </Typography>
        <Box flexGrow="1" />
        <IconButton aria-label="edit-rule" onClick={() => handleEdit()}>
          <EditIcon  fontSize="small" />
        </IconButton>
        <IconButton aria-label="copy-rule" onClick={() => handleCopy()}>
          <SvgIcon component={CopyIcon} />
        </IconButton>
        <IconButton aria-label="delete-rule" onClick={() => props.handleDelete(ruleInfo._id)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
        <FormControlLabel
          value=""
          control={<Switch checked={enabledFlag} color="primary" />}
          label=""
          labelPlacement="start"
          onChange={e => handleDisable()}
        />
      </Box>

      <Box mt={-2} display="flex">
        <Typography variant="body2" color="textPrimary">
          When customer is part of the view
        </Typography>
        {ruleInfo.triggers.views.map(view => (
          <DisplayViewName view={view} viewsList={props.viewsList} />
        ))}
        <Typography variant="body2" color="textPrimary">
          &nbsp;change
        </Typography>

        {ruleInfo.actions.map(action => (
          <DisplayColumnName column={action} columnsList={props.columnsList} />
        ))}

        {ruleInfo.triggers.schedule && ruleInfo.triggers.schedule.every !== 0 && (
          <Typography variant="body2" color="textPrimary">
            &nbsp;repeat every&nbsp;
            <b>
              {ruleInfo.triggers.schedule.every}&nbsp;{ruleInfo.triggers.schedule.period}
            </b>
          </Typography>
        )}
        <Typography variant="body2" color="textPrimary">
          .
        </Typography>
      </Box>
    </Box>
  );
};

const RulesList = ({ ...props }) => {
  const [errorFlag, setErrorFlag] = useState(false);
  let tempArr = props.rulesList.length > 0 ? props.rulesList : [];
  const [rulesArr, setRulesArr] = useState(tempArr.map(item => item._id));
  const [deleteDialogFlag, setDeleteDialogFlag] = useState(false);
  const [selectedRule, setSelectedRule] = useState('');
  useEffect(() => {
    tempArr = props.rulesList.length > 0 ? props.rulesList.map(item => item._id) : [];
    setRulesArr(tempArr);
  }, [props.rulesList]);

  const handleDelete = id => {
    setSelectedRule(id);
    setDeleteDialogFlag(true);
  };

  const confirmDelete = () =>{
    setRulesArr(rulesArr.filter(item => item !== selectedRule));
    deleteRule({ _id: selectedRule })
      .then(function (response) {
        if (response.data.status_code === 200) {
          setDeleteDialogFlag(false);
        } else {
          setErrorFlag(true);
        }
      })
      .catch(function (response) {
        setErrorFlag(true);
      });
  }

  return (
    <>
    {deleteDialogFlag && (
      <MessageDialog
        dialogType="deleteRule"
        handleCloseDialog={() => setDeleteDialogFlag(false)}
        openDialog={deleteDialogFlag}
        handlePrimaryButtonClick={confirmDelete}
      />
    )}
    {errorFlag && (
      <ImagePlaceHolder
        message={'Something went wrong. Please contact the involve.ai team to get this fixed.'}
        imageUrl={require('./../../assets/error-general.png')}
        altText={'Error'}
      />
    )}
      {rulesArr.map(rule => (
        <Rule
          rule={props.rulesList.filter(item => item._id === rule)}
          viewsList={props.viewsList}
          columnsList={props.columnsList}
          handleDelete={handleDelete}
          setErrorFlag={setErrorFlag}
        />
      ))}
    </>
  );
};

export default RulesList;
