import { Chart } from 'react-chartjs-2';

// Doughnut chart with text inside

var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function () {
    originalDoughnutDraw.apply(this, arguments);
    var chart = this.chart;
    var width = chart.chart.width,
    height = chart.chart.height,
    ctx = chart.chart.ctx;
    ctx.font = 24 + 'px Roboto';
    ctx.fillStyle = '#263238';
    ctx.textBaseline = 'middle';
    var text = this.chart.config.data.total,
    textX = Math.round((width - ctx.measureText(text).width) / 2),
    textY = height / 2;
    ctx.fillText(text, textX, textY);
  },
});
