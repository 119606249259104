import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Bar } from 'react-chartjs-2';
import { makeStyles, useTheme, Typography, Box } from '@material-ui/core';
import { reportsStyles } from '../../reports.theme';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
}));

const GroupedChart = ({ labels, fullDataSet, className, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const styles = reportsStyles();

  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
       const j = Math.floor(Math.random() * (i + 1));
       [array[i], array[j]] = [array[j], array[i]];
   }

    return array;
  }
  const [shuffledColors, setShuffledColors] = useState([]);
  useEffect(() => {
    const colorsList = ['#0061FF', '#009688', '#FFC106', '#5850EC', '#9E9D24'];
    setShuffledColors(shuffle(colorsList));
  }, []);

  let data = {
    datasets: [],
    labels,
  };

  fullDataSet.forEach(function (item, i) {
    let tempColor = shuffledColors[i];
    switch (item.metric) {
      case 'low_convos':
        tempColor = theme.palette.low.main;
        break;
      case 'medium_convos':
        tempColor = theme.palette.medium.main;
        break;
      case 'high_convos':
        tempColor = theme.palette.high.main;
        break;
      default:
        tempColor = shuffledColors[i];
    }
    data.datasets.push({
      label: item.label,
      backgroundColor: tempColor,
      data: item.values,
      barThickness: 12,
      maxBarThickness: 10,
      barPercentage: 1.0,
      categoryPercentage: 1.0,
    });
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cornerRadius: 20,
    legend: {
      display: false,
      position: 'bottom',
      labels: { usePointStyle: true },
    },
    layout: {
      padding: 0,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider,
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            maxTicksLimit: 5,
            callback: value => value,
          },
        },
      ],
    },
    tooltips: {
      enabled: true,
      displayColors: false,
      mode: 'single',
      intersect: false,
      caretSize: 10,
      yPadding: 20,
      xPadding: 20,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.background.default,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        title: () => {},
        label: tooltipItem => {
          let label = `${tooltipItem.yLabel}`;
          return label;
        },
      },
    },
  };

  return (
    <>
      <div className={clsx(classes.root, className)} {...rest} style={{ height: '241px' }}>
        <Bar data={data} options={options} />
      </div>
      {data.datasets.length > 0 && (
        <Box display="flex" mt={2}  style={{flexWrap: 'wrap'}}>
          {data.datasets.map((item, i) => (
            <Box display="flex" style={{ alignItems: 'center' }} key={item.label}>
              <span className={styles.dot} style={{ backgroundColor: item.backgroundColor }} />
              <Typography variant="subtitle2" color="textPrimary">
                {item.label} &nbsp; &nbsp;
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

GroupedChart.propTypes = {
  className: PropTypes.string,
  fullDataSet: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
};

export default GroupedChart;
