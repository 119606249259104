import React, { useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Checkbox,
  Popover,
  MenuList,
  MenuItem,
  Divider,
  Button,
  TextField,
  Chip,
} from '@material-ui/core/';
import { insightsStyles } from '../insights/insights.theme';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import classes from '../insights/insights.module.css';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useTheme } from '@material-ui/core';

const AddNewTask = ({ ...props }) => {
  const styles = insightsStyles();
  const [isAddNew, setIsAddNew] = useState(false);
  const [hasDueDate, setHasDueDate] = useState(false);
  const [description, setDescription] = useState('');
  const [selectedDate, setSelectedDate] = React.useState(new Date().toISOString().substring(0, 10));
  const [dateInDateFormat, setDateInDateFormat] = React.useState(new Date());
  const handleDateChange = date => {
    setSelectedDate(date.toISOString().substring(0, 10));
    setDateInDateFormat(date);
  };

  const handleAdd = () => {
    props.handleUpdateTaskNote(props.id, 'task', 'create', {
      description: description,
      due_date: hasDueDate ? selectedDate : null,
    });
    setIsAddNew(false);
  };

  const handleCancel = () => {
    setIsAddNew(false);
    setHasDueDate(false);
  };
  return (
    <Box mt={1.5}>
      {isAddNew ? (
        <>
          <TextField
            id="outlined-textarea"
            placeholder="Add a new task"
            multiline
            rows={3}
            fullWidth
            variant="outlined"
            onChange={e => setDescription(e.target.value)}
          />
          <Box display="flex" mt={2}>
            <Checkbox
              checked={hasDueDate}
              name="checked"
              color="primary"
              style={{ paddingLeft: '0px', paddingTop: '0px', height: '18px', width: '18px' }}
              onChange={() => setHasDueDate(!hasDueDate)}
            />
            <Typography variant="subtitle2" color="textPrimary">
              Add due date
            </Typography>
          </Box>
          {hasDueDate && (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                minDate={new Date()}
                value={dateInDateFormat}
                onChange={d => handleDateChange(d)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                autoOk="true"
              />
            </MuiPickersUtilsProvider>
          )}
          <Box mt={2} display="flex">
            <Button variant="contained" style={{ marginRight: '16px' }} onClick={() => handleCancel()}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={() => handleAdd()}>
              Add
            </Button>
          </Box>
        </>
      ) : (
        <Button
          variant="text"
          className={styles.addNew}
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => setIsAddNew(true)}
          style={{ marginLeft: '2px' }}
        >
          Add New Task
        </Button>
      )}
    </Box>
  );
};

const Task = ({ company_id, ...props }) => {
  const styles = insightsStyles();
  const theme = useTheme();
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const [isEditing, setIsEditing] = useState(false);
  const [hasDueDate, setHasDueDate] = useState(props.info.hasOwnProperty('due_date') && props.info.due_date !== '');
  const [description, setDescription] = useState(props.info.description);
  const [selectedDate, setSelectedDate] = React.useState(
    props.info.hasOwnProperty('due_date') && props.info.due_date !== ''
      ? props.info.due_date
      : new Date().toISOString().substring(0, 10),
  );
  const [dateInDateFormat, setDateInDateFormat] = React.useState(
    props.info.hasOwnProperty('due_date') && props.info.due_date !== ''
      ? new Date(new Date(props.info.due_date).getTime() - new Date(props.info.due_date).getTimezoneOffset() * -60000)
      : new Date(),
  );
  const handleDateChange = date => {
    setSelectedDate(date.toISOString().substring(0, 10));
    setDateInDateFormat(date);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const checkDateDifference = time => {
    const date1 = new Date(time);
    const date2 = new Date();
    const diffTime = date1 - date2;
    const diffDays = diffTime / (1000 * 60 * 60 * 24);
    return Math.ceil(diffDays);
  };

  const taskDueDate =
    props.info.hasOwnProperty('due_date') && checkDateDifference(props.info.due_date) > 0 ? true : false;
  const taskPastDueDate =
    props.info.hasOwnProperty('due_date') && props.info.due_date === new Date().toISOString().substring(0, 10)
      ? styles.dateDueChip
      : styles.datePastChip;

  const AccessTimeIconColor = taskDueDate
    ? theme.palette.doesNotExist.main
    : taskPastDueDate === styles.dateDueChip
    ? theme.palette.medium.main
    : theme.palette.low.main;

  const handleCheckbox = e => {
    props.handleUpdateTaskNote(company_id, 'task', 'update', {
      description: description,
      item_id: props.info.item_id,
      is_completed: !props.info.is_completed,
      is_deleted: props.info.is_deleted,
      due_date: hasDueDate ? props.info.due_date : null,
    });
  };

  const handleEdit = item_id => {
    handleClose();
    setIsEditing(true);
  };
  const handleDeleteClick = item_id => {
    handleClose();
    props.handleUpdateTaskNote(company_id, 'task', 'update', {
      description: description,
      item_id: props.info.item_id,
      is_completed: props.info.is_completed,
      is_deleted: true,
      due_date: hasDueDate ? props.info.due_date : null,
    });
  };

  const handleSave = () => {
    props.handleUpdateTaskNote(company_id, 'task', 'update', {
      description: description,
      item_id: props.info.item_id,
      is_completed: props.info.is_completed,
      is_deleted: props.info.is_deleted,
      due_date: hasDueDate ? selectedDate : null,
    });
    setIsEditing(false);
  };

  const formatDateFunc = timestampVal => {
    let unformattedDate = new Date(timestampVal);
    let year = unformattedDate.getUTCFullYear();
    let month = months[unformattedDate.getUTCMonth()];
    let date = unformattedDate.getUTCDate();
    let formattedDate = date + ' ' + month + ', ' + year + ' ';
    return formattedDate;
  };

  return (
    <>
      {isEditing ? (
        <Box mt={2}>
          <TextField
            id="outlined-textarea"
            placeholder="Add a new task"
            multiline
            rows={3}
            fullWidth
            variant="outlined"
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          <Box display="flex" mt={2}>
            <Checkbox
              checked={hasDueDate}
              name="checked"
              color="primary"
              style={{ paddingLeft: '0px', paddingTop: '0px', height: '18px', width: '18px' }}
              onChange={() => setHasDueDate(!hasDueDate)}
            />
            <Typography variant="subtitle2" color="textPrimary">
              Add due date
            </Typography>
          </Box>
          {hasDueDate && (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                minDate={new Date()}
                value={dateInDateFormat}
                onChange={d => handleDateChange(d)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                autoOk="true"
              />
            </MuiPickersUtilsProvider>
          )}
          <Box mt={2} mb={1} display="flex">
            <Button variant="contained" style={{ marginRight: '16px' }} onClick={() => setIsEditing(false)}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={() => handleSave()}>
              Save
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          <Box mt={2} display="flex">
            <Checkbox
              checked={props.info.is_completed}
              name="checked"
              color="primary"
              style={{ padding: '0px', height: '18px', width: '18px', alignItems: 'end' }}
              onChange={handleCheckbox}
            />

            <Typography
              variant="body2"
              color="textPrimary"
              style={
                props.info.is_completed
                  ? { textDecorationLine: 'line-through', paddingRight: '16px', width: '533px', marginLeft: '12px' }
                  : { paddingRight: '16px', width: '533px', marginLeft: '12px' }
              }
            >
              {props.info.description}
            </Typography>

            <IconButton style={{ height: '10px', width: '10px' }} onClick={handleClick} className={classes.moreIcon}>
              <MoreHorizIcon />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuList>
                {!props.info.is_completed && <MenuItem onClick={() => handleEdit(props.info.item_id)}>Edit</MenuItem>}
                <MenuItem onClick={() => handleDeleteClick(props.info.item_id)}>Delete</MenuItem>
              </MenuList>
            </Popover>
          </Box>
          {props.info.due_date && (
            <>
              {' '}
              {props.info.is_completed ? (
                <Box display="flex" alignItems="center" ml={3.5}>
                  <AccessTimeIcon style={{ height: '16px', width: '16px' }} />
                  <Typography
                    variant="body2"
                    style={{ marginLeft: '8px', textDecorationLine: 'line-through' }}
                    color="textSecondary"
                  >
                    {formatDateFunc(props.info.due_date)}
                  </Typography>
                </Box>
              ) : (
                <Box display="flex" alignItems="center" ml={3.5}>
                  <Chip
                    label={formatDateFunc(props.info.due_date)}
                    key={props.info.item_id}
                    icon={<AccessTimeIcon style={{ height: '16px', width: '16px', color: AccessTimeIconColor }} />}
                    className={taskDueDate ? styles.dateChip : taskPastDueDate}
                    clickable={false}
                  ></Chip>
                </Box>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

const CompletedTasks = ({ ...props }) => {
  const [expandedFlag, setExpandedFlag] = useState(false);

  return (
    <Box mt={1.5}>
      <Divider />
      <Box display="flex" mt={1}>
        <Typography variant="body2" color="textSecondary" style={{ marginTop: '8px' }}>
          Completed ({props.completedCount})
        </Typography>
        <Box flexGrow={1} />
        <Box>
          <IconButton aria-label="expand row" size="small" onClick={() => setExpandedFlag(!expandedFlag)}>
            {expandedFlag ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
        </Box>
      </Box>
      {expandedFlag && (
        <>
          {props.cardDetails.tasks.map(task => (
            <Box key={task.item_id}>
              {!task.is_deleted && task.is_completed && (
                <Task
                  info={task}
                  company_id={props.company_id}
                  changeInsightStatus={props.changeInsightStatus}
                  classes={classes}
                  setShowDetailedCard={props.setShowDetailedCard}
                  handleUpdateTaskNote={props.handleUpdateTaskNote}
                />
              )}
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};

const GeneralInfoTasks = ({ ...props }) => {
  const completedCount = props.cardDetails.tasks.filter(item => item.is_completed === true && item.is_deleted !== true)
    .length;

  return (
    <Box mt={2}>
      <Box>
        <Typography variant="subtitle1" color="textPrimary">
          Tasks
        </Typography>
        {props.cardDetails.tasks.map(task => (
          <Box key={task.item_id}>
            {!task.is_deleted && !task.is_completed && (
              <Task
                info={task}
                company_id={props.company_id}
                changeInsightStatus={props.changeInsightStatus}
                classes={classes}
                setShowDetailedCard={props.setShowDetailedCard}
                handleUpdateTaskNote={props.handleUpdateTaskNote}
              />
            )}
          </Box>
        ))}

        <AddNewTask
          id={props.company_id}
          handleUpdateTaskNote={props.handleUpdateTaskNote}
          company_id={props.company_id}
        />

        {completedCount > 0 && (
          <CompletedTasks
            cardDetails={props.cardDetails}
            completedCount={completedCount}
            handleUpdateTaskNote={props.handleUpdateTaskNote}
            company_id={props.company_id}
          />
        )}
      </Box>
    </Box>
  );
};
export default GeneralInfoTasks;
