import React, { useState } from 'react';
import { Box, Typography, Container, Button, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { viewReport } from './../ReportsAPI';
import { reportsStyles } from './../reports.theme';
import { useParams } from 'react-router-dom';
import ReportView from './ReportView';

const Reports = () => {
  const styles = reportsStyles();
  const { companyName, reportId } = useParams();
  const [loadingFlag, setLoadingFlag] = useState(true);
  const [authenticationError, setAuthenticationError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authenticatedUser, setAuthenticatedUser] = useState(false);

  const handleVerification = () => {
    viewReport({
      report_id: reportId,
      company_name: companyName,
      email: email,
      password: password,
    }).then(response => {
      if (response.data.response) {
        if (response.data.statusCode === 200) {
          const token = response.data['jwt'];
          localStorage.setItem('token', token);
          setAuthenticatedUser(true);
        } else {
          setAuthenticationError(response.data.response);
          setAuthenticatedUser(false);
        }
      } else {
        setAuthenticationError('Something went wrong. Try again');
        setAuthenticatedUser(false);
      }
      setLoadingFlag(false);
    });
  };

  return (
    <>
      {authenticatedUser ? (
        <ReportView />
      ) : (
        <Box height="100vh" display="flex" mt={15}>
          <Container maxWidth="sm">
            <Typography color="textPrimary" gutterBottom variant="h3" align="center">
              Welcome
            </Typography>

            <Box>
              <Container style={{ maxWidth: '480px' }}>
                {authenticationError && (
                  <Alert severity="error" style={{ maxWidth: '100%' }} p={1}>
                    {authenticationError}
                  </Alert>
                )}

                <TextField
                  fullWidth
                  autoFocus
                  label="Email Address"
                  margin="normal"
                  name="email"
                  type="email"
                  defaultValue=""
                  variant="outlined"
                  className={styles.textFieldLogin}
                  onChange={e => setEmail(e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Access code"
                  margin="normal"
                  name="password"
                  type="password"
                  defaultValue=""
                  variant="outlined"
                  className={styles.textFieldLogin}
                  onChange={e => setPassword(e.target.value)}
                />
                <Box mt={2}>
                  <Button fullWidth style={{height: '50px'}} variant="contained" color="primary" onClick={handleVerification}>
                    View Report
                  </Button>
                </Box>
              </Container>
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
};

export default Reports;
