import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Popover,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
} from '@material-ui/core';
import MessageDialog from './MessageDialog';
import { getAllReports, deleteReport } from './ReportsAPI';
import { reportsStyles } from './reports.theme';
import ImagePlaceHolder from './../customers/ImagePlaceholder';
import AddNewDialog from './AddNewDialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useHistory } from 'react-router-dom';
import DuplicateReport from './DuplicateReport';

const StyledTableCell = withStyles(theme => ({
  head: {
    color: '#546E7A',
  },
  body: {
    color: '#263238',
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: '20px',
    textAlign: 'left',
    border: 'none',
    padding: '16px',
  },
}))(TableCell);

const StyledTableHeadCell = withStyles(theme => ({
  head: {
    color: '#546E7A',
    borderBottom: '1px solid #EEEEEE',
    borderTop: '1px solid #EEEEEE',
    fontWeight: 400,
  },
  body: {
    color: '#263238',
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: '20px',
    textAlign: 'left',
    padding: '16px',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#FAFBFF',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#FFFFFF',
    },
  },
}))(TableRow);

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const formatDateFunc = timestampVal => {
  if (timestampVal !== undefined && timestampVal !== '') {
    let unformattedDate = new Date(timestampVal);
    let year = unformattedDate.getFullYear();
    let month = months[unformattedDate.getMonth()];
    let date = unformattedDate.getDate();
    let formattedDate = date + ' ' + month + ', ' + year + ' ';
    return formattedDate;
  } else {
    return ' ';
  }
};
const getTime = timestampVal => {
  if (timestampVal !== undefined && timestampVal !== '') {
    return new Date(timestampVal).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  } else {
    return ' ';
  }
};

function ReportRow(rowProps) {
  let history = useHistory();
  let report = rowProps.report;
  const [showPassword, setShowPassword] = useState(false);
  const [showViewIcon, setShowViewIcon] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const uniqueViews = report.views;
  const uniqueEmails = report.views
    .map(item => item.email)
    .filter((value, index, self) => self.indexOf(value) === index);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editReportFunc = () => {
    history.push(`/reports/edit/${report.report_id}`);
  };

  const deleteReportFunc = () => {
    handleClose();
    rowProps.openDeleteDialog(report);
  };
  const duplicateReportFunc = () => {
    handleClose();
    rowProps.openDuplicateDialog(report);
  };

  return (
    <>
      <StyledTableCell>
        <a
          underline="always"
          target="_self"
          rel="noopener noreferrer"
          href={'/reports/edit/' + report.report_id}
          style={{ color: '#263238' }}
        >
          {report.name}
        </a>
      </StyledTableCell>
      <StyledTableCell>{formatDateFunc(report.created_date)}</StyledTableCell>
      {report.views.length > 0 ? (
        <Tooltip
          title={
            <React.Fragment>
              {uniqueViews.map(view => (
                <Typography style={{ fontSize: '12px' }}>
                  {formatDateFunc(view.timestamp)} at {getTime(view.timestamp)} {view.email}
                </Typography>
              ))}
            </React.Fragment>
          }
          placement="top-start"
          arrow
        >
          <StyledTableCell>
            {report.views.length} view(s) from {uniqueEmails.length} email(s)
          </StyledTableCell>
        </Tooltip>
      ) : (
        <StyledTableCell> </StyledTableCell>
      )}
      <StyledTableCell
        style={{ paddingTop: '0px', paddingBottom: '0px' }}
        onMouseEnter={() => setShowViewIcon(true)}
        onMouseLeave={() => setShowViewIcon(false)}
      >
        {showPassword ? <>{report.password}</> : <>*&nbsp;*&nbsp;*&nbsp;*&nbsp;*&nbsp;</>}
        {showViewIcon && (
          <IconButton
            aria-label="show-report-password"
            id={report.report_id}
            onClick={() => {
              setShowPassword(!showPassword);
            }}
            size="small"
            style={{ marginLeft: '8px', padding: '0px' }}
          >
            {!showPassword ? (
              <VisibilityIcon fontSize="small" color="primary" />
            ) : (
              <VisibilityOffIcon fontSize="small" color="primary" />
            )}
          </IconButton>
        )}
      </StyledTableCell>
      <StyledTableCell>{formatDateFunc(report.expiration_date)}</StyledTableCell>
      <StyledTableCell style={{ width: '10px', padding: '0px' }}>
        <IconButton aria-label="report-more-options" id={report.report_id} onClick={handleClick}>
          <MoreHorizIcon fontSize="inherit" />
        </IconButton>
        <Popover
          id={report.report_id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuList>
            <MenuItem aria-label="report-options-edit" onClick={editReportFunc}>
              Edit
            </MenuItem>
            <MenuItem aria-label="report-options-edit" onClick={duplicateReportFunc}>
              Duplicate
            </MenuItem>
            <MenuItem aria-label="report-options-delete" onClick={deleteReportFunc}>
              Delete
            </MenuItem>
          </MenuList>
        </Popover>
      </StyledTableCell>
    </>
  );
}

const ReportsList = () => {
  const styles = reportsStyles();
  const [loadingFlag, setLoadingFlag] = useState(true);
  const [errorFlag, setErrorFlag] = useState(false);
  const [reportsList, setReportsList] = useState([]);
  const [addNewFlag, setAddNewFlag] = useState(false);
  const [deleteDialogFlag, setDeleteDialogFlag] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});
  const [duplicateReportFlag, setDuplicateReportFlag] = useState(false);

  const ReportsListHead = [
    { id: 'name', label: 'Report Name' },
    { id: 'created_date', label: 'Created On' },
    { id: 'views', label: 'Views' },
    { id: 'password', label: 'Password' },
    { id: 'expiration_date', label: 'Link Expiration Date' },
    { id: 'empty_head', label: ' ' },
  ];

  const tableHead = headCell => {
    return (
      <StyledTableHeadCell id={headCell.id} key={headCell.id}>
        {headCell.label}
      </StyledTableHeadCell>
    );
  };

  useEffect(() => {
    getAllReports()
      .then(function (response) {
        setLoadingFlag(false);
        if (response.data.data) {
          setReportsList(response.data.data);
        } else {
          setErrorFlag(true);
        }
      })
      .catch(function (response) {
        setLoadingFlag(false);
        setErrorFlag(true);
      });
  }, []);
  const addNewReport = () => {
    setAddNewFlag(true);
  };
  const handleCloseDialog = () => {
    setAddNewFlag(false);
  };

  const deleteReportFunction = () => {
    let reportsListCopy = reportsList.filter(row => row.report_id !== selectedReport.report_id);
    setReportsList(reportsListCopy);
    setDeleteDialogFlag(false);
    deleteReport({
      report_id: selectedReport.report_id,
    })
      .then(function (response) {
        setLoadingFlag(false);
        if (response.data && response.data.response && response.data.response !== 'success') {
          setErrorFlag(true);
        }
      })
      .catch(function (response) {
        setErrorFlag(true);
      });
  };

  const openDeleteDialog = report => {
    setSelectedReport(report);
    setDeleteDialogFlag(true);
  };

  const openDuplicateDialog = report => {
    setSelectedReport(report);
    setDuplicateReportFlag(true);
  };
  const handleCloseDuplicateDialog = () => {
    setDuplicateReportFlag(false);
  };
  return (
    <>
    {errorFlag && (
      <ImagePlaceHolder
        message={'Something went wrong. Please contact the involve.ai team to get this fixed.'}
        imageUrl={require('./../../assets/error-general.png')}
        altText={'Error'}
      />
    )}
      {deleteDialogFlag && (
        <MessageDialog
          dialogType="deleteReport"
          handleCloseDialog={() => setDeleteDialogFlag(false)}
          openDialog={deleteDialogFlag}
          handlePrimaryButtonClick={deleteReportFunction}
        />
      )}
      {loadingFlag ? (
        <div className={styles.loader}> </div>
      ) : (
        <>
          {reportsList.length <= 0 ? (
            <>
              <AddNewDialog openDialogFlag={addNewFlag} handleClose={handleCloseDialog} />

              <ImagePlaceHolder
                message={'You do not have any reports yet. Go ahead and create a new one.'}
                imageUrl={require('./../../assets/chart-empty.png')}
                altText={'No Reports'}
                buttonLabel={'ADD NEW REPORT'}
                buttonFunction={addNewReport}
              />
            </>
          ) : (
            <>
              <DuplicateReport
                report={selectedReport}
                openDialogFlag={duplicateReportFlag}
                handleClose={handleCloseDuplicateDialog}
              />
              <TableContainer id="ReportsList" className={styles.dataSimTable}>
                <Table>
                  <TableHead>
                    <TableRow id="ReportsListHead" style={{ height: '60px' }}>
                      {ReportsListHead.map(headCell => (
                        <>{tableHead(headCell)}</>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportsList.map((report, index) => (
                      <StyledTableRow key={report.report_id + index} id={report.report_id + index}>
                        <ReportRow
                          report={report}
                          openDeleteDialog={openDeleteDialog}
                          openDuplicateDialog={openDuplicateDialog}
                        />
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ReportsList;
