import React from 'react';
import { Box, Typography, Checkbox, TextField, FormControl, Select } from '@material-ui/core/';
import { ruleStyles } from './../rule.theme';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const durationOptions = [
  { id: 'days', name: 'days' },
  { id: 'weeks', name: 'weeks' },
  { id: 'months', name: 'months' },
  { id: 'quarters', name: 'quarters' },
  { id: 'years', name: 'years' },
];
const Repeat = ({ ...props }) => {
  const styles = ruleStyles();

  return (
    <Box mt={2} display="flex" alignItems="center">
      <Checkbox
        className={styles.checkBoxDimensions}
        checkedIcon={<CheckBoxIcon />}
        checked={props.repeatFlag}
        color="primary"
        classes={{
          root: styles.dropdownMultiSelect,
        }}
        id="repeat-checkbox"
        onChange={e => {
          props.setRepeatFlag(!props.repeatFlag);
          props.setRepeatVal()
        }}
      />

      <Typography variant="body2" color= {props.repeatFlag ? "textPrimary" : "textSecondary"}>
        Repeat every
      </Typography>
      <TextField
        placeholder="Enter a number"
        type={'number'}
        inputProps={{ min: 0 }}
        name="repeat-val"
        value={props.repeatVal}
        variant="outlined"
        onChange={e => props.setRepeatVal(e.target.value)}
        disabled={!props.repeatFlag}
        className={styles.inputHeight}
        size="small"
        style={{maxWidth: '160px'}}
      />

      <FormControl variant="outlined" size="small" >
        <Select native value={props.selectedDuration} onChange={e => props.setSelectedDuration(e.target.value)} disabled={!props.repeatFlag} className={styles.inputHeight}>
          {durationOptions.map((duration, index) => (
            <option value={duration._id} key={duration._id}  id={duration._id}>{duration.name}</option>
          ))}
        </Select>
      </FormControl>
      <Box flexGrow="1" />
    </Box>
  );
};
export default Repeat;
