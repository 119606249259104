import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box, Button, Dialog,  Slider, Tooltip } from '@material-ui/core/';
import  {updateDashboadTable} from './CustomerAPI';
import Alert from '@material-ui/lab/Alert';
import LoadingSpinner from '../LoadingSpinner';

const useStylesTooltip = makeStyles(theme => ({
    arrow: {
      fontSize: '14px',
      width: 17,
      '&::before': {
        border: '1px solid #EEEEEE',
        backgroundColor: '#fff',
        boxSizing: 'border-box',
      },
    },
    tooltip: {
      backgroundColor: 'white',
      color: '#263238',
      fontFamily: 'Roboto',
      fontSize: '14px',
      letterSpacing: '0.1px',
      lineHeight: '17px',
      textAlign: 'left',
      border: '1px solid #EEEEEE',
      borderRadius: 1,
      fontWeight: '400',
    },
  }));

const FeedbackSlider = withStyles({
    root: {
      color: '#0061FF',
      height: 8,
      width: '99%',
    },
    thumb: {
      height: 14,
      width: 14,
      backgroundColor: '#fff',
      marginTop: -8,
      marginLeft: -12,
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
      '&:focus, &:hover, &$active': {
        boxShadow: '#ccc 0 2px 3px 1px',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 3,
      borderRadius: 4,
    },
    rail: {
      color: '#d8d8d8',
      height: 3,
      borderRadius: 4,
    },
  })(Slider);


const DialogEditableValue = ({open, setOpenEditable, editableColumn, updateData}) => {
    const [sliderValue, setSliderValue] = useState(editableColumn.value);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    useEffect(() => {
        setSliderValue(editableColumn.value);
    },[editableColumn]);

    function ValueLabelComponent(props) {
        const { children, open, value } = props;
    
        const tooltipClasses = useStylesTooltip();
        let newTooltipValue = value + '%';
        return (
          <Tooltip
            open={open}
            enterTouchDelay={0}
            leaveDelay={100}
            placement="top"
            title={newTooltipValue}
            arrow
            classes={tooltipClasses}
          >
            {children}
          </Tooltip>
        );
    }

    const handleSubmit = () => {
        if (editableColumn.value === sliderValue){
          setErrorMessage ('New value must be different from old value');
          return;
        }
        let requestData = {
          company_name: editableColumn.companyName,
          company_id: editableColumn.companyId,
          value:sliderValue,
          old_value: editableColumn.value,
          column_id: editableColumn.columnId.includes ('_value')? editableColumn.columnId : editableColumn.columnId + "_value",
          column_category: editableColumn.category === undefined? '' : editableColumn.category,
          old_normalized_value: editableColumn.normalized
        }
        setErrorMessage ('');
        setIsSubmitting (true);
        updateDashboadTable (requestData)
            .then(response => {
              if (response === undefined) 
                setErrorMessage ("Something went wrong. Please contact the involve.ai team to get this fixed.");
              else if (response.data.hasOwnProperty('Error')) {
                setErrorMessage ("Something went wrong. Please contact the involve.ai team to get this fixed.");
              } else {
                let values = editableColumn;
                values.value = sliderValue;
                values.normalized = response.data.normalized;
                values.log = response.data.log;
                values.new_prediction = response.data.new_prediction;
                updateData(values);
                setOpenEditable(false);
              }
              setIsSubmitting(false);
        });

    }

    return (
        <Dialog maxWidth="sm" fullWidth open={open} >
        <Box p={3}>
          <Typography gutterBottom variant="h3" color="textPrimary">
            {editableColumn !== undefined? editableColumn.title : ''}
          </Typography>
          <Typography gutterBottom variant="body2" color="textSecondary">
                Update your  {editableColumn !== undefined? editableColumn.title + ' ': ''}
                by picking any percentage from 0 to 100%.
          </Typography>
          <Box mt={5} />
          
          <FeedbackSlider
            ValueLabelComponent={ValueLabelComponent}
            aria-label="custom thumb label"
            valueLabelDisplay="on"
            value={sliderValue}
            defaultValue={editableColumn.value}
            onChange={(event, val) => {
              setSliderValue(val);
            }}
          />
          {errorMessage !== '' ? (
                            <Alert severity="error"  p={1}>
                                {' '}
                                {typeof errorMessage === 'string'
                                    ? errorMessage
                                    : Object.keys(errorMessage).map((key, index) => {
                                        return <li>{errorMessage[key]}</li>;
                                    })}
                            </Alert>
            ) : null}
          <Box display="flex" pt={2}>
            <Button variant="contained" style={{height:'42px'}} onClick={() => {setErrorMessage (''); setOpenEditable(false)}}>
              Cancel
            </Button>
            <Box flexGrow={1} mr={2} />
                    {isSubmitting ? 
                          <LoadingSpinner></LoadingSpinner> : 
                          <Button variant="contained"  style={{height:'42px', padding: '0px 24px'}} color="primary" onClick={() => handleSubmit()} value="No">
                          UPDATE
                          </Button>
                    }
            </Box>
        </Box>
      </Dialog>
    );
}

export default DialogEditableValue;