import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    action: {
     disabledBackground: '#7fb0ff',
     // disabled: 'white'
   },
    primary: {
      main: '#0061ff',
    },
    text: {
        secondary: '#546E7A'
    },
    divider: '#EEEEEE',
    high:{
      main : '#09BC71',
      light: '#e7f8f1'
    },
    low: {
      main: '#D32F2F',
      light: '#FEEBEE'
    },
    medium: {
      main: '#F67C01',
      light: '#FFF3E0'
    },
    doesNotExist: {
      main: '#546E7A',
      light: '#F5F5F5'
    }
  },
  typography: {
    fontFamily: 'Roboto',
    h2: {
      fontSize: '48px',
      letterSpacing: '-0.12px',
      lineHeight: '58px',
      fontWeight: '500'
    },
    h3: {
      fontWeight: '500',
      fontSize: '28px',
      letterSpacing: '-0.06px',
      lineHeight: '28px',
    },
    h4: {
      fontSize: '24px',
      letterSpacing: '-0.06px',
      lineHeight: '28px',
      fontWeight: '500',
    },
    h5: {
      fontWeight: '700',
      fontSize: '16px',
      letterSpacing: '-0.06px',
      lineHeight: '28px',
    },
    subtitle1: {
      fontSize: '16px',
      letterSpacing: '-0.05px',
      lineHeight: '20px',
      fontWeight: '500',
    },
    subtitle2: {
      fontSize: '14px',
      letterSpacing: '0.1px',
      lineHeight: '20px',
      fontWeight: '400',
    },
    // body1: {
    //   fontSize: '12px',
    //   letterSpacing: '0.83px',
    //   lineHeight: '14px'
    // },
    f14: {
      fontSize: '14px'
    },
    body2: {
      fontSize: '14px',
      letterSpacing: '0.1px',
      lineHeight: '20px',
    }
  },
});

export default theme;
