import { makeStyles } from '@material-ui/core';

export const zendeskStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    icon: {
        width: "32px",
        height: "32px"
    },
}));
