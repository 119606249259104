import { makeStyles } from '@material-ui/core';

export const historyStyles = makeStyles({
  highlightClass: {
    backgroundColor: '#FFF3E0',
  },
  highlightPositiveClass: {
    color:'#09bc71',
    backgroundColor: '#e7f8f1',
  },
  highlightNegativeClass: {
    color: '#D32F2F',
    backgroundColor: '#FEEBEE',
  },
});
