import React, { useState, useEffect} from 'react';
import SecondaryNav from '../../SecondaryNav';
import { Typography, Container }from "@material-ui/core";
import UpdateUserImage from './UpdateUserImage';
import UpdateUserInfo from './UpdateUserInfo';
import UpdateUserPassword from './UpdateUserPassword';
import {accountStyles} from './account.theme';
import styles from './accountsettings.module.css';
import { getAccountInfo } from './AccountSettingsApi'
import UserSettingsMenu from '../UserSettingsMenu'

const  AccountSettings = () => {
    const classes = accountStyles();
    const [loadingFlag, setLoadingFlag] = useState(true);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userImg, setUserImg] = useState(localStorage.getItem("user_image")?localStorage.getItem("user_image") : "");

    const userEmail = localStorage.getItem("email");


    useEffect(()=> {
        let requestData = {"email": userEmail};
        setLoadingFlag (true);
        getAccountInfo(requestData).then(response => {
            if (response.data.hasOwnProperty('Error')) {
              //setErrorFlag(true);
            } else {
                setFirstName(response.data.first_name);
                setLastName (response.data.last_name);
                //setUserImg(response.data.img);
            }
            setLoadingFlag (false);
          });

    },[]);


    return (
        <div >
            <SecondaryNav userImg={userImg}/>

            <UserSettingsMenu></UserSettingsMenu>
            {loadingFlag ? (<div className={styles.loader}> </div>) :

            <Container
                className='appBody hideScroll menu-left' >

                    <Typography variant="h5" className={classes.marginTop} color="textPrimary">
                    <span>Account Settings</span>
                    </Typography>

                    <UpdateUserImage userImg={userImg} setUserImg={setUserImg} userEmail={userEmail}></UpdateUserImage>
                <UpdateUserInfo userEmail={userEmail} firstName={firstName} lastName={lastName} setFirstName={setFirstName} setLastName={setLastName} ></UpdateUserInfo>
                    <Typography variant="h5" className={classes.marginTop} color="textPrimary">
                    <span>Change Password</span>
                    </Typography>
                <UpdateUserPassword setLoadingFlag={setLoadingFlag}></UpdateUserPassword>
            </Container>}
        </div>

    );
}

export default AccountSettings;
