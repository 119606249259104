import { Route, Redirect } from 'react-router-dom';
import React from 'react';

export const attachToken = state => {
  const token = localStorage.getItem('token');
  if (!token) return state;
  if (!('headers' in state)) {
    state['headers'] = {};
  }
  if (!('' in state['headers'])) {
    state['headers']['Authorization'] = `Token ${token}`;
  }
  return state;
};

const parseJwt = token => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const validateToken = (token, searchedCustomer) => {
  if (searchedCustomer !== '') {
    localStorage.setItem('searchedCustomer', searchedCustomer);
  }
  if (token === null || token === undefined) {
    return false;
  }
  const user_data = parseJwt(token);
  if (user_data !== null && 'username' in user_data && 'exp' in user_data && user_data['exp'] * 1000 > Date.now()) {
    window.pendo.initialize({
      visitor: {
        id: user_data.username,
        role: user_data.role
        // email:        // Recommended if using Pendo Feedback, or NPS Email
        // full_name:    // Recommended if using Pendo Feedback
      },

      account: {
        id: user_data.company_name, // Highly recommended
        name: user_data.company_name        // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional
      },
    });
    return true;
  }
  return false;
};

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      validateToken(localStorage.getItem('token'), props.location.hash) ? (
        <Component {...props} />
      ) : (
        <Redirect to={'/login'} />
      )
    }
  />
);
