import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core';
import { Box, Button, Slider, Tooltip, Typography, Dialog, DialogActions, SvgIcon } from '@material-ui/core/';
import { postNPSFeedback } from './CustomerAPI.js';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles({
  dialogPaper: {
    maxWidth: '496px',
    maxHeight: '580px',
  },
  dialogPadding: {
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  dataExportImg: {
    maxHeight: '280px',
  },
  productFeedbackSubmittedDialog: {
    width: '400px',
    height: '329px',
  },
});

const useStylesTooltip = makeStyles(theme => ({
  arrow: {
    fontSize: '14px',
    width: 17,
    '&::before': {
      border: '1px solid #EEEEEE',
      backgroundColor: '#fff',
      boxSizing: 'border-box',
    },
  },
  tooltip: {
    backgroundColor: 'white',
    color: '#263238',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: '17px',
    textAlign: 'left',
    border: '1px solid #EEEEEE',
    borderRadius: 1,
    fontWeight: '400',
  },
}));

const FeedbackSlider = withStyles({
  root: {
    color: '#0061FF',
    height: 8,
    width: '99%',
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    marginTop: -8,
    marginLeft: -12,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    '&:focus, &:hover, &$active': {
      boxShadow: '#ccc 0 2px 3px 1px',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    color: '#d8d8d8',
    height: 8,
    borderRadius: 4,
  },
})(Slider);

function ValueLabelComponent(props) {
  const { children, value } = props;
  const tooltipClasses = useStylesTooltip();
  return (
    <Tooltip open={true} enterTouchDelay={0} placement="top" title={value} arrow classes={tooltipClasses}>
      {children}
    </Tooltip>
  );
}

const ProductFeedback = props => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.openDialog);
  const [sliderValue, setSliderValue] = useState(1);
  const [errorFlag, setErrorFlag] = useState(false);
  const [submittedFlag, setSubmittedFlag] = useState(false);

  React.useEffect(() => {
    setTimeout(() => setSliderValue(10), 500);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = remindLater => {
    postNPSFeedback({
      company_name: props.companyName,
      email_id: props.email,
      remind_later: remindLater,
      feedback_value: sliderValue,
    }).then(response => {
      if (response.data.statusCode === 200) {
        remindLater ? setOpen(false) : setSubmittedFlag(true);
      } else {
        setErrorFlag(true);
      }
    });
  };

  const handleFeedbackValueChange = (event, newValue) => {
    if (newValue) {
      setSliderValue(newValue);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paper: classes.dialogPaper }}>
      {submittedFlag ? (
        <>
        <Box alignItems="center" className={classes.productFeedbackSubmittedDialog}>
        <Box pt={10} pb={3} pl={20}>
        <SvgIcon style={{ width: 80, height: 80, alignSelf: "center"}}>
          <CheckCircleIcon color="primary" />
        </SvgIcon>
        </Box>
        <Typography color="textPrimary" gutterBottom variant="h4" align="center">
          Thank You!
        </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            Your feedback has been submitted successfully.
          </Typography>

        </Box>
        </>
      ) : (
        <>
          <img
            src={require('./../../assets/rate-experience.png')}
            alt="Feedback Rating"
            className={classes.dataExportImg}
          />
          <Box p={4}>
            <Typography variant="h4" color="textPrimary">Rate your experience</Typography>
            <Box pt={2} pb={4}>
              {errorFlag && (
                <Typography variant="body2" color="textSecondary">
                  It looks like we encountered an error. Please contact the involve.ai team to get this fixed.
                </Typography>
              )}
              <Typography variant="body2" color="textSecondary">
                Considering your experience with us so far, how much value do we create for you?
              </Typography>
              <Box pt={5}>
                <FeedbackSlider
                  ValueLabelComponent={ValueLabelComponent}
                  aria-label="custom thumb label"
                  valueLabelDisplay="on"
                  value={sliderValue}
                  min={1}
                  max={10}
                  defaultValue={5}
                  onChange={(event, val) => {
                    handleFeedbackValueChange(event, val);
                  }}
                />
              </Box>
            </Box>
            <DialogActions style={{ padding: '0px' }}>
              <Button onClick={event => handleSubmit(true)} variant="contained">
                Ask Later
              </Button>
              <Box flexGrow="1" />
              <Button onClick={event => handleSubmit(false)} color="primary" type="submit" variant="contained">
                Submit
              </Button>
            </DialogActions>
          </Box>
        </>
      )}
    </Dialog>
  );
};
export default ProductFeedback;
