import React, { useState, useEffect } from 'react';
import { makeStyles, Box, TextField, DialogTitle, DialogContent, Checkbox } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import GroupedChart from './ChartWidget/GroupedChart';
import { getChartData, createChart } from '../ReportsAPI';
import ImagePlaceHolder from './../../customers/ImagePlaceholder';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles({
  dialogPaper: {
    maxWidth: '738px',
  },
  metricsBox: {
    border: '1px solid #C4C4C4',
    borderRadius: '4px',
    width: '690px',
    height: '200px',
    overflow: 'scroll',
  },
  chartBox: {
    border: '1px solid #C4C4C4',
    borderRadius: '4px',
    width: '690px',
    height: '328px',
    overflow: 'scroll',
  },
});

const DisplayOptions = ({ option, selectedMetrics, setSelectedMetrics, setErrorMessage, ...rest }) => {
  const classes = useStyles();
  const handleSelect = metric => {
    if (selectedMetrics.includes(metric)) {
      setErrorMessage('');
      let newMetrics = [...selectedMetrics];
      newMetrics = newMetrics.filter(item => item !== metric);
      setSelectedMetrics(newMetrics);
    } else {
      if (selectedMetrics.length < 5) {
        let newMetrics = [...selectedMetrics, metric];
        setSelectedMetrics(newMetrics);
      } else {
        setErrorMessage('You can pick up to 5 different metrics');
      }
    }
  };
  const handleSelectAll = keyIndicator => {
    console.log('Selected');
  };
  return (
    <>
      <Box display="flex" alignItems="center">
        {option.metrics.length <= 1 ? (
          <>
            <Checkbox
              className={classes.checkBoxDimensions}
              checkedIcon={<CheckBoxIcon />}
              checked={selectedMetrics.includes(option.key_indicator)}
              indeterminateIcon={<IndeterminateCheckBoxIcon className={classes.checkBoxIndeterminate} />}
              indeterminate={false}
              color="primary"
              classes={{
                root: classes.dropdownMultiSelect,
              }}
              id={option.key_indicator}
              onChange={e => {
                if (option.metrics.length <= 1) {
                  handleSelect(e.target.id);
                } else {
                  handleSelectAll(option.key_indicator);
                }
              }}
            />
            <Typography variant="subtitle2" color="textPrimary" style={{ fontWeight: 500 }}>
              {option.key_indicator_label}
            </Typography>
          </>
        ) : (
          <Box mt={1}>
            <Typography variant="subtitle2" color="textPrimary" style={{ fontWeight: 500, marginLeft: '12px' }}>
              {option.key_indicator_label}
            </Typography>
          </Box>
        )}
      </Box>
      {option.metrics.length > 1 && (
        <>
          {option.metrics.map((detailed_key_indicator, i) => (
            <Box display="flex" alignItems="center" id={i} key={i} ml={2}>
              <Checkbox
                className={classes.checkBoxDimensions}
                checkedIcon={<CheckBoxIcon />}
                checked={selectedMetrics.includes(detailed_key_indicator.metric)}
                indeterminateIcon={<IndeterminateCheckBoxIcon className={classes.checkBoxIndeterminate} />}
                indeterminate={false}
                color="primary"
                classes={{
                  root: classes.dropdownMultiSelect,
                }}
                id={detailed_key_indicator.metric}
                onChange={e => {
                  handleSelect(e.target.id);
                }}
              />
              <Typography variant="subtitle2" color="textPrimary">
                {detailed_key_indicator.label}
              </Typography>
            </Box>
          ))}
        </>
      )}
    </>
  );
};

const CreateNewChartModal = ({ reportId, chartOptions, openDialogFlag, handleClose, loaderClass, ...rest }) => {
  const classes = useStyles();
  const [chartTitle, setChartTitle] = useState('Untitled Chart');
  const [chartData, setChartData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMetrics, setSelectedMetrics] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getChartData({
      block: '',
      metrics: selectedMetrics,
      duration: 'monthly',
      filters: {},
      report_id: reportId,
    }).then(response => {
      if (response.data.data) {
        setChartData(response.data.data);
        setErrorMessage('');
      } else {
        setErrorMessage(response.data.Error);
      }
      setIsLoading(false);
    });
  }, [reportId, selectedMetrics]);

  const resetData = () => {
    setChartTitle('Untitled Chart');
    setChartData([]);
    setErrorMessage('');
    setSelectedMetrics([]);
  };

  const handleAdd = () => {
    createChart({
      report_id: reportId,
      name: chartTitle,
      metrics: selectedMetrics,
    }).then(response => {
      if (response.data.response && response.data.response === 'success') {
        handleCancelAndClose();
        rest.setNewChart({
          layout: 'half-width',
          id: response.data.id,
          components: [
            {
              name: chartTitle,
              type: 'bar',
              metrics: selectedMetrics,
            },
          ],
        });
        setErrorMessage('');
      } else {
        setErrorMessage(response.data.Error);
      }
      setIsLoading(false);
    });
  };

  const handleCancelAndClose = () => {
    resetData();
    handleClose();
  };
  return (
    <Dialog open={openDialogFlag} onClose={handleCancelAndClose} classes={{ paper: classes.dialogPaper }}>
      <DialogTitle id="add-new-report-dialog" style={{ paddingBottom: '0px' }}>
        Add New Chart
      </DialogTitle>
      <DialogContent>
        <Box mt={1} mb={1}>
          <Typography color="textSecondary" variant="subtitle2">
            Select total of up to 5 metrics.
          </Typography>
        </Box>
        {errorMessage !== '' && (
          <Box mb={2}>
            <Alert severity="error" style={{ maxWidth: '100%' }} p={1}>
              {errorMessage}
            </Alert>
          </Box>
        )}
        <Box className={classes.metricsBox} mb={1}>
          {chartOptions.map((option, index) => (
            <Box key={index} index={index}>
              <DisplayOptions
                option={option}
                selectedMetrics={selectedMetrics}
                setSelectedMetrics={setSelectedMetrics}
                setErrorMessage={setErrorMessage}
              />
            </Box>
          ))}
        </Box>
        <TextField
          fullWidth
          autoFocus
          label="Chart Name"
          margin="normal"
          name="chart_name"
          type="text"
          value={chartTitle}
          variant="outlined"
          onChange={e => setChartTitle(e.target.value)}
        />
        <Box className={classes.chartBox} mt={2}>
          {isLoading && <div className={loaderClass} />}
          <Box m={2} mt={3}>
            {selectedMetrics.length <= 0 && (
              <ImagePlaceHolder
                message={'Please select one or more metrics to preview the chart.'}
                imageUrl={require('./../../../assets/chart-empty.png')}
                altText={'Empty state'}
              />
            )}

            {chartData.length > 0 && <GroupedChart fullDataSet={chartData} labels={chartData[0].dates} />}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'flex-start', padding: '8px 24px 16px 24px' }}>
        <Button onClick={handleCancelAndClose} variant="contained">
          Cancel
        </Button>
        <Box flexGrow="1" />
        <Button onClick={handleAdd} color="primary" variant="contained">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default CreateNewChartModal;
