import { makeStyles } from '@material-ui/core';

export const insightsStyles = makeStyles(theme => ({
  banner: {
    backgroundColor: theme.palette.background.paper,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  board: {
    display: 'flex',
    height: 'calc(100vh - 250px)',
    overflow: 'scroll',
  },
  column: {
    minWidth: 'calc(100vw / 4.30)',
    margin: '0 auto',
  },
  suggestionsColumn: {
    minWidth: 'calc(100vw / 4.30)',
    margin: '0 auto',
    backgroundColor: '#f9f9f9',
    height: 'inherit',
    paddingBottom: '16px',
    minHeight: '100%',
  },
  columnHead: {
    padding: 10,
    fontSize: '16px',
    fontWeight: 400,
    borderBottom: '1px solid #EEEEEE',
    margin: '0px 8px 0 8px',
  },
  item: {
    padding: 10,
    margin: 10,
    fontSize: '0.8em',
    cursor: 'pointer',
    backgroundColor: 'white',
  },
  newInsightCardChip: {
    backgroundColor: theme.palette.high.light,
    color: theme.palette.high.main,
    margin: '0px 0 0 24px',
    fontSize: '12px',
    fontWeight: '500',
    letterSpacing: '0.83px',
    lineHeight: '14px',
    textAlign: 'center',
    width: '64px',
    height: '20px',
    borderRadius: '10px',
  },
  newInsightChip: {
    backgroundColor: '#CAEEE3',
    color: '#09BC71',
    width: '45px',
    height: '24px',
    borderRadius: '10px',
    fontSize: '12px',
    fontWeight: '500',
    margin: '4px 4px 0 0px',
    padding: '4px 8px 4px 8px',
  },
  dismissedChip: {
    backgroundColor: theme.palette.doesNotExist.light,
    color: theme.palette.doesNotExist.main,
    width: '76px',
    height: '24px',
    borderRadius: '10px',
    fontSize: '12px',
    fontWeight: '500',
    margin: '4px 4px 0 0px',
    padding: '4px 8px 4px 8px',
  },
  convertedToTaskChip: {
    backgroundColor: theme.palette.high.light,
    color: theme.palette.high.main,
    width: '140px',
    height: '24px',
    borderRadius: '10px',
    fontSize: '12px',
    fontWeight: '500',
    margin: '4px 4px 0 0px',
    padding: '4px 8px 4px 8px',
  },
  dialogPaper: {
    padding: '24px',
  },
  addNew: {
    padding: '0px',
    color: '#546E7A',
    backgroundColor: 'white !important',
    boxShadow: 'none !important',
    textTransform: 'none',
    fontWeight: '400',
  },
  dateChip: {
    backgroundColor: theme.palette.doesNotExist.light,
    color: theme.palette.doesNotExist.main,
  },
  dateDueChip: {
    backgroundColor: theme.palette.medium.light,
    color: theme.palette.medium.main,
  },
  pastDateAvatar: {
    backgroundColor: theme.palette.low.light,
    color: theme.palette.low.main,
    width: '26px',
    height: '26px',
    margin: '0 4px 0 4px',
  },
  dueDateAvatar: {
    backgroundColor: theme.palette.medium.light,
    color: theme.palette.medium.main,
    width: '26px',
    height: '26px',
    margin: '0 4px 0 4px',
  },
  datePastChip: {
    backgroundColor: theme.palette.low.light,
    color: theme.palette.low.main,
  },
  redArrow: {
    backgroundColor: theme.palette.low.light,
    color: theme.palette.low.main,
    borderRadius: '2px',
    width: '16px',
    height: '16px',
    marginLeft: '16px',
  },
  greenArrow: {
    backgroundColor: theme.palette.high.light,
    color: theme.palette.high.main,
    borderRadius: '2px',
    width: '16px',
    height: '16px',
    marginLeft: '16px',
  },
}));
