import React, {useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import styles from './customer.module.css';
import CustomerProbToRenew from './CustomerProbToRenew';
import { useStyles } from './customerDataTable.theme';
import HelpIcon from '@material-ui/icons/Help';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import {editableCell} from './DataTableUtils'
import DialogEditableValue from './DialogEditableValue';

const StyledTableCell = withStyles(theme => ({
  head: {
    color: '#546E7A',
  },
  body: {
    color: '#263238',
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: '20px',
    textAlign: 'left',
    border: 'none',
    padding: '8px',
  },
}))(TableCell);

const StyledTruncatedTableCell = withStyles(theme => ({
  head: {
    color: '#546E7A',
  },
  body: {
    color: '#263238',
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: '20px',
    textAlign: 'left',
    border: 'none',
    padding: '8px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#edeef2',
    },
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#FAFBFF',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#FFFFFF',
    },
  },
}))(TableRow);

const tableCell = (companyName, companyId,attrVal, aditionalValue, log,headCell, handleOpenEditable,setEditableColumn) => {
  switch (attrVal) {
    case 'Low':
      if (headCell.is_support_ticket) {
        return <StyledTableCell className={styles.high}>{editableCell(companyName, companyId,attrVal, aditionalValue, log,headCell, handleOpenEditable,setEditableColumn)}</StyledTableCell>;
      } else {
        return <StyledTableCell className={styles.low}>{editableCell(companyName, companyId,attrVal, aditionalValue, log,headCell, handleOpenEditable,setEditableColumn)}</StyledTableCell>;
      }
    case 'Medium':
      return <StyledTableCell className={styles.medium}>{editableCell(companyName, companyId,attrVal, aditionalValue, log,headCell, handleOpenEditable,setEditableColumn)}</StyledTableCell>;
    case 'High':
      if (headCell.is_support_ticket) {
        return <StyledTableCell className={styles.low}>{editableCell(companyName, companyId,attrVal, aditionalValue, log,headCell, handleOpenEditable,setEditableColumn)}</StyledTableCell>;
      } else {
        return <StyledTableCell className={styles.high}>{editableCell(companyName, companyId,attrVal, aditionalValue, log,headCell, handleOpenEditable,setEditableColumn)}</StyledTableCell>;
      }
    case 'Does not Exist':
      return <StyledTableCell className={styles.doesNotExist}>{attrVal}</StyledTableCell>;
    default:
      return;
  }
};

function TableCellWithTooltip(props) {
  const classes = useStyles();
  const customer = props.customer;

  const tooltipLabel = customer.hasOwnProperty(props.id) && customer[props.id] != null && customer[props.id] !== undefined ? customer[props.id].replaceAll(', ', '\n') : ' ';

  return (
    <>
      {customer.hasOwnProperty(props.id) && customer[props.id].length <= 36 && (
        <StyledTableCell>{customer[props.id]}</StyledTableCell>
      )}
      {customer.hasOwnProperty(props.id) && customer[props.id].length > 36 && (
        <StyledTruncatedTableCell>
          {' '}
          <Tooltip
            title={tooltipLabel}
            arrow
            placement="top"
            interactive
            classes={{ tooltip: classes.columnTruncatedTooltip, arrow: classes.columnTruncatedArrow }}
          >
            <span className={styles.tableCellTruncated}>{customer[props.id]}</span>
          </Tooltip>
        </StyledTruncatedTableCell>
      )}
    </>
  );
}

const parseValue = (columnValue, dataType) =>
{
  switch (dataType){
    case 'number':
      return columnValue != null && columnValue !== undefined ? columnValue.toLocaleString() : '';
    case 'currency':
      return columnValue != null && columnValue !== undefined ? columnValue.toLocaleString('en-US', { maximumFractionDigits: 10 }) : '';
    default:
      return columnValue;
  }
}

const renderColumn = (column, customer,handleOpenEditable,setEditableColumn) =>
{
  
  let columnValue = customer[column.id];
  let dataTypeColumn = column.data_type
    switch (column.style) {
      case 'styled':        
        return (<StyledTableCell>{parseValue(columnValue, dataTypeColumn)}</StyledTableCell>);
      case 'tooltip':
        return (<TableCellWithTooltip customer={customer} id={column.id}></TableCellWithTooltip>) ;
      case 'tablecell':
        return tableCell(customer["company_name"], customer["account_id"], columnValue, customer[column.id+'_value'],
        customer['logs'] !== undefined ? customer['logs'].filter(x=> x.field_id === column.id):[] , column,
                handleOpenEditable,setEditableColumn );
      default:
        return (<></>)
    }  
    
}

const DataTableSimpleView = props => {
  const classes = useStyles();  
  const [openEditable, setOpenEditable] = useState(false);
  const [editableColumn, setEditableColumn] = useState({});
  
  const handleOpenEditable = () => {
    setOpenEditable (true);
  }

  const updateData = (editableColumn) => {
    let customers = [...props.customerTableData];
    let index = customers.findIndex(x=> editableColumn.companyName === x.company_name);
    if (index > -1) {
      let customerToReplace = customers[index];
      customerToReplace[editableColumn.columnId + '_value'] = editableColumn.value;
      customerToReplace[editableColumn.columnId + '_value_category'] = editableColumn.normalized;
      customerToReplace[editableColumn.columnId] = editableColumn.normalized;
      customerToReplace['probability_to_renew'] = editableColumn.new_prediction;
     
      let indexLog = customerToReplace['logs'].findIndex(x=> editableColumn.log['field_id']=== x.field_id)
      if (indexLog > -1)
        customerToReplace['logs'][indexLog] = editableColumn.log;
      else 
        customerToReplace['logs'].push(editableColumn.log);
      customers[index] = customerToReplace;
      
      props.setCustomers(customers);
    }
  }
  return (
    <>
      <TableContainer id="dataSimTable" className={styles.dataSimTable}>
        <Table stickyHeader id={styles.simpleViewTable}>
          <TableHead>
            <TableRow id="dataSimTableHead" style={{ height: '60px' }}>
              <TableCell>
                {' '}
              </TableCell>
              {props.dataColumns.map(headCell => (
                <>
                  {
                    <TableCell
                      className={
                        headCell.id === 'product_name' ? clsx(classes.tableProductHead) : clsx(classes.tableHead)
                      }
                      id={headCell.id}
                      key={headCell.id}
                    >
                      <Box display="flex">
                        <span style={{ paddingTop: '8px' }}>
                          {' '}
                          {headCell.label}
                          {headCell.tooltipBody && (
                            <Tooltip
                              title={headCell.tooltipBody}
                              arrow
                              placement="top"
                              classes={{ tooltip: classes.headerTooltip }}
                            >
                              <HelpIcon className={classes.iconClasses} />
                            </Tooltip>
                          )}
                        </span>
                        <Box flexGrow="1" />
                        {headCell.label !== ' ' && (
                          <Box display="grid">
                            {props.orderBy === headCell.id && props.order === 'asc' ? (
                              <ArrowDropUpIcon
                                className={classes.cursorPointer}
                                onClick={props.createSortHandler(headCell.id, 'asc')}
                                color="primary"
                              />
                            ) : (
                              <ArrowDropUpIcon
                                className={classes.cursorPointer}
                                onClick={props.createSortHandler(headCell.id, 'asc')}
                              />
                            )}
                            {props.orderBy === headCell.id && props.order === 'desc' ? (
                              <ArrowDropDownIcon
                                className={classes.cursorPointer}
                                onClick={props.createSortHandler(headCell.id, 'desc')}
                                color="primary"
                              />
                            ) : (
                              <ArrowDropDownIcon
                                className={classes.cursorPointer}
                                onClick={props.createSortHandler(headCell.id, 'desc')}
                              />
                            )}
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                  }
                </>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.customerTableData.map(customer => (              
              <StyledTableRow key={customer._id + 'StyledTableRow'}>
                {customer.client_status === 'Churned' ? (
                  <StyledTableCell>
                    <Tooltip title="Churned" arrow placement="top">
                      <span className={classes.redCircle}></span>
                    </Tooltip>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell>
                    <Tooltip title={customer.client_status ? customer.client_status : ''} arrow placement="top">
                      <span className={classes.greenCircle}></span>
                    </Tooltip>
                  </StyledTableCell>
                )}
                
                {customer.hasOwnProperty('customer_id') &&
                  <StyledTableCell>{customer.customer_id}</StyledTableCell>
                }

                {customer.hasOwnProperty('customer360_link') ? (
                  <StyledTableCell style={{ maxWidth: '350px' }}>
                    <Tooltip title="Link to Customer 360 profile" arrow placement="top">
                      <a
                        underline="always"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={customer.customer360_link}
                        style={{ color: '#263238' }}
                      >
                        {customer.company_name}
                      </a>
                    </Tooltip>
                  </StyledTableCell>
                ) : (
                  props.dataColumns.findIndex(col => col.id === 'company_name') !== -1) ? <StyledTableCell style={{ maxWidth: '350px' }}>{customer.company_name}</StyledTableCell> : ''
                }

                {props.dataColumns.map(headCell => (
                  (headCell.id !== 'company_name' && headCell.id !== 'probability_to_renew' && headCell.id !== 'customer_id') && 
                    renderColumn(headCell, customer, handleOpenEditable,setEditableColumn )
                  )
                  // (renderColumn(headCell, customer))
                  // )}
                )}

                <StyledTableCell>
                  <CustomerProbToRenew customer={customer} />
                </StyledTableCell>
                <StyledTableCell style={{ width: '10px', padding: '0px' }}>
                  <IconButton
                    aria-label="delete"
                    className={classes.chevron}
                    id={customer._id}
                    onClick={() => props.showMoreInfo(customer)}
                  >
                    <ChevronRightIcon fontSize="inherit" />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DialogEditableValue 
            open={openEditable} 
            setOpenEditable={setOpenEditable} 
            editableColumn={editableColumn} 
            updateData = {updateData}
          >
        </DialogEditableValue>
    </>
  );
};

export default DataTableSimpleView;
