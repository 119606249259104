import React from 'react';
import { map } from 'lodash';
import {
    Box,
    Button,
    Typography,
    Dialog,
    DialogActions,
    TextField,
    FormControl,
    Divider,
    Checkbox,
    RadioGroup,
    FormControlLabel,
    Radio,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    DialogTitle, DialogContent, DialogContentText,
    makeStyles
} from '@material-ui/core/';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles({
    dialogPaper: {
        maxWidth: '496px',
        maxHeight: '858px',
    },
    root: {
        width: '100%',
        maxHeight: '160px',
        overflow: 'auto',
        border: 'border: 1px solid #C4C4C4;',
        paddingBottom: '0px',
        paddingTop: '0px'
    },
    checkElement: {
        paddingBottom: '0px',
        paddingTop: '0px'
    },
    label: {
        fontSize: '14px'
    },
    option: {
        fontSize: '14px',
        lineHeight: '20px'
    }
});

const ViewDialog = props => {
    const classes = useStyles();
    const handleChangeFirstView = event => {
        props.setFirstView(event.target.value);
    };

    const handleToggleSimple = (value) => () => {
        const currentIndex = props.selectedSimpleViewColumns.findIndex(x => x.id === value.id);
        const newChecked = [...props.selectedSimpleViewColumns];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        props.setSelectedSimpleViewColumns(newChecked);
    };

    const handleToggleDetailed = (value) => () => {
        const currentIndex = props.selectedDetailedViewColumns.findIndex(x => x.id === value.id);
        const newChecked = [...props.selectedDetailedViewColumns];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        props.setSelectedDetailedViewColumns(newChecked);
    };

    const descriptionElementRef = React.useRef(null);
    const dialogContentRef = React.useRef(null);

    React.useEffect(() => {
        if (props.errorMessage !== '') {
            const { current: dialogContent } = dialogContentRef;
            dialogContent.scrollTop = 0;
        }
        if (props.openDialog) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [props.openDialog, props.errorMessage]);


    return (
        <Dialog open={props.openDialog} onClose={props.closeDialogFun} classes={{ paper: classes.dialogPaper }}>

            <DialogTitle id="scroll-dialog-title">
                <React.Fragment>
                    {props.newView ? (
                        <Typography variant="h4" color="textPrimary">Save as new View</Typography>
                    ) : (
                        <Typography variant="h4" color="textPrimary">Edit View</Typography>
                    )}
                    <Box pt={1}>
                        {props.errorMessage !== '' ? (
                            <Alert severity="error" style={{ maxWidth: '400px' }} p={1}>
                                {' '}
                                {typeof props.errorMessage === 'string'
                                    ? props.errorMessage
                                    : Object.keys(props.errorMessage).map((key, index) => {
                                        return <li>{props.errorMessage[key]}</li>;
                                    })}
                            </Alert>
                        ) : null}
                        <TextField
                            fullWidth
                            label="View Name *"
                            margin="normal"
                            name="name"
                            type="text"
                            variant="outlined"
                            style={{ maxWidth: '432px' }}
                            value={props.viewName}
                            onChange={e => props.setViewName(e.target.value)}
                        />
                        <Box>
                        <FormControlLabel
                            name="includeFilters"
                            label="Save current filters for this view"
                            labelPlacement="end"
                            classes={{ label: classes.option }}
                            control={<Checkbox className={classes.label} color="primary" onChange={e => props.setIncludeFilters(e.target.checked)} checked={props.includeFilters} />}
                        />
                        </Box>
                        <FormControlLabel
                            name="default"
                            label="Set this view as default"
                            labelPlacement="end"
                            classes={{ label: classes.option }}
                            control={<Checkbox className={classes.label} color="primary" onChange={e => props.setDefaultView(e.target.checked)} checked={props.defatultView} />}
                        />
                    </Box>
                </React.Fragment>
            </DialogTitle>
            <DialogContent dividers={true} ref={dialogContentRef}>
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}>
                    <Typography variant="h5" color="textPrimary">Customize Your View</Typography>
                    <Typography variant="subtitle2" color="textSecondary">Select which view would you like to see first</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="report-template"
                            name="report-template-type"
                            value={props.firstView}
                            onChange={handleChangeFirstView}
                        >
                            <FormControlLabel value="simple" classes={{ label: classes.option }} control={<Radio color="primary" />} label="Simple View" />
                            <FormControlLabel value="detailed" classes={{ label: classes.option }} control={<Radio color="primary" />} label="Detailed View" />
                        </RadioGroup>
                    </FormControl>
                    <Divider></Divider>
                    <Typography style={{ marginTop: '16px' }} variant="subtitle2" color="textSecondary">Uncheck columns if you want to hide them.</Typography>
                    <Typography variant="subtitle2" color="textPrimary">Simple View</Typography>
                    <Box mt={1} mb={1} borderColor="#C4C4C4" border={1} style={{ paddingTop: '0px', paddingBottom: '0px', width: '432px' }}>
                        <List className={classes.root}>
                            {map(props.simpleViewColumns, column => (
                                <ListItem className={classes.checkElement} key={column.id} button onClick={handleToggleSimple(column)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            color="primary"
                                            edge="start"
                                            checked={props.selectedSimpleViewColumns.findIndex(col => col.id === column.id) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': `checkbox-${column.id}` }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={column.id} primary={column.label} />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                    <Typography variant="subtitle2" color="textPrimary">Detailed View</Typography>
                    <Box mt={1} mb={1} borderColor="#C4C4C4" border={1}>
                        <List className={classes.root}>
                            {map(props.detailedViewColumns, column => (
                                <ListItem className={classes.checkElement} key={column.id} button onClick={handleToggleDetailed(column)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            className={classes.label}
                                            color="primary"
                                            edge="start"
                                            checked={props.selectedDetailedViewColumns.findIndex(col => col.id === column.id) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': `checkbox-${column.id}` }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={column.id} primary={column.label} />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ padding: '16px' }}>
                <Button onClick={props.closeDialogFun} variant="contained" size="large">
                    Cancel
                </Button>
                <Box flexGrow="1" />
                <Button onClick={props.handleSave} color="primary" type="submit" variant="contained" size="large">
                    Save
                </Button>
            </DialogActions>

        </Dialog>
    );
}

export default ViewDialog;
